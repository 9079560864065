import { makeStyles } from "@material-ui/styles";
import React, { FC, useContext, useEffect, useState } from "react";
import API from "../_shared/axios";
import {
  alertError,
  alertSuccess,
  getErrorMsg,
  isObjectsEqual,
  renderDate,
} from "../_shared/utils";
import { AlertContextType } from "../_shared/types";
import { AlertContext } from "./_shared/ToastList";
import { BlurType, weekDays } from "./ShopsPage/_shared/types";
import TimeTable from "./ShopsPage/_shared/TimeTable";
import { WorkingTime } from "./_shared/types";
import Button from "@material-ui/core/Button";
const useStyles = makeStyles({
  page: {
    padding: 50,
  },
  content: {
    boxShadow:
      "0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12)",
    backgroundColor: "white",
    padding: "20px 50px 50px",
    maxWidth: 1140,
    width: "100%",
    borderRadius: 10,
    margin: "30px auto",
    "& button": {
      marginLeft: 15,
    },
  },
  title: {
    fontWeight: "bold",
    paddingLeft: 15,
    marginBottom: 10,
    fontSize: 16,
  },
});

const tableBlur: BlurType = weekDays.reduce(
  (base, { eng: day }) => ({ ...base, [day]: false }),
  {} as BlurType
);

const tableTT: WorkingTime = weekDays.reduce(
  (base, { eng: day }) => ({
    ...base,
    [day]: {
      from: new Date(NaN),
      till: new Date(NaN),
    },
  }),
  {} as WorkingTime
);

type actionSettingsEl = {
  currentDay: boolean;
  description: string;
  id: number;
  day: string;
  timeFrom: string;
  timeTill: string;
};
type promotionSchedule = {
  actionSettings: actionSettingsEl[];
};

const getFormattedDate = (data: actionSettingsEl[], name: string) => {
  const day = data.find(
    (el) => el.description.toLowerCase() === name.toLowerCase()
  );
  if (!day)
    return {
      from: new Date(),
      till: new Date(),
    };
  const getSliceTimeFrom = day.timeFrom.slice(0, 5);
  const getSliceTimeTill = day.timeTill.slice(0, 5);
  const fromDateString = `2020-01-01-T-${getSliceTimeFrom}`;
  const tillDateString = `2020-01-01-T-${getSliceTimeTill}`;
  const f = fromDateString.replace(/[ :]/g, "-").split("-");
  const t = tillDateString.replace(/[ :]/g, "-").split("-");
  return {
    from: new Date(+f[0], +f[1] - 1, +f[2], +f[4], +f[5], 0),
    till: new Date(+t[0], +t[1] - 1, +t[2], +t[4], +t[5], 0),
  };
};

const getNewData = (data: actionSettingsEl[]) => {
  return {
    mon: getFormattedDate(data, "Понедельник"),
    tue: getFormattedDate(data, "Вторник"),
    wed: getFormattedDate(data, "Среда"),
    thu: getFormattedDate(data, "Четверг"),
    fri: getFormattedDate(data, "Пятница"),
    sat: getFormattedDate(data, "Суббота"),
    sun: getFormattedDate(data, "Воскресенье"),
  };
};

const PromotionSchedulePage: FC = () => {
  const classes = useStyles();
  const alertContext = useContext<AlertContextType>(AlertContext);
  const [pickupTT, setPickupTT] = useState(tableTT);
  const [oldPickupTT, setOldPickupTT] = useState(tableTT);
  const [actionSettings, setActionSettings] = useState<actionSettingsEl[]>([]);
  const [isTTError, setIsTTError] = useState(false);
  const [currentDayArray, setCurrentDayArray] = useState<string[]>([]);
  const [oldCurrentDayArray, setOldCurrentDayArray] = useState<string[]>([]);

  useEffect(() => {
    API.get("/promotionSchedule")
      .then(({ data }: { data: promotionSchedule }) => {
        const sortedData = data.actionSettings.sort((a, b) => b.id - a.id);
        setActionSettings(data.actionSettings);
        const newData = getNewData(sortedData);
        const newCurrentDayArray = data.actionSettings
          .filter((el) => el.currentDay)
          .map((el) => el.day);
        setCurrentDayArray(newCurrentDayArray);
        setOldCurrentDayArray(newCurrentDayArray);
        setPickupTT(newData);
        setOldPickupTT(newData);
      })
      .catch((error) => {
        alertError(
          alertContext,
          getErrorMsg(error.response, "Ошибка получения графика работы акций")
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [blur, setBlur] = useState({
    schedule: tableBlur,
  });

  const getFormattedPickupTT = () => {
    let data: actionSettingsEl[] | [] = [];
    for (let day in pickupTT) {
      let dayRu = "";
      switch (day) {
        case "mon":
          dayRu = "Понедельник";
          break;
        case "tue":
          dayRu = "Вторник";
          break;
        case "wed":
          dayRu = "Среда";
          break;
        case "thu":
          dayRu = "Четверг";
          break;
        case "fri":
          dayRu = "Пятница";
          break;
        case "sat":
          dayRu = "Суббота";
          break;
        case "sun":
          dayRu = "Воскресенье";
          break;
      }

      const newData: actionSettingsEl = {
        id: actionSettings.filter((el) => el.description === dayRu)[0].id,
        description: dayRu,
        day,
        currentDay: !!currentDayArray.find((el) => el === day),
        // @ts-ignore
        timeFrom: renderDate(pickupTT[day].from, true),
        // @ts-ignore
        timeTill: renderDate(pickupTT[day].till, true),
      };
      data = [...data, newData];
    }
    return data;
  };

  return (
    <div className={classes.page}>
      <div className={classes.content}>
        <br />
        <div className={classes.title}>График работы акций</div>
        <br />
        <TimeTable
          timetable={pickupTT}
          setTimeTable={setPickupTT}
          blur={blur.schedule}
          setBlur={(ttblur) => setBlur({ ...blur, schedule: ttblur })}
          withCurrentDay
          currentDayArray={currentDayArray}
          setIsError={setIsTTError}
          setCurrentDayArray={setCurrentDayArray}
        />
        <br />
        <Button
          type="submit"
          variant={"outlined"}
          color={"primary"}
          onClick={() => {
            const actionSettings = {
              ActionSettings: getFormattedPickupTT(),
            };
            API.patch("/promotionSchedule", actionSettings)
              .then(() => {
                setOldCurrentDayArray(currentDayArray);
                setOldPickupTT(pickupTT);
                alertSuccess(alertContext, "График акций успешно сохранен");
              })
              .catch((error) =>
                alertError(
                  alertContext,
                  getErrorMsg(error.response, "Ошибка сохранения графика акций")
                )
              );
          }}
          disabled={
            isTTError ||
            (isObjectsEqual(
              oldCurrentDayArray.sort((a: any, b: any) =>
                a > b ? 1 : b > a ? -1 : 0
              ),
              currentDayArray.sort((a: any, b: any) =>
                a > b ? 1 : b > a ? -1 : 0
              )
            ) &&
              isObjectsEqual(oldPickupTT, pickupTT))
          }
        >
          Сохранить
        </Button>
      </div>
    </div>
  );
};

export default PromotionSchedulePage;
