import React, { FC, useState, useEffect, useContext } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Switch,
  Collapse,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogContentText,
} from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";
import { ProgressBar } from "../_shared/ProgressBar";
import {
  AlertContextType,
  maxSafeNumber,
  StreetSearchResponse,
} from "../../_shared/types";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import API from "../../_shared/axios";
import { alertError, copyObject, isObjectsEqual } from "../../_shared/utils";
import { AlertContext } from "../_shared/ToastList";
import TimeTable from "./_shared/TimeTable";
import { PaymentGateway, BlurType, weekDays } from "./_shared/types";
import { Shop, WorkingTime } from "../_shared/types";
import styles from "./_shared/styles";
import { getTTStatus } from "./_shared/utils";
import moment from "moment";
import {
  KladrObject,
  KladrObjectRibbon,
  KladrObjectType,
} from "../StreetsPage/_shared/types";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import LabelRibbon from "../_shared/LabelRibbon";
import AlertDialogSlide from "./_shared/AlertDialog";
import Transition from "../_shared/Transition";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      scrollbarWidth: "none",
      "& ::-webkit-scrollbar": {
        display: "none",
      },
    },
    streetField: { marginBottom: 3 },
    page: {
      width: "100%",
      flexDirection: "column",
      "& > div": {
        "& .MuiAccordionSummary-content": {
          margin: "0 !important",
        },
        "& .MuiAccordionDetails-root": {
          padding: "0 0 0 20px !important",
          "&:last-child": {
            paddingBottom: "10px !important",
          },
        },
      },
    },
    pl15: { paddingLeft: 15 },
    variantsWrapper: { width: "60%", display: "flex", alignItems: "center" },
    variants: {
      marginTop: 0,
      display: "flex",
      flexWrap: "wrap",
    },
    normal: {
      "& input": {
        transition: "color 150ms ease-in-out",
      },
      "& > p": {
        position: "absolute",
        top: "100%",
      },
    },
    transparent: {
      "& input": {
        color: "transparent",
      },
      "& > p": {
        position: "absolute",
        top: "100%",
      },
    },
  })
);

const tableBlur: BlurType = weekDays.reduce(
  (base, { eng: day }) => ({ ...base, [day]: false }),
  {} as BlurType
);

const tableTT: WorkingTime = weekDays.reduce(
  (base, { eng: day }) => ({
    ...base,
    [day]: {
      from: new Date(NaN),
      till: new Date(NaN),
    },
  }),
  {} as WorkingTime
);

const parseDataTT = (data: any) =>
  weekDays.reduce(
    (base, { eng: day, num }) => ({
      ...base,
      [day]: {
        from: moment(data[num].workingTime[0].timeFrom)
          // .add(new Date().getTimezoneOffset() * -1, 'm')
          .toDate(),
        till: moment(data[num].workingTime[0].timeTill)
          // .add(new Date().getTimezoneOffset() * -1, 'm')
          .toDate(),
      },
    }),
    {} as WorkingTime
  );

const EditShopForm: FC<{
  onSend: (id: number, data: Shop) => Promise<any>;
  data?: Shop;
  onClose: () => void;
}> = ({ onSend, data: dataProp, onClose }) => {
  const [data, setData] = useState<any | undefined>();
  const [alerted, setAlerted] = useState(false);
  const [blur, setBlur] = useState({
    name: false,
    house: false,
    street: false,
    workingTime: false,
    pickup: tableBlur,
    delivery: tableBlur,
    position: false,
  });
  const [progress, setProgress] = useState(false);

  const alertContext = useContext<AlertContextType>(AlertContext);

  const [confirmWindowOpen, setConfirmWindowOpen] = useState(false);
  const [currentKey, setCurrentKey] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [oldDistricts, setOldDistricts] = useState<KladrObjectRibbon[]>([]);
  const [selectType, setSelectType] = useState<"district" | "street">("street");

  const [position, setPosition] = useState(0);
  const [orderLimit, setOrderLimit] = useState(0);
  const [oldOrderLimit, setOldOrderLimit] = useState(0);
  const [isLocalPickup, setIsLocalPickup] = useState(true);
  const [oldIsLocalPickup, setOldIsLocalPickup] = useState(true);
  const [isDelivered, setIsDelivered] = useState(true);
  const [oldIsDelivered, setOldIsDelivered] = useState(true);
  const [paymentGatewayId, setPaymentGatewayId] = useState("0");
  const [paymentGateways, setPaymentGateways] = useState<PaymentGateway[]>([]);
  const [roundTheClock, setRoundTheClock] = useState({
    delivery: false,
    pickup: false,
  });
  const [oldRoundTheClock, setOldRoundTheClock] = useState({
    delivery: false,
    pickup: false,
  });
  const [name, setName] = useState("");
  const [street, setStreet] = useState<{
    name: string;
    kladrObjectTypeId: number | undefined;
  }>({ name: "", kladrObjectTypeId: undefined });
  const [house, setHouse] = useState("");
  const [searchList, setSearchList] = useState<StreetSearchResponse[]>([]);
  const [kladrObjectTypes, setKladrObjectTypes] = useState<KladrObjectType[]>(
    []
  );
  const [dataPickupTT, setDataPickupTT] = useState<any>(undefined);
  const [dataDeliveryTT, setDataDeliveryTT] = useState<any>(undefined);
  const [pickupTT, setPickupTT] = useState(tableTT);
  const [oldPickupTT, setOldPickupTT] = useState(tableTT);
  const [deliveryTT, setDeliveryTT] = useState(tableTT);
  const [oldDeliveryTT, setOldDeliveryTT] = useState(tableTT);
  const [pickupFromDelivery, setLocalFromDelivery] = useState(false);
  const [oldPickupFromDelivery, setOldLocalFromDelivery] = useState(false);
  const [deliveryFromPickup, setDeliveryFromLocal] = useState(false);
  const [oldDeliveryFromPickup, setOldDeliveryFromLocal] = useState(false);
  const [districts, setDistricts] = useState<KladrObjectRibbon[]>([]);
  const [shopStreetsList, setShopStreetsList] = useState<number[]>([]);
  const [shopType, setShopType] = useState<"shopId" | "aroundTheClockShopId">(
    "shopId"
  );
  const [isStreetsAlertOpen, setIsStreetsAlertOpen] = useState(false);
  const [timeTableError, setTimeTableError] = useState(false);
  const [streetsTouched, setStreetsTouched] = useState(false);
  const [streetAlert, setStreetsAlert] = useState(false);

  const commonClasses = styles();
  const classes = useStyles();

  useEffect(() => {
    if (
      (oldRoundTheClock.delivery || oldRoundTheClock.pickup) &&
      !(deliveryFromPickup ? roundTheClock.pickup : roundTheClock.delivery) &&
      !(pickupFromDelivery ? roundTheClock.delivery : roundTheClock.pickup)
    )
      setIsStreetsAlertOpen(true);
  }, [roundTheClock, oldRoundTheClock, deliveryFromPickup, pickupFromDelivery]);

  useEffect(() => {
    if (typeof dataProp !== "undefined") {
      setDistricts([]);
      setOldDistricts([]);
      setShopStreetsList([]);
      setPaymentGateways([]);
      setAlerted(false);
      setProgress(true);
      setBlur({
        name: false,
        house: false,
        street: false,
        workingTime: false,
        pickup: tableBlur,
        delivery: tableBlur,
        position: false,
      });
      setLocalFromDelivery(false);
      setOldLocalFromDelivery(false);
      setDeliveryFromLocal(false);
      setOldDeliveryFromLocal(false);
      setOrderLimit(0);
      setOldOrderLimit(0);

      API.get(`/kladrObjects/1/children?level=2`)
        .then(({ data }: { data: KladrObject[] }) => {
          const kladrsData = data;
          const formattedData = copyObject(data).map((el: KladrObject) => {
            return {
              ...el,
              isChecked: false,
              isSelected: false,
              isActive: true,
              childrenList: el.childrenList.map((child) => {
                return { ...child, isSelected: false };
              }),
            };
          });
          API.get(`/kladrObjects/types`)
            .then(({ data }: { data: KladrObjectType[] }) => {
              const types = data;
              setKladrObjectTypes(data);
              let districtTypeId =
                types.find((el) => el.nameRu.toLowerCase() === "район")?.id ||
                2;
              let districtsData = formattedData.filter(
                (el: KladrObjectRibbon) =>
                  el.kladrObjectTypeId === districtTypeId &&
                  el.childrenList.length
              );
              if (districtsData.length !== kladrsData.length) {
                let otherStreetsDistrict = { ...districtsData[0] };
                otherStreetsDistrict.name = "Улицы без района";
                otherStreetsDistrict.id = Date.now();
                otherStreetsDistrict.childrenList = formattedData.filter(
                  (el: KladrObjectRibbon) =>
                    el.kladrObjectTypeId !== districtTypeId
                );
                districtsData = [...districtsData, otherStreetsDistrict];
              }
              setDistricts((districts) => districtsData);
            })
            .catch((error) => {
              alertError(
                alertContext,
                "Ошибка получения списка типов кладр-объектов"
              );
            });
        })
        .catch((error) => {
          alertError(alertContext, "Ошибка получения списка районов и улиц");
        });

      API.get(`/shops/${dataProp.id}`)
        .then(({ data: shop }) => {
          API.get("payments/gateways")
            .then(({ data: gateways }) => {
              setData(shop);
              setPaymentGateways(gateways);
              const isAroundTheClockShop =
                shop.isAroundTheClockDelivery || shop.isAroundTheClockPickup;
              const shopType = isAroundTheClockShop
                ? "aroundTheClockShopId"
                : "shopId";
              setShopType(shopType);
              API.get(`/kladrObjects?$filter=${shopType} eq ${dataProp.id}`)
                .then(({ data }: { data: KladrObject[] }) => {
                  let streetsList: number[] = [];
                  for (let obj of data) {
                    streetsList = [...streetsList, obj.id];
                  }
                  setShopStreetsList(streetsList);
                })
                .catch((error) => {
                  alertError(
                    alertContext,
                    "Ошибка получения списка районов и улиц"
                  );
                });
            })
            .catch((error) =>
              alertError(
                alertContext,
                "Ошибка получения списка платежных шлюзов"
              )
            );
        })
        .catch((error) =>
          alertError(alertContext, "Ошибка получения данных на точку продаж")
        )
        .finally(() => setProgress(false));
    } else {
      setData(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProp]);

  useEffect(() => {
    setDistricts((districts) => oldDistricts);
  }, [oldDistricts]);

  useEffect(() => {
    if (districts.length) {
      if (shopStreetsList.length) {
        const newDistricts = copyObject(districts);
        for (let i = 0; i < districts.length; i++) {
          for (let j = 0; j < districts[i].childrenList.length; j++) {
            if (shopStreetsList.includes(districts[i].childrenList[j].id)) {
              newDistricts[i].childrenList[j].isSelected = true;
              newDistricts[i].isChecked = true;
              newDistricts[i].isSelected = !!newDistricts[i].childrenList.every(
                (el: KladrObjectRibbon) => el.isSelected
              );
            }
          }
        }
        setShopStreetsList([]);
        setOldDistricts(newDistricts);
      } else {
        if (oldDistricts.length === 0) setOldDistricts(districts);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [districts, shopStreetsList]);

  useEffect(() => {
    if (typeof data !== "undefined") {
      const deliveryWorking = data.isAroundTheClockDelivery
        ? tableTT
        : parseDataTT(data.deliveryWorking.workingDays);
      const pickupWorking = data.isAroundTheClockPickup
        ? tableTT
        : parseDataTT(data.pickupWorking.workingDays);

      setDataDeliveryTT(deliveryWorking);
      setDataPickupTT(pickupWorking);
      setPaymentGatewayId(
        typeof data.paymentGateway?.id !== "undefined" &&
          data.paymentGateway?.id !== null
          ? String(data.paymentGateway?.id)
          : "0"
      );
      setName(data.name);
      setHouse(data.house);
      setStreet({
        name: data.kladrObjectName,
        kladrObjectTypeId: data.kladrObjectTypeId,
      });
      setPosition(typeof data.position !== "undefined" ? data.position : 0);
      setIsLocalPickup(data.isLocalPickup);
      setOldIsLocalPickup(data.isLocalPickup);
      setIsDelivered(data.isDelivered);
      setOrderLimit(data.orderLimit ? data.orderLimit : 0);
      setOldOrderLimit(data.orderLimit ? data.orderLimit : 0);
      setOldIsDelivered(data.isDelivered);
      setRoundTheClock({
        delivery: data.isAroundTheClockDelivery,
        pickup: data.isAroundTheClockPickup,
      });
      setOldRoundTheClock({
        delivery: data.isAroundTheClockDelivery,
        pickup: data.isAroundTheClockPickup,
      });
      setDeliveryTT(deliveryWorking);
      setOldDeliveryTT(deliveryWorking);
      setPickupTT(pickupWorking);
      setOldPickupTT(pickupWorking);
    }
  }, [data]);

  const onSubmit = () => {
    if (streetsTouched && !streetAlert) {
      return setStreetsAlert(true);
    }
    if (data && data.id) {
      setProgress(true);
      const streetsToBind = getStreetsToBind();
      const districtsList =
        streetsToBind.districtsArr.length > 0
          ? streetsToBind.districtsArr
          : undefined;
      const streetsList =
        streetsToBind.streetsArr.length > 0
          ? streetsToBind.streetsArr
          : undefined;
      onSend(data.id, {
        name,
        house,
        street: street.name,
        position: position === 0 ? undefined : position,
        isDelivered,
        isLocalPickup,
        orderLimit: orderLimit,
        paymentGatewayId:
          paymentGatewayId === "0" ? null : Number(paymentGatewayId),
        isAroundTheClockDelivery: deliveryFromPickup
          ? roundTheClock.pickup
          : roundTheClock.delivery,
        isAroundTheClockPickup: pickupFromDelivery
          ? roundTheClock.delivery
          : roundTheClock.pickup,
        deliveryWorking: deliveryFromPickup ? pickupTT : deliveryTT,
        pickupWorking: pickupFromDelivery ? deliveryTT : pickupTT,
        districtsList,
        streetsList,
      })
        .then((data) => {
          setOldDistricts([]);
          setShopStreetsList([]);
          onClose();
        })
        .catch((error) => console.log(error))
        .finally(() => {
          setProgress(false);
          setStreetsAlert(false);
        });
    }
  };

  const { ttComplete: pickupTTComplete } =
    typeof pickupTT !== "undefined"
      ? getTTStatus(pickupTT, roundTheClock.pickup)
      : { ttComplete: false };

  const { ttComplete: deliveryTTComplete } =
    typeof deliveryTT !== "undefined"
      ? getTTStatus(deliveryTT, roundTheClock.delivery)
      : { ttComplete: false };

  // const compareTT = (a: WorkingTime, b: WorkingTime) =>
  //   Object.keys(pickupTT)
  //     .map(
  //       (k) =>
  //         a[k as keyof WorkingTime].from.getTime() ===
  //           b[k as keyof WorkingTime].from.getTime() &&
  //         a[k as keyof WorkingTime].till.getTime() ===
  //           b[k as keyof WorkingTime].till.getTime()
  //     )
  //     .filter((i) => !i).length === 0;

  const safeToExit = () => {
    return typeof data !== "undefined" &&
      typeof dataPickupTT !== "undefined" &&
      typeof dataDeliveryTT !== "undefined"
      ? // compareTT(pickupTT, dataPickupTT) &&
        // compareTT(deliveryTT, dataDeliveryTT) &&
        (oldDistricts.length > 0
          ? isObjectsEqual(oldDistricts, districts)
          : true) &&
          name === data.name &&
          street.name === data.kladrObjectName &&
          house === data.house &&
          position === data.position &&
          paymentGatewayId ===
            (data.paymentGateway?.id ? String(data.paymentGateway?.id) : "0")
      : true;
  };

  const pickupTTReady = roundTheClock.pickup ? true : pickupTTComplete;
  const deliveryTTReady = roundTheClock.delivery ? true : deliveryTTComplete;

  const readyToSubmit =
    street.name !== "" &&
    house !== "" &&
    name !== "" &&
    position !== 0 &&
    (isLocalPickup
      ? pickupFromDelivery
        ? deliveryTTReady
        : pickupTTReady
      : true) &&
    (isDelivered
      ? deliveryFromPickup
        ? pickupTTReady
        : deliveryTTReady
      : true);

  const getStreetsToBind = () => {
    let districtsArr: number[] = [];
    let streetsArr: number[] = [];
    const checkedDistricts = districts.filter((el) => el.isChecked);
    for (let district of checkedDistricts) {
      if (district.isSelected && district.name !== "Улицы без района")
        districtsArr = [...districtsArr, district.id];
      else {
        for (let street of district.childrenList) {
          if (street.isSelected) streetsArr = [...streetsArr, street.id];
        }
      }
    }
    return { districtsArr, streetsArr };
  };

  const selectDistrict = (keyProp?: number) => {
    let newDistricts = copyObject(districts);
    const key = keyProp !== undefined ? keyProp : currentKey;
    newDistricts[key].isSelected = !newDistricts[key].isSelected;
    newDistricts[key].childrenList = newDistricts[key].childrenList.map(
      (el: KladrObjectRibbon) => {
        return {
          ...el,
          isSelected: newDistricts[key].isSelected,
        };
      }
    );
    setDistricts((districts) => newDistricts);
  };

  function getOptionLabel(option: StreetSearchResponse) {
    const typePart = option.kladrObjectTypeId
      ? `${
          kladrObjectTypes.find((el) => el.id === option.kladrObjectTypeId)
            ?.nameRu
        } `
      : "";
    const namePart = option.fullName ? option.fullName : option.name!;
    return `${typePart}${namePart}`;
  }

  const selectStreet = (keyProp?: number, indexProp?: number) => {
    let newDistricts = copyObject(districts);
    const key = keyProp !== undefined ? keyProp : currentKey;
    const index = indexProp !== undefined ? indexProp : currentIndex;
    newDistricts[key].childrenList[index].isSelected = !newDistricts[key]
      .childrenList[index].isSelected;
    newDistricts[key].isSelected = !!newDistricts[key].childrenList.every(
      (el: KladrObjectRibbon) => el.isSelected
    );
    setDistricts((districts) => newDistricts);
  };

  useEffect(() => {
    if (
      !isObjectsEqual(oldDistricts, districts) &&
      !!oldDistricts.length &&
      !!districts.length
    )
      setStreetsTouched(true);
  }, [districts]);

  return (
    <Dialog
      disableBackdropClick
      onBackdropClick={() =>
        progress
          ? null
          : safeToExit() &&
            isObjectsEqual(oldDeliveryFromPickup, deliveryFromPickup) &&
            isObjectsEqual(oldPickupTT, pickupTT) &&
            isObjectsEqual(oldDeliveryTT, deliveryTT) &&
            isObjectsEqual(oldPickupFromDelivery, pickupFromDelivery) &&
            isObjectsEqual(oldIsDelivered, isDelivered) &&
            isObjectsEqual(oldIsLocalPickup, isLocalPickup) &&
            isObjectsEqual(oldRoundTheClock, roundTheClock)
          ? onClose()
          : setAlerted(true)
      }
      open={!!data}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        Редактирование точки продаж
      </DialogTitle>
      <DialogContent className={classes.list}>
        <Dialog
          open={confirmWindowOpen}
          // @ts-ignore
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setConfirmWindowOpen(false)}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{"Внимание"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Данный объект уже привязан к другой точке, хотите привязать к его
              к текущей точке?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmWindowOpen(false)} color="primary">
              Отмена
            </Button>
            <Button
              onClick={() => {
                selectType === "street" ? selectStreet() : selectDistrict();
                setConfirmWindowOpen(false);
              }}
              color="primary"
            >
              Подтвердить
            </Button>
          </DialogActions>
        </Dialog>
        <AlertDialogSlide
          text={
            'Для всех улиц из списка "районы и улицы" данная точка будет использоваться как "обычная"'
          }
          open={isStreetsAlertOpen}
          setOpen={setIsStreetsAlertOpen}
        />
        <TextField
          margin="dense"
          id="address"
          onChange={(e) => setName(e.target.value)}
          label="Наименование"
          inputProps={{ maxLength: 100 }}
          value={name}
          type="text"
          fullWidth
          onBlur={() => setBlur({ ...blur, name: true })}
          error={!name && blur.name}
          helperText={!name && blur.name ? "Обязательное поле" : ""}
          required
        />
        <Autocomplete
          id="combo-box-demo"
          fullWidth
          onChange={(
            event,
            newValue: StreetSearchResponse | null | undefined | string
          ) => {
            if (newValue && typeof newValue !== "string") {
              setStreet({
                name: newValue.name!,
                kladrObjectTypeId: newValue.kladrObjectTypeId!,
              });
            }
          }}
          clearOnBlur
          onBlur={() => {
            setBlur({ ...blur, street: true });
            setSearchList([]);
          }}
          freeSolo
          options={searchList}
          defaultValue={{ name: street.name }}
          getOptionLabel={(option: StreetSearchResponse) =>
            getOptionLabel(option)
          }
          renderInput={(params) => (
            <TextField
              {...params}
              required
              className={classes.streetField}
              error={!street && blur.street}
              helperText={!street && blur.street ? "Обязательное поле" : ""}
              onChange={(e) => {
                if (e.target.value.length > 2) {
                  const url = encodeURI(
                    `kladrObjectsByLevel?level=10&name=${e.target.value}`
                  );
                  API.get(url)
                    .then(({ data }) => {
                      setSearchList(data);
                    })
                    .catch((error) =>
                      alertError(alertContext, "Ошибка поиска улицы")
                    );
                } else {
                  setSearchList([]);
                }
              }}
              label="Улица"
            />
          )}
        />
        <TextField
          label="Дом"
          type="text"
          fullWidth
          required
          error={!house && blur.house}
          helperText={!house && blur.house ? "Обязательное поле" : ""}
          inputProps={{ maxLength: 10 }}
          onBlur={() => setBlur({ ...blur, house: true })}
          value={house}
          onChange={(e) => {
            let newValue = e.target.value;
            if (newValue.slice(0, 1) === "0") newValue = newValue.slice(1);
            setHouse(newValue);
          }}
        />
        <TextField
          margin="dense"
          id="position"
          label="Позиция"
          value={position > 0 ? position : ""}
          onChange={(e) =>
            e.target.value !== ""
              ? !isNaN(Number(e.target.value)) &&
                Number(e.target.value) > 0 &&
                Number(e.target.value) < maxSafeNumber &&
                setPosition(Number(e.target.value))
              : setPosition(0)
          }
          type="text"
          fullWidth
          required
          error={!position && blur.position}
          helperText={!position && blur.position ? "Обязательное поле" : ""}
          onBlur={() => setBlur({ ...blur, position: true })}
        />
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Платежный шлюз</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={paymentGatewayId}
            onChange={(e) => setPaymentGatewayId(e.target.value as string)}
            disabled={paymentGateways.length === 0}
          >
            <MenuItem key={0} value={"0"}>
              Без онлайна
            </MenuItem>
            {paymentGateways.map((g, key) => (
              <MenuItem key={key + 1} value={String(g.id)}>
                {g.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          margin="dense"
          id="position"
          label="Лимит заказов"
          value={orderLimit > 0 ? orderLimit : ""}
          onChange={(e) =>
            e.target.value !== ""
              ? !isNaN(Number(e.target.value)) &&
                Number(e.target.value) > 0 &&
                Number(e.target.value) < maxSafeNumber &&
                setOrderLimit(Number(e.target.value))
              : setOrderLimit(0)
          }
          type="text"
          fullWidth
        />
        <List>
          {/*<Typography*/}
          {/*  variant="subtitle1"*/}
          {/*  style={{*/}
          {/*    marginTop: 'calc(8px + 1rem * 0.75)',*/}
          {/*    color: 'rgba(0, 0, 0, 0.54)',*/}
          {/*    fontSize: '12px',*/}
          {/*  }}*/}
          {/*>*/}
          {/*  Способы реализации (выберите хотя бы один из способов и заполните*/}
          {/*  режим работы) **/}
          {/*</Typography>*/}
          <br />
          <ListItem disableGutters>
            <ListItemText id="isLocalPickup" primary="Самовывоз" />
            <ListItemSecondaryAction>
              <Switch
                edge="end"
                onChange={() => {
                  setIsLocalPickup(!isLocalPickup);
                  setDeliveryFromLocal(false);
                }}
                checked={isLocalPickup}
                inputProps={{
                  "aria-labelledby": "switch-list-label-bluetooth",
                }}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <Collapse in={isLocalPickup} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        !pickupFromDelivery
                          ? roundTheClock.pickup
                          : roundTheClock.delivery
                      }
                      onChange={() => {
                        if (roundTheClock.pickup) setDeliveryFromLocal(false);
                        setRoundTheClock({
                          ...roundTheClock,
                          pickup: !roundTheClock.pickup,
                        });
                      }}
                      name="rtcLocal"
                      color="primary"
                      disabled={pickupFromDelivery}
                    />
                  }
                  label="Круглосуточно"
                />
              </ListItem>
              {deliveryTTComplete && isDelivered && !deliveryFromPickup && (
                <ListItem>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={pickupFromDelivery}
                        onChange={() =>
                          setLocalFromDelivery(!pickupFromDelivery)
                        }
                        name="pickupFromDelivery"
                        color="primary"
                      />
                    }
                    label="График совпадает с доставкой"
                  />
                </ListItem>
              )}
              <Collapse
                in={!roundTheClock.pickup && !pickupFromDelivery}
                timeout="auto"
                unmountOnExit
              >
                <TimeTable
                  timetable={pickupTT}
                  setTimeTable={setPickupTT}
                  blur={blur.pickup}
                  setBlur={(ttblur) => setBlur({ ...blur, pickup: ttblur })}
                  setError={(value) => setTimeTableError(value)}
                />
              </Collapse>
            </List>
          </Collapse>
          <ListItem disableGutters>
            <ListItemText id="isDelivered" primary="Доставка" />
            <ListItemSecondaryAction>
              <Switch
                edge="end"
                onChange={() => {
                  setIsDelivered(!isDelivered);
                  setLocalFromDelivery(false);
                }}
                checked={isDelivered}
                inputProps={{ "aria-labelledby": "switch-list-label-wifi" }}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <Collapse in={isDelivered} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        !deliveryFromPickup
                          ? roundTheClock.delivery
                          : roundTheClock.pickup
                      }
                      onChange={() => {
                        if (roundTheClock.delivery) setLocalFromDelivery(false);
                        setRoundTheClock({
                          ...roundTheClock,
                          delivery: !roundTheClock.delivery,
                        });
                      }}
                      name="rtcDelivery"
                      color="primary"
                      disabled={deliveryFromPickup}
                    />
                  }
                  label="Круглосуточно"
                />
              </ListItem>
              {pickupTTComplete && isLocalPickup && !pickupFromDelivery && (
                <ListItem>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={deliveryFromPickup}
                        onChange={() =>
                          setDeliveryFromLocal(!deliveryFromPickup)
                        }
                        name="deliveryFromPickup"
                        color="primary"
                      />
                    }
                    label="График совпадает с самовывозом"
                  />
                </ListItem>
              )}
              <Collapse
                in={!roundTheClock.delivery && !deliveryFromPickup}
                timeout="auto"
                unmountOnExit
              >
                <TimeTable
                  timetable={deliveryTT}
                  setTimeTable={setDeliveryTT}
                  blur={blur.delivery}
                  setBlur={(ttblur) => setBlur({ ...blur, delivery: ttblur })}
                  setError={(value) => setTimeTableError(value)}
                />
              </Collapse>
            </List>
          </Collapse>
        </List>
        <LabelRibbon
          data={districts}
          title={"Районы и улицы"}
          // @ts-ignore
          onConfirm={(data: KladrObjectRibbon[]) =>
            setDistricts((districts) => data)
          }
        >
          <div className={classes.page}>
            {districts.map(
              (el, key) =>
                el.isChecked && (
                  <Accordion key={key}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-label="Expand"
                      aria-controls="additional-actions1-content"
                      id="additional-actions1-header"
                    >
                      <FormControlLabel
                        aria-label="Acknowledge"
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                        control={
                          <Checkbox
                            indeterminate={
                              el.childrenList.some((ch) => ch.isSelected) &&
                              el.childrenList.some((ch) => !ch.isSelected)
                            }
                            color={"primary"}
                            checked={el.childrenList.some(
                              (ch) => ch.isSelected
                            )}
                            onChange={(e) => {
                              let isAnyShop = false;
                              let countOfShops = 0;
                              for (let child of el.childrenList) {
                                if (child[shopType]) {
                                  isAnyShop = true;
                                  if (child[shopType] !== data.id) {
                                    countOfShops++;
                                  }
                                }
                              }
                              if (
                                isAnyShop &&
                                e.target.checked &&
                                countOfShops > 0
                              ) {
                                setCurrentKey(key);
                                setSelectType("district");
                                setConfirmWindowOpen(true);
                              } else {
                                selectDistrict(key);
                              }
                            }}
                          />
                        }
                        label={el.name}
                      />
                    </AccordionSummary>
                    {el.childrenList.map((child, index) => (
                      <AccordionDetails
                        key={index}
                        className={classes.variants}
                      >
                        <div className={classes.variantsWrapper}>
                          <FormControlLabel
                            className={classes.pl15}
                            aria-label="Acknowledge"
                            onFocus={(event) => event.stopPropagation()}
                            control={
                              <Checkbox
                                color={"primary"}
                                checked={child.isSelected}
                                onChange={(e) => {
                                  if (
                                    child[shopType] &&
                                    child[shopType] !== data.id &&
                                    e.target.checked
                                  ) {
                                    setCurrentKey(key);
                                    setCurrentIndex(index);
                                    setSelectType("street");
                                    setConfirmWindowOpen(true);
                                  } else {
                                    selectStreet(key, index);
                                  }
                                }}
                              />
                            }
                            label={child.name}
                          />
                        </div>
                      </AccordionDetails>
                    ))}
                  </Accordion>
                )
            )}
          </div>
        </LabelRibbon>
      </DialogContent>
      <DialogActions className={commonClasses.dialogActions}>
        {alerted ? (
          <Alert
            severity="warning"
            action={
              <>
                <Button color="inherit" size="small" onClick={onClose}>
                  Да
                </Button>
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => setAlerted(false)}
                >
                  Нет
                </Button>
              </>
            }
          >
            Вы действительно хотите отменить все изменения и выйти? Введенные
            данные будут утеряны
          </Alert>
        ) : streetAlert ? (
          <Alert
            severity="warning"
            action={
              <>
                <Button color="inherit" size="small" onClick={onSubmit}>
                  Да
                </Button>
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => setStreetsAlert(false)}
                >
                  Нет
                </Button>
              </>
            }
          >
            Привязки улиц были изменены, вы уверены что хотите продолжить?
          </Alert>
        ) : (
          <>
            <Button onClick={onClose} color="primary" disabled={progress}>
              Отмена
            </Button>
            <Button
              onClick={onSubmit}
              color="primary"
              type="submit"
              disabled={
                (safeToExit() &&
                  oldOrderLimit === orderLimit &&
                  isObjectsEqual(oldDeliveryFromPickup, deliveryFromPickup) &&
                  isObjectsEqual(oldPickupTT, pickupTT) &&
                  isObjectsEqual(oldDeliveryTT, deliveryTT) &&
                  isObjectsEqual(oldPickupFromDelivery, pickupFromDelivery) &&
                  isObjectsEqual(oldIsDelivered, isDelivered) &&
                  isObjectsEqual(oldIsLocalPickup, isLocalPickup) &&
                  isObjectsEqual(oldDistricts, districts) &&
                  isObjectsEqual(oldRoundTheClock, roundTheClock)) ||
                progress ||
                !readyToSubmit ||
                timeTableError
              }
            >
              Подтвердить
            </Button>
          </>
        )}
      </DialogActions>
      {progress && <ProgressBar />}
    </Dialog>
  );
};

export default EditShopForm;
