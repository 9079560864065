import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  AlertContextType,
  Banner,
  PCDataEdit,
  ProductRow,
} from "../../_shared/types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TableBody,
} from "@material-ui/core";
import DropdownOptions from "../_shared/DropdownOptions";
import BlockIcon from "@material-ui/icons/Block";
import DeleteIcon from "@material-ui/icons/Delete";
import { AlertContext } from "../_shared/ToastList";
import NewProductForm from "./NewProductForm";
import EditProductForm from "./EditProductForm";
import API from "../../_shared/axios";
import styles from "../_shared/styles";
import { alertError, alertSuccess, renderPrice } from "../../_shared/utils";
import { makeStyles } from "@material-ui/styles";
import { ProgressBar } from "../_shared/ProgressBar";
import Transition from "../_shared/Transition";

const columns = [
  { id: "image", label: "" },
  { id: "name", label: "Наименование" },
  {
    id: "description",
    label: "Описание",
  },
  {
    id: "price",
    label: "Стоимость",
  },
  {
    id: "category",
    label: "Категория",
  },
  // {
  //   id: 'isFreeSauce',
  //   label: 'Есть бесплатный соус',
  // },
  {
    id: "dropdownOptions",
    label: "",
  },
];

const useStyles = makeStyles({
  page: {
    height: "calc(100% - 64px)",
  },
  image: { maxWidth: 100 },
  cell: { minWidth: 110 },
  content: {
    padding: 50,
    maxWidth: 1140,
    margin: "0 auto",
    "& .MuiTableCell-body": {
      cursor: "pointer",
    },
    "& th": {
      backgroundColor: "white",
    },
  },
  newCategoryWrapper: {
    maxWidth: 1140,
    width: "100%",
    margin: "0 auto 20px auto",
  },
});

const ProductsPage: FC = () => {
  const commonClasses = styles();
  const classes = useStyles();
  const alertContext = useContext<AlertContextType>(AlertContext);
  const [editProductId, setEditProductId] = useState<number | undefined>(
    undefined
  );
  const [banners, setBanners] = useState<Banner[]>([]);
  const [slideOpen, setSlideOpen] = useState(false);
  const [slideData, setSlideData] = useState<{
    product: ProductRow;
    banners: string;
    isDeleting?: boolean;
  }>();

  const [rows, setRows] = useState<ProductRow[]>([]);
  const [progress, setProgress] = useState(false);

  const onProductDelete = useCallback(
    (row: ProductRow) => {
      API.get("/banners")
        .then(({ data }) => {
          let bannersData = data.filter(
            (el: Banner) => el.productId === row.id
          );
          setBanners(bannersData);
          bannersData = bannersData.map((el: Banner) => el.name);
          if (bannersData.length === 0)
            API.delete(`/products/${row.id}`)
              .then((data) => {
                alertSuccess(alertContext, "Товар удален");
                API.get(`/products`)
                  .then(({ data }) => {
                    setRows(data);
                  })
                  .catch((error) => {
                    alertError(
                      alertContext,
                      "Ошибка обновления списка товаров"
                    );
                  });
              })
              .catch((error) => {
                alertError(alertContext, "Ошибка удаления товара");
              });
          else {
            setSlideData({
              product: row,
              banners: bannersData.join(", "),
              isDeleting: true,
            });
            setSlideOpen(true);
          }
        })
        .catch((error) =>
          alertError(alertContext, "Ошибка получения списка баннеров")
        );
    },
    [alertContext]
  );

  const onProductBlock = useCallback(
    (row: ProductRow) => {
      API.get("/banners")
        .then(({ data }) => {
          let bannersData = data.filter(
            (el: Banner) =>
              el.productId === row.id && el.isActive === row.isActive
          );
          setBanners(bannersData);
          bannersData = bannersData.map((el: Banner) => el.name);
          if (bannersData.length === 0)
            API.post(
              `/products/${row.id}/${row.isActive ? "block" : "unblock"}`
            )
              .then(() =>
                API.get(`/products`)
                  .then(({ data }) => setRows(data))
                  .catch((error) => {
                    alertError(
                      alertContext,
                      "Ошибка обновления списка товаров"
                    );
                  })
              )
              .catch((error) =>
                alertError(alertContext, "Ошибка блокировки товара")
              );
          else {
            setSlideData({
              product: row,
              banners: bannersData.join(", "),
              isDeleting: false,
            });
            setSlideOpen(true);
          }
        })
        .catch((error) =>
          alertError(alertContext, "Ошибка получения списка баннеров")
        );
    },
    [alertContext]
  );

  const dropdownMenuOptions = useCallback(
    (row: ProductRow) => [
      {
        icon: <BlockIcon fontSize={"small"} />,
        text: row.isActive ? "Заблокировать" : "Разблокировать",
        event: () => onProductBlock(row),
      },
      {
        icon: <DeleteIcon fontSize={"small"} />,
        text: "Удалить",
        event: () => onProductDelete(row),
      },
    ],
    [onProductBlock, onProductDelete]
  );

  useEffect(() => {
    setProgress(true);
    API.get(`/products`)
      .then(({ data }) => {
        setRows(data);
        setProgress(false);
      })
      .catch((error) => {
        setProgress(false);
        alertError(alertContext, "Ошибка получения списка товаров");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onProductCreate = (data: any) =>
    new Promise((resolve, reject) => {
      API.post("/products", data)
        .then(({ data }) => {
          alertSuccess(alertContext, "Товар добавлен");
          API.get(`/products`)
            .then(({ data }) => {
              setRows(data);
              resolve(data);
            })
            .catch((error) => {
              alertError(alertContext, "Ошибка обновления списка товаров");
              reject(error);
            });
        })
        .catch((error) => {
          alertError(alertContext, "Ошибка добавления товара");
          reject(error);
        });
    });

  const onProductEdit = (id: number, data: PCDataEdit) =>
    new Promise((resolve, reject) => {
      API.patch(`/products/${id}`, data)
        .then(({ data }) => {
          alertSuccess(alertContext, "Успешное редактирование товара");
          API.get(`/products`)
            .then(({ data }) => {
              setRows(data);
              resolve(data);
            })
            .catch((error) => {
              alertError(alertContext, "Ошибка обновления списка товаров");
              reject(error);
            });
        })
        .catch((error) => {
          alertError(alertContext, "Ошибка редактирования товара");
          reject(error);
        });
    });

  return (
    <div className={classes.page}>
      <EditProductForm
        onProductEdit={onProductEdit}
        productId={editProductId}
        onClose={() => setEditProductId(undefined)}
      />
      <Dialog
        open={slideOpen}
        // @ts-ignore
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setSlideOpen(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Внимание"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {!slideData?.isDeleting && !slideData?.product.isActive
              ? `Вместе с товаром можно разблокировать следующие привязанные баннеры: ${slideData?.banners}`
              : slideData?.isDeleting
              ? `Вместе с товаром будут удалены следующие баннеры ${slideData?.banners}`
              : `Вместе с товаром будут заблокированы следующие баннеры ${slideData?.banners}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (!slideData?.isDeleting && !slideData?.product.isActive)
                API.post(`/products/${slideData?.product.id}/unblock`)
                  .then(() => {
                    API.get(`/products`)
                      .then(({ data }) => setRows(data))
                      .catch((error) =>
                        alertError(
                          alertContext,
                          "Ошибка обновления списка товаров"
                        )
                      );
                  })
                  .catch((error) => {
                    alertError(alertContext, "Ошибка разблокировки товара");
                  });
              setSlideOpen(false);
            }}
            color="primary"
          >
            {slideData?.product.isActive
              ? "Отменить блокировку товара"
              : "Разблокировать только товар"}
          </Button>
          <Button
            onClick={() => {
              setSlideOpen(false);
              slideData?.isDeleting
                ? API.delete(`/products/${slideData.product.id}`)
                    .then((data) => {
                      alertSuccess(alertContext, "Товар удален");
                      API.get(`/products`)
                        .then(({ data }) => {
                          setRows(data);
                        })
                        .catch((error) => {
                          alertError(
                            alertContext,
                            "Ошибка обновления списка товаров"
                          );
                        });
                    })
                    .catch((error) => {
                      alertError(alertContext, "Ошибка удаления товара");
                    })
                : API.post(
                    `/products/${slideData?.product.id}/${
                      slideData?.product.isActive ? "block" : "unblock"
                    }`
                  )
                    .then(() => {
                      if (
                        !slideData?.isDeleting &&
                        !slideData?.product.isActive
                      ) {
                        for (let i = 0; i < banners.length; i++) {
                          API.post(`/banners/${banners[i].id}/unblock`)
                            .then(() => {})
                            .catch((error) =>
                              alertError(
                                alertContext,
                                "Ошибка разблокировки баннера"
                              )
                            );
                        }
                      }
                      API.get(`/products`)
                        .then(({ data }) => setRows(data))
                        .catch((error) => {
                          alertError(
                            alertContext,
                            "Ошибка обновления списка товаров"
                          );
                        });
                    })
                    .catch((error) =>
                      alertError(alertContext, "Ошибка блокировки товара")
                    );
            }}
            color="primary"
          >
            Подтвердить
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classes.content}>
        <div className={classes.newCategoryWrapper}>
          <NewProductForm onProductCreate={onProductCreate} />
        </div>
        {progress ? (
          <ProgressBar />
        ) : (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} className={classes.cell}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.id}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    className={
                      !row.isActive ? commonClasses.blocked : undefined
                    }
                    onClick={() => setEditProductId(row.id)}
                  >
                    <TableCell>
                      <img
                        src={row.thumbnail}
                        alt={"Product"}
                        className={classes.image}
                      />
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell>{renderPrice(row.price)}</TableCell>
                    <TableCell>{row.categoryName}</TableCell>
                    {/*<TableCell>*/}
                    {/*  <FormControlLabel*/}
                    {/*    disabled*/}
                    {/*    checked={row.hasFreeSauce}*/}
                    {/*    control={<Checkbox name="checkedD" />}*/}
                    {/*    label=""*/}
                    {/*  />*/}
                    {/*</TableCell>*/}
                    <TableCell>
                      <DropdownOptions
                        options={() => dropdownMenuOptions(row)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </div>
  );
};

export default ProductsPage;
