import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Checkbox, FormControl, FormControlLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Alert } from "@material-ui/lab";
import {
  AlertContextType,
  Category,
  Ingredient,
  IngredientCategories,
  IngredientCategory,
  Option,
  ProductEdit,
  ProductRow,
} from "../../_shared/types";
import CheckboxList from "../_shared/CheckboxList";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  alertError,
  copyObject,
  getErrorMsg,
  getFloatFromString,
  getIntFromString,
  isObjectsEqual,
} from "../../_shared/utils";
import IngredientCombinations from "./IngredientCombinations";
import DropdownOptions from "../_shared/DropdownOptions";
import AddIcon from "@material-ui/icons/Add";
import API from "../../_shared/axios";
import { AlertContext } from "../_shared/ToastList";
import { ProgressBar } from "../_shared/ProgressBar";
import RemoveIcon from "@material-ui/icons/Remove";

const useStyles = makeStyles({
  weightPriceWrapper: { width: "35%", display: "flex", alignItems: "center" },
  categoryRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginTop: 15,
  },
  variants: {
    marginTop: 10,
    display: "flex",
    flexWrap: "wrap",
  },
  variantWrapper: {
    padding: "8px 30px 8px 16px",
    border: "1px solid #ccc",
    borderRadius: 4,
    marginRight: 10,
    marginTop: 10,
    marginBottom: 10,
    maxWidth: 234,
    position: "relative",
  },
  variantDeleteIcon: {
    position: "absolute",
    top: 8,
    right: 6,
    zIndex: 100,
    cursor: "pointer",
  },
  alert: {
    width: "100%",
  },
  colorInactive: { color: "rgba(0, 0, 0, 0.54)" },
  pointer: { cursor: "pointer" },
  OptionName: {
    width: "65%",
    display: "flex",
    paddingRight: "4%",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: 500,
  },
  priceField: { width: "30%", marginRight: "auto" },
  weightField: { width: "30%", marginRight: "5%" },
  categoryName: {
    width: "65%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: 500,
  },
  formControl: {
    minWidth: 120,
  },
  wrapper: {
    width: "100%",
    display: "inline",
  },
  mt20: { marginTop: 20 },
  categoriesWrapper: {
    marginTop: 25,
    width: "100%",
    "& > div": {
      display: "flex",
      width: "100%",
    },
  },
});

const EditIngredientForm: FC<{
  ingredientId: number | undefined;
  onClose: () => void;
  onIngredientEdit: (id: number, category: Ingredient) => Promise<any>;
}> = ({ onIngredientEdit, onClose, ingredientId }) => {
  const [categoriesModalOpen, setCategoriesModalOpen] = React.useState(false);
  const ingredientInit = {
    id: 1,
    name: "",
    position: 0,
    maxQuantity: 0,
    isAvailable: true,
    categories: [
      {
        categoryId: 1,
        price: 1,
        weight: 1,
        isCommon: true,
        variants: [
          {
            price: 1,
            weight: 1,
            variantId: 1,
            values: [{ optionId: 1, optionValueId: 1 }],
          },
        ],
      },
    ],
  };
  const [oldIngredient, setOldIngredient] = useState<Ingredient>(
    ingredientInit
  );
  const [products, setProducts] = useState<ProductRow[]>([]);
  const [ingredient, setIngredient] = useState<Ingredient>(ingredientInit);
  const [categories, setCategories] = useState<Category[]>([]);

  const [progress, setProgress] = useState(false);
  const [disabledAlert, setDisabledAlert] = useState(false);
  const [disabledCategories, setDisabledCategories] = useState<number[]>([]);
  const [options, setOptions] = useState<Option[]>([]);

  const [categoriesData, setCategoriesData] = useState<IngredientCategory[]>(
    []
  );
  const [oldVariantsNames, setOldVariantsNames] = useState<
    {
      optionName: string | undefined;
      optionValueName: string | undefined;
      optionId: number;
    }[][]
  >([]);
  const [variantsNames, setVariantsNames] = useState<
    {
      categoryId: number;
      optionName: string | undefined;
      optionValueName: string | undefined;
      optionValueId: number;
      optionId: number;
    }[][]
  >([]);
  const [isVariantsNotUpdated, setIsVariantsNotUpdated] = useState<
    boolean | undefined
  >(undefined);
  const [oldCategoriesData, setOldCategoriesData] = useState<
    IngredientCategory[]
  >([]);

  const isCategoriesValid = () => {
    let isValid = true;
    const selectedCategories = categoriesData.filter((el) => el.isChecked);
    if (selectedCategories.length < 1) return false;
    for (let i = 0; i < selectedCategories.length; i++) {
      if (
        selectedCategories[i].price === undefined ||
        selectedCategories[i].price.toString() === ("0" || "")
      )
        isValid = false;
      let uncheckedOptionsCounter = 0;
      for (let j = 0; j < selectedCategories[i].options.length; j++) {
        const selectedVariants = selectedCategories[i].options[
          j
        ].optionVariants.filter((el) => el.isChecked);
        if (selectedVariants.length < 1) uncheckedOptionsCounter++;
        if (uncheckedOptionsCounter === selectedCategories[i].options.length)
          return false;
        for (let k = 0; k < selectedVariants.length; k++) {
          if (selectedCategories[i].options[j].impactOnPrice) {
            if (
              selectedVariants[k].price === undefined ||
              selectedVariants[k].price?.toString() === "0" ||
              selectedVariants[k].price?.toString() === ""
            )
              isValid = false;
          }
        }
      }
    }
    return isValid;
  };

  const alertContext = useContext<AlertContextType>(AlertContext);

  useEffect(() => {
    if (ingredientId) {
      API.get(`/options`)
        .then(({ data }: { data: Option[] }) => {
          API.get(`/categories`)
            .then(({ data }: { data: Category[] }) => {
              const formattedCategories = data.map((el) => {
                return {
                  ...el,
                  isCommon: true,
                  isChecked: false,
                  isVariantsOpen: false,
                  isOptionsOpen: true,
                  price: 0,
                  weight: 0,
                  options: [],
                };
              });
              setCategories(formattedCategories);
            })
            .catch((error) =>
              alertError(
                alertContext,
                getErrorMsg(error.response, "Ошибка получения списка категорий")
              )
            );
          setOptions(data);
        })
        .catch((error) =>
          alertError(
            alertContext,
            getErrorMsg(error.response, "Ошибка получения списка опций")
          )
        );
      API.get(`/ingredients/${ingredientId}`)
        .then(({ data }: { data: Ingredient }) => {
          const ingredientData = {
            ...data,
            maxQuantity: data.maxQuantity ? data.maxQuantity : 0,
          };
          setIngredient(ingredientData);
          setOldIngredient(ingredientData);
        })
        .catch((error) =>
          alertError(
            alertContext,
            getErrorMsg(error.response, "Ошибка получения ингредиента")
          )
        );
      setOldVariantsNames([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ingredientId]);

  useEffect(() => {
    if (categories.length > 0 && ingredient) {
      const newCategories = copyObject(categories);
      // проходим по всем существующим категориям
      for (let i = 0; i < categories.length; i++) {
        // создаем основу будущей категории
        const newCategory: IngredientCategory & {
          position: number;
          isActive: boolean;
        } = {
          ...categories[i],
          isChecked: false,
          isOptionsOpen: true,
          isVariantsOpen: true,
          isCommon: false,
          options: [],
          price: 0,
          weight: 0,
          position: 0,
          isActive: true,
        };
        // берем данные из категории, относящиеся к текущей категории
        const currentCategoryIngredientData = ingredient.categories.filter(
          (el: IngredientCategories) => el.categoryId === categories[i].id
        );
        for (let j = 0; j < currentCategoryIngredientData.length; j++) {
          // берем оттуда цену и вес
          newCategory.weight = +currentCategoryIngredientData[j].weight;
          newCategory.price = currentCategoryIngredientData[j].price.toString();
          newCategory.isCommon = currentCategoryIngredientData[j].isCommon;
          newCategory.options = [];
          newCategory.isChecked = true;
          newCategory.isOptionsOpen = true;
          // проходим по всем вариантам
          for (
            let k = 0;
            k < currentCategoryIngredientData[j].variants.length;
            k++
          ) {
            // у каждого варианта проходим по values и смотрим массив вариантов. у вариантов смотрим айди опций и эти опции добавляем к
            // категории и отмечаем у нее isChecked по optionValueId. есть есть price и weight у варианта то среди опций ищем ту
            // которая pricing и ей даем наши weight и price
            const values = [
              ...currentCategoryIngredientData[j].variants[k].values,
            ];
            for (let m = 0; m < values.length; m++) {
              const optionId = values[m].optionId;
              const optionValueId = values[m].optionValueId;
              const currentOption = options.find((el) => el.id === optionId);
              const newOption = {
                id: optionId,
                categories: [],
                value: currentOption!.value,
                impactOnPrice: currentOption!.impactOnPrice,
                isChecked: true,
                optionVariants: currentOption!.optionVariants.map((el) => {
                  return {
                    id: el.id,
                    value: el.value,
                    price:
                      el.id === optionValueId &&
                      currentOption!.impactOnPrice &&
                      currentCategoryIngredientData[j].variants[k].price
                        ? currentCategoryIngredientData[j].variants[
                            k
                          ].price.toString()
                        : undefined,
                    weight:
                      el.id === optionValueId && currentOption!.impactOnPrice
                        ? +currentCategoryIngredientData[j].variants[k].weight
                        : undefined,
                    isChecked: el.id === optionValueId,
                  };
                }),
              };
              let idx = 0;
              const sameOption = newCategory.options.find((el, index) => {
                if (el.id === newOption.id) idx = index;
                return el.id === newOption.id;
              });
              if (sameOption) {
                // если такая опция уже добавлена, то надо смотреть варианты, те варианты которые одинаковые
                // надо перезаписать новыми вариантами
                // проходим по всем новым вариантам
                for (let y = 0; y < newOption.optionVariants.length; y++) {
                  const variant = newOption.optionVariants[y];
                  if (variant.isChecked)
                    newCategory.options[idx].optionVariants[y] = variant;
                }
              } else {
                newCategory.options = [...newCategory.options, newOption];
              }
            }
          }
          for (let h = 0; h < newCategory.options.length; h++) {
            const curOption = newCategory.options[h];
            curOption.optionVariants = curOption.optionVariants.filter(
              (el, index) =>
                newCategory.options[h].optionVariants
                  .map((el) => el.value)
                  .indexOf(el.value) === index
            );
          }
        }
        let newOptions: any = [];
        const curCatOptions = options.filter((el) =>
          el.categories.includes(categories[i].id)
        );
        for (let g = 0; g < curCatOptions.length; g++) {
          if (
            !newCategory.options
              .map((el) => el.id)
              .includes(curCatOptions[g].id)
          ) {
            const newOption = {
              id: curCatOptions[g].id,
              categories: [],
              value: curCatOptions[g].value,
              impactOnPrice: curCatOptions[g].impactOnPrice,
              isChecked: false,
              optionVariants: curCatOptions[g].optionVariants,
            };
            newOptions = [...newOptions, newOption].map((el) => {
              return {
                ...el,
                optionVariants: el.optionVariants.map((el: any) => {
                  return { ...el, isChecked: false };
                }),
              };
            });
          }
        }
        newCategory.options = [...newCategory.options, ...newOptions];
        newCategories[i] = { ...newCategory };
      }

      let variantNames: {
        optionName: string | undefined;
        optionValueName: string | undefined;
        optionId: number;
      }[][] = [];
      for (let p = 0; p < newCategories.length; p++) {
        let newCurrentOptionsFiltered: any[] = [];
        for (let j = 0; j < newCategories[p].options.length; j++) {
          newCurrentOptionsFiltered = [
            ...newCurrentOptionsFiltered,
            newCategories[p].options[j],
          ];
        }

        // итоговый объект вариантов
        let variants: any = [];
        let combinations: {
          optionId: number;
          optionValueId: number;
        }[] = [];

        // функция получает текущие комбинации.
        const getValues = (
          combinations: {
            optionId: number;
            optionValueId: number;
          }[]
        ) => {
          // ПОЛУЧАЕМ ВСЕ НЕ ПРОЙДЕННЫЕ ЕЩЁ ОПЦИИ, КОТОРЫЕ СОДЕРЖАТ МИНИМУМ 1 ВЫДЕЛЕННЫЙ ВАРИАНТ
          const otherOptions = newCurrentOptionsFiltered
            .filter(
              (el: any) =>
                !combinations.map((el) => el.optionId).includes(el.id)
            )
            .filter(
              (el: any) =>
                el.optionVariants.filter((el: any) => el.isChecked).length > 0
            );
          if (otherOptions.length === 0) variants = [...variants, combinations];
          else {
            for (let option of otherOptions) {
              for (let variant of option.optionVariants.filter(
                (el: any) => el.isChecked
              )) {
                getValues([
                  ...combinations,
                  {
                    optionId: option.id,
                    optionValueId: variant.id,
                  },
                ]);
              }
            }
          }
        };

        // ПРОХОДИМ ПО ВСЕМ ОПЦИЯМ, КОТОРЫЕ СОДЕРЖАТ ВЫДЕЛЕННЫЕ ВАРИАНТЫ
        for (let option of newCurrentOptionsFiltered.filter(
          (el: any) =>
            el.optionVariants.filter((el: any) => el.isChecked).length > 0
        )) {
          // ПРОХОДИМ ПО ВСЕМ ВЫДЕЛЕННЫМ ВАРИАНТАМ В КАЖДОЙ ОПЦИИ
          for (let variant of option.optionVariants.filter(
            (el: any) => el.isChecked
          )) {
            // ПОЛУЧАЕМ КОМБИНАЦИИ ДЛЯ ТЕКУЩЕГО ВАРИАНТА ОПЦИИ И ДОБАВЛЯЕМ ИХ В ОБЩИЙ МАССИВ ВАРИАНТОВ
            combinations = [
              {
                optionId: option.id,
                optionValueId: variant.id,
              },
            ];
            getValues(combinations);
          }
        }

        // полученные значения вариантов сортируем по айди опции
        for (let i = 0; i < variants.length; i++) {
          const currentValues = variants[i];
          variants[i] = currentValues.sort((a: any, b: any) =>
            a.optionId > b.optionId ? 1 : b.optionId > a.optionId ? -1 : 0
          );
        }
        // также фильтруем сами варианты на дубликаты
        variants = variants
          .filter(
            (el: any, index: number) =>
              variants
                .map((variant: any) => JSON.stringify(variant))
                .indexOf(JSON.stringify(el)) === index
          )
          .filter((el: any) => el);

        variants = variants.filter((variantArray: any) => {
          let variantArrIds: number[] = [];
          for (let i = 0; i < variantArray.length; i++) {
            variantArrIds = [...variantArrIds, variantArray[i].optionId];
            variantArrIds = [...variantArrIds, variantArray[i].optionValueId];
          }
          let result = false;
          const currentCategory = ingredient.categories.find(
            (el) => newCategories[p].id === el.categoryId
          );
          if (currentCategory)
            for (let i = 0; i < currentCategory.variants.length; i++) {
              let productIds: number[] = [];
              for (
                let j = 0;
                j < currentCategory.variants[i].values.length;
                j++
              ) {
                productIds = [
                  ...productIds,
                  currentCategory.variants[i].values[j].optionId,
                ];
                productIds = [
                  ...productIds,
                  currentCategory.variants[i].values[j].optionValueId,
                ];
              }
              if (isObjectsEqual(variantArrIds, productIds)) result = true;
            }
          return result;
        });
        for (let variant of variants) {
          let newVariant: {
            categoryId: number;
            optionName: string | undefined;
            optionValueName: string | undefined;
            optionId: number;
            optionValueId: number;
          }[] = [];
          for (let option of variant) {
            const currentOption = options.find(
              (el) => el.id === option.optionId
            );
            const optionName = currentOption?.value;
            const optionValueName = currentOption?.optionVariants.find(
              (el) => el.id === option.optionValueId
            )?.value;
            const optionValueId = currentOption?.optionVariants.find(
              (el) => el.id === option.optionValueId
            )?.id!;
            newVariant = [
              ...newVariant,
              {
                categoryId: newCategories[p].id,
                optionId: option.optionId,
                optionValueId,
                optionName,
                optionValueName,
              },
            ];
          }
          variantNames = [...variantNames, newVariant];
        }
      }

      if (oldVariantsNames.length < 1) setOldVariantsNames(variantNames);
      // @ts-ignore
      setVariantsNames(variantNames);

      // @ts-ignore
      setCategoriesData(newCategories);
      // @ts-ignore
      setOldCategoriesData(copyObject(newCategories));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, options]);

  useEffect(() => {
    if (products.length > 0) {
      const selectedCategoriesIds = categoriesData
        .filter((el) => el.isChecked)
        .map((el) => el.id);
      const productIds = products.map((el) => el.id);
      let newDisabledCategories: number[] = [];
      let productsCounter = 0;
      productIds.forEach((id) => {
        API.get(`/products/${id}`)
          .then(({ data }: { data: ProductEdit }) => {
            for (let m = 0; m < data.ingredientsForAdding.length; m++) {
              if (
                data.ingredientsForAdding[m].id === ingredient.id &&
                selectedCategoriesIds.includes(data.categoryId)
              ) {
                newDisabledCategories = [
                  ...newDisabledCategories,
                  data.categoryId,
                ];
              }
            }
            for (let m = 0; m < data.ingredientsForRemoving.length; m++) {
              if (
                data.ingredientsForRemoving[m].id === ingredient.id &&
                selectedCategoriesIds.includes(data.categoryId)
              )
                newDisabledCategories = [
                  ...newDisabledCategories,
                  data.categoryId,
                ];
            }
            productsCounter++;
            if (productsCounter === productIds.length)
              setDisabledCategories(newDisabledCategories);
          })
          .catch((error) =>
            alertError(
              alertContext,
              getErrorMsg(error.response, "Ошибка получения товара")
            )
          );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  useEffect(() => {
    if (categoriesData.length > 0 && ingredient.id && products.length === 0)
      API.get(`/products`)
        .then(({ data }: { data: ProductRow[] }) => {
          setProducts(data);
        })
        .catch((error) =>
          alertError(
            alertContext,
            getErrorMsg(error.response, "Ошибка получения списка товаров")
          )
        );
  }, [
    categoriesData,
    alertContext,
    disabledCategories,
    ingredient.id,
    products.length,
  ]);

  const getIngredientCategories = () => {
    let createdData: any = {
      ...ingredient,
      maxQuantity:
        ingredient.maxQuantity !== 0 ? ingredient.maxQuantity : undefined,
      categories: [],
    };
    const checkedCategories = categoriesData.filter((el) => el.isChecked);
    // проходим по всем выбранным категориям
    for (let i = 0; i < checkedCategories.length; i++) {
      // заготавливаем объект для добавления его в массив categories
      let data: any = {
        isCommon: checkedCategories[i].isCommon,
        categoryId: checkedCategories[i].id,
        price: +checkedCategories[i].price,
        weight:
          +checkedCategories[i].weight !== 0
            ? +checkedCategories[i].weight
            : undefined,
        variants: [],
      };

      let priceWeightArray: {
        variantId: number;
        price: string | number;
        weight: string | number | null;
        value: {
          optionId: number;
          optionValueId: number;
        };
      }[] = [];

      const getValues = (
        combinations: {
          optionId: number;
          optionValueId: number;
        }[]
      ) => {
        // ПОЛУЧАЕМ ВСЕ НЕ ПРОЙДЕННЫЕ ЕЩЁ ОПЦИИ, КОТОРЫЕ СОДЕРЖАТ ВЫДЕЛЕННЫЕ ВАРИАНТЫ
        const otherOptions = checkedCategories[i].options
          .filter(
            (el) => !combinations.map((el) => el.optionId).includes(el.id)
          )
          .filter(
            (el) => el.optionVariants.filter((el) => el.isChecked).length > 0
          );
        if (otherOptions.length === 0) {
          data.variants = [...data.variants, { values: combinations }];
        } else {
          for (let option = 0; option < otherOptions.length; option++) {
            for (let variant of otherOptions[0].optionVariants.filter(
              (el) => el.isChecked
            )) {
              if (variant.price) {
                priceWeightArray = [
                  ...priceWeightArray,
                  {
                    variantId: variant.id,
                    value: {
                      optionId: otherOptions[0].id,
                      optionValueId: variant.id,
                    },
                    price: +variant.price,
                    weight: variant.weight ? variant.weight : null,
                  },
                ];
              }
              getValues([
                ...combinations,
                {
                  optionId: otherOptions[0].id,
                  optionValueId: variant.id,
                },
              ]);
            }
          }
        }
      };

      getValues([]);

      let variants = data.variants;
      for (let i = 0; i < variants.length; i++) {
        const currentValues = variants[i].values;
        variants[i].values = currentValues.sort((a: any, b: any) =>
          a.optionId > b.optionId ? 1 : b.optionId > a.optionId ? -1 : 0
        );
      }

      data.variants = variants
        .filter(
          (el: any, index: number) =>
            variants
              .map((variant: any) => JSON.stringify(variant.values))
              .indexOf(JSON.stringify(el.values)) === index
        )
        .filter((el: any) => el);

      let newData = { ...data };
      for (let index = 0; index < data.variants.length; index++) {
        for (let value of data.variants[index].values) {
          for (let priceWeight of priceWeightArray) {
            if (isObjectsEqual(priceWeight.value, value)) {
              newData.variants[index].price = +priceWeight.price;
              newData.variants[index].weight = priceWeight.weight
                ? priceWeight.weight
                : null;
            }
          }
        }
      }
      newData.variants = newData.variants.filter((variant: any) => {
        let result = false;
        const approachVariantsNames = variantsNames.filter((el) =>
          el.find((el) => el.categoryId === newData.categoryId)
        );
        const valuesOptionIds = variant.values.map((el: any) => el.optionId);
        const valuesOptionValueIds = variant.values.map(
          (el: any) => el.optionValueId
        );
        for (let i = 0; i < approachVariantsNames.length; i++) {
          const optionIds = approachVariantsNames[i].map((el) => el.optionId);
          const optionValueIds = approachVariantsNames[i].map(
            (el) => el.optionValueId
          );
          if (
            isObjectsEqual(valuesOptionIds, optionIds) &&
            isObjectsEqual(valuesOptionValueIds, optionValueIds)
          )
            result = true;
        }
        return result;
      });
      createdData.categories = copyObject([
        ...createdData.categories,
        newData,
      ]).map((el: any) => {
        return {
          ...el,
          variants: !el.variants.some(
            (variant: any) => variant.values.length === 0
          )
            ? el.variants
            : [],
        };
      });
    }
    return createdData;
  };

  const isBlurredInit = {
    name: false,
    position: false,
  };
  const [isBlurred, setIsBlurred] = useState(isBlurredInit);
  const setBlurredField = (field: string) => {
    setIsBlurred({ ...isBlurred, [field]: true });
  };

  const handleClose = () => {
    setIsVariantsNotUpdated(undefined);
    setIngredient(ingredientInit);
    setOldIngredient(ingredientInit);
    setOldCategoriesData([]);
    setOldVariantsNames(variantsNames);
    setCategoriesData([]);
    setProducts([]);
    setAlerted(false);
    setDisabledCategories([]);
    onClose();
  };

  const classes = useStyles();

  const [alerted, setAlerted] = useState(false);

  const isCategoryDisabled = useCallback(
    (id: number) => disabledCategories.includes(id),
    [disabledCategories]
  );

  const dropdownMenuOptions = useCallback(
    (row: IngredientCategory, index: number) => [
      {
        icon: categoriesData[index].isOptionsOpen ? (
          <RemoveIcon fontSize={"small"} />
        ) : (
          <AddIcon fontSize={"small"} />
        ),
        text: categoriesData[index].isOptionsOpen
          ? "Скрыть вариации"
          : "Показать вариации",
        event: () => {
          const newList = [...categoriesData];
          newList[index].isOptionsOpen = !newList[index].isOptionsOpen;
          setCategoriesData((c) => newList);
        },
      },
      {
        icon: <DeleteIcon fontSize={"small"} />,
        text: "Удалить",
        event: () => {
          if (isCategoryDisabled(row.id)) {
            setDisabledAlert(true);
            setAlerted(true);
          } else
            setCategoriesData(
              categoriesData.map((el) =>
                el.id !== row.id
                  ? el
                  : {
                      ...el,
                      isChecked: !el.isChecked,
                      price: 0,
                      weight: 0,
                      isCommon: true,
                      options: el.options.map((opt) => {
                        return {
                          ...opt,
                          isChecked: false,
                          optionVariants: opt.optionVariants.map((variant) => {
                            return {
                              ...variant,
                              isChecked: false,
                              price: undefined,
                              weight: undefined,
                            };
                          }),
                        };
                      }),
                    }
              )
            );
        },
      },
    ],
    [categoriesData, isCategoryDisabled]
  );

  useEffect(() => {
    if (disabledAlert) {
      const id = setTimeout(() => {
        clearTimeout(id);
        setAlerted(false);
        setDisabledAlert(false);
      }, 3000);
    }
  }, [disabledAlert]);

  const setAlert = () => {
    setDisabledAlert(false);
    setAlerted(true);
  };

  return (
    <div className={classes.wrapper}>
      <Dialog
        disableBackdropClick
        onBackdropClick={() =>
          progress
            ? null
            : isObjectsEqual(ingredient, oldIngredient) &&
              isVariantsNotUpdated !== false
            ? handleClose()
            : setAlert()
        }
        open={ingredientId !== undefined}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className="newIngredientDialog"
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle id="form-dialog-title">
          Редактирование ингредиента
        </DialogTitle>
        <DialogContent>
          <form>
            <TextField
              autoFocus
              margin="dense"
              label="Наименование"
              inputProps={{ maxLength: 100 }}
              value={ingredient.name}
              onChange={(e) =>
                setIngredient({ ...ingredient, name: e.target.value })
              }
              type="text"
              onBlur={() => setBlurredField("name")}
              error={!ingredient.name && isBlurred.name}
              helperText={
                !ingredient.name && isBlurred.name ? "Обязательное поле" : ""
              }
              fullWidth
              required
            />
            <TextField
              margin="dense"
              label="Позиция"
              value={ingredient.position}
              type="text"
              onChange={(e) =>
                e.target.value === " "
                  ? null
                  : setIngredient({
                      ...ingredient,
                      position: getIntFromString(e.target.value),
                    })
              }
              fullWidth
              onBlur={() => setBlurredField("position")}
              error={!ingredient.position && isBlurred.position}
              helperText={
                !ingredient.position && isBlurred.position
                  ? "Обязательное поле"
                  : ""
              }
              required
            />
            <TextField
              margin="dense"
              label="Максимальное количество"
              inputProps={{ maxLength: 10 }}
              value={ingredient.maxQuantity}
              type="text"
              onChange={(e) =>
                e.target.value === " "
                  ? null
                  : setIngredient({
                      ...ingredient,
                      maxQuantity: getIntFromString(e.target.value),
                    })
              }
              fullWidth
            />
            <div className={classes.categoriesWrapper}>
              {categoriesData.filter((el) => !el.isChecked).length > 0 && (
                <>
                  <Button
                    color="primary"
                    size={"small"}
                    variant="outlined"
                    onClick={() => setCategoriesModalOpen(true)}
                  >
                    Добавить категории *
                  </Button>
                  <CheckboxList
                    title={"Добавляемые категории"}
                    data={categoriesData.map((el) => {
                      return {
                        ...el,
                        id: el.id,
                        name: el.name,
                        isChecked: el.isChecked,
                      };
                    })}
                    isOpen={categoriesModalOpen}
                    onClose={() => setCategoriesModalOpen(false)}
                    onConfirm={(data) => {
                      let newList = copyObject(data);
                      for (let i = 0; i < data.length; i++) {
                        newList[i] = {
                          ...newList[i],
                          options: newList[i].options.map((el: any) => {
                            return {
                              ...el,
                              isChecked: el.isChecked,
                              optionVariants: el.optionVariants.map(
                                (variant: any) => {
                                  return {
                                    ...variant,
                                    isChecked: variant.isChecked,
                                    price: variant.price
                                      ? variant.price
                                      : undefined,
                                    weight: variant.weight
                                      ? variant.weight
                                      : undefined,
                                  };
                                }
                              ),
                            };
                          }),
                          isChecked: data[i].isChecked,
                        };
                      }
                      setCategoriesData(newList);
                      setCategoriesModalOpen(false);
                    }}
                  />
                </>
              )}
              {categoriesData.map(
                (row, index) =>
                  row.isChecked && (
                    <>
                      <div className={classes.categoryRow} key={row.name}>
                        <span className={classes.categoryName}>
                          {row.name}
                          <span className={classes.OptionName}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={categoriesData[index].isCommon}
                                  onChange={(e) => {
                                    setCategoriesData(
                                      categoriesData.map((el) =>
                                        el.name !== row.name
                                          ? el
                                          : {
                                              ...el,
                                              isCommon: e.target.checked,
                                              isOptionsOpen: !e.target.checked
                                                ? true
                                                : el.isOptionsOpen,
                                            }
                                      )
                                    );
                                  }}
                                  name="checkedB"
                                  color="primary"
                                  disabled={
                                    categoriesData[index].options.length === 0
                                  }
                                />
                              }
                              label="Ингредиент доступен всей категории"
                            />
                          </span>
                        </span>
                        <div className={classes.weightPriceWrapper}>
                          <TextField
                            margin="dense"
                            label="Вес"
                            className={classes.weightField}
                            type="text"
                            value={categoriesData[index].weight}
                            onChange={(e) =>
                              e.target.value === " "
                                ? null
                                : setCategoriesData(
                                    categoriesData.map((el) =>
                                      el.name !== row.name
                                        ? el
                                        : {
                                            ...el,
                                            weight: getFloatFromString(
                                              e.target.value
                                            ),
                                          }
                                    )
                                  )
                            }
                          />
                          <TextField
                            margin="dense"
                            type="text"
                            label="Цена"
                            required
                            className={classes.priceField}
                            value={categoriesData[index].price}
                            error={categoriesData[index].price === (0 || "")}
                            helperText={
                              categoriesData[index].price === 0
                                ? "Не может быть равно 0"
                                : ""
                            }
                            onChange={(e) =>
                              e.target.value === " "
                                ? null
                                : setCategoriesData(
                                    categoriesData.map((el) =>
                                      el.name !== row.name
                                        ? el
                                        : {
                                            ...el,
                                            price: getFloatFromString(
                                              e.target.value
                                            ),
                                          }
                                    )
                                  )
                            }
                          />
                          <DropdownOptions
                            options={() => dropdownMenuOptions(row, index)}
                          />
                        </div>
                      </div>
                      <p className={classes.colorInactive}>
                        {!row.isCommon &&
                        categoriesData.filter(
                          (el) =>
                            el.options.filter((el) =>
                              el.optionVariants.some((el) => el.isChecked)
                            ).length > 0
                        ).length === 0
                          ? "(выберите один из вариантов)"
                          : ""}
                      </p>
                      <IngredientCombinations
                        categoryIndex={index}
                        categoriesData={categoriesData}
                        onUpdateList={(data) => {
                          setIsVariantsNotUpdated(
                            isObjectsEqual(data, oldCategoriesData)
                          );

                          let variantNames: any[] = [];
                          for (let p = 0; p < data.length; p++) {
                            let newCurrentOptionsFiltered: any[] = [];
                            for (let j = 0; j < data[p].options.length; j++) {
                              newCurrentOptionsFiltered = [
                                ...newCurrentOptionsFiltered,
                                data[p].options[j],
                              ];
                            }

                            // итоговый объект вариантов
                            let variants: any = [];
                            let combinations: {
                              optionId: number;
                              optionValueId: number;
                            }[] = [];

                            // функция получает текущие комбинации.
                            const getValues = (
                              combinations: {
                                optionId: number;
                                optionValueId: number;
                              }[]
                            ) => {
                              // ПОЛУЧАЕМ ВСЕ НЕ ПРОЙДЕННЫЕ ЕЩЁ ОПЦИИ, КОТОРЫЕ СОДЕРЖАТ МИНИМУМ 1 ВЫДЕЛЕННЫЙ ВАРИАНТ
                              const otherOptions = newCurrentOptionsFiltered
                                .filter(
                                  (el: any) =>
                                    !combinations
                                      .map((el) => el.optionId)
                                      .includes(el.id)
                                )
                                .filter(
                                  (el: any) =>
                                    el.optionVariants.filter(
                                      (el: any) => el.isChecked
                                    ).length > 0
                                );
                              if (otherOptions.length === 0)
                                variants = [...variants, combinations];
                              else {
                                for (let option of otherOptions) {
                                  for (let variant of option.optionVariants.filter(
                                    (el: any) => el.isChecked
                                  )) {
                                    getValues([
                                      ...combinations,
                                      {
                                        optionId: option.id,
                                        optionValueId: variant.id,
                                      },
                                    ]);
                                  }
                                }
                              }
                            };

                            // ПРОХОДИМ ПО ВСЕМ ОПЦИЯМ, КОТОРЫЕ СОДЕРЖАТ ВЫДЕЛЕННЫЕ ВАРИАНТЫ
                            for (let option of newCurrentOptionsFiltered.filter(
                              (el: any) =>
                                el.optionVariants.filter(
                                  (el: any) => el.isChecked
                                ).length > 0
                            )) {
                              // ПРОХОДИМ ПО ВСЕМ ВЫДЕЛЕННЫМ ВАРИАНТАМ В КАЖДОЙ ОПЦИИ
                              for (let variant of option.optionVariants.filter(
                                (el: any) => el.isChecked
                              )) {
                                // ПОЛУЧАЕМ КОМБИНАЦИИ ДЛЯ ТЕКУЩЕГО ВАРИАНТА ОПЦИИ И ДОБАВЛЯЕМ ИХ В ОБЩИЙ МАССИВ ВАРИАНТОВ
                                combinations = [
                                  {
                                    optionId: option.id,
                                    optionValueId: variant.id,
                                  },
                                ];
                                getValues(combinations);
                              }
                            }

                            // полученные значения вариантов сортируем по айди опции
                            for (let i = 0; i < variants.length; i++) {
                              const currentValues = variants[i];
                              variants[
                                i
                              ] = currentValues.sort((a: any, b: any) =>
                                a.optionId > b.optionId
                                  ? 1
                                  : b.optionId > a.optionId
                                  ? -1
                                  : 0
                              );
                            }
                            // также фильтруем сами варианты на дубликаты
                            variants = variants
                              .filter(
                                (el: any, index: number) =>
                                  variants
                                    .map((variant: any) =>
                                      JSON.stringify(variant)
                                    )
                                    .indexOf(JSON.stringify(el)) === index
                              )
                              .filter((el: any) => el);

                            for (let variant of variants) {
                              let newVariant: {
                                categoryId: number;
                                optionName: string | undefined;
                                optionValueName: string | undefined;
                                optionValueId: number;
                                optionId: number;
                              }[] = [];
                              for (let option of variant) {
                                const currentOption = options.find(
                                  (el) => el.id === option.optionId
                                );
                                const optionName = currentOption?.value;
                                const optionValueName = currentOption?.optionVariants.find(
                                  (el) => el.id === option.optionValueId
                                )?.value;
                                const optionValueId = currentOption?.optionVariants.find(
                                  (el) => el.id === option.optionValueId
                                )?.id!;
                                newVariant = [
                                  ...newVariant,
                                  {
                                    categoryId: data[p].id,
                                    optionId: option.optionId,
                                    optionName,
                                    optionValueId,
                                    optionValueName,
                                  },
                                ];
                              }
                              variantNames = [...variantNames, newVariant];
                            }
                          }
                          // @ts-ignore
                          setVariantsNames(variantNames);

                          setCategoriesData(data);
                        }}
                        isOpen={categoriesData[index].isOptionsOpen}
                      />
                      {categoriesData.length > 0 && (
                        <div className={classes.variants}>
                          {variantsNames
                            .filter(
                              (el) =>
                                el.filter((el) => el.categoryId === row.id)
                                  .length > 0
                            )
                            .map(
                              (
                                variant: {
                                  categoryId: number;
                                  optionName: string | undefined;
                                  optionValueName: string | undefined;
                                  optionId: number;
                                }[],
                                key
                              ) => (
                                <div
                                  key={key}
                                  className={classes.variantWrapper}
                                >
                                  <div className={classes.variantDeleteIcon}>
                                    <DeleteIcon
                                      fontSize={"small"}
                                      color={"primary"}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        let newVariantsNames = copyObject(
                                          variantsNames
                                        );
                                        let countOfVariantsBeforeCurrentCategory = 0;
                                        for (let i = 0; i < index; i++) {
                                          const categoryId =
                                            categoriesData[i].id;
                                          const countOfVariants = variantsNames.filter(
                                            (el) =>
                                              el.some(
                                                (el) =>
                                                  el.categoryId === categoryId
                                              )
                                          ).length;
                                          countOfVariantsBeforeCurrentCategory += countOfVariants;
                                        }
                                        newVariantsNames = newVariantsNames.filter(
                                          (el: any, variantIndex: number) => {
                                            return (
                                              variantIndex !==
                                              key +
                                                countOfVariantsBeforeCurrentCategory
                                            );
                                          }
                                        );
                                        setVariantsNames(newVariantsNames);
                                        let newCategoriesData = categoriesData;
                                        for (
                                          let i = 0;
                                          i < variant.length;
                                          i++
                                        ) {
                                          const deletedOptionName =
                                            variant[i].optionName;
                                          const deletedOptionValueName =
                                            variant[i].optionValueName;
                                          const otherVariantsNames = variantsNames
                                            .filter((el) =>
                                              el.some(
                                                (el) => el.categoryId === row.id
                                              )
                                            )
                                            .filter(
                                              (el, index) =>
                                                index !==
                                                key +
                                                  countOfVariantsBeforeCurrentCategory
                                            );
                                          if (
                                            !otherVariantsNames.find((el) =>
                                              el.some(
                                                (el) =>
                                                  el.optionName ===
                                                    deletedOptionName &&
                                                  el.optionValueName ===
                                                    deletedOptionValueName
                                              )
                                            )
                                          ) {
                                            newCategoriesData = newCategoriesData.map(
                                              (category) => {
                                                return {
                                                  ...category,
                                                  options:
                                                    row.id !== category.id
                                                      ? category.options
                                                      : category.options.map(
                                                          (option) => {
                                                            return {
                                                              ...option,
                                                              optionVariants: option.optionVariants.map(
                                                                (variant) =>
                                                                  variant.value ===
                                                                    deletedOptionValueName &&
                                                                  option.value ===
                                                                    deletedOptionName
                                                                    ? {
                                                                        ...variant,
                                                                        isChecked: false,
                                                                      }
                                                                    : variant
                                                              ),
                                                            };
                                                          }
                                                        ),
                                                };
                                              }
                                            );
                                          }
                                        }
                                        newCategoriesData[
                                          index
                                        ].options = newCategoriesData[
                                          index
                                        ].options.map((option) => {
                                          return {
                                            ...option,
                                            isChecked: option.optionVariants.some(
                                              (el: any) => el.isChecked
                                            ),
                                          };
                                        });
                                        setCategoriesData(newCategoriesData);
                                      }}
                                    />
                                  </div>
                                  {variant.map((el: any, index: number) => (
                                    <span key={index}>
                                      <span>
                                        {el.optionName.toLowerCase()}:&nbsp;
                                      </span>
                                      <span>
                                        {el.optionValueName.toLowerCase()}
                                      </span>
                                      <br />
                                    </span>
                                  ))}
                                </div>
                              )
                            )}
                        </div>
                      )}
                    </>
                  )
              )}
              <></>
            </div>

            <FormControl className={classes.mt20}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={ingredient.isAvailable}
                    onChange={(e) => {
                      setIngredient({
                        ...ingredient,
                        isAvailable: e.target.checked,
                      });
                    }}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Ингредиент доступен"
              />
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          {alerted ? (
            <Alert
              onClick={() => {
                setAlerted(false);
                setDisabledAlert(false);
              }}
              className={[classes.alert, disabledAlert && classes.pointer].join(
                " "
              )}
              severity="warning"
              action={
                !disabledAlert && (
                  <>
                    <Button
                      color="inherit"
                      size="small"
                      onClick={() => handleClose()}
                    >
                      Да
                    </Button>
                    <Button
                      color="inherit"
                      size="small"
                      onClick={() => setAlerted(false)}
                    >
                      Нет
                    </Button>
                  </>
                )
              }
            >
              {disabledAlert
                ? "Удаление категории невозможно, так как существует товар из данной категории, имеющий в составе текущий ингредиент"
                : "Вы действительно хотите отменить все изменения и выйти? Введенные данные будут утеряны"}
            </Alert>
          ) : (
            <>
              <Button onClick={handleClose} color="primary" disabled={progress}>
                Отмена
              </Button>
              <Button
                onClick={() => {
                  setProgress(true);
                  const ingredientCategories = getIngredientCategories();
                  onIngredientEdit(
                    ingredientId ? ingredientId : 1,
                    ingredientCategories
                  )
                    .then((data) => handleClose())
                    .catch((error) => console.log(error))
                    .finally(() => setProgress(false));
                }}
                color="primary"
                type="submit"
                disabled={
                  ingredient.name === "" ||
                  ingredient.position === 0 ||
                  (isObjectsEqual(ingredient, oldIngredient) &&
                    isObjectsEqual(categoriesData, oldCategoriesData) &&
                    isObjectsEqual(variantsNames, oldVariantsNames) &&
                    isVariantsNotUpdated !== false) ||
                  !isCategoriesValid() ||
                  progress
                }
              >
                Подтвердить
              </Button>
            </>
          )}
        </DialogActions>
        {progress && <ProgressBar bottom />}
      </Dialog>
    </div>
  );
};

export default EditIngredientForm;
