import React, { FC, useState, useContext } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Switch,
  Collapse,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogContentText,
} from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";
import { ProgressBar } from "../_shared/ProgressBar";
import {
  AlertContextType,
  maxSafeNumber,
  StreetSearchResponse,
} from "../../_shared/types";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import API from "../../_shared/axios";
import { alertError, copyObject, getErrorMsg } from "../../_shared/utils";
import { AlertContext } from "../_shared/ToastList";
import TimeTable from "./_shared/TimeTable";
import { PaymentGateway, BlurType, weekDays } from "./_shared/types";
import { Shop, WorkingTime } from "../_shared/types";
import styles from "./_shared/styles";
import { getTTStatus } from "./_shared/utils";
import {
  KladrObject,
  KladrObjectRibbon,
  KladrObjectType,
} from "../StreetsPage/_shared/types";
import LabelRibbon from "../_shared/LabelRibbon";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Transition from "../_shared/Transition";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      scrollbarWidth: "none",
      "& ::-webkit-scrollbar": {
        display: "none",
      },
    },
    streetField: { marginBottom: 3 },
    pl15: { paddingLeft: 15 },
    variantsWrapper: { width: "60%", display: "flex", alignItems: "center" },
    variants: {
      marginTop: 0,
      display: "flex",
      flexWrap: "wrap",
    },
    page: {
      width: "100%",
      flexDirection: "column",
      "& > div": {
        "& .MuiAccordionSummary-content": {
          margin: "0 !important",
        },
        "& .MuiAccordionDetails-root": {
          padding: "0 0 0 20px !important",
          "&:last-child": {
            paddingBottom: "10px !important",
          },
        },
      },
    },
    normal: {
      "& input": {
        transition: "color 150ms ease-in-out",
      },
      "& > p": {
        position: "absolute",
        top: "100%",
      },
    },

    transparent: {
      "& input": {
        color: "transparent",
      },
      "& > p": {
        position: "absolute",
        top: "100%",
      },
    },
  })
);

const tableBlur: BlurType = weekDays.reduce(
  (base, { eng: day }) => ({ ...base, [day]: false }),
  {} as BlurType
);

const tableTT: WorkingTime = weekDays.reduce(
  (base, { eng: day }) => ({
    ...base,
    [day]: {
      from: new Date(NaN),
      till: new Date(NaN),
    },
  }),
  {} as WorkingTime
);

const NewShopForm: FC<{
  onSend: (data: Shop) => Promise<any>;
}> = ({ onSend }) => {
  const [modal, setModal] = useState(false);
  const [alerted, setAlerted] = useState(false);
  const [blur, setBlur] = useState({
    name: false,
    house: false,
    street: false,
    workingTime: false,
    pickup: tableBlur,
    delivery: tableBlur,
    position: false,
  });
  const [progress, setProgress] = useState(false);

  const alertContext = useContext<AlertContextType>(AlertContext);

  const [street, setStreet] = useState("");
  const [house, setHouse] = useState("");
  const [searchList, setSearchList] = useState<StreetSearchResponse[]>([]);

  const [position, setPosition] = useState(0);
  const [orderLimit, setOrderLimit] = useState(0);
  const [isLocalPickup, setIsLocalPickup] = useState(true);
  const [isDelivered, setIsDelivered] = useState(true);
  const [paymentGatewayId, setPaymentGatewayId] = useState("0");
  const [paymentGateways, setPaymentGateways] = useState<PaymentGateway[]>([]);
  const [roundTheClock, setRoundTheClock] = useState({
    delivery: false,
    pickup: false,
  });
  const [pickupTT, setPickupTT] = useState(tableTT);
  const [deliveryTT, setDeliveryTT] = useState(tableTT);
  const [pickupFromDelivery, setLocalFromDelivery] = useState(false);
  const [deliveryFromPickup, setDeliveryFromLocal] = useState(false);
  const [confirmWindowOpen, setConfirmWindowOpen] = useState(false);
  const [currentKey, setCurrentKey] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [districts, setDistricts] = useState<KladrObjectRibbon[]>([]);
  const [selectType, setSelectType] = useState<"district" | "street">("street");
  const [name, setName] = useState("");
  const [timeTableError, setTimeTableError] = useState(false);

  const commonClasses = styles();
  const classes = useStyles();

  const onOpen = () => {
    setPaymentGatewayId("0");
    setPaymentGateways([]);
    API.get("payments/gateways")
      .then(({ data }) => setPaymentGateways(data))
      .catch((error) =>
        alertError(
          alertContext,
          getErrorMsg(
            error.response,
            "Ошибка получения списка платежных шлюзов"
          )
        )
      );
    setAlerted(false);
    setBlur({
      name: false,
      house: false,
      street: false,
      workingTime: false,
      pickup: tableBlur,
      delivery: tableBlur,
      position: false,
    });
    setName("");
    setName("");
    setStreet("");
    setHouse("");
    setPosition(0);
    setOrderLimit(0);
    setIsLocalPickup(true);
    setIsDelivered(true);
    setRoundTheClock({
      delivery: false,
      pickup: false,
    });
    setPickupTT(tableTT);
    setDeliveryTT(tableTT);
    setLocalFromDelivery(false);
    setDeliveryFromLocal(false);
    setModal(true);

    API.get(`/kladrObjects/1/children?level=2`)
      .then(({ data }: { data: KladrObject[] }) => {
        const kladrsData = data;
        const formattedData = copyObject(data).map((el: KladrObject) => {
          return {
            ...el,
            isChecked: false,
            isSelected: false,
            isActive: true,
            childrenList: el.childrenList.map((child) => {
              return { ...child, isSelected: false };
            }),
          };
        });
        API.get(`/kladrObjects/types`)
          .then(({ data }: { data: KladrObjectType[] }) => {
            const types = data;
            let districtTypeId =
              types.find((el) => el.nameRu.toLowerCase() === "район")?.id || 2;
            let districtsData = formattedData.filter(
              (el: KladrObjectRibbon) => el.kladrObjectTypeId === districtTypeId
            );
            if (districtsData.length !== kladrsData.length) {
              let otherStreetsDistrict = { ...districtsData[0] };
              otherStreetsDistrict.name = "Улицы без района";
              otherStreetsDistrict.id = Date.now();
              otherStreetsDistrict.childrenList = formattedData.filter(
                (el: KladrObjectRibbon) =>
                  el.kladrObjectTypeId !== districtTypeId
              );
              districtsData = [...districtsData, otherStreetsDistrict];
            }
            setDistricts(districtsData);
          })
          .catch((error) => {
            alertError(
              alertContext,
              getErrorMsg(
                error.response,
                "Ошибка получения списка типов кладр-объектов"
              )
            );
          });
      })
      .catch((error) => {
        alertError(
          alertContext,
          getErrorMsg(error.response, "Ошибка получения списка районов и улиц")
        );
      });
  };

  const onSubmit = () => {
    setProgress(true);
    const streetsToBind = getStreetsToBind();
    const districtsList =
      streetsToBind.districtsArr.length > 0
        ? streetsToBind.districtsArr
        : undefined;
    const streetsList =
      streetsToBind.streetsArr.length > 0
        ? streetsToBind.streetsArr
        : undefined;
    onSend({
      name,
      house,
      street,
      position,
      isDelivered,
      isLocalPickup,
      orderLimit,
      paymentGatewayId:
        paymentGatewayId === "0" ? null : Number(paymentGatewayId),
      isAroundTheClockDelivery: deliveryFromPickup
        ? roundTheClock.pickup
        : roundTheClock.delivery,
      isAroundTheClockPickup: pickupFromDelivery
        ? roundTheClock.delivery
        : roundTheClock.pickup,
      deliveryWorking: deliveryFromPickup ? pickupTT : deliveryTT,
      pickupWorking: pickupFromDelivery ? deliveryTT : pickupTT,
      districtsList,
      streetsList,
    })
      .then((data) => setModal(false))
      .catch((error) => console.log(error))
      .finally(() => setProgress(false));
  };

  const {
    ttComplete: pickupTTComplete,
    ttIsBlank: pickupTTIsBlank,
  } = getTTStatus(pickupTT, roundTheClock.pickup);

  const {
    ttComplete: deliveryTTComplete,
    ttIsBlank: deliveryTTIsBlank,
  } = getTTStatus(deliveryTT, roundTheClock.delivery);

  const safeToExit =
    districts.filter((el) => el.isChecked).length === 0 &&
    !pickupFromDelivery &&
    !deliveryFromPickup &&
    !roundTheClock.delivery &&
    !roundTheClock.pickup &&
    pickupTTIsBlank &&
    deliveryTTIsBlank &&
    name === "" &&
    street === "" &&
    house === "" &&
    name === "" &&
    position === 0 &&
    paymentGatewayId === "0";

  const pickupTTReady = roundTheClock.pickup ? true : pickupTTComplete;
  const deliveryTTReady = roundTheClock.delivery ? true : deliveryTTComplete;

  const readyToSubmit =
    street !== "" &&
    house !== "" &&
    name !== "" &&
    position !== 0 &&
    (isLocalPickup
      ? pickupFromDelivery
        ? deliveryTTReady
        : pickupTTReady
      : true) &&
    (isDelivered
      ? deliveryFromPickup
        ? pickupTTReady
        : deliveryTTReady
      : true) &&
    !timeTableError;

  const getStreetsToBind = () => {
    let districtsArr: number[] = [];
    let streetsArr: number[] = [];
    const checkedDistricts = districts.filter((el) => el.isChecked);
    for (let district of checkedDistricts) {
      if (district.isSelected && district.name !== "Улицы без района")
        districtsArr = [...districtsArr, district.id];
      else {
        for (let street of district.childrenList) {
          if (street.isSelected) streetsArr = [...streetsArr, street.id];
        }
      }
    }
    return { districtsArr, streetsArr };
  };

  const selectDistrict = (keyProp?: number) => {
    let newDistricts = copyObject(districts);
    const key = keyProp !== undefined ? keyProp : currentKey;
    newDistricts[key].isSelected = !newDistricts[key].isSelected;
    newDistricts[key].childrenList = newDistricts[key].childrenList.map(
      (el: KladrObjectRibbon) => {
        return {
          ...el,
          isSelected: newDistricts[key].isSelected,
        };
      }
    );
    setDistricts(newDistricts);
  };

  const selectStreet = (keyProp?: number, indexProp?: number) => {
    let newDistricts = copyObject(districts);
    const key = keyProp !== undefined ? keyProp : currentKey;
    const index = indexProp !== undefined ? indexProp : currentIndex;
    newDistricts[key].childrenList[index].isSelected = !newDistricts[key]
      .childrenList[index].isSelected;
    newDistricts[key].isSelected = !!newDistricts[key].childrenList.every(
      (el: KladrObjectRibbon) => el.isSelected
    );
    setDistricts(newDistricts);
  };

  return (
    <>
      <Button variant="outlined" color="primary" onClick={onOpen}>
        Добавить точку продаж
      </Button>
      <Dialog
        disableBackdropClick
        onBackdropClick={() =>
          progress ? null : safeToExit ? setModal(false) : setAlerted(true)
        }
        open={modal}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">Новая точка продаж</DialogTitle>
        <DialogContent className={classes.list}>
          <Dialog
            open={confirmWindowOpen}
            // @ts-ignore
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setConfirmWindowOpen(false)}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">
              {"Внимание"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Данный объект уже привязан к другой точке, хотите привязать к
                его к текущей точке?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setConfirmWindowOpen(false)}
                color="primary"
              >
                Отмена
              </Button>
              <Button
                onClick={() => {
                  selectType === "street" ? selectStreet() : selectDistrict();
                  setConfirmWindowOpen(false);
                }}
                color="primary"
              >
                Подтвердить
              </Button>
            </DialogActions>
          </Dialog>
          <TextField
            margin="dense"
            id="address"
            label="Наименование"
            inputProps={{ maxLength: 100 }}
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
            fullWidth
            onBlur={() => setBlur({ ...blur, name: true })}
            error={!name && blur.name}
            helperText={!name && blur.name ? "Обязательное поле" : ""}
            required
          />
          <Autocomplete
            id="combo-box-demo"
            fullWidth
            onChange={(
              event,
              newValue: StreetSearchResponse | null | undefined | string
            ) => {
              if (newValue && typeof newValue !== "string")
                setStreet(newValue.name!);
            }}
            clearOnBlur
            onBlur={() => {
              setBlur({ ...blur, street: true });
              setSearchList([]);
            }}
            freeSolo
            options={searchList}
            getOptionLabel={(option: StreetSearchResponse) =>
              option.fullName ? option.fullName : option.name!
            }
            renderInput={(params) => (
              <TextField
                {...params}
                required
                className={classes.streetField}
                error={!street && blur.street}
                helperText={!street && blur.street ? "Обязательное поле" : ""}
                onChange={(e) => {
                  if (e.target.value.length > 2) {
                    const url = encodeURI(
                      `kladrObjectsByLevel?level=10&name=${e.target.value}`
                    );
                    API.get(url)
                      .then(({ data }) => {
                        setSearchList(data);
                      })
                      .catch((error) =>
                        alertError(
                          alertContext,
                          getErrorMsg(error.response, "Ошибка поиска улицы")
                        )
                      );
                  } else {
                    setSearchList([]);
                  }
                }}
                label="Улица"
              />
            )}
          />
          <TextField
            label="Дом"
            required
            type="text"
            fullWidth
            error={!house && blur.house}
            helperText={!house && blur.house ? "Обязательное поле" : ""}
            onBlur={() => setBlur({ ...blur, house: true })}
            inputProps={{ maxLength: 10 }}
            value={house}
            onChange={(e) => {
              let newValue = e.target.value;
              if (newValue.slice(0, 1) === "0") newValue = newValue.slice(1);
              setHouse(newValue);
            }}
          />
          <TextField
            margin="dense"
            id="position"
            label="Позиция"
            value={position > 0 ? position : ""}
            onChange={(e) =>
              e.target.value !== ""
                ? !isNaN(Number(e.target.value)) &&
                  Number(e.target.value) > 0 &&
                  Number(e.target.value) < maxSafeNumber &&
                  setPosition(Number(e.target.value))
                : setPosition(0)
            }
            type="text"
            fullWidth
            required
            error={!position && blur.position}
            helperText={!position && blur.position ? "Обязательное поле" : ""}
            onBlur={() => setBlur({ ...blur, position: true })}
          />
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Платежный шлюз
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={paymentGatewayId}
              onChange={(e) => setPaymentGatewayId(e.target.value as string)}
              disabled={paymentGateways.length === 0}
            >
              <MenuItem key={0} value={"0"}>
                Без онлайна
              </MenuItem>
              {paymentGateways.map((g, key) => (
                <MenuItem key={key + 1} value={String(g.id)}>
                  {g.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            id="position"
            label="Лимит заказов"
            value={orderLimit > 0 ? orderLimit : ""}
            onChange={(e) =>
              e.target.value !== ""
                ? !isNaN(Number(e.target.value)) &&
                  Number(e.target.value) > 0 &&
                  Number(e.target.value) < maxSafeNumber &&
                  setOrderLimit(Number(e.target.value))
                : setOrderLimit(0)
            }
            type="text"
            fullWidth
          />
          <List>
            {/*<Typography*/}
            {/*  variant="subtitle1"*/}
            {/*  style={{*/}
            {/*    marginTop: 'calc(8px + 1rem * 0.75)',*/}
            {/*    color: 'rgba(0, 0, 0, 0.54)',*/}
            {/*    fontSize: '12px',*/}
            {/*  }}*/}
            {/*>*/}
            {/*  Способы реализации (выберите хотя бы один из способов и заполните*/}
            {/*  режим работы) **/}
            {/*</Typography>*/}
            <br />
            <ListItem disableGutters>
              <ListItemText id="isLocalPickup" primary="Самовывоз" />
              <ListItemSecondaryAction>
                <Switch
                  edge="end"
                  onChange={() => {
                    setIsLocalPickup(!isLocalPickup);
                    setDeliveryFromLocal(false);
                  }}
                  checked={isLocalPickup}
                  inputProps={{
                    "aria-labelledby": "switch-list-label-bluetooth",
                  }}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <Collapse in={isLocalPickup} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          !pickupFromDelivery
                            ? roundTheClock.pickup
                            : roundTheClock.delivery
                        }
                        onChange={() => {
                          if (roundTheClock.pickup) setDeliveryFromLocal(false);
                          setRoundTheClock({
                            ...roundTheClock,
                            pickup: !roundTheClock.pickup,
                          });
                        }}
                        name="rtcLocal"
                        color="primary"
                        disabled={pickupFromDelivery}
                      />
                    }
                    label="Круглосуточно"
                  />
                </ListItem>
                {deliveryTTComplete && isDelivered && !deliveryFromPickup && (
                  <ListItem>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={pickupFromDelivery}
                          onChange={() =>
                            setLocalFromDelivery(!pickupFromDelivery)
                          }
                          name="pickupFromDelivery"
                          color="primary"
                        />
                      }
                      label="График совпадает с доставкой"
                    />
                  </ListItem>
                )}
                <Collapse
                  in={!roundTheClock.pickup && !pickupFromDelivery}
                  timeout="auto"
                  unmountOnExit
                >
                  <TimeTable
                    timetable={pickupTT}
                    setTimeTable={setPickupTT}
                    blur={blur.pickup}
                    setBlur={(ttblur) => setBlur({ ...blur, pickup: ttblur })}
                    setError={(value) => setTimeTableError(value)}
                  />
                </Collapse>
              </List>
            </Collapse>
            <ListItem disableGutters>
              <ListItemText id="isDelivered" primary="Доставка" />
              <ListItemSecondaryAction>
                <Switch
                  edge="end"
                  onChange={() => {
                    setIsDelivered(!isDelivered);
                    setLocalFromDelivery(false);
                  }}
                  checked={isDelivered}
                  inputProps={{ "aria-labelledby": "switch-list-label-wifi" }}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <Collapse in={isDelivered} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          !deliveryFromPickup
                            ? roundTheClock.delivery
                            : roundTheClock.pickup
                        }
                        onChange={() => {
                          if (roundTheClock.delivery)
                            setLocalFromDelivery(false);
                          setRoundTheClock({
                            ...roundTheClock,
                            delivery: !roundTheClock.delivery,
                          });
                        }}
                        name="rtcDelivery"
                        color="primary"
                        disabled={deliveryFromPickup}
                      />
                    }
                    label="Круглосуточно"
                  />
                </ListItem>
                {pickupTTComplete && isLocalPickup && !pickupFromDelivery && (
                  <ListItem>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={deliveryFromPickup}
                          onChange={() =>
                            setDeliveryFromLocal(!deliveryFromPickup)
                          }
                          name="deliveryFromPickup"
                          color="primary"
                        />
                      }
                      label="График совпадает с самовывозом"
                    />
                  </ListItem>
                )}
                <Collapse
                  in={!roundTheClock.delivery && !deliveryFromPickup}
                  timeout="auto"
                  unmountOnExit
                >
                  <TimeTable
                    timetable={deliveryTT}
                    setTimeTable={setDeliveryTT}
                    blur={blur.delivery}
                    setBlur={(ttblur) => setBlur({ ...blur, delivery: ttblur })}
                    setError={(value) => setTimeTableError(value)}
                  />
                </Collapse>
              </List>
            </Collapse>
          </List>
          <LabelRibbon
            data={districts}
            title={"Районы и улицы"}
            // @ts-ignore
            onConfirm={(data: KladrObjectRibbon[]) => setDistricts(data)}
          >
            <div className={classes.page}>
              {districts.map(
                (el, key) =>
                  el.isChecked && (
                    <Accordion key={key}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        id="additional-actions1-header"
                      >
                        <FormControlLabel
                          aria-label="Acknowledge"
                          onClick={(event) => event.stopPropagation()}
                          onFocus={(event) => event.stopPropagation()}
                          control={
                            <Checkbox
                              color={"primary"}
                              checked={el.isSelected}
                              onChange={(e) => {
                                let isAnyShop = false;
                                for (let child of el.childrenList) {
                                  if (
                                    child.shopId ||
                                    child.aroundTheClockShopId
                                  )
                                    isAnyShop = true;
                                }
                                if (isAnyShop && e.target.checked) {
                                  setCurrentKey(key);
                                  setSelectType("district");
                                  setConfirmWindowOpen(true);
                                } else {
                                  selectDistrict(key);
                                }
                              }}
                            />
                          }
                          label={el.name}
                        />
                      </AccordionSummary>
                      {el.childrenList.map((child, index) => (
                        <AccordionDetails
                          key={index}
                          className={classes.variants}
                        >
                          <div className={classes.variantsWrapper}>
                            <FormControlLabel
                              className={classes.pl15}
                              aria-label="Acknowledge"
                              onFocus={(event) => event.stopPropagation()}
                              control={
                                <Checkbox
                                  color={"primary"}
                                  checked={child.isSelected}
                                  onChange={(e) => {
                                    if (
                                      child.shopId ||
                                      (child.aroundTheClockShopId &&
                                        e.target.checked)
                                    ) {
                                      setCurrentKey(key);
                                      setCurrentIndex(index);
                                      setSelectType("street");
                                      setConfirmWindowOpen(true);
                                    } else {
                                      selectStreet(key, index);
                                    }
                                  }}
                                />
                              }
                              label={child.name}
                            />
                          </div>
                        </AccordionDetails>
                      ))}
                    </Accordion>
                  )
              )}
            </div>
          </LabelRibbon>
        </DialogContent>
        <DialogActions className={commonClasses.dialogActions}>
          {alerted ? (
            <Alert
              severity="warning"
              action={
                <>
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => setModal(false)}
                  >
                    Да
                  </Button>
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => setAlerted(false)}
                  >
                    Нет
                  </Button>
                </>
              }
            >
              Вы действительно хотите отменить все изменения и выйти? Введенные
              данные будут утеряны
            </Alert>
          ) : (
            <>
              <Button
                onClick={() => setModal(false)}
                color="primary"
                disabled={progress}
              >
                Отмена
              </Button>
              <Button
                onClick={onSubmit}
                color="primary"
                type="submit"
                disabled={!readyToSubmit || progress}
              >
                Создать
              </Button>
            </>
          )}
        </DialogActions>
        {progress && <ProgressBar />}
      </Dialog>
    </>
  );
};

export default NewShopForm;
