import React, { FC, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import SelectPromotionProductForm from "./SelectPromotionProductForm";
import { PromotionCategoryRibbon, ProductSet } from "../../_shared/types";

const useStyles = makeStyles({
  page: {
    width: "100%",
  },
  header: {
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "5px 0 10px",
  },
});

const ProductSetList: FC<{
  title: string;
  onConfirm: (data: ProductSet[]) => void;
  data: PromotionCategoryRibbon[];
  children?: React.ReactNode;
}> = ({ children, data, title, onConfirm }) => {
  const [open, setOpen] = useState(false);

  const isAllChecked = () => data.filter((x: any) => !x.isChecked).length === 0;

  const classes = useStyles();

  return (
    <div className={classes.page}>
      <SelectPromotionProductForm
        title={title}
        data={data}
        onConfirm={onConfirm}
        isOpen={open}
        onClose={() => setOpen(false)}
      />
      <div className={classes.header}>
        {title}
        <IconButton
          aria-label="add"
          color={"primary"}
          disabled={isAllChecked()}
          onClick={() => setOpen(true)}
        >
          <AddIcon />
        </IconButton>
      </div>
      {children}
    </div>
  );
};

export default ProductSetList;
