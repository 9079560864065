import React, { useContext, useEffect, useState, useRef } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  Button,
} from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Remove";
import ReplyIcon from "@material-ui/icons/Reply";
import FlexRow from "../FlexRow";
import styles from "./index.module.scss";
import API from "../../../../_shared/axios";
import { alertError, getErrorMsg } from "../../../../_shared/utils";
import {
  AlertContextType,
  DispatcherProduct,
  ProductEdit,
} from "../../../../_shared/types";
import { AlertContext } from "../../../_shared/ToastList";
import OptionSelect from "../OptionSelect";
import CounterButton from "../../../_shared/CounterButton";
import { Alert } from "@material-ui/lab";

export type IngsToRemove = { id: number; name: string };
export type IngToAdd = {
  id: number;
  quantity: number;
  name: string;
  isActive?: boolean;
};

const parseNaN = (n: any) => (n ? n : 0);

const getOptions = (data: any) =>
  data.variants[0]?.values.map((i: any) => ({
    option: i.optionData,
    values: [
      // @ts-ignore
      ...new Set(
        data.variants.map(
          (v: any) =>
            v.values.find((val: any) => val.optionData === i.optionData)
              .optionValueData
        )
      ),
    ].sort((a, b) => (a < b ? -1 : a > b ? 1 : 0)),
  }));

const getCurrentIngs = (ings: any, vID: any) => {
  return ings.reduce(
    (sum: any, i: any) =>
      i.isCommon ||
      i.variants?.filter((v: any) => v.variantId === vID).length !== 0
        ? {
            ...sum,
            [i.id]: i.isCommon
              ? i
              : {
                  ...i,
                  price: i.variants
                    ? i.variants.find((v: any) => v.variantId === vID)
                      ? i.variants.find((v: any) => v.variantId === vID).price
                      : i.price
                    : i.price,
                  weight: i.variants
                    ? i.variants.find((v: any) => v.variantId === vID)
                      ? i.variants.find((v: any) => v.variantId === vID).weight
                      : i.weight
                    : i.weight,
                },
          }
        : sum,
    []
  );
};

function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export default function ProductForm({
  productId,
  touchCart,
  onClose,
  product,
  productOptionsProp,
}: {
  productId: number;
  touchCart: (data: DispatcherProduct) => void;
  onClose: () => void;
  product: DispatcherProduct | null;
  productOptionsProp?: { [id: string]: string[] } | null;
}) {
  const alertContext = useContext<AlertContextType>(AlertContext);

  const [alerted, setAlerted] = useState(false);
  const [touched, setTouched] = useState(false);

  const [options, setOptions] = useState<any>(null);
  const [productOptions, setProductOptions] = useState<string[]>([]);
  const [variant, setVariant] = useState<any>(null);
  const [productData, setProductData] = useState<null | ProductEdit>(null);
  const [isAvailableVariant, setIsAvailableVariant] = useState(true);
  const [productCopied, setProductCopied] = useState(false);
  const [isVariantSetted, setVariantSetted] = useState(false);
  const [cart, setCart] = useState<any>();
  const [ingAdd, setIngAdd] = useState<any>({});
  const [ingRem, setIngRem] = useState<any>({});
  const [ingredients, setIngredients] = useState<any>([]);

  const prevOptions = usePrevious(options);

  useEffect(() => {
    API.get(`/products/${productId}`)
      .then(({ data }: { data: ProductEdit }) => {
        setProductData({
          ...data,
          ingredientsForAdding: data.ingredientsForAdding.filter(
            (el) => el.isActive
          ),
          ingredientsForRemoving: data.ingredientsForRemoving.filter(
            (el) => el.isActive
          ),
        });
      })
      .catch((error) => {
        alertError(
          alertContext,
          getErrorMsg(error.response, "Ошибка получения товарв")
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  useEffect(() => {
    if (product) {
      const added: any = {};
      product.ingredientsToAdd.map((v: any) => {
        return (added[v.ingredientId] = { ...v });
      });

      const removed: any = {};
      product.ingredientsToRemove.map((v: any) => {
        return (removed[v.id] = { ...v });
      });

      setIngAdd(added);
      setIngRem(removed);
    }
  }, []);

  useEffect(() => {
    if (productData) {
      const opt = getOptions(productData)?.reduce(
        (sum: any, o: any) => ({
          ...sum,
          [o.option]: o.values.reduce(
            (sum: any, o: any, i: any) => ({ ...sum, [o]: false }),
            {}
          ),
        }),
        {}
      );

      const newIngRem = { data: productData?.ingredientsForRemoving };
      const newIngAdd = { data: productData?.ingredientsForAdding };
      setIngredients({
        forAdd: newIngAdd.data,
        forRemove: newIngRem.data.map((v) => {
          return { id: v.id, name: v.name };
        }),
      });

      if (productData.variants.length && productData.variants[0]) {
        productData.variants[0].values.forEach((v) => {
          opt[v.optionData][v.optionValueData] = true;
        });
      }

      setOptions(opt ? opt : {});
    }
  }, [productData]);

  useEffect(() => {
    if (productData && options !== null) {
      if (Object.keys(options).length > 0) {
        if (
          productData?.variants.length &&
          productData?.variants?.some((el) => el.price)
        ) {
          const optmap = Object.keys(options).map((o) => [
            o,
            // @ts-ignore
            Object.keys(options[o]).find((v) => options[o][v]),
          ]);

          let current = productData?.variants
            .filter((variant) => variant.values.length === optmap.length)
            .find((v) =>
              v.values
                .map(
                  (val) =>
                    optmap.find((m) => m[0] === val.optionData)![1] ===
                    val.optionValueData
                )
                .every((v) => v === true)
            );

          if (!current) {
            let newOptions = {};
            let chosenKey = { name: "k", value: "name" };

            if (options && options.length === optmap.length) {
              Object.entries(options).map(([k, v]) => {
                // @ts-ignore
                Object.entries(v).map(([name, value]) => {
                  // @ts-ignore
                  if (prevOptions[k][name] !== value && value === true) {
                    chosenKey = { name: k, value: name };
                  }
                  return true;
                });
                return true;
              });

              current = productData.variants.find((v) =>
                v.values
                  .map(
                    (val) =>
                      val.optionData === chosenKey.name &&
                      val.optionValueData === chosenKey.value
                  )
                  .some((v) => v)
              )!;

              current.values.forEach((val) => {
                // @ts-ignore
                newOptions[val.optionData] = { [val.optionValueData]: true };
              });

              setOptions({ ...options, ...newOptions });
            }
          } else {
            setVariant(
              variant?.variantId !== current.variantId
                ? {
                    price: current.price ? current.price : productData.price,
                    variantId:
                      product && !isVariantSetted
                        ? product.variantId
                        : current.variantId,
                  }
                : {
                    price: productData.price,
                  }
            );
          }
        } else {
          setVariant({
            price: productData.price,
          });
        }
      } else {
        setVariant({
          price: productData.price,
        });
      }
    }
  }, [productData, options]);

  useEffect(() => {
    if (variant) setVariantSetted(true);
  }, [variant]);

  useEffect(() => {
    if (
      productData &&
      ((variant &&
        productData.variants
          .map((el) => el.variantId)
          .includes(variant.variantId)) ||
        productData.variants.length === 0)
    )
      setIsAvailableVariant(true);
    else setIsAvailableVariant(false);
  }, [variant, productData]);

  useEffect(() => {
    if (ingredients.forAdd && ingredients.forRemove && variant) {
      if (variant.values)
        setProductOptions(
          variant.values.reduce(
            (sum: string[], acc: any) => [...sum, acc.optionValueData],
            []
          )
        );
      const ing = {
        add: Object.entries(ingredients.forAdd).map(([id, i]: [any, any]) => {
          const count = product?.ingredientsToAdd.find(
            (el) => el.ingredientId === id
          )?.quantity;
          return {
            id: id,
            count: !productCopied && count ? count : 0,
            name: i.name,
          };
        }),
        remove: Object.entries(ingredients.forRemove).map(
          ([id, i]: [any, any]) => ({
            id: id,
            selected: productCopied
              ? false
              : product?.ingredientsToRemove.map((el) => el.id).includes(id),
            name: i.name,
          })
        ),
      };

      if (!productCopied) setProductCopied(true);
    }
  }, [ingredients, variant]);

  useEffect(() => {
    if (cart) {
      if (
        typeof cart.productId !== "undefined" &&
        typeof cart.quantity !== "undefined"
      ) {
        const curIngs = getCurrentIngs(ingredients.forAdd, variant.variantId);
        touchCart({
          ...cart,
          options: [productOptions],
          price:
            variant.price +
            Object.entries(ingAdd).reduce(
              (sum: any, [id, ing]: any) =>
                sum +
                ing.quantity *
                  parseNaN(
                    curIngs[id]?.variants?.find(
                      (v: any) => v.variantId === variant.variantId
                    )
                      ? curIngs[id]?.variants?.find(
                          (v: any) => v.variantId === variant.variantId
                        )?.price
                      : curIngs[id]?.price
                  ),
              0
            ),
        });
      }

      if (!cart.quantity) setCart(undefined);
    }
  }, [cart]);

  const ingredientsToAdd = () =>
    ingredients.forRemove && variant
      ? getCurrentIngs(ingredients.forAdd, variant.variantId)
      : {};
  const ingredientsToRemove = () =>
    ingredients.forRemove && variant
      ? getCurrentIngs(ingredients.forRemove, variant.variantId)
      : {};

  const IngsAddHandler = (id: number) => {
    if (ingAdd[id]) {
      setIngAdd((state: any) => {
        return {
          ...state,
          [id]: { ...state[id], quantity: state[id].quantity + 1 },
        };
      });
    } else {
      setIngAdd((state: any) => {
        return { ...state, [id]: { ...ingredientsToAdd()[id], quantity: 1 } };
      });
    }
  };

  const IngsRemoveHandler = (id: number) => {
    if (ingAdd[id]?.quantity < 2) {
      setIngAdd((state: any) => {
        delete state[id];
        return { ...state };
      });
    } else {
      setIngAdd((state: any) => {
        return {
          ...state,
          [id]: { ...state[id], quantity: state[id].quantity - 1 },
        };
      });
    }
  };

  const getOptionLabel = () => {
    let result = "";
    const currentVariant = productData?.variants.find(
      (el) => el.variantId === variant.variantId
    )!;

    currentVariant.values.forEach(
      (el: { optionData: string; optionValueData: string }, index: number) => {
        result += `${el.optionData}: ${el.optionValueData}`;
        if (index !== currentVariant.values.length - 1) result += ", ";
      }
    );
    return result.toLowerCase();
  };

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={true}
      fullWidth
      maxWidth={"sm"}
      onBackdropClick={() => (touched ? setAlerted(true) : onClose())}
    >
      <DialogContent dividers className={styles.content}>
        <Typography gutterBottom>
          <b>{productData?.name}</b>
        </Typography>
        {productData?.variants.length ? (
          variant?.variantId ? (
            <Typography gutterBottom>{getOptionLabel()}</Typography>
          ) : (
            <Typography gutterBottom>выбранный вариант недоступен</Typography>
          )
        ) : null}
        <br />
        {productData && (
          // @ts-ignore
          <OptionSelect
            product={productData}
            variant={variant}
            setVariant={(i: any) => {
              if (productData && isVariantSetted) {
                setVariant(
                  !i
                    ? { price: productData?.price }
                    : i.price
                    ? i
                    : { ...i, price: productData?.price }
                );
              }
            }}
          />
        )}
        <br />
        {!!Object.keys(ingredientsToRemove()).length && (
          <>
            <Typography gutterBottom>Убрать ингредиенты:</Typography>
            <FlexRow>
              {Object.entries(ingredientsToRemove()).map(
                ([id, ingredient]: [any, any]) => {
                  const isChecked = ingRem[id];
                  return (
                    <Button
                      key={ingredient.id}
                      className={styles.button}
                      variant="contained"
                      color={isChecked ? "secondary" : "default"}
                      endIcon={isChecked ? <ReplyIcon /> : <RemoveIcon />}
                      onClick={() => {
                        isChecked
                          ? setIngRem((state: any) => {
                              delete state[id];
                              return { ...state };
                            })
                          : setIngRem((state: any) => {
                              return { ...state, [id]: ingredient };
                            });
                        !touched && setTouched(true);
                      }}
                    >
                      {ingredient.name}
                    </Button>
                  );
                }
              )}
            </FlexRow>
            <br />
          </>
        )}
        {!!Object.keys(ingredientsToAdd()).length && (
          <>
            <Typography gutterBottom>Добавить ингредиенты:</Typography>
            <FlexRow>
              {Object.entries(ingredientsToAdd()).map(
                ([id, ingredient]: [any, any]) =>
                  ingAdd[id] ? (
                    <CounterButton
                      name={ingredient.name}
                      add={() => IngsAddHandler(ingredient.id)}
                      remove={() => IngsRemoveHandler(ingredient.id)}
                      amount={ingAdd[id].quantity ? ingAdd[id].quantity : 0}
                      max={ingredient.maxQuantity}
                    />
                  ) : (
                    <Button
                      key={ingredient.id}
                      className={styles.button}
                      variant="contained"
                      color={"default"}
                      onClick={() => {
                        !touched && setTouched(true);
                        setIngAdd((state: any) => {
                          return {
                            ...state,
                            [id]: { ...ingredient, quantity: 1 },
                          };
                        });
                      }}
                    >
                      {ingredient.name}
                    </Button>
                  )
              )}
            </FlexRow>
          </>
        )}
      </DialogContent>
      <DialogActions>
        {alerted ? (
          <Alert
            severity="warning"
            action={
              <>
                <Button color="inherit" size="small" onClick={() => onClose()}>
                  Да
                </Button>
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => setAlerted(false)}
                >
                  Нет
                </Button>
              </>
            }
          >
            Вы действительно хотите отменить все изменения и выйти? Введенные
            данные будут утеряны
          </Alert>
        ) : (
          <>
            <Typography>
              <b>
                {variant &&
                  (cart?.quantity ? cart.quantity : 1) *
                    (variant.price +
                      Object.entries(ingAdd)?.reduce(
                        (sum: any, [id, i]: any) =>
                          sum +
                          i.quantity *
                            parseNaN(
                              getCurrentIngs(
                                ingredients.forAdd,
                                variant.variantId
                              )[id]?.variants?.find(
                                (v: any) => v.variantId === variant.variantId
                              )
                                ? getCurrentIngs(
                                    ingredients.forAdd,
                                    variant.variantId
                                  )[id]?.variants?.find(
                                    (v: any) =>
                                      v.variantId === variant.variantId
                                  )?.price
                                : getCurrentIngs(
                                    ingredients.forAdd,
                                    variant.variantId
                                  )[id]?.price
                            ),
                        0
                      ))}{" "}
                ₽
              </b>
            </Typography>
            <Button
              size="small"
              className={styles.actionBtn}
              autoFocus
              disabled={!isAvailableVariant}
              color="primary"
              variant="contained"
              onClick={() => {
                setCart({
                  productId: productId ? productId : productData?.id,
                  variantId: variant.variantId,
                  quantity: 1,
                  ingredientsToRemove: Object.entries(ingRem).map(
                    ([id, ing]: any) => ({
                      id: id,
                      name: ing.name,
                    })
                  ),
                  ingredientsToAdd: Object.entries(ingAdd).map(
                    ([id, ing]: any) => ({
                      ingredientId: id,
                      quantity: ing.quantity,
                      name: ing.name,
                    })
                  ),
                });
              }}
            >
              {product ? "Сохранить" : "Добавить"}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
