import React, { useState } from "react";
import { getIntFromString } from "./utils";

export function useInput<T>(initialValue: T) {
  const [value, setValue] = useState(initialValue);

  const onChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => setValue(e.target.value as T);

  return {
    fields: {
      value,
      onChange,
    },
    setValue,
  };
}

export function useIntInput(initialValue: number) {
  const [value, setValue] = useState(initialValue);

  const onChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) =>
    e.target.value === " "
      ? null
      : setValue(getIntFromString(e.target.value as string));

  return {
    fields: {
      value,
      onChange,
    },
    setValue,
  };
}
