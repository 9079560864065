import React, { useState, useEffect, useContext, FC, useCallback } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import BlockIcon from "@material-ui/icons/Block";
import DropdownOptions from "../_shared/DropdownOptions";
import { AlertContextType } from "../../_shared/types";
import NewFilterForm from "./NewFilterForm";
import EditFilterForm from "./EditFilterForm";
import API from "../../_shared/axios";
import { AlertContext } from "../_shared/ToastList";
import { MPData, Filter } from "./_shared/types";
import styles from "../_shared/styles";
import { alertError, alertSuccess, getErrorMsg } from "../../_shared/utils";
import { makeStyles } from "@material-ui/styles";
import { ProgressBar } from "../_shared/ProgressBar";

const useStyles = makeStyles({
  page: {
    margin: 0,
  },
  content: {
    padding: 50,
    maxWidth: 1140,
    margin: "0 auto",
    "& .MuiTableCell-body": {
      cursor: "pointer",
    },
    "& th.thumbnail": {
      width: "25%",
      "& img": {
        width: 100,
      },
    },
    "& .MuiTableRow-root.false": {
      backgroundColor: "#fafafa",
      "& > *": {
        color: "gray",
      },
    },
  },
});

const FiltersPage: FC = () => {
  const [rows, setRows] = useState<Filter[]>([]);
  const [data, setData] = useState<Filter | undefined>();

  const alertContext = useContext<AlertContextType>(AlertContext);
  const commonClasses = styles();
  const classes = useStyles();
  const [progress, setProgress] = useState(false);

  useEffect(() => {
    setProgress(true);
    API.get(`/filters`)
      .then(({ data }) => {
        setRows(data);
        setProgress(false);
      })
      .catch((error) => {
        alertError(
          alertContext,
          getErrorMsg(error.response, "Ошибка получения списка фильтров")
        );
        setProgress(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dropdownMenuOptions = useCallback(
    (row: Filter) => [
      {
        icon: <BlockIcon fontSize={"small"} />,
        text: row.isActive ? "Заблокировать" : "Разблокировать",
        event: () => {
          API.post(`/filters/${row.id}/${row.isActive ? "block" : "unblock"}`)
            .then(() =>
              API.get(`/filters`)
                .then(({ data }) => setRows(data))
                .catch((error) =>
                  alertError(
                    alertContext,
                    getErrorMsg(
                      error.response,
                      "Ошибка обновления списка фильтров"
                    )
                  )
                )
            )
            .catch((error) =>
              alertError(
                alertContext,
                getErrorMsg(error.response, "Ошибка блокировки фильтра")
              )
            );
        },
      },
      {
        icon: <DeleteIcon fontSize={"small"} />,
        text: "Удалить",
        event: () =>
          API.delete(`/filters/${row.id}`)
            .then(() => {
              alertSuccess(alertContext, "Фильтр удален");
              API.get(`/filters`)
                .then(({ data }) => setRows(data))
                .catch((error) =>
                  alertError(
                    alertContext,
                    getErrorMsg(
                      error.response,
                      "Ошибка обновления списка фильтров"
                    )
                  )
                );
            })
            .catch((error) =>
              alertError(
                alertContext,
                getErrorMsg(error.response, "Ошибка удаления фильтра")
              )
            ),
      },
    ],
    [alertContext]
  );

  const onSend = (data: MPData) =>
    new Promise((resolve, reject) => {
      API.post(`/filters`, data)
        .then(({ data }) => {
          alertSuccess(alertContext, "Фильтр добавлен");
          API.get(`/filters`)
            .then(({ data }) => {
              setRows(data);
              resolve(data);
            })
            .catch((error) => {
              alertError(
                alertContext,
                getErrorMsg(error.response, "Ошибка обновления списка фильтров")
              );
              reject(error);
            });
        })
        .catch((error) => {
          alertError(
            alertContext,
            getErrorMsg(error.response, "Ошибка добавления фильтра")
          );
          reject(error);
        });
    });

  const onEdit = (id: number, data: MPData) =>
    new Promise((resolve, reject) => {
      API.patch(`/filters/${id}`, data)
        .then(({ data }) => {
          alertSuccess(alertContext, "Успешное редактирование фильтра");
          API.get(`/filters`)
            .then(({ data }) => {
              setRows(data);
              resolve(data);
            })
            .catch((error) => {
              alertError(
                alertContext,
                getErrorMsg(error.response, "Ошибка обновления списка фильтров")
              );
              reject(error);
            });
        })
        .catch((error) => {
          alertError(
            alertContext,
            getErrorMsg(error.response, "Ошибка редактирования фильтра")
          );
          reject(error);
        });
    });

  return (
    <div className={classes.page}>
      <EditFilterForm
        onSend={onEdit}
        data={data}
        onClose={() => setData(undefined)}
      />
      <div className={classes.content}>
        <h2>
          Фильтры &nbsp;&nbsp; <NewFilterForm onSend={onSend} />
        </h2>
        {progress ? (
          <ProgressBar />
        ) : (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Изображение</TableCell>
                  <TableCell align="left">Наименование</TableCell>
                  <TableCell align="left">Описание</TableCell>
                  <TableCell align="left">Позиция</TableCell>
                  <TableCell align="left"> </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, key) => (
                  <TableRow
                    key={key}
                    className={
                      !row.isActive ? commonClasses.blocked : undefined
                    }
                    hover
                    onClick={() => setData(row)}
                  >
                    <TableCell className="thumbnail" component="th" scope="row">
                      {row.thumbnail ? (
                        <img src={row.thumbnail} alt={row.thumbnail} />
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.name ? row.name : "-"}
                    </TableCell>
                    <TableCell align="left">
                      {row.description ? row.description : "-"}
                    </TableCell>
                    <TableCell align="left">
                      {row.position ? row.position : "-"}
                    </TableCell>
                    <TableCell align="left">
                      <DropdownOptions
                        options={() => dropdownMenuOptions(row)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </div>
  );
};

export default FiltersPage;
