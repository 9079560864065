export type BlurType = {
  mon: {
    from: boolean;
    till: boolean;
  };
  tue: {
    from: boolean;
    till: boolean;
  };
  wed: {
    from: boolean;
    till: boolean;
  };
  thu: {
    from: boolean;
    till: boolean;
  };
  fri: {
    from: boolean;
    till: boolean;
  };
  sat: {
    from: boolean;
    till: boolean;
  };
  sun: {
    from: boolean;
    till: boolean;
  };
};

export type WorkingTimeProcessed = {
  mon: {
    from: string;
    till: string;
  };
  tue: {
    from: string;
    till: string;
  };
  wed: {
    from: string;
    till: string;
  };
  thu: {
    from: string;
    till: string;
  };
  fri: {
    from: string;
    till: string;
  };
  sat: {
    from: string;
    till: string;
  };
  sun: {
    from: string;
    till: string;
  };
};

export type PaymentGateway = {
  id: number;
  isActive: boolean;
  name: string;
};

export const weekDays = [
  { eng: "mon", rus: "пн", num: 0 },
  { eng: "tue", rus: "вт", num: 1 },
  { eng: "wed", rus: "ср", num: 2 },
  { eng: "thu", rus: "чт", num: 3 },
  { eng: "fri", rus: "пт", num: 4 },
  { eng: "sat", rus: "сб", num: 5 },
  { eng: "sun", rus: "вс", num: 6 },
];
