import React from "react";
import styles from "./index.module.scss";

export default function FlexRow({
  children,
  className,
  onClick,
}: {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
}) {
  return (
    <div className={[styles.row, className].join(" ")} onClick={onClick}>
      {children}
    </div>
  );
}
