import React, { FC, useContext, useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  AlertContextType,
  OptionData,
  OptionTypeCreate,
  OptionTypes,
} from "../../_shared/types";
import { TableBody, FormControlLabel, Checkbox } from "@material-ui/core";
import { AlertContext } from "../_shared/ToastList";
import API from "../../_shared/axios";
import NewOptionForm from "./NewOptionForm";
import EditOptionForm from "./EditOptionForm";
import { alertError, alertSuccess } from "../../_shared/utils";
import { makeStyles } from "@material-ui/styles";
import { ProgressBar } from "../_shared/ProgressBar";

const columns = [
  { id: "name", label: "Наименование" },
  {
    id: "style",
    label: "Стиль",
  },
  {
    id: "pricing",
    label: "Ценообразование",
  },
  {
    id: "isAvailable",
    label: "Доступность",
  },
];

const useStyles = makeStyles({
  page: {
    height: "calc(100% - 64px)",
  },
  minWidth: { minWidth: 100 },
  content: {
    padding: 50,
    maxWidth: 1140,
    width: "100%",
    margin: "0 auto 20px auto",
    "& .MuiTableCell-body": {
      cursor: "pointer",
    },
  },
});

const OptionsPage: FC = () => {
  const [editOptionId, setEditOptionId] = useState<number | undefined>(
    undefined
  );
  const [rows, setRows] = useState<OptionData[]>([]);
  const [types, setTypes] = useState<OptionTypes[]>([]);
  const [progress, setProgress] = useState(false);

  const classes = useStyles();

  const alertContext = useContext<AlertContextType>(AlertContext);

  useEffect(() => {
    setProgress(true);
    API.get(`/options/types`)
      .then(({ data }) => {
        setTypes(data);
        API.get(`/options`)
          .then(({ data }) => {
            setRows(data);
            setProgress(false);
          })
          .catch((error) => {
            setProgress(false);
            alertError(alertContext, "Ошибка получения списка опций");
          });
      })
      .catch((error) => {
        setProgress(false);
        alertError(alertContext, "Ошибка получения типов опций");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onOptionCreate = (data: OptionTypeCreate) =>
    new Promise((resolve, reject) =>
      API.post("/options", data)
        .then(({ data }) => {
          alertSuccess(alertContext, "Опция добавлена");
          API.get(`/options`)
            .then(({ data }) => {
              setRows(data);
              resolve(data);
            })
            .catch((error) => {
              alertError(alertContext, "Ошибка обновления списка опций");
              reject(error);
            });
        })
        .catch((error) => {
          alertError(alertContext, "Ошибка добавления опции");
          reject(error);
        })
    );

  const onOptionEdit = (id: number, option: OptionTypeCreate) =>
    new Promise((resolve, reject) =>
      API.patch(`/options/${id}`, option)
        .then(({ data }) => {
          alertSuccess(alertContext, "Успешное редактирование опции");
          API.get(`/options`)
            .then(({ data }) => {
              setRows(data);
              resolve(data);
            })
            .catch((error) => {
              alertError(alertContext, "Ошибка получения списка опций");
              reject(error);
            });
        })
        .catch((error) => {
          alertError(alertContext, "Ошибка редактирования опции");
          reject(error);
        })
    );

  return (
    <div className={classes.page}>
      <EditOptionForm
        onOptionEdit={onOptionEdit}
        optionId={editOptionId}
        onClose={() => setEditOptionId(undefined)}
      />
      <div className={classes.content}>
        <NewOptionForm onOptionCreate={onOptionCreate} />
        {progress ? (
          <ProgressBar />
        ) : (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} className={classes.minWidth}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, key) => {
                  return (
                    row.type < types.length && (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={key}
                        onClick={() => setEditOptionId(row.id)}
                      >
                        <TableCell>{row.value}</TableCell>
                        <TableCell>{types[row.type].description}</TableCell>
                        <TableCell>
                          <FormControlLabel
                            disabled
                            checked={row.impactOnPrice}
                            control={<Checkbox name="checkedD" />}
                            label=""
                          />
                        </TableCell>
                        <TableCell>
                          <FormControlLabel
                            disabled
                            checked={row.isActive}
                            control={<Checkbox name="checkedD" />}
                            label=""
                          />
                        </TableCell>
                      </TableRow>
                    )
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </div>
  );
};

export default OptionsPage;
