import { unstable_createMuiStrictModeTheme } from "@material-ui/core";

const customTheme = unstable_createMuiStrictModeTheme({
  typography: {
    button: {
      textTransform: "none",
    },
  },
  overrides: {
    MuiTableCell: {
      body: {
        wordBreak: "break-word",
      },
    },
    MuiCardHeader: {
      root: {
        flexDirection: "row-reverse",
      },
      avatar: {
        marginRight: "initial",
        marginLeft: "16px",
      },
    },
  },
});

export default customTheme;
