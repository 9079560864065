import React, { FC, useContext, useEffect, useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Avatar,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {
  AlertContextType,
  Category,
  Ingredient,
  LabelRibbonData,
  Option,
  PCDataEdit,
  ProductEdit,
  ProductRow,
  CurrentOption,
  PromotionRow,
  PromotionCategory,
} from "../../_shared/types";
import { makeStyles } from "@material-ui/styles";
import PublishIcon from "@material-ui/icons/Publish";
import API from "../../_shared/axios";
import {
  alertError,
  copyObject,
  getFloatFromString,
  getIntFromString,
  getSizeOfStringInBytes,
  isObjectsEqual,
  renderPrice,
} from "../../_shared/utils";
import { AlertContext } from "../_shared/ToastList";
import { Filter } from "../FiltersPage/_shared/types";
import { ProgressBar } from "../_shared/ProgressBar";
import LabelRibbon from "../_shared/LabelRibbon";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles({
  marginWidth: {
    marginRight: "2% !important",
    width: "32%",
  },
  variantWrapper: {
    padding: "8px 30px 8px 16px",
    border: "1px solid #ccc",
    borderRadius: 4,
    marginRight: 10,
    marginTop: 10,
    marginBottom: 10,
    maxWidth: 234,
    position: "relative",
  },
  variantDeleteIcon: {
    position: "absolute",
    top: 8,
    right: 6,
    zIndex: 100,
    cursor: "pointer",
  },
  variants: {
    marginTop: 10,
    display: "flex",
    flexWrap: "wrap",
  },
  requiredWarning: {
    color: "#f44336",
    margin: "4px 0 0 0",
    fontSize: "0.75rem",
    letterSpacing: "0.03333em",
    lineHeight: "1.66",
  },
  accordionDetails: { display: "flex", alignItems: "center" },
  detail: { paddingLeft: 15 },
  colorError: { color: "#f44336" },
  colorBlack: { color: "rgba(0, 0, 0, 0.87)" },
  mt20: { marginTop: 20 },
  entityList: {
    "& > div": {
      margin: "0 10px 25px 0",
      color: "rgba(0, 0, 0, 0.87) !important",
      "& > span": {
        fontWeight: "bold !important",
        fontSize: "14px !important",
      },
    },
  },
  isRecommended: { marginTop: 20, marginRight: "2% !important", width: "32%" },
  alert: { width: "100%" },
  hasFreeSauce: { marginTop: 20 },
  carbohydrates: { width: "32%" },
  productName: {
    marginTop: 30,
  },
  imageDisplay: {
    display: "none",
  },
  accordionSummary: {
    fontSize: 16,
  },
  large: {
    width: "100%",
    height: "220px",
    "& img": {
      objectFit: "contain",
    },
  },
  options: {
    width: "100%",
    flexDirection: "column",
    marginBottom: 5,
    "& > div": {
      "& .MuiAccordionSummary-content": {
        margin: "0 !important",
        width: "100% !important",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      },
      "& .MuiAccordionDetails-root": {
        padding: "0 0 0 20px !important",
        "&:last-child": {
          paddingBottom: "10px !important",
        },
      },
    },
  },
  detailsWrapper: {
    width: "60%",
    display: "flex",
    alignItems: "center",
  },
  imgWrapper: {
    position: "relative",
    width: "50%",
    margin: "auto",
    "& img": {
      objectFit: "contain",
    },
  },
  imgControls: {
    position: "absolute",
    right: "1rem",
    bottom: "1rem",
  },
});

const EditProductForm: FC<{
  productId?: number;
  onProductEdit: (id: number, product: PCDataEdit) => Promise<any>;
  onClose: () => void;
}> = ({ productId, onProductEdit, onClose }) => {
  const productInit: ProductRow = {
    id: 1,
    thumbnail: "",
    name: "",
    categoryId: 0,
    description: "",
    price: "",
    position: 0,
    weight: "0",
    isDeleted: false,
    hasVariants: false,
    nutrition: {
      fats: 0,
      carbohydrates: 0,
      proteins: 0,
      calories: 0,
    },
    imgName: "",
    calories: "0",
    variants: [],
    proteins: "0",
    fats: "0",
    carbohydrates: "0",
    hasFreeSauce: true,
    isRecommended: true,
    isPickupOnly: false,
    isActive: true,
  };
  const [product, setProduct] = useState(productInit);
  const [oldProduct, setOldProduct] = useState(productInit);

  const [progress, setProgress] = useState(false);

  const [categories, setCategories] = useState<
    { id: number; name: string; isActive: boolean }[]
  >([]);
  const [directoryId, setDirectoryId] = useState<number>();
  const classes = useStyles();

  const fileRef = useRef<HTMLInputElement>(null);

  const [oldFilters, setOldFilters] = useState<LabelRibbonData[]>([]);
  const [filters, setFilters] = useState<LabelRibbonData[]>([]);
  const [ingredients, setIngredients] = useState<LabelRibbonData[]>([]);
  const [deletedIngredients, setDeletedIngredients] = useState<
    LabelRibbonData[]
  >([]);
  const [oldDeletedIngredients, setOldDeletedIngredients] = useState<
    LabelRibbonData[]
  >([]);
  const [addedIngredients, setAddedIngredients] = useState<LabelRibbonData[]>(
    []
  );
  const [oldVariantNames, setOldVariantsNames] = useState<
    {
      optionName: string | undefined;
      optionValueName: string | undefined;
      optionId: number;
    }[][]
  >([]);
  const [variantsNames, setVariantsNames] = useState<
    {
      optionName: string | undefined;
      optionValueName: string | undefined;
      optionId: number;
    }[][]
  >([]);
  const [productOptionVariants, setProductOptionVariants] = useState<
    {
      variantId: number;
      values: { optionId: number; optionValueId: number }[];
      price: number | string;
      weight: number | string;
    }[]
  >([]);
  const [oldProductOptionVariants, setOldProductOptionVariants] = useState<
    {
      values: { optionId: number; optionValueId: number }[];
      price: number | string;
    }[]
  >([]);
  const [options, setOptions] = useState<
    {
      id: number;
      name: string;
      isChecked: boolean;
      categories: number[];
      isActive: boolean;
      impactOnPrice: boolean;
      optionVariants: {
        id: number;
        value: string;
        price?: string | number;
        weight?: string | number;
        isChecked?: boolean;
      }[];
    }[]
  >([]);

  const [currentOptions, setCurrentOptions] = useState<CurrentOption[]>([]);
  const [oldCurrentOptions, setOldCurrentOptions] = useState<CurrentOption[]>(
    []
  );
  const [oldAddedIngredients, setOldAddedIngredients] = useState<
    LabelRibbonData[]
  >([]);
  const [thumbnail, setThumbnail] = useState<any>(undefined);
  const [image, setImage] = useState<File | undefined>(undefined);
  const [productOptionsInit, setProductOptionsInit] = useState(true);
  const alertContext = useContext<AlertContextType>(AlertContext);
  const [usedCombinations, setUsedCombinations] = useState([]);

  useEffect(() => {
    if (options.length > 0) {
      // получаем айди опций товара
      let optionsIds: number[] = [];
      for (let variant of product.variants) {
        for (let value of variant.values) {
          if (!optionsIds.includes(value.optionId))
            optionsIds = [...optionsIds, value.optionId];
        }
      }
      // генерируем массив этих опций
      let newOptions = options
        .filter((el) => el.categories?.includes(product.categoryId))
        .map((el) => {
          return {
            ...el,
            isChecked: optionsIds.includes(el.id),
            optionVariants: el.optionVariants.map((variant) => {
              return { ...variant, isChecked: false };
            }),
          };
        });
      // если это инициализация
      if (newOptions.length > 0 && productOptionsInit) {
        setProductOptionsInit(false);
        const currentOptionsIds = newOptions.map((el: any) => el.id);
        // проставляем у вариантов isChecked = true
        for (let variant of product.variants) {
          for (let value of variant.values) {
            const index = currentOptionsIds.indexOf(value.optionId);
            const optionValueIndex = newOptions[index].optionVariants
              .map((el: any) => el.id)
              .indexOf(value.optionValueId);
            newOptions[index].optionVariants[optionValueIndex].isChecked = true;
          }
        }
        // берем только выбранные опции
        const newCurrentOptionsFiltered = newOptions.filter(
          (el: any) => el.isChecked
        );

        // итоговый объект вариантов
        let variants: any = [];
        let combinations: {
          optionId: number;
          optionValueId: number;
        }[] = [];

        // функция получает текущие комбинации.
        const getValues = (
          combinations: {
            optionId: number;
            optionValueId: number;
          }[]
        ) => {
          // ПОЛУЧАЕМ ВСЕ НЕ ПРОЙДЕННЫЕ ЕЩЁ ОПЦИИ, КОТОРЫЕ СОДЕРЖАТ МИНИМУМ 1 ВЫДЕЛЕННЫЙ ВАРИАНТ
          const otherOptions = newCurrentOptionsFiltered
            .filter(
              (el: any) =>
                !combinations.map((el) => el.optionId).includes(el.id)
            )
            .filter(
              (el: any) =>
                el.optionVariants.filter((el: any) => el.isChecked).length > 0
            );
          if (otherOptions.length === 0) variants = [...variants, combinations];
          else {
            for (let option of otherOptions) {
              for (let variant of option.optionVariants.filter(
                (el: any) => el.isChecked
              )) {
                getValues([
                  ...combinations,
                  {
                    optionId: option.id,
                    optionValueId: variant.id,
                  },
                ]);
              }
            }
          }
        };

        // ПРОХОДИМ ПО ВСЕМ ОПЦИЯМ, КОТОРЫЕ СОДЕРЖАТ ВЫДЕЛЕННЫЕ ВАРИАНТЫ
        for (let option of newCurrentOptionsFiltered.filter(
          (el: any) =>
            el.optionVariants.filter((el: any) => el.isChecked).length > 0
        )) {
          // ПРОХОДИМ ПО ВСЕМ ВЫДЕЛЕННЫМ ВАРИАНТАМ В КАЖДОЙ ОПЦИИ
          for (let variant of option.optionVariants.filter(
            (el: any) => el.isChecked
          )) {
            // ПОЛУЧАЕМ КОМБИНАЦИИ ДЛЯ ТЕКУЩЕГО ВАРИАНТА ОПЦИИ И ДОБАВЛЯЕМ ИХ В ОБЩИЙ МАССИВ ВАРИАНТОВ
            combinations = [
              {
                optionId: option.id,
                optionValueId: variant.id,
              },
            ];

            getValues(combinations);
          }
        }
        // полученные значения вариантов сортируем по айди опции
        for (let i = 0; i < variants.length; i++) {
          const currentValues = variants[i];
          variants[i] = currentValues.sort((a: any, b: any) =>
            a.optionId > b.optionId ? 1 : b.optionId > a.optionId ? -1 : 0
          );
        }
        // также фильтруем сами варианты на дубликаты
        variants = variants
          .filter(
            (el: any, index: number) =>
              variants
                .map((variant: any) => JSON.stringify(variant))
                .indexOf(JSON.stringify(el)) === index
          )
          .filter((el: any) => el);

        const productVariantsFormatted = product.variants.map((variant) => {
          return {
            ...variant,
            values: variant.values.map((value) => [
              value.optionId,
              value.optionValueId,
            ]),
          };
        });
        variants = variants.filter((variant: any) => {
          const variantArr = variant.map((v: any) => [
            v.optionId,
            v.optionValueId,
          ]);
          return productVariantsFormatted
            .map((el) => el.values)
            .find((i) => isObjectsEqual(i, variantArr));
        });
        const newProductVariants = variants
          .filter((el: any, index: number) => index < product.variants.length)
          .map((el: any) => {
            let impactOnPrice = false;
            for (let obj of el) {
              if (
                // @ts-ignore
                options.find((el) => el.id === obj.optionId).impactOnPrice
              )
                impactOnPrice = true;
            }
            const curValues = el.map((el: any) => [
              el.optionId,
              el.optionValueId,
            ]);
            const curOption = productVariantsFormatted.find((variant) =>
              variant.values.every((v, index) => {
                return isObjectsEqual(v, curValues[index]);
              })
            )!;
            return {
              variantId: curOption.variantId,
              price: impactOnPrice ? curOption.price : undefined,
              weight: curOption.weight !== null ? curOption.weight : "",
              values: el,
            };
          });
        if (oldProductOptionVariants.length < 1)
          setOldProductOptionVariants(newProductVariants);
        setProductOptionVariants(newProductVariants);
        let variantNames: {
          optionName: string | undefined;
          optionValueName: string | undefined;
          optionId: number;
        }[][] = [];
        variants = variants.filter((variantArray: any) => {
          let variantArrIds: number[] = [];
          for (let i = 0; i < variantArray.length; i++) {
            variantArrIds = [...variantArrIds, variantArray[i].optionId];
            variantArrIds = [...variantArrIds, variantArray[i].optionValueId];
          }

          let result = false;
          for (let i = 0; i < product.variants.length; i++) {
            let productIds: number[] = [];
            for (let j = 0; j < product.variants[i].values.length; j++) {
              productIds = [
                ...productIds,
                product.variants[i].values[j].optionId,
              ];
              productIds = [
                ...productIds,
                product.variants[i].values[j].optionValueId,
              ];
            }
            if (isObjectsEqual(variantArrIds, productIds)) result = true;
          }
          return result;
        });
        for (let variant of variants) {
          let newVariant: {
            optionName: string | undefined;
            optionValueName: string | undefined;
            optionId: number;
          }[] = [];
          for (let option of variant) {
            const currentOption = options.find(
              (el) => el.id === option.optionId
            );
            const optionName = currentOption?.name;
            const optionValueName = currentOption?.optionVariants.find(
              (el) => el.id === option.optionValueId
            )?.value;
            newVariant = [
              ...newVariant,
              {
                optionId: option.optionId,
                optionName,
                optionValueName,
              },
            ];
          }
          variantNames = [...variantNames, newVariant];
        }
        if (oldVariantNames.length < 1) setOldVariantsNames(variantNames);
        setVariantsNames(variantNames);
        setCurrentOptions(newOptions);
        setOldCurrentOptions(newOptions);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useEffect(() => {
    if (productId) {
      API.get("/files/images/directories")
        .then(({ data }) =>
          setDirectoryId(
            data.find(
              (el: { id: number; value: string; description: string }) =>
                el.value === "Products"
            ).id
          )
        )
        .catch((error) =>
          alertError(
            alertContext,
            "Ошибка получения списка директорий изображений"
          )
        );

      API.get("/promotionCategories")
        .then(({ data }) => {
          const promotionCategoriesIds = data.map(
            (cat: PromotionCategory) => cat.id
          );
          Promise.all(
            promotionCategoriesIds.map((id: number) =>
              API.get(`/promotionCategories/${id}`)
            )
          ).then((data) => {
            const thisPromotionCategories = data
              .filter((el: any) =>
                el.data.products
                  .map((product: any) => product.id)
                  .includes(productId)
              )
              .map((cat: any) => cat.data.id);

            API.get(`/categories`)
              .then(({ data: categoriesData }: { data: Category[] }) => {
                setCategories(
                  categoriesData.map((el) => {
                    return { id: el.id, name: el.name, isActive: el.isActive };
                  })
                );
                API.get(`/products/${productId}`)
                  .then(({ data }: { data: ProductEdit }) => {
                    // todo заменить, когда будет готов бэк
                    API.get(`/promotions`)
                      .then(({ data }: { data: PromotionRow[] }) => {
                        const promotionsIds = data.map((promo) => promo.id);
                        Promise.all(
                          promotionsIds.map((id) =>
                            API.get(`/promotions/${id}`)
                          )
                        ).then((data: any) => {
                          const promotionsProductSetsLists = data
                            .map((el: any) =>
                              el.data.productSetsList.filter(
                                (listEl: any) =>
                                  listEl.productId === productId ||
                                  (thisPromotionCategories.includes(
                                    listEl.promotionCategoryId
                                  ) &&
                                    !listEl.productId)
                              )
                            )
                            .map((el: any) =>
                              el.map((el: any) =>
                                el.productSetVariantsList.map((listEl: any) =>
                                  listEl.values.map((val: any) => {
                                    return {
                                      optionId: val.optionId,
                                      optionValueId: val.optionValueId,
                                    };
                                  })
                                )
                              )
                            )
                            .reduce(
                              (sum: any, acc: any) => [...sum, ...acc],
                              []
                            );
                          setUsedCombinations(promotionsProductSetsLists);
                        });
                      })
                      .catch((error) =>
                        alertError(
                          alertContext,
                          "Ошибка получения списка акций"
                        )
                      );

                    const productCategoryId = data.categoryId;
                    let newProduct = {
                      ...data,
                      price: renderPrice(getFloatFromString(data.price)),
                      description: data.description ? data.description : "",
                      proteins: data.proteins ? data.proteins : 0,
                      categoryId: data.categoryId ? data.categoryId : 0,
                      imgName: data.thumbnail,
                      fats: data.fats ? data.fats : 0,
                      carbohydrates: data.carbohydrates
                        ? data.carbohydrates
                        : 0,
                      weight: data.weight ? data.weight : 0,
                      calories: data.calories ? data.calories : 0,
                      isPickupOnly: data.isPickupOnly,
                    };
                    if (data.categoryId)
                      newProduct.categoryId = categoriesData
                        .filter((el) => el.isActive)
                        .map((el) => el.id)
                        .includes(data.categoryId)
                        ? data.categoryId
                        : 0;
                    setThumbnail(data.imgName);
                    delete newProduct.thumbnail;
                    API.get(`/options`)
                      .then(({ data }: { data: Option[] }) => {
                        setOptions(
                          data
                            .filter((el) => el.isActive)
                            .map((option) => {
                              return {
                                id: option.id ? option.id : 0,
                                name: option.value,
                                categories: option.categories,
                                optionVariants: option.optionVariants.map(
                                  (el) => {
                                    return { ...el, isChecked: false };
                                  }
                                ),
                                impactOnPrice: option.impactOnPrice || false,
                                isChecked: false,
                                isActive: option.isActive
                                  ? option.isActive
                                  : true,
                              };
                            })
                        );
                      })
                      .catch((error) =>
                        alertError(
                          alertContext,
                          "Ошибка получения списка опций"
                        )
                      );

                    API.get("/filters")
                      .then(({ data }: { data: Filter[] }) => {
                        const newFilters = data.map((el) => {
                          return {
                            id: el.id,
                            name: el.name,
                            isChecked: false,
                            isActive: el.isActive,
                          };
                        });
                        for (let i = 0; i < newFilters.length; i++) {
                          if (
                            newProduct.filters
                              .map((el) => el.id)
                              .includes(newFilters[i].id)
                          )
                            newFilters[i].isChecked = true;
                        }
                        setOldFilters(newFilters);
                        setFilters(newFilters);
                      })
                      .catch((error) => {
                        alertError(alertContext, "Ошибка получения фильтров");
                      });

                    API.get(`/ingredients`)
                      .then(({ data }: { data: Ingredient[] }) => {
                        let newIngredients = data
                          .filter((el) => el.isActive)
                          .map((el) => {
                            return {
                              ...el,
                              isChecked: false,
                              categories: undefined,
                            };
                          });
                        const ingredientsIds = newIngredients.map(
                          (el) => el.id
                        );
                        ingredientsIds.forEach((id) =>
                          API.get(`/ingredients/${id}`)
                            .then(({ data }: { data: Ingredient }) => {
                              // @ts-ignore
                              newIngredients = newIngredients.map(
                                (ingredient) =>
                                  ingredient.id === data.id
                                    ? {
                                        ...ingredient,
                                        categories: data.categories,
                                      }
                                    : ingredient
                              );
                              if (
                                newIngredients.filter((el) => el.categories)
                                  .length === newIngredients.length
                              ) {
                                // @ts-ignore
                                setIngredients(newIngredients);
                                newIngredients = newIngredients.filter((el) =>
                                  // @ts-ignore
                                  el.categories
                                    .map((el: any) => el.categoryId)
                                    .includes(productCategoryId)
                                );
                                let newIngredientsToAdd = copyObject(
                                  newIngredients
                                );
                                let newIngredientsToRemove = copyObject(
                                  newIngredients
                                );
                                for (
                                  let j = 0;
                                  j < newIngredients.length;
                                  j++
                                ) {
                                  if (
                                    newProduct.ingredientsForRemoving
                                      .map((el) => el.id)
                                      .includes(newIngredients[j].id)
                                  )
                                    newIngredientsToRemove[j].isChecked = true;
                                  if (
                                    newProduct.ingredientsForAdding
                                      .map((el) => el.id)
                                      .includes(newIngredients[j].id)
                                  )
                                    newIngredientsToAdd[j].isChecked = true;
                                }

                                setOldAddedIngredients(newIngredientsToAdd);
                                setAddedIngredients(newIngredientsToAdd);
                                setOldDeletedIngredients(
                                  newIngredientsToRemove
                                );
                                setDeletedIngredients(newIngredientsToRemove);
                              }
                            })
                            .catch((error) =>
                              alertError(
                                alertContext,
                                "Ошибка получения ингредиента"
                              )
                            )
                        );
                      })
                      .catch((error) =>
                        alertError(
                          alertContext,
                          "Ошибка получения списка ингредиентов"
                        )
                      );

                    // @ts-ignore
                    setProduct(newProduct);
                    // @ts-ignore
                    setOldProduct(newProduct);
                    setAlerted(false);
                  })
                  .catch((error) =>
                    alertError(alertContext, "Ошибка получения товара")
                  );
              })
              .catch((error) =>
                alertError(alertContext, "Ошибка получения списка категорий")
              );
          });
        })
        .catch((error) =>
          alertError(alertContext, "Ошибка получения списка категорий акций")
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  const [alerted, setAlerted] = useState(false);

  const getIds = (data: LabelRibbonData[]) =>
    data.filter((el) => el.isChecked).map((el) => el.id);

  const requiredFieldEmpty = () =>
    product.name === "" ||
    product.categoryId === 0 ||
    product.price === "0" ||
    product.position === 0;

  const closeAndClear = () => {
    setOldAddedIngredients([]);
    setOldDeletedIngredients([]);
    setProductOptionVariants([]);
    setCurrentOptions([]);
    setOldProductOptionVariants([]);
    setOldVariantsNames([]);
    setProductOptionsInit(true);
    setAlerted(false);
    onClose();
  };

  const isImageFormatWrong = () =>
    image?.name?.slice(image?.name?.length - 4, image?.name?.length) !==
      ".png" && image?.name;

  const priceOrWeightIsNull = () =>
    productOptionVariants.filter(
      (el) => el.price && el.price.toString() === "0"
    ).length > 0 ||
    (isPizza() &&
      productOptionVariants.filter((el) => el.weight.toString() === "").length >
        0);

  const isImpactOnPriceVariantAvailable = (variant: {
    optionName: string | undefined;
    optionValueName: string | undefined;
    optionId: number;
  }) => {
    let result = false;
    for (let i = 0; i < variantsNames.length; i++) {
      for (let j = 0; j < variantsNames[i].length; j++)
        if (
          options.find((option) => option.id === variantsNames[i][j].optionId)
            ?.impactOnPrice
        )
          result = true;
    }
    return result;
  };

  const isCurrentOptionDisabled = (optionId: number) => {
    const selectedOptions = currentOptions.filter(
      (el) =>
        el.optionVariants.filter((variant) => variant.isChecked).length > 0
    );
    const currentImpact = currentOptions.find((el) => el.id === optionId)!
      .impactOnPrice;
    const selectedImpactOnPrice = selectedOptions.find(
      (el) => el.impactOnPrice
    );
    return !selectedImpactOnPrice
      ? false
      : selectedImpactOnPrice.id !== optionId && currentImpact;
  };

  const variantIsCheckedOnChange = (newCurrentOptions: CurrentOption[]) => {
    const getValues = (
      combinations: {
        optionId: number;
        optionValueId: number;
      }[]
    ) => {
      // ПОЛУЧАЕМ ВСЕ НЕ ПРОЙДЕННЫЕ ЕЩЁ ОПЦИИ, КОТОРЫЕ СОДЕРЖАТ ВЫДЕЛЕННЫЕ ВАРИАНТЫ
      const otherOptions = newCurrentOptions
        .filter((el) => el.isChecked)
        .filter((el) => !combinations.map((el) => el.optionId).includes(el.id))
        .filter(
          (el) => el.optionVariants.filter((el) => el.isChecked).length > 0
        );
      if (otherOptions.length === 0) variants = [...variants, combinations];
      else {
        for (let option of otherOptions) {
          for (let variant of option.optionVariants.filter(
            (el) => el.isChecked
          )) {
            getValues([
              ...combinations,
              {
                optionId: option.id,
                optionValueId: variant.id,
              },
            ]);
          }
        }
      }
    };

    // итоговый объект вариантов
    let variants: any = [];
    let combinations: {
      optionId: number;
      optionValueId: number;
    }[] = [];

    // ПРОХОДИМ ПО ВСЕМ ОПЦИЯМ, КОТОРЫЕ СОДЕРЖАТ ВЫДЕЛЕННЫЕ ВАРИАНТЫ
    for (let option of newCurrentOptions.filter(
      (el) => el.optionVariants.filter((el) => el.isChecked).length > 0
    )) {
      // ПРОХОДИМ ПО ВСЕМ ВЫДЕЛЕННЫМ ВАРИАНТАМ В КАЖДОЙ ОПЦИИ
      for (let variant of option.optionVariants.filter((el) => el.isChecked)) {
        // ПОЛУЧАЕМ КОМБИНАЦИИ ДЛЯ ТЕКУЩЕГО ВАРИАНТА ОПЦИИ И ДОБАВЛЯЕМ ИХ В ОБЩИЙ МАССИВ ВАРИАНТОВ
        combinations = [
          {
            optionId: option.id,
            optionValueId: variant.id,
          },
        ];
        getValues(combinations);
      }
    }
    for (let i = 0; i < variants.length; i++) {
      const currentValues = variants[i];
      variants[i] = currentValues.sort((a: any, b: any) =>
        a.optionId > b.optionId ? 1 : b.optionId > a.optionId ? -1 : 0
      );
    }
    variants = variants
      .filter(
        (el: any, index: number) =>
          variants
            .map((variant: any) => JSON.stringify(variant))
            .indexOf(JSON.stringify(el)) === index
      )
      .filter((el: any) => el);

    const newProductOptionVariants = variants.map((el: any) => {
      let impactOnPrice = false;
      for (let obj of el) {
        if (
          // @ts-ignore
          options.find((el) => el.id === obj.optionId).impactOnPrice
        )
          impactOnPrice = true;
      }
      return {
        price: impactOnPrice ? "0" : undefined,
        values: el,
        weight: "",
      };
    });

    for (let i = 0; i < newProductOptionVariants.length; i++) {
      const sameLengthProductOptionVariants = productOptionVariants.filter(
        (optionVariant) =>
          optionVariant.values.length ===
          newProductOptionVariants[i].values.length
      );
      for (let j = 0; j < sameLengthProductOptionVariants.length; j++) {
        if (
          isObjectsEqual(
            sameLengthProductOptionVariants[j].values,
            newProductOptionVariants[i].values
          )
        ) {
          if (sameLengthProductOptionVariants[j].variantId)
            newProductOptionVariants[i].variantId =
              sameLengthProductOptionVariants[j].variantId;
          if (sameLengthProductOptionVariants[j].price)
            newProductOptionVariants[i].price =
              sameLengthProductOptionVariants[j].price;
          newProductOptionVariants[i].weight =
            sameLengthProductOptionVariants[j].weight;
        }
      }
    }
    let variantNames: {
      optionName: string | undefined;
      optionValueName: string | undefined;
      optionId: number;
    }[][] = [];
    for (let variant of variants) {
      let newVariant: {
        optionName: string | undefined;
        optionValueName: string | undefined;
        optionId: number;
      }[] = [];
      for (let option of variant) {
        const currentOption = options.find((el) => el.id === option.optionId);
        const optionName = currentOption?.name;
        const optionValueName = currentOption?.optionVariants.find(
          (el) => el.id === option.optionValueId
        )?.value;
        newVariant = [
          ...newVariant,
          {
            optionId: option.optionId,
            optionName,
            optionValueName,
          },
        ];
      }
      variantNames = [...variantNames, newVariant];
    }
    return { optionVariants: newProductOptionVariants, variantNames };
    // setVariantsNames(variantNames);
  };

  const isOptionDisabled = (
    propCurrentOptions?: any[],
    id?: number,
    optionIndex?: number,
    variantIndex?: number,
    productOptionVariants?: {
      variantId: number;
      values: { optionId: number; optionValueId: number }[];
      price: number | string;
    }[]
  ) => {
    let newCurrentOptions = propCurrentOptions
      ? propCurrentOptions
      : optionIndex !== undefined && variantIndex !== undefined
      ? []
      : [...currentOptions].map((el) =>
          el.id !== id
            ? el
            : {
                ...el,
                isChecked: false,
                optionVariants: el.optionVariants.map((el) => {
                  return { ...el, isChecked: false };
                }),
              }
        );
    if (optionIndex !== undefined && variantIndex !== undefined) {
      newCurrentOptions = copyObject(currentOptions);
      newCurrentOptions[optionIndex].optionVariants[
        variantIndex
      ].isChecked = !newCurrentOptions[optionIndex].optionVariants[variantIndex]
        .isChecked;
    }
    const optionVariants = productOptionVariants
      ? productOptionVariants
      : variantIsCheckedOnChange(newCurrentOptions).optionVariants;
    return !isOptionsContainsAllUsedCombinations(optionVariants);
  };

  const isOptionsContainsAllUsedCombinations = (
    optionVariants: {
      price?: number;
      values: { optionId: number; optionValueId: number }[];
    }[]
  ) => {
    if (!usedCombinations || usedCombinations.length < 1) return true;
    const optionVariantsValues = optionVariants.map((variant) =>
      JSON.stringify(variant.values)
    );
    return usedCombinations.every((comb) =>
      // @ts-ignore
      comb.every((valueItem: any) =>
        optionVariantsValues.includes(JSON.stringify(valueItem))
      )
    );
  };
  const isOptionsContainsSomeUsedCombinations = (
    optionVariants: {
      price?: number;
      values: { optionId: number; optionValueId: number }[];
    }[]
  ) => {
    if (!usedCombinations || usedCombinations.length < 1) return false;
    const optionVariantsValues = optionVariants.map(
      (variant) => variant.values
    );
    return usedCombinations.some((comb) =>
      // @ts-ignore
      comb.some((valueItem: any) =>
        optionVariantsValues.some((variant: any) =>
          isObjectsEqual(variant, valueItem)
        )
      )
    );
  };

  const isPizza = () =>
    categories
      .find((el) => el.id === product.categoryId)
      ?.name.toLowerCase() === "пицца";

  return (
    <Dialog
      disableBackdropClick
      onBackdropClick={() =>
        progress
          ? null
          : isObjectsEqual(product, oldProduct) &&
            isObjectsEqual(filters, oldFilters) &&
            isObjectsEqual(deletedIngredients, oldDeletedIngredients) &&
            isObjectsEqual(addedIngredients, oldAddedIngredients) &&
            isObjectsEqual(currentOptions, oldCurrentOptions) &&
            isObjectsEqual(variantsNames, oldVariantNames) &&
            thumbnail === undefined
          ? closeAndClear()
          : setAlerted(true)
      }
      open={!!productId}
      maxWidth={"lg"}
      onClose={closeAndClear}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Редактирование товара</DialogTitle>
      <DialogContent>
        <form>
          <div className={classes.imgWrapper}>
            <input
              accept="image/*"
              className={classes.imageDisplay}
              id="contained-button-file"
              type="file"
              ref={fileRef}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.files?.[0]) {
                  const reader = new FileReader();
                  reader.onload = (e) => {
                    // @ts-ignore
                    setThumbnail(e.target.result);
                  };
                  reader.readAsDataURL(e.target.files[0]);

                  setImage(e.target.files[0]);
                }
              }}
            />
            <Avatar
              alt={thumbnail === undefined ? product.imgName : thumbnail}
              src={thumbnail === undefined ? product.imgName : thumbnail}
              variant="rounded"
              className={classes.large}
            />
            {isImageFormatWrong() && (
              <p className={classes.requiredWarning}>
                Выберите изображение в "png" формате
              </p>
            )}
            <div className={classes.imgControls}>
              <label htmlFor="contained-button-file">
                <IconButton
                  component="span"
                  onClick={(e: React.MouseEvent<HTMLSpanElement>) =>
                    e.stopPropagation()
                  }
                >
                  <PublishIcon />
                </IconButton>
              </label>
            </div>
          </div>
          <TextField
            margin="dense"
            id="name"
            label="Наименование"
            inputProps={{ maxLength: 100 }}
            className={classes.productName}
            value={product.name}
            onChange={(e) => setProduct({ ...product, name: e.target.value })}
            type="text"
            fullWidth
            error={!product.name}
            helperText={!product.name ? "Обязательное поле" : ""}
            required
          />
          <TextField
            margin="dense"
            id="description"
            label="Описание"
            value={product.description}
            multiline
            onChange={(e) => {
              if (getSizeOfStringInBytes(e.target.value) <= 4000)
                setProduct({ ...product, description: e.target.value });
            }}
            type="text"
            fullWidth
          />
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={product.categoryId}
            inputProps={{ "aria-label": "Without label" }}
            defaultValue={0}
            required
            fullWidth
            className={[
              classes.mt20,
              product.categoryId === 0
                ? classes.colorError
                : classes.colorBlack,
            ].join(" ")}
            onChange={(e: any) => {
              setProductOptionVariants([]);
              setCurrentOptions(
                options
                  .filter((el) => el.categories?.includes(e.target.value))
                  .map((option) => {
                    return {
                      ...option,
                      optionVariants: option.optionVariants.map((variant) => {
                        return { ...variant, isChecked: false };
                      }),
                    };
                  })
              );
              setVariantsNames([]);
              setProduct({
                ...product,
                categoryId: e.target.value,
              });
              const filteredIngredients = ingredients.filter((el) =>
                el.categories
                  ?.map((el) => el.categoryId)
                  .includes(e.target.value)
              );
              setDeletedIngredients(filteredIngredients);
              setAddedIngredients(filteredIngredients);
            }}
          >
            <MenuItem value={0} disabled>
              Категория *
            </MenuItem>
            {categories.map((el) => (
              <MenuItem value={el.id} key={el.id} disabled={!el.isActive}>
                {el.name}
              </MenuItem>
            ))}
          </Select>
          {product.categoryId === 0 && (
            <p className={classes.requiredWarning}>Обязательное поле</p>
          )}
          <TextField
            margin="dense"
            id="position"
            label="Позиция"
            inputProps={{ maxLength: 10 }}
            value={product.position}
            onChange={(e) =>
              e.target.value === " "
                ? null
                : setProduct({
                    ...product,
                    position: getIntFromString(e.target.value),
                  })
            }
            type="text"
            fullWidth
            error={!product.position}
            helperText={!product.position ? "Обязательное поле" : ""}
            required
          />
          <TextField
            margin="dense"
            type="text"
            label="Цена"
            inputProps={{ maxLength: 10 }}
            value={product.price}
            onChange={(e) =>
              e.target.value === " "
                ? null
                : setProduct({
                    ...product,
                    price: getFloatFromString(e.target.value),
                  })
            }
            fullWidth
            error={product.price === "0"}
            helperText={product.price === "0" ? "Обязательное поле" : ""}
            required
          />

          <TextField
            margin="dense"
            id="weight"
            label="Вес"
            inputProps={{ maxLength: 10 }}
            value={product.weight}
            onChange={(e) =>
              e.target.value === " "
                ? null
                : setProduct({
                    ...product,
                    weight: getFloatFromString(e.target.value),
                  })
            }
            type="text"
            fullWidth
          />
          <TextField
            margin="dense"
            id="calories"
            label="Калории"
            inputProps={{ maxLength: 10 }}
            value={product.calories}
            onChange={(e) =>
              e.target.value === " "
                ? null
                : setProduct({
                    ...product,
                    calories: getFloatFromString(e.target.value),
                  })
            }
            type="text"
            fullWidth
          />
          <TextField
            margin="dense"
            id="proteins"
            label="Белки"
            inputProps={{ maxLength: 10 }}
            value={product.proteins}
            className={classes.marginWidth}
            onChange={(e) =>
              e.target.value === " "
                ? null
                : setProduct({
                    ...product,
                    proteins: getFloatFromString(e.target.value),
                  })
            }
            type="text"
          />
          <TextField
            margin="dense"
            id="fats"
            label="Жиры"
            inputProps={{ maxLength: 10 }}
            className={classes.marginWidth}
            value={product.fats}
            onChange={(e) =>
              e.target.value === " "
                ? null
                : setProduct({
                    ...product,
                    fats: getFloatFromString(e.target.value),
                  })
            }
            type="text"
          />
          <TextField
            margin="dense"
            id="carbohydrates"
            label="Углеводы"
            inputProps={{ maxLength: 10 }}
            className={classes.carbohydrates}
            value={product.carbohydrates}
            onChange={(e) =>
              e.target.value === " "
                ? null
                : setProduct({
                    ...product,
                    carbohydrates: getFloatFromString(e.target.value),
                  })
            }
            type="text"
          />
          <FormControl className={classes.isRecommended}>
            <FormControlLabel
              control={
                <Checkbox
                  value={product.isRecommended}
                  checked={product.isRecommended}
                  onChange={() =>
                    setProduct({
                      ...product,
                      isRecommended: !product.isRecommended,
                    })
                  }
                  name="checkedB"
                  color="primary"
                />
              }
              label="Товар рекомендован"
            />
          </FormControl>
          <Divider />
          <FormControl className={classes.isRecommended}>
            <FormControlLabel
              control={
                <Checkbox
                  value={product.isPickupOnly}
                  checked={product.isPickupOnly}
                  onChange={() =>
                    setProduct({
                      ...product,
                      isPickupOnly: !product.isPickupOnly,
                    })
                  }
                  name="checkedB"
                  color="primary"
                />
              }
              label="Только для самовывоза"
            />
          </FormControl>
          <LabelRibbon
            title={"Фильтры"}
            data={filters}
            onConfirm={(data: LabelRibbonData[]) => {
              setFilters(data);
            }}
          />
          <div className={classes.entityList}>
            {filters
              .filter((el) => el.isChecked)
              .map((entity, key) => (
                <Chip
                  key={key}
                  label={entity.name}
                  onDelete={() => {
                    setFilters(
                      filters.map((el) =>
                        el.id !== entity.id
                          ? el
                          : { ...el, isChecked: !el.isChecked }
                      )
                    );
                  }}
                  color="primary"
                  variant="outlined"
                />
              ))}
          </div>
          <Divider />
          <LabelRibbon
            title={"Удаляемые ингредиенты"}
            data={deletedIngredients}
            onConfirm={(data: LabelRibbonData[]) => {
              setDeletedIngredients(data);
            }}
          />
          <div className={classes.entityList}>
            {deletedIngredients
              .filter((el) => el.isChecked)
              .map((entity, key) => (
                <Chip
                  key={key}
                  label={entity.name}
                  onDelete={() => {
                    setDeletedIngredients(
                      deletedIngredients.map((el) =>
                        el.id !== entity.id
                          ? el
                          : { ...el, isChecked: !el.isChecked }
                      )
                    );
                  }}
                  color="primary"
                  variant="outlined"
                />
              ))}
          </div>
          <Divider />
          <LabelRibbon
            title={"Добавляемые ингредиенты"}
            data={addedIngredients}
            onConfirm={(data: LabelRibbonData[]) => {
              setAddedIngredients(data);
            }}
          />
          <div className={classes.entityList}>
            {addedIngredients
              .filter((el) => el.isChecked)
              .map((entity, key) => (
                <Chip
                  key={key}
                  label={entity.name}
                  onDelete={() => {
                    setAddedIngredients(
                      addedIngredients.map((el) =>
                        el.id !== entity.id
                          ? el
                          : { ...el, isChecked: !el.isChecked }
                      )
                    );
                  }}
                  color="primary"
                  variant="outlined"
                />
              ))}
          </div>
          <Divider />
          <LabelRibbon
            // @ts-ignore
            data={currentOptions}
            disabled={isOptionsContainsSomeUsedCombinations(
              variantIsCheckedOnChange(currentOptions).optionVariants
            )}
            title={"Опции"}
            // @ts-ignore
            onConfirm={(
              data: {
                id: number;
                name: string;
                isChecked: boolean;
                categories: [];
                impactOnPrice: boolean;
                optionVariants: {
                  id: number;
                  value: string;
                  price?: string | number;
                  weight?: string | number;
                  isChecked?: boolean;
                }[];
              }[]
            ) => {
              setCurrentOptions(data);
            }}
          />
          {currentOptions.map(
            (option, optionIndex) =>
              option.isChecked && (
                <div className={classes.options} key={optionIndex}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-label="Expand"
                      aria-controls="additional-actions1-content"
                      id="additional-actions1-header"
                      className={classes.accordionSummary}
                    >
                      <span>{option.name}</span>
                      <DeleteIcon
                        color={
                          isOptionDisabled(undefined, option.id)
                            ? "disabled"
                            : "primary"
                        }
                        fontSize={"small"}
                        onClick={(e) => {
                          const newCurrentOptions = currentOptions.map((el) =>
                            el.id !== option.id
                              ? el
                              : {
                                  ...el,
                                  isChecked: false,
                                  optionVariants: el.optionVariants.map(
                                    (el) => {
                                      return { ...el, isChecked: false };
                                    }
                                  ),
                                }
                          );
                          if (!isOptionDisabled(newCurrentOptions, option.id)) {
                            const {
                              optionVariants,
                              variantNames,
                            } = variantIsCheckedOnChange(newCurrentOptions);
                            setProductOptionVariants(optionVariants);
                            setVariantsNames(variantNames);
                            setCurrentOptions(newCurrentOptions);
                          }
                        }}
                      />
                    </AccordionSummary>
                    {option.optionVariants.map((variant, variantIndex) => (
                      <AccordionDetails
                        key={variantIndex}
                        className={classes.accordionDetails}
                      >
                        <div className={classes.detailsWrapper}>
                          <FormControlLabel
                            className={classes.detail}
                            aria-label="Acknowledge"
                            onClick={(event) => event.stopPropagation()}
                            onFocus={(event) => event.stopPropagation()}
                            control={
                              <Checkbox
                                disabled={
                                  isCurrentOptionDisabled(option.id) ||
                                  (isOptionDisabled(
                                    undefined,
                                    undefined,
                                    optionIndex,
                                    variantIndex
                                  ) &&
                                    variant.isChecked)
                                }
                                color={"primary"}
                                checked={variant.isChecked}
                                onChange={(e) => {
                                  const newCurrentOptions = currentOptions.map(
                                    (option, index) =>
                                      index === optionIndex
                                        ? {
                                            ...option,
                                            optionVariants: option.optionVariants.map(
                                              (variant, key) =>
                                                key === variantIndex
                                                  ? {
                                                      ...variant,
                                                      isChecked: !variant.isChecked,
                                                    }
                                                  : variant
                                            ),
                                          }
                                        : option
                                  );
                                  const {
                                    optionVariants,
                                    variantNames,
                                  } = variantIsCheckedOnChange(
                                    newCurrentOptions
                                  );
                                  setProductOptionVariants(optionVariants);
                                  setVariantsNames(variantNames);
                                  setCurrentOptions(newCurrentOptions);
                                }}
                              />
                            }
                            label={variant.value}
                          />
                        </div>
                      </AccordionDetails>
                    ))}
                  </Accordion>
                </div>
              )
          )}
          {currentOptions.filter((option) => option.isChecked).length === 0 && (
            <Divider />
          )}
          {productOptionVariants.length > 0 && (
            <div className={classes.variants}>
              {variantsNames.map(
                (
                  variant: {
                    optionName: string | undefined;
                    optionValueName: string | undefined;
                    optionId: number;
                  }[],
                  key
                ) => {
                  const isDisabled = isOptionDisabled(
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    productOptionVariants.filter((el, index) => index !== key)
                  );
                  return (
                    <div key={key} className={classes.variantWrapper}>
                      <div className={classes.variantDeleteIcon}>
                        <DeleteIcon
                          fontSize={"small"}
                          color={isDisabled ? "disabled" : "primary"}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            if (
                              !isOptionDisabled(
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                productOptionVariants.filter(
                                  (el, index) => index !== key
                                )
                              )
                            ) {
                              setProductOptionVariants(
                                productOptionVariants.filter(
                                  (el, index) => index !== key
                                )
                              );
                              const newVariantsNames = variantsNames.filter(
                                (el, index) => index !== key
                              );
                              setVariantsNames(newVariantsNames);
                              let newCurrentOptions = [...currentOptions];
                              for (let i = 0; i < variant.length; i++) {
                                const deletedOptionName = variant[i].optionName;
                                const deletedOptionValueName =
                                  variant[i].optionValueName;
                                const otherVariantsNames = variantsNames.filter(
                                  (el, index) => index !== key
                                );
                                if (
                                  !otherVariantsNames.find(
                                    (el) =>
                                      el.filter(
                                        (el) =>
                                          el.optionName === deletedOptionName &&
                                          el.optionValueName ===
                                            deletedOptionValueName
                                      ).length > 0
                                  )
                                ) {
                                  newCurrentOptions = newCurrentOptions.map(
                                    (option) => {
                                      return {
                                        ...option,
                                        optionVariants: option.optionVariants.map(
                                          (variant) =>
                                            variant.value ===
                                              deletedOptionValueName &&
                                            option.name === deletedOptionName
                                              ? { ...variant, isChecked: false }
                                              : variant
                                        ),
                                      };
                                    }
                                  );
                                }
                              }
                              setCurrentOptions(newCurrentOptions);
                            }
                          }}
                        />
                      </div>
                      {variant.map((el: any, index: number) => (
                        <span key={index}>
                          <span>{el.optionName.toLowerCase()}:&nbsp;</span>
                          <span>{el.optionValueName.toLowerCase()}</span>
                          {index === variant.length - 1 && (
                            <TextField
                              margin="dense"
                              type="text"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {isPizza() ? "Вес *" : "Вес"}
                                  </InputAdornment>
                                ),
                              }}
                              inputProps={{ maxLength: 9 }}
                              value={productOptionVariants[key].weight}
                              onChange={(e) => {
                                if (e.target.value === " ") return null;
                                let newValue = e.target.value.length
                                  ? getIntFromString(e.target.value, true)
                                  : "";
                                if (
                                  newValue === "" ||
                                  (typeof newValue === "number" &&
                                    !isNaN(newValue))
                                )
                                  setProductOptionVariants(
                                    productOptionVariants.map((el, index) => {
                                      return index === key
                                        ? {
                                            ...el,
                                            weight: newValue,
                                          }
                                        : el;
                                    })
                                  );
                              }}
                              fullWidth
                              required
                            />
                          )}
                          {isImpactOnPriceVariantAvailable(el) &&
                            index === variant.length - 1 && (
                              <TextField
                                margin="dense"
                                type="text"
                                helperText={
                                  isDisabled ? "Используется в акции" : ""
                                }
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment
                                      position="start"
                                      style={{ paddingBottom: 5 }}
                                    >
                                      Цена *
                                    </InputAdornment>
                                  ),
                                }}
                                inputProps={{ maxLength: 8 }}
                                value={productOptionVariants[key].price}
                                onChange={(e) =>
                                  e.target.value === " "
                                    ? null
                                    : setProductOptionVariants(
                                        productOptionVariants.map(
                                          (el, index) => {
                                            return index === key
                                              ? {
                                                  ...el,
                                                  price: getFloatFromString(
                                                    e.target.value
                                                  ),
                                                }
                                              : el;
                                          }
                                        )
                                      )
                                }
                                fullWidth
                                required
                              />
                            )}

                          <br />
                        </span>
                      ))}
                    </div>
                  );
                }
              )}
            </div>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        {alerted ? (
          <Alert
            severity="warning"
            className={classes.alert}
            action={
              <>
                <Button color="inherit" size="small" onClick={closeAndClear}>
                  Да
                </Button>
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => setAlerted(false)}
                >
                  Нет
                </Button>
              </>
            }
          >
            Вы действительно хотите отменить все изменения и выйти? Введенные
            данные будут утеряны
          </Alert>
        ) : (
          <>
            <Button onClick={closeAndClear} color="primary" disabled={progress}>
              Отмена
            </Button>
            <Button
              onClick={() => {
                setProgress(true);
                const filtersIds = getIds(filters);
                const oldFiltersIds = getIds(oldFilters);
                const filtersToAdd = filtersIds.filter(
                  (el) => !oldFiltersIds.includes(el)
                );
                const filtersToRemove = oldFiltersIds.filter(
                  (el) => !filtersIds.includes(el)
                );
                ///////////////////
                const deletedIngredientsIds = getIds(deletedIngredients);
                const oldDeletedIngredientsIds = getIds(oldDeletedIngredients);

                const deletedIngredientsToAdd = deletedIngredientsIds.filter(
                  // @ts-ignore
                  (el) => !oldDeletedIngredientsIds.includes(el)
                );
                const deletedIngredientsToRemove = oldDeletedIngredientsIds.filter(
                  // @ts-ignore
                  (el) => !deletedIngredientsIds.includes(el)
                );
                ///////////////////
                const addedIngredientsIds = getIds(addedIngredients);
                const oldAddedIngredientsIds = getIds(oldAddedIngredients);
                const addedIngredientsToAdd = addedIngredientsIds.filter(
                  // @ts-ignore
                  (el) => !oldAddedIngredientsIds.includes(el)
                );
                const addedIngredientsToRemove = oldAddedIngredientsIds.filter(
                  // @ts-ignore
                  (el) => !addedIngredientsIds.includes(el)
                );
                let newProduct = {
                  ...product,
                  proteins: +product.proteins !== 0 ? +product.proteins : null,
                  fats: +product.fats !== 0 ? +product.fats : null,
                  carbohydrates:
                    +product.carbohydrates !== 0
                      ? +product.carbohydrates
                      : null,
                  weight: +product.weight !== 0 ? +product.weight : null,
                  calories: +product.calories !== 0 ? +product.calories : null,
                  filtersToAdd: filtersToAdd || [],
                  filtersToRemove: filtersToRemove || [],
                  addIngredientsToRemoveList: deletedIngredientsToAdd || [],
                  removeIngredientsFromRemoveList:
                    deletedIngredientsToRemove || [],
                  addIngredientsToAddList: addedIngredientsToAdd || [],
                  removeIngredientsFromAddList: addedIngredientsToRemove || [],
                };
                if (newProduct.filtersToAdd.length === 0)
                  delete newProduct.filtersToAdd;
                if (newProduct.filtersToRemove.length === 0)
                  delete newProduct.filtersToRemove;
                if (newProduct.addIngredientsToRemoveList.length === 0)
                  delete newProduct.addIngredientsToRemoveList;
                if (newProduct.removeIngredientsFromRemoveList.length === 0)
                  delete newProduct.removeIngredientsFromRemoveList;
                if (newProduct.removeIngredientsFromAddList.length === 0)
                  delete newProduct.removeIngredientsFromAddList;
                if (newProduct.addIngredientsToAddList.length === 0)
                  delete newProduct.addIngredientsToAddList;
                if (thumbnail === undefined) {
                  const idx = newProduct.imgName?.lastIndexOf("/") || 1;
                  newProduct = {
                    ...newProduct,
                    // @ts-ignore
                    variants: productOptionVariants.map((el) => ({
                      ...el,
                      weight:
                        !!el.weight && +el.weight !== 0 ? el.weight : null,
                    })),
                    imgName: newProduct.imgName?.slice(idx + 1),
                  };
                  // @ts-ignore
                  onProductEdit(productId ? productId : 1, newProduct)
                    .then(closeAndClear)
                    .catch((error) => console.log(error))
                    .finally(() => setProgress(false));
                } else {
                  const imageToUpload = {
                    File: image,
                    Folder: directoryId,
                  };
                  const formData = new FormData();
                  (Object.keys(imageToUpload) as Array<
                    keyof { File: File; Folder: number }
                  >).map((k: keyof { File: File; Folder: number }) =>
                    // @ts-ignore
                    formData.append(k as string, imageToUpload[k])
                  );
                  API.post("/files/images/upload", formData)
                    .then(({ data }) => {
                      newProduct = {
                        ...newProduct,
                        // @ts-ignore
                        variants: productOptionVariants,
                        imgName: data,
                      };
                      // @ts-ignore
                      onProductEdit(productId ? productId : 1, newProduct)
                        .then(closeAndClear)
                        .catch((error) => console.log(error))
                        .finally(() => setProgress(false));
                    })
                    .catch((error) => {
                      alertError(alertContext, "Ошибка загрузки изображения");
                    });
                }
              }}
              color="primary"
              type="submit"
              disabled={
                !!isImageFormatWrong() ||
                priceOrWeightIsNull() ||
                (currentOptions.filter((el) => el.isChecked).length > 0 &&
                  productOptionVariants.filter((el) => el.values.length > 0)
                    .length === 0) ||
                requiredFieldEmpty() ||
                (isObjectsEqual(product, oldProduct) &&
                  isObjectsEqual(filters, oldFilters) &&
                  isObjectsEqual(deletedIngredients, oldDeletedIngredients) &&
                  isObjectsEqual(addedIngredients, oldAddedIngredients) &&
                  isObjectsEqual(variantsNames, oldVariantNames) &&
                  isObjectsEqual(
                    productOptionVariants,
                    oldProductOptionVariants
                  ) &&
                  thumbnail === undefined) ||
                progress
              }
            >
              Подтвердить
            </Button>
          </>
        )}
      </DialogActions>
      {progress && <ProgressBar bottom />}
    </Dialog>
  );
};

export default EditProductForm;
