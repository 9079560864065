import React, { FC, useContext, useEffect, useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Alert } from "@material-ui/lab";
import { alertError, getErrorMsg, isObjectsEqual } from "../../_shared/utils";
import { ProgressBar } from "../_shared/ProgressBar";
import {
  Avatar,
  FormControl,
  IconButton,
  MenuItem,
  Select,
} from "@material-ui/core";
import API from "../../_shared/axios";
import {
  AlertContextType,
  Banner,
  BannerCreate,
  Category,
  ProductRow,
} from "../../_shared/types";
import { AlertContext } from "../_shared/ToastList";
import PublishIcon from "@material-ui/icons/Publish";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  mt20: { marginTop: 20 },
  alert: { width: "100%" },
  large: {
    width: "100%",
    height: "220px",
    "& img": {
      objectFit: "contain",
    },
  },
  requiredWarning: {
    color: "#f44336",
    margin: "4px 0 0 0",
    fontSize: "0.75rem",
    letterSpacing: "0.03333em",
    lineHeight: "1.66",
  },
  imageDisplay: {
    display: "none",
  },
  imgWrapper: {
    position: "relative",
    margin: "auto",
    width: "50%",
    "& img": {
      objectFit: "contain",
    },
  },
  imgControls: {
    position: "absolute",
    right: "1rem",
    bottom: "1rem",
  },
});

const EditBannerForm: FC<{
  bannerId: number | undefined;
  onClose: () => void;
  onBannerEdit: (id: number, banner: BannerCreate) => Promise<any>;
}> = ({ onBannerEdit, onClose, bannerId }) => {
  const classes = useStyles();
  const fileRef = useRef<HTMLInputElement>(null);

  const alertContext = useContext<AlertContextType>(AlertContext);
  const [categories, setCategories] = useState<Category[]>([]);
  const [products, setProducts] = useState<ProductRow[]>([]);
  const [directoryId, setDirectoryId] = useState<number>();

  const bannerInit = {
    name: "",
    categoryId: 0,
    productId: 0,
    imgFile: "",
  };

  const [banner, setBanner] = useState(bannerInit);
  const [oldBanner, setOldBanner] = useState(bannerInit);
  const [thumbnail, setThumbnail] = useState<File | undefined>(undefined);
  const [isNewImage, setIsNewImage] = useState(false);
  const [progress, setProgress] = useState(false);
  const [alerted, setAlerted] = useState(false);

  useEffect(() => {
    setThumbnail(undefined);
    if (bannerId)
      API.get(`/banners/${bannerId}`)
        .then(({ data }: { data: Banner }) => {
          setIsNewImage(false);
          const newBanner = {
            ...data,
            imgFile: data.thumbnail,
            categoryId: data.categoryId ? data.categoryId : 0,
            productId: data.productId ? data.productId : 0,
          };
          setBanner(newBanner);
          setOldBanner(newBanner);
          setAlerted(false);
          API.get("/files/images/directories")
            .then(({ data }) =>
              setDirectoryId(
                data.find(
                  (el: { id: number; value: string; description: string }) =>
                    el.value === "Banners"
                ).id
              )
            )
            .catch((error) =>
              alertError(
                alertContext,
                getErrorMsg(error.response, "Ошибка загрузки")
              )
            );
          API.get("/categories")
            .then(({ data }: { data: Category[] }) => {
              setCategories(data);
              API.get("/products")
                .then(({ data }: { data: ProductRow[] }) => {
                  setProducts(data);
                })
                .catch((error) =>
                  alertError(
                    alertContext,
                    getErrorMsg(
                      error.response,
                      "Ошибка получения списка товаров"
                    )
                  )
                );
            })
            .catch((error) =>
              alertError(
                alertContext,
                getErrorMsg(error.response, "Ошибка получения списка категорий")
              )
            );
        })
        .catch((error) =>
          alertError(
            alertContext,
            getErrorMsg(error.response, "Ошибка получения баннера")
          )
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bannerId]);

  const isImageFormatWrong = () =>
    thumbnail?.name?.slice(
      thumbnail?.name?.length - 4,
      thumbnail?.name?.length
    ) !== ".png" && thumbnail?.name;

  return (
    <div>
      <Dialog
        fullWidth={true}
        disableBackdropClick
        onBackdropClick={() =>
          progress
            ? null
            : isObjectsEqual(banner, oldBanner) && !isNewImage
            ? onClose()
            : setAlerted(true)
        }
        open={!!bannerId}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
        maxWidth={"md"}
      >
        <DialogTitle id="form-dialog-title">Редактирование баннера</DialogTitle>
        <DialogContent>
          <form>
            <div className={classes.imgWrapper}>
              <input
                accept="image/*"
                className={classes.imageDisplay}
                id="contained-button-file"
                type="file"
                ref={fileRef}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e.target.files?.[0]) {
                    setIsNewImage(true);
                    setThumbnail(e.target.files[0]);
                  }
                }}
              />
              <Avatar
                alt={
                  thumbnail ? URL.createObjectURL(thumbnail) : banner.imgFile
                }
                src={
                  thumbnail ? URL.createObjectURL(thumbnail) : banner.imgFile
                }
                variant="rounded"
                className={classes.large}
              />
              {isImageFormatWrong() && (
                <p className={classes.requiredWarning}>
                  Выберите изображение в "png" формате
                </p>
              )}
              <div className={classes.imgControls}>
                <label htmlFor="contained-button-file">
                  <IconButton
                    component="span"
                    onClick={(e: React.MouseEvent<HTMLSpanElement>) =>
                      e.stopPropagation()
                    }
                  >
                    <PublishIcon />
                  </IconButton>
                </label>
              </div>
            </div>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Наименование"
              inputProps={{ maxLength: 100 }}
              value={banner.name}
              onChange={(e) => setBanner({ ...banner, name: e.target.value })}
              type="text"
              fullWidth
              className={classes.mt20}
              error={!banner.name}
              helperText={!banner.name ? "Обязательное поле" : ""}
              required
            />
            <FormControl
              fullWidth
              component="fieldset"
              className={classes.mt20}
              disabled={banner.productId !== 0}
            >
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={banner.categoryId}
                defaultValue={undefined}
                inputProps={{ "aria-label": "Without label" }}
                displayEmpty
                onChange={(e: any) =>
                  setBanner({
                    ...banner,
                    categoryId: e.target.value,
                  })
                }
              >
                <MenuItem value={0}>Категория</MenuItem>
                {categories.map((el, key) => (
                  <MenuItem key={key} value={el.id} disabled={!el.isActive}>
                    {el.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              fullWidth
              component="fieldset"
              className={classes.mt20}
              disabled={banner.categoryId !== 0}
            >
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={banner.productId}
                defaultValue={undefined}
                inputProps={{ "aria-label": "Without label" }}
                displayEmpty
                onChange={(e: any) =>
                  setBanner({ ...banner, productId: e.target.value })
                }
              >
                <MenuItem value={0}>Товар</MenuItem>
                {products.map((el, key) => (
                  <MenuItem key={key} value={el.id} disabled={!el.isActive}>
                    {el.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          {alerted ? (
            <Alert
              className={classes.alert}
              severity="warning"
              action={
                <>
                  <Button color="inherit" size="small" onClick={onClose}>
                    Да
                  </Button>
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => setAlerted(false)}
                  >
                    Нет
                  </Button>
                </>
              }
            >
              Вы действительно хотите отменить все изменения и выйти? Введенные
              данные будут утеряны
            </Alert>
          ) : (
            <>
              <Button onClick={onClose} color="primary" disabled={progress}>
                Отмена
              </Button>
              <Button
                onClick={() => {
                  if (bannerId) {
                    setProgress(true);
                    const { name, categoryId, productId } = banner;
                    const newBanner = {
                      name,
                      imgName: banner.imgFile.slice(
                        banner.imgFile.lastIndexOf("/") + 1 || 1
                      ),
                      categoryId: categoryId ? categoryId.toString() : "",
                      productId: productId ? productId.toString() : "",
                      isActive: "true",
                    };
                    if (categoryId === 0) delete newBanner.categoryId;
                    if (productId === 0) delete newBanner.productId;
                    if (isNewImage) {
                      const imageToUpload = {
                        File: thumbnail,
                        Folder: directoryId,
                      };
                      const formData = new FormData();
                      (Object.keys(imageToUpload) as Array<
                        keyof { File: File; Folder: number }
                      >).map((k: keyof { File: File; Folder: number }) =>
                        // @ts-ignore
                        formData.append(k as string, imageToUpload[k])
                      );
                      API.post("/files/images/upload", formData)
                        .then(({ data }) => {
                          newBanner.imgName = data;
                          onBannerEdit(bannerId ? bannerId : 1, newBanner)
                            .then(onClose)
                            .catch((error) => console.log(error))
                            .finally(() => setProgress(false));
                        })
                        .catch((error) => {
                          alertError(
                            alertContext,
                            getErrorMsg(
                              error.response,
                              "Ошибка загрузки изображения"
                            )
                          );
                        });
                    } else {
                      onBannerEdit(bannerId ? bannerId : 1, newBanner)
                        .then(onClose)
                        .catch((error) => console.log(error))
                        .finally(() => setProgress(false));
                    }
                  }
                }}
                color="primary"
                type="submit"
                disabled={
                  !!isImageFormatWrong() ||
                  banner.name === "" ||
                  (isObjectsEqual(banner, oldBanner) && !isNewImage) ||
                  progress
                }
              >
                Подтвердить
              </Button>
            </>
          )}
        </DialogActions>
        {progress && <ProgressBar bottom />}
      </Dialog>
    </div>
  );
};

export default EditBannerForm;
