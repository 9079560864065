import React, { FC, useCallback, useContext, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Checkbox, FormControl, FormControlLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Alert } from "@material-ui/lab";
import {
  AlertContextType,
  Category,
  IngredientCategory,
  IngredientCreate,
  Option,
} from "../../_shared/types";
import CheckboxList from "../_shared/CheckboxList";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  alertError,
  copyObject,
  getErrorMsg,
  getFloatFromString,
  getIntFromString,
  isObjectsEqual,
} from "../../_shared/utils";
import IngredientCombinations from "./IngredientCombinations";
import DropdownOptions from "../_shared/DropdownOptions";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { ProgressBar } from "../_shared/ProgressBar";
import API from "../../_shared/axios";
import { AlertContext } from "../_shared/ToastList";

const useStyles = makeStyles({
  categoriesListWrapper: {
    marginTop: 25,
    width: "100%",
    "& > div": {
      display: "flex",
      width: "100%",
    },
  },
  variants: {
    marginTop: 10,
    display: "flex",
    flexWrap: "wrap",
  },
  variantWrapper: {
    padding: "8px 30px 8px 16px",
    border: "1px solid #ccc",
    borderRadius: 4,
    marginRight: 10,
    marginTop: 10,
    marginBottom: 10,
    maxWidth: 234,
    position: "relative",
  },
  variantDeleteIcon: {
    position: "absolute",
    top: 8,
    right: 6,
    zIndex: 100,
    cursor: "pointer",
  },
  colorInactive: { color: "rgba(0, 0, 0, 0.54)" },
  alert: { width: "100%" },
  mt20: { marginTop: 20 },
  formControl: {
    minWidth: 120,
  },
  wrapper: {
    display: "inline",
  },
  OptionsWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginTop: 15,
  },
  OptionName: {
    width: "65%",
    display: "flex",
    paddingRight: "4%",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: 500,
  },
  OptionValues: {
    width: "35%",
    display: "flex",
    alignItems: "center",
  },
  OptionWeight: {
    width: "30%",
    marginRight: "5%",
  },
  OptionPrice: {
    width: "30%",
    marginRight: "auto",
  },
});

const NewIngredientForm: FC<{
  onIngredientCreate: (ingredient: IngredientCreate) => Promise<any>;
}> = ({ onIngredientCreate }) => {
  const [open, setOpen] = React.useState(false);
  const [categoriesModalOpen, setCategoriesModalOpen] = useState(false);

  const ingredientInit = {
    name: "",
    position: 0,
    maxQuantity: 0,
    isAvailable: true,
  };
  const [ingredient, setIngredient] = useState(ingredientInit);

  const [progress, setProgress] = useState(false);

  const [categoriesData, setCategoriesData] = useState<IngredientCategory[]>(
    []
  );
  const [variantsNames, setVariantsNames] = useState<
    {
      categoryId: number;
      optionName: string | undefined;
      optionValueName: string | undefined;
      optionValueId: number;
      optionId: number;
    }[][]
  >([]);
  const isBlurredInit = {
    name: false,
    position: false,
  };
  const [isBlurred, setIsBlurred] = useState(isBlurredInit);
  const setBlurredField = (field: string) => {
    setIsBlurred({ ...isBlurred, [field]: true });
  };
  const alertContext = useContext<AlertContextType>(AlertContext);

  const [options, setOptions] = useState<Option[]>([]);

  const updateCategories = (categories: IngredientCategory[]) => {
    const optionsFormatted = options.map((option) => {
      return {
        ...option,
        categories: option.categories.map((categoryId) => {
          const category = categories.find((el) => el.id === categoryId);
          return { id: categoryId, name: category!.name };
        }),
      };
    });
    const categoriesIds = categories
      .filter((el) => el.isChecked)
      .map((el) => el.id);
    const newData = [...categories];
    for (let i = 0; i < optionsFormatted.length; i++) {
      for (let j = 0; j < optionsFormatted[i].categories.length; j++) {
        if (categoriesIds.includes(optionsFormatted[i].categories[j].id)) {
          for (let m = 0; m < categories.length; m++) {
            if (
              optionsFormatted[i].categories
                .map((el: any) => el.name)
                .includes(categories[m].name)
            ) {
              const newOption = {
                ...optionsFormatted[i],
                optionVariants: optionsFormatted[i].optionVariants.map(
                  (el: any) => {
                    return { ...el, isChecked: false };
                  }
                ),
              };
              if (
                newData[m].options.filter((el) => el.id === newOption.id)
                  .length === 0
              ) {
                // @ts-ignore
                newData[m].options = [
                  ...newData[m].options,
                  { ...newOption, isChecked: false },
                ];
                setCategoriesData((categoriesData) => newData);
              }
            }
          }
        } else setCategoriesData((categoriesData) => newData);
      }
    }
  };

  const isCategoriesValid = () => {
    let isValid = true;
    const selectedCategories = categoriesData.filter((el) => el.isChecked);
    if (selectedCategories.length < 1) return false;
    for (let i = 0; i < selectedCategories.length; i++) {
      if (selectedCategories[i].price.toString() === "0") return false;
      if (!selectedCategories[i].isCommon) {
        let uncheckedOptionsCounter = 0;
        for (let j = 0; j < selectedCategories[i].options.length; j++) {
          const selectedVariants = selectedCategories[i].options[
            j
          ].optionVariants.filter((el) => el.isChecked);
          if (selectedVariants.length < 1) uncheckedOptionsCounter++;
          if (uncheckedOptionsCounter === selectedCategories[i].options.length)
            return false;
          for (let k = 0; k < selectedVariants.length; k++) {
            if (selectedCategories[i].options[j].impactOnPrice) {
              if (
                selectedVariants[k].price?.toString() === "0" ||
                selectedVariants[k].price === undefined
              )
                return false;
            }
          }
        }
      }
    }
    return isValid;
  };

  const handleClickOpen = () => {
    setOpen(true);
    setAlerted(false);
    setIngredient(ingredientInit);
    setIsBlurred(isBlurredInit);

    API.get("/categories")
      .then(({ data }: { data: Category[] }) => {
        setCategoriesData(
          data.map((el) => {
            return {
              ...el,
              isActive: el.isActive,
              isCommon: true,
              isChecked: false,
              isVariantsOpen: false,
              isOptionsOpen: false,
              price: 0,
              weight: 0,
              options: [],
            };
          })
        );
        API.get("/options")
          .then(({ data }: { data: Option[] }) => {
            setOptions(data);
          })
          .catch((error) => {
            alertError(
              alertContext,
              getErrorMsg(error.response, "Ошибка получения списка опций")
            );
          });
      })
      .catch((error) => {
        alertError(
          alertContext,
          getErrorMsg(error.response, "Ошибка получения списка категорий")
        );
      });
  };

  const getIngredientCategories = () => {
    let createdData: any = {
      ...ingredient,
      maxQuantity:
        ingredient.maxQuantity !== 0 ? ingredient.maxQuantity : undefined,
      categories: [],
    };
    const checkedCategories = categoriesData.filter((el) => el.isChecked);
    // проходим по всем выбранным категориям
    for (let i = 0; i < checkedCategories.length; i++) {
      // заготавливаем объект для добавления его в массив categories
      let data: any = {
        isCommon: checkedCategories[i].isCommon,
        categoryId: checkedCategories[i].id,
        price: +checkedCategories[i].price,
        weight:
          +checkedCategories[i].weight !== 0
            ? +checkedCategories[i].weight
            : undefined,

        variants: [],
      };

      let priceWeightArray: {
        price: string | number;
        weight: string | number;
        value: {
          optionId: number;
          optionValueId: number;
        };
      }[] = [];

      const getValues = (
        combinations: {
          optionId: number;
          optionValueId: number;
        }[]
      ) => {
        // ПОЛУЧАЕМ ВСЕ НЕ ПРОЙДЕННЫЕ ЕЩЁ ОПЦИИ, КОТОРЫЕ СОДЕРЖАТ ВЫДЕЛЕННЫЕ ВАРИАНТЫ
        const otherOptions = checkedCategories[i].options
          .filter(
            (el) => !combinations.map((el) => el.optionId).includes(el.id)
          )
          .filter(
            (el) => el.optionVariants.filter((el) => el.isChecked).length > 0
          );
        if (otherOptions.length === 0) {
          data.variants = [...data.variants, { values: combinations }];
        } else {
          for (let option = 0; option < otherOptions.length; option++) {
            for (let variant of otherOptions[0].optionVariants.filter(
              (el) => el.isChecked
            )) {
              if (variant.price && variant.weight) {
                priceWeightArray = [
                  ...priceWeightArray,
                  {
                    value: {
                      optionId: otherOptions[0].id,
                      optionValueId: variant.id,
                    },
                    price: +variant.price,
                    weight: +variant.weight,
                  },
                ];
              }
              getValues([
                ...combinations,
                {
                  optionId: otherOptions[0].id,
                  optionValueId: variant.id,
                },
              ]);
            }
          }
        }
      };

      getValues([]);

      let variants = data.variants;
      for (let i = 0; i < variants.length; i++) {
        const currentValues = variants[i].values;
        variants[i].values = currentValues.sort((a: any, b: any) =>
          a.optionId > b.optionId ? 1 : b.optionId > a.optionId ? -1 : 0
        );
      }

      data.variants = variants
        .filter(
          (el: any, index: number) =>
            variants
              .map((variant: any) => JSON.stringify(variant.values))
              .indexOf(JSON.stringify(el.values)) === index
        )
        .filter((el: any) => el);

      let newData = { ...data };
      for (let index = 0; index < data.variants.length; index++) {
        for (let value of data.variants[index].values) {
          for (let priceWeight of priceWeightArray) {
            if (isObjectsEqual(priceWeight.value, value)) {
              newData.variants[index].price = priceWeight.price;
              newData.variants[index].weight = priceWeight.weight;
            }
          }
        }
      }
      newData.variants = newData.variants.filter((variant: any) => {
        let result = false;
        const approachVariantsNames = variantsNames.filter((el) =>
          el.find((el) => el.categoryId === newData.categoryId)
        );
        const valuesOptionIds = variant.values.map((el: any) => el.optionId);
        const valuesOptionValueIds = variant.values.map(
          (el: any) => el.optionValueId
        );
        for (let i = 0; i < approachVariantsNames.length; i++) {
          const optionIds = approachVariantsNames[i].map((el) => el.optionId);
          const optionValueIds = approachVariantsNames[i].map(
            (el) => el.optionValueId
          );
          if (
            isObjectsEqual(valuesOptionIds, optionIds) &&
            isObjectsEqual(valuesOptionValueIds, optionValueIds)
          )
            result = true;
        }
        return result;
      });
      createdData.categories = copyObject([
        ...createdData.categories,
        data,
      ]).map((el: any) => {
        return {
          ...el,
          variants: !el.variants.some(
            (variant: any) => variant.values.length === 0
          )
            ? el.variants
            : [],
        };
      });
    }
    return createdData;
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  const [alerted, setAlerted] = useState(false);

  const dropdownMenuOptions = useCallback(
    (row: IngredientCategory, index: number) => [
      {
        icon: categoriesData[index].isVariantsOpen ? (
          <RemoveIcon fontSize={"small"} />
        ) : (
          <AddIcon fontSize={"small"} />
        ),
        text: categoriesData[index].isVariantsOpen
          ? "Скрыть вариации"
          : "Показать вариации",
        event: () => {
          const newList = [...categoriesData];
          newList[index].isVariantsOpen = !newList[index].isVariantsOpen;
          setCategoriesData(newList);
        },
      },
      {
        icon: <DeleteIcon fontSize={"small"} />,
        text: "Удалить",
        event: () =>
          setCategoriesData(
            categoriesData.map((el) =>
              el.name !== row.name
                ? el
                : { ...el, isChecked: !el.isChecked, options: [] }
            )
          ),
      },
    ],
    [categoriesData]
  );

  const categoriesAdded = () =>
    categoriesData.filter((el) => el.isChecked).length > 0;

  return (
    <div className={classes.wrapper}>
      <h2>
        Ингредиенты &nbsp; &nbsp;
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          Добавить ингредиент
        </Button>
      </h2>
      <Dialog
        disableBackdropClick
        onBackdropClick={() =>
          progress
            ? null
            : isObjectsEqual(ingredient, ingredientInit) && !categoriesAdded()
            ? setOpen(false)
            : setAlerted(true)
        }
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className="newIngredientDialog"
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle id="form-dialog-title">Новый ингредиент</DialogTitle>
        <DialogContent>
          <form>
            <TextField
              autoFocus
              margin="dense"
              label="Наименование"
              inputProps={{ maxLength: 100 }}
              value={ingredient.name}
              onChange={(e) =>
                setIngredient({ ...ingredient, name: e.target.value })
              }
              type="text"
              onBlur={() => setBlurredField("name")}
              error={!ingredient.name && isBlurred.name}
              helperText={
                !ingredient.name && isBlurred.name ? "Обязательное поле" : ""
              }
              fullWidth
              required
            />
            <TextField
              margin="dense"
              label="Позиция"
              inputProps={{ maxLength: 10 }}
              value={ingredient.position}
              type="text"
              onChange={(e) =>
                e.target.value === " "
                  ? null
                  : setIngredient({
                      ...ingredient,
                      position: getIntFromString(e.target.value),
                    })
              }
              fullWidth
              onBlur={() => setBlurredField("position")}
              error={!ingredient.position && isBlurred.position}
              helperText={
                !ingredient.position && isBlurred.position
                  ? "Обязательное поле"
                  : ""
              }
              required
            />
            <TextField
              margin="dense"
              label="Максимальное количество"
              inputProps={{ maxLength: 10 }}
              value={ingredient.maxQuantity}
              type="text"
              onChange={(e) =>
                e.target.value === " "
                  ? null
                  : setIngredient({
                      ...ingredient,
                      maxQuantity: getIntFromString(e.target.value),
                    })
              }
              fullWidth
            />
            <div className={classes.categoriesListWrapper}>
              {categoriesData.filter((el) => !el.isChecked).length > 0 && (
                <>
                  <Button
                    color="primary"
                    size={"small"}
                    variant="outlined"
                    onClick={() => setCategoriesModalOpen(true)}
                  >
                    Добавить категории *
                  </Button>
                  <CheckboxList
                    title={"Добавляемые категории"}
                    data={categoriesData.map((el) => {
                      return {
                        id: el.id,
                        name: el.name,
                        isChecked: el.isChecked,
                        isActive: el.isActive,
                      };
                    })}
                    isOpen={categoriesModalOpen}
                    onClose={() => setCategoriesModalOpen(false)}
                    onConfirm={(data) => {
                      let newList = [...categoriesData];
                      for (let i = 0; i < data.length; i++) {
                        newList[i] = {
                          ...newList[i],
                          isChecked: data[i].isChecked,
                        };
                      }
                      updateCategories(newList);
                    }}
                  />
                </>
              )}
              {categoriesData.map(
                (row, index) =>
                  row.isChecked && (
                    <>
                      <div key={row.name} className={classes.OptionsWrapper}>
                        <span className={classes.OptionName}>
                          {row.name}
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={categoriesData[index].isCommon}
                                onChange={(e) => {
                                  setCategoriesData(
                                    categoriesData.map((el) =>
                                      el.name !== row.name
                                        ? el
                                        : {
                                            ...el,
                                            isCommon: !el.isCommon,
                                            isVariantsOpen: !e.target.checked
                                              ? true
                                              : el.isVariantsOpen,
                                          }
                                    )
                                  );
                                }}
                                name="checkedB"
                                color="primary"
                                disabled={
                                  categoriesData[index].options.length === 0
                                }
                              />
                            }
                            label="Ингредиент доступен всей категории"
                          />
                        </span>
                        <div className={classes.OptionValues}>
                          <TextField
                            label="Вес"
                            inputProps={{ maxLength: 10 }}
                            className={classes.OptionWeight}
                            type="text"
                            value={categoriesData[index].weight}
                            onChange={(e) =>
                              e.target.value === " "
                                ? null
                                : setCategoriesData(
                                    categoriesData.map((el) =>
                                      el.name !== row.name
                                        ? el
                                        : {
                                            ...el,
                                            weight: getFloatFromString(
                                              e.target.value
                                            ),
                                          }
                                    )
                                  )
                            }
                          />
                          <TextField
                            type="text"
                            label="Цена"
                            required
                            inputProps={{ maxLength: 10 }}
                            className={classes.OptionPrice}
                            value={categoriesData[index].price}
                            onChange={(e) =>
                              e.target.value === " "
                                ? null
                                : setCategoriesData(
                                    categoriesData.map((el) =>
                                      el.name !== row.name
                                        ? el
                                        : {
                                            ...el,
                                            price: getFloatFromString(
                                              e.target.value
                                            ),
                                          }
                                    )
                                  )
                            }
                          />
                          <DropdownOptions
                            options={() => dropdownMenuOptions(row, index)}
                          />
                        </div>
                      </div>
                      <p className={classes.colorInactive}>
                        {!row.isCommon &&
                        categoriesData.filter(
                          (el) =>
                            el.options.filter((el) =>
                              el.optionVariants.some((el) => el.isChecked)
                            ).length > 0
                        ).length === 0
                          ? "(выберите один из вариантов)"
                          : ""}
                      </p>
                      <IngredientCombinations
                        categoryIndex={index}
                        categoriesData={categoriesData}
                        onUpdateList={(data) => {
                          let variantNames: any[] = [];
                          for (let p = 0; p < data.length; p++) {
                            let newCurrentOptionsFiltered: any[] = [];
                            for (let j = 0; j < data[p].options.length; j++) {
                              newCurrentOptionsFiltered = [
                                ...newCurrentOptionsFiltered,
                                data[p].options[j],
                              ];
                            }

                            // итоговый объект вариантов
                            let variants: any = [];
                            let combinations: {
                              optionId: number;
                              optionValueId: number;
                            }[] = [];

                            // функция получает текущие комбинации.
                            const getValues = (
                              combinations: {
                                optionId: number;
                                optionValueId: number;
                              }[]
                            ) => {
                              // ПОЛУЧАЕМ ВСЕ НЕ ПРОЙДЕННЫЕ ЕЩЁ ОПЦИИ, КОТОРЫЕ СОДЕРЖАТ МИНИМУМ 1 ВЫДЕЛЕННЫЙ ВАРИАНТ
                              const otherOptions = newCurrentOptionsFiltered
                                .filter(
                                  (el: any) =>
                                    !combinations
                                      .map((el) => el.optionId)
                                      .includes(el.id)
                                )
                                .filter(
                                  (el: any) =>
                                    el.optionVariants.filter(
                                      (el: any) => el.isChecked
                                    ).length > 0
                                );
                              if (otherOptions.length === 0)
                                variants = [...variants, combinations];
                              else {
                                for (let option of otherOptions) {
                                  for (let variant of option.optionVariants.filter(
                                    (el: any) => el.isChecked
                                  )) {
                                    getValues([
                                      ...combinations,
                                      {
                                        optionId: option.id,
                                        optionValueId: variant.id,
                                      },
                                    ]);
                                  }
                                }
                              }
                            };

                            // ПРОХОДИМ ПО ВСЕМ ОПЦИЯМ, КОТОРЫЕ СОДЕРЖАТ ВЫДЕЛЕННЫЕ ВАРИАНТЫ
                            for (let option of newCurrentOptionsFiltered.filter(
                              (el: any) =>
                                el.optionVariants.filter(
                                  (el: any) => el.isChecked
                                ).length > 0
                            )) {
                              // ПРОХОДИМ ПО ВСЕМ ВЫДЕЛЕННЫМ ВАРИАНТАМ В КАЖДОЙ ОПЦИИ
                              for (let variant of option.optionVariants.filter(
                                (el: any) => el.isChecked
                              )) {
                                // ПОЛУЧАЕМ КОМБИНАЦИИ ДЛЯ ТЕКУЩЕГО ВАРИАНТА ОПЦИИ И ДОБАВЛЯЕМ ИХ В ОБЩИЙ МАССИВ ВАРИАНТОВ
                                combinations = [
                                  {
                                    optionId: option.id,
                                    optionValueId: variant.id,
                                  },
                                ];
                                getValues(combinations);
                              }
                            }

                            // полученные значения вариантов сортируем по айди опции
                            for (let i = 0; i < variants.length; i++) {
                              const currentValues = variants[i];
                              variants[
                                i
                              ] = currentValues.sort((a: any, b: any) =>
                                a.optionId > b.optionId
                                  ? 1
                                  : b.optionId > a.optionId
                                  ? -1
                                  : 0
                              );
                            }
                            // также фильтруем сами варианты на дубликаты
                            variants = variants
                              .filter(
                                (el: any, index: number) =>
                                  variants
                                    .map((variant: any) =>
                                      JSON.stringify(variant)
                                    )
                                    .indexOf(JSON.stringify(el)) === index
                              )
                              .filter((el: any) => el);

                            for (let variant of variants) {
                              let newVariant: {
                                categoryId: number;
                                optionName: string | undefined;
                                optionValueName: string | undefined;
                                optionValueId: number;
                                optionId: number;
                              }[] = [];
                              for (let option of variant) {
                                const currentOption = options.find(
                                  (el) => el.id === option.optionId
                                );
                                const optionName = currentOption?.value;
                                const optionValueName = currentOption?.optionVariants.find(
                                  (el) => el.id === option.optionValueId
                                )?.value;
                                const optionValueId = currentOption?.optionVariants.find(
                                  (el) => el.id === option.optionValueId
                                )?.id!;
                                newVariant = [
                                  ...newVariant,
                                  {
                                    categoryId: data[p].id,
                                    optionId: option.optionId,
                                    optionName,
                                    optionValueId,
                                    optionValueName,
                                  },
                                ];
                              }
                              variantNames = [...variantNames, newVariant];
                            }
                          }
                          // @ts-ignore
                          setVariantsNames(variantNames);

                          setCategoriesData(data);
                        }}
                        isOpen={categoriesData[index].isVariantsOpen}
                      />
                      {categoriesData.length > 0 && (
                        <div className={classes.variants}>
                          {variantsNames
                            .filter(
                              (el) =>
                                el.filter((el) => el.categoryId === row.id)
                                  .length > 0
                            )
                            .map(
                              (
                                variant: {
                                  categoryId: number;
                                  optionName: string | undefined;
                                  optionValueName: string | undefined;
                                  optionId: number;
                                }[],
                                key
                              ) => (
                                <div
                                  key={key}
                                  className={classes.variantWrapper}
                                >
                                  <div className={classes.variantDeleteIcon}>
                                    <DeleteIcon
                                      fontSize={"small"}
                                      color={"primary"}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        let newVariantsNames = copyObject(
                                          variantsNames
                                        );
                                        let countOfVariantsBeforeCurrentCategory = 0;
                                        for (let i = 0; i < index; i++) {
                                          const categoryId =
                                            categoriesData[i].id;
                                          const countOfVariants = variantsNames.filter(
                                            (el) =>
                                              el.some(
                                                (el) =>
                                                  el.categoryId === categoryId
                                              )
                                          ).length;
                                          countOfVariantsBeforeCurrentCategory += countOfVariants;
                                        }
                                        newVariantsNames = newVariantsNames.filter(
                                          (el: any, variantIndex: number) => {
                                            return (
                                              variantIndex !==
                                              key +
                                                countOfVariantsBeforeCurrentCategory
                                            );
                                          }
                                        );
                                        setVariantsNames(newVariantsNames);
                                        let newCategoriesData = categoriesData;
                                        for (
                                          let i = 0;
                                          i < variant.length;
                                          i++
                                        ) {
                                          const deletedOptionName =
                                            variant[i].optionName;
                                          const deletedOptionValueName =
                                            variant[i].optionValueName;
                                          const otherVariantsNames = variantsNames
                                            .filter((el) =>
                                              el.some(
                                                (el) => el.categoryId === row.id
                                              )
                                            )
                                            .filter(
                                              (el, index) =>
                                                index !==
                                                key +
                                                  countOfVariantsBeforeCurrentCategory
                                            );
                                          if (
                                            !otherVariantsNames.find((el) =>
                                              el.some(
                                                (el) =>
                                                  el.optionName ===
                                                    deletedOptionName &&
                                                  el.optionValueName ===
                                                    deletedOptionValueName
                                              )
                                            )
                                          ) {
                                            newCategoriesData = newCategoriesData.map(
                                              (category) => {
                                                return {
                                                  ...category,
                                                  options:
                                                    row.id !== category.id
                                                      ? category.options
                                                      : category.options.map(
                                                          (option) => {
                                                            return {
                                                              ...option,
                                                              optionVariants: option.optionVariants.map(
                                                                (variant) =>
                                                                  variant.value ===
                                                                    deletedOptionValueName &&
                                                                  option.value ===
                                                                    deletedOptionName
                                                                    ? {
                                                                        ...variant,
                                                                        isChecked: false,
                                                                      }
                                                                    : variant
                                                              ),
                                                            };
                                                          }
                                                        ),
                                                };
                                              }
                                            );
                                          }
                                        }
                                        newCategoriesData[
                                          index
                                        ].options = newCategoriesData[
                                          index
                                        ].options.map((option) => {
                                          return {
                                            ...option,
                                            isChecked: option.optionVariants.some(
                                              (el: any) => el.isChecked
                                            ),
                                          };
                                        });
                                        setCategoriesData(newCategoriesData);
                                      }}
                                    />
                                  </div>
                                  {variant.map((el: any, index: number) => (
                                    <span key={index}>
                                      <span>
                                        {el.optionName.toLowerCase()}:&nbsp;
                                      </span>
                                      <span>
                                        {el.optionValueName.toLowerCase()}
                                      </span>
                                      <br />
                                    </span>
                                  ))}
                                </div>
                              )
                            )}
                        </div>
                      )}
                    </>
                  )
              )}
            </div>
            <FormControl className={classes.mt20}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={ingredient.isAvailable}
                    onChange={(e) =>
                      setIngredient({
                        ...ingredient,
                        isAvailable: e.target.checked,
                      })
                    }
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Ингредиент доступен"
              />
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          {alerted ? (
            <Alert
              className={classes.alert}
              severity="warning"
              action={
                <>
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => setOpen(false)}
                  >
                    Да
                  </Button>
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => setAlerted(false)}
                  >
                    Нет
                  </Button>
                </>
              }
            >
              Вы действительно хотите отменить все изменения и выйти? Введенные
              данные будут утеряны
            </Alert>
          ) : (
            <>
              <Button onClick={handleClose} color="primary" disabled={progress}>
                Отмена
              </Button>
              <Button
                onClick={() => {
                  setProgress(true);
                  const ingredientCategories = getIngredientCategories();
                  onIngredientCreate(ingredientCategories)
                    .then((data) => setOpen(false))
                    .catch((error) => console.log(error))
                    .finally(() => setProgress(false));
                }}
                color="primary"
                type="submit"
                disabled={
                  ingredient.name === "" ||
                  ingredient.position === 0 ||
                  !isCategoriesValid() ||
                  progress
                }
              >
                Создать
              </Button>
            </>
          )}
        </DialogActions>
        {progress && <ProgressBar bottom />}
      </Dialog>
    </div>
  );
};

export default NewIngredientForm;
