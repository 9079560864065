import React, { FC, useEffect, useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  FormControlLabel,
  Checkbox,
  FormControl,
} from "@material-ui/core";
import { TimePicker } from "@material-ui/pickers";
import { withStyles } from "@material-ui/core/styles";
import { BlurType, weekDays } from "./types";
import { WorkingTime } from "../../_shared/types";
import styles from "./styles";
import { makeStyles } from "@material-ui/styles";
import { copyObject } from "../../../_shared/utils";
import { log } from "util";

const MuiTableCell = withStyles({
  root: {
    borderBottom: "none",
  },
})(TableCell);

const useStyles = makeStyles({
  thWidths: {
    "& > th, td": {
      "& > div": {
        width: "100% !important",
      },
      "&:first-child": {
        width: 140,
      },
      "&:last-child": {
        width: 191,
        paddingRight: 0,
      },
    },
  },
});

const TimeTable: FC<{
  timetable: WorkingTime;
  setTimeTable: (timetable: WorkingTime) => void;
  setIsError?: React.Dispatch<React.SetStateAction<boolean>>;
  blur: BlurType;
  setBlur: (blur: BlurType) => void;
  setError?: (value: boolean) => void;
  withCurrentDay?: boolean;
  currentDayArray?: string[];
  setCurrentDayArray?: React.Dispatch<React.SetStateAction<string[]>>;
}> = ({
  timetable,
  setIsError,
  setTimeTable,
  blur,
  setBlur,
  setError,
  withCurrentDay,
  setCurrentDayArray,
  currentDayArray,
}) => {
  const classes = styles();
  const thStyles = useStyles();

  // работа с пересечением по времени till и from
  const days = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
  let daysTimes: Date[] = [];
  // берем все времена в порядке возрастания от пн до вс от from до till каждый день
  days.forEach((day) => {
    daysTimes.push(timetable[day as keyof WorkingTime].from);
    daysTimes.push(timetable[day as keyof WorkingTime].till);
  });
  // добавляем воскресные времена в начало и время понедельника в конец
  daysTimes.unshift(daysTimes[13]);
  daysTimes.unshift(daysTimes[13]);
  daysTimes.push(daysTimes[2]);
  daysTimes.push(daysTimes[3]);
  const today = new Date();
  // ставим везде текущий день, оставляя часы и минуты
  daysTimes = daysTimes.map((el) => {
    el.setFullYear(today.getFullYear());
    el.setMonth(today.getMonth());
    el.setDate(today.getDate());
    return el;
  });
  const timesCrossing = daysTimes.map((el, index) => {
    // индексы 2, 4, 6... (т.е время till) : индексы 1, 3, 5...(т.е время from)
    return index % 2
      ? el > daysTimes[index + 1] && daysTimes[index - 1] > el
      : el < daysTimes[index - 1] &&
          daysTimes[index - 1] < daysTimes[index - 2];
  });

  const newDateIOS = (data: Date) => {
    const year = data.getFullYear();
    const month = data.getMonth();
    const date = data.getDate();
    const hours = data.getHours();
    const minutes = data.getMinutes();
    const dateString = `${year}-${month}-${date}-T-${hours}-${minutes}`;
    const d = dateString.replace(/[ :]/g, "-").split("-");
    return new Date(+d[0], +d[1] - 1, +d[2], +d[4], +d[5], 0);
  };

  const isDateOutOfCurrentDay = (
    row: { from: Date; till: Date },
    eng: string
  ) => {
    if (!withCurrentDay) return false;
    if (currentDayCheckedArr.length > 0) {
      if (!currentDayCheckedArr.find((el) => el === eng)) return false;
      const fromDate = newDateIOS(row.from).getTime();
      const tillDate = newDateIOS(row.till).getTime();
      return fromDate > tillDate;
    } else return false;
  };

  const [currentDayCheckedArr, setCurrentDayCheckedArr] = useState<string[]>(
    []
  );

  useEffect(() => {
    if (withCurrentDay && currentDayArray) {
      setCurrentDayCheckedArr(currentDayArray);
    }
  }, [withCurrentDay, currentDayArray]);

  useEffect(() => {
    if (!withCurrentDay && setIsError !== undefined) setIsError(false);
    else {
      let isError = false;
      for (let timetableKey in timetable) {
        if (
          isDateOutOfCurrentDay(
            // @ts-ignore
            timetable[timetableKey],
            timetableKey
          )
        ) {
          isError = true;
        }
      }
      if (setIsError !== undefined) setIsError(isError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timetable, currentDayCheckedArr]);

  useEffect(() => {
    let error = false;
    for (let i = 0; i < weekDays.length; i++) {
      const isCrossingFrom = timesCrossing[i * 2 + 2];
      const isCrossingTill = timesCrossing[i * 2 + 3];
      error = error || isCrossingFrom || isCrossingTill;
    }
    if (setError) setError(error);
  }, [timetable]);

  return (
    <Table aria-label="simple table" size="small">
      <TableHead>
        <TableRow className={withCurrentDay ? thStyles.thWidths : ""}>
          <MuiTableCell>День недели</MuiTableCell>
          <MuiTableCell align="left">От</MuiTableCell>
          <MuiTableCell align="left">До</MuiTableCell>
          <MuiTableCell align="left"> </MuiTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {weekDays.map((row, key) => {
          const isOutOfRange = isDateOutOfCurrentDay(
            timetable[row.eng as keyof WorkingTime],
            row.eng
          );
          const isCrossingFrom = timesCrossing[key * 2 + 2];
          const isCrossingTill = timesCrossing[key * 2 + 3];

          return (
            <TableRow
              key={key}
              className={withCurrentDay ? thStyles.thWidths : ""}
            >
              <MuiTableCell component="th" scope="row">
                {row.rus}
              </MuiTableCell>
              <MuiTableCell component="th" scope="row">
                <TimePicker
                  margin="dense"
                  variant="inline"
                  ampm={false}
                  className={
                    withCurrentDay ||
                    !isNaN(
                      timetable[row.eng as keyof WorkingTime].from.getTime()
                    )
                      ? classes.normal
                      : classes.transparent
                  }
                  value={timetable[row.eng as keyof WorkingTime].from}
                  onChange={(date) =>
                    date
                      ? setTimeTable({
                          ...timetable,
                          [row.eng]: {
                            ...timetable[row.eng as keyof WorkingTime],
                            from: date.toDate(),
                          },
                        })
                      : null
                  }
                  onBlur={() =>
                    setBlur({
                      ...blur,
                      [row.eng]: {
                        ...blur[row.eng as keyof BlurType],
                        from: true,
                      },
                    })
                  }
                  error={
                    (isNaN(
                      timetable[row.eng as keyof WorkingTime].from.getTime()
                    ) &&
                      blur[row.eng as keyof BlurType].from) ||
                    isOutOfRange ||
                    isCrossingFrom
                    // (blur[row.eng as keyof BlurType].from ||
                    //   blur[row.eng as keyof BlurType].till)
                  }
                  helperText={
                    blur[row.eng as keyof BlurType].from &&
                    isNaN(
                      timetable[row.eng as keyof WorkingTime].from.getTime()
                    )
                      ? "Обязательное поле"
                      : isOutOfRange
                      ? // (blur[row.eng as keyof BlurType].from ||
                        //   blur[row.eng as keyof BlurType].till)
                        "должно быть меньше времени окончания"
                      : isCrossingFrom
                      ? "пересечение времени"
                      : ""
                  }
                  required
                />
              </MuiTableCell>
              <MuiTableCell align="left">
                <TimePicker
                  margin="dense"
                  variant="inline"
                  ampm={false}
                  className={
                    withCurrentDay ||
                    !isNaN(
                      timetable[row.eng as keyof WorkingTime].till.getTime()
                    )
                      ? classes.normal
                      : classes.transparent
                  }
                  value={timetable[row.eng as keyof WorkingTime].till}
                  onChange={(date) =>
                    date
                      ? setTimeTable({
                          ...timetable,
                          [row.eng]: {
                            ...timetable[row.eng as keyof WorkingTime],
                            till: date.toDate(),
                          },
                        })
                      : null
                  }
                  onBlur={() =>
                    setBlur({
                      ...blur,
                      [row.eng]: {
                        ...blur[row.eng as keyof BlurType],
                        till: true,
                      },
                    })
                  }
                  error={
                    (isNaN(
                      timetable[row.eng as keyof WorkingTime].till.getTime()
                    ) &&
                      blur[row.eng as keyof BlurType].till) ||
                    isOutOfRange ||
                    isCrossingTill
                    // (blur[row.eng as keyof BlurType].from ||
                    //   blur[row.eng as keyof BlurType].till))
                  }
                  helperText={
                    blur[row.eng as keyof BlurType].till &&
                    isNaN(
                      timetable[row.eng as keyof WorkingTime].till.getTime()
                    )
                      ? "Обязательное поле"
                      : isOutOfRange
                      ? // (blur[row.eng as keyof BlurType].from ||
                        //   blur[row.eng as keyof BlurType].till)
                        "должно быть больше времени начала"
                      : isCrossingTill
                      ? "пересечение времени"
                      : ""
                  }
                  required
                />
              </MuiTableCell>
              {withCurrentDay && currentDayCheckedArr && (
                <MuiTableCell>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={
                            !!currentDayCheckedArr.find((el) => el === row.eng)
                          }
                          checked={
                            !!currentDayCheckedArr.find((el) => el === row.eng)
                          }
                          onChange={(e) => {
                            let newCurrentDayArray = copyObject(
                              currentDayCheckedArr
                            );
                            if (e.target.checked) {
                              newCurrentDayArray = [
                                ...newCurrentDayArray,
                                row.eng,
                              ];
                            } else
                              newCurrentDayArray = newCurrentDayArray.filter(
                                (el: any) => el !== row.eng
                              );
                            setCurrentDayCheckedArr(newCurrentDayArray);
                            if (setCurrentDayArray !== undefined)
                              setCurrentDayArray(newCurrentDayArray);
                          }}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label="Текущие сутки"
                    />
                  </FormControl>
                </MuiTableCell>
              )}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default TimeTable;
