import React, { FC, useState, useEffect, useRef, useContext } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  IconButton,
  Avatar,
} from "@material-ui/core";
import PublishIcon from "@material-ui/icons/Publish";
import { Alert } from "@material-ui/lab";
import { Filter, MPData } from "./_shared/types";
import API from "../../_shared/axios";
import useStyles from "./_shared/styles";
import { ProductRow, AlertContextType } from "../../_shared/types";
import { AlertContext } from "../_shared/ToastList";
import TransferList from "./_shared/TransferList";
import {
  alertError,
  getErrorMsg,
  getSizeOfStringInBytes,
} from "../../_shared/utils";
import { ProgressBar } from "../_shared/ProgressBar";

const EditFilterForm: FC<{
  onSend: (id: number, data: MPData) => Promise<any>;
  data?: Filter;
  onClose: () => void;
}> = ({ onSend, data: dataProp, onClose }) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const [alerted, setAlerted] = useState(false);
  const [blur, setBlur] = useState({
    imgFile: false,
    name: false,
    position: false,
  });
  const [progress, setProgress] = useState(false);
  const [progressImg, setProgressImg] = useState(false);

  const [data, setData] = useState<Filter | undefined>();
  const [dataImg, setDataImg] = useState<File | undefined>();
  const [imgFile, setImgFile] = useState<File | undefined>();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [position, setPosition] = useState(0);
  const [products, setProducts] = useState<ProductRow[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<ProductRow[]>([]);
  const [directoryId, setDirectoryId] = useState<number>();

  const classes = useStyles();
  const alertContext = useContext<AlertContextType>(AlertContext);

  useEffect(() => {
    if (typeof dataProp !== "undefined") {
      setImgFile(undefined);
      setProgressImg(true);
      setProducts([]);
      setProgress(true);
      API.get(`/filters/${dataProp.id}`)
        .then(({ data: filter }) => {
          API.get("/files/images/directories")
            .then(({ data }) =>
              setDirectoryId(
                data.find(
                  (el: { id: number; value: string; description: string }) =>
                    el.value === "Filters"
                ).id
              )
            )
            .catch((error) =>
              alertError(
                alertContext,
                getErrorMsg(
                  error.response,
                  "Ошибка получения списка директорий изображений"
                )
              )
            );
          API.get(`/products`)
            .then(({ data: products }) => {
              setData(filter);
              setProducts(products);
            })
            .catch((error) =>
              alertError(
                alertContext,
                getErrorMsg(error.response, "Ошибка загрузки списка продуктов")
              )
            );
        })
        .catch((error) =>
          alertError(
            alertContext,
            getErrorMsg(error.response, "Ошибка получения фильтра")
          )
        )
        .finally(() => setProgress(false));
    } else {
      setData(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProp]);

  useEffect(() => {
    if (typeof data !== "undefined") {
      API.get(data.thumbnail.replace(/http:/, "https:"), {
        responseType: "blob",
      })
        .then(({ data }) => setDataImg(data))
        .catch((error) => setDataImg(undefined))
        .finally(() => setProgressImg(false));

      setName(data.name);
      setDescription(data.description ? data.description : "");
      setPosition(data.position);
      setSelectedProducts([]);

      setAlerted(false);
      setBlur({
        imgFile: false,
        name: false,
        position: false,
      });
    }
  }, [data]);

  useEffect(() => {
    setImgFile(dataImg);
  }, [dataImg]);

  const onSubmit = () => {
    if (typeof data !== "undefined" && imgFile) {
      const added = selectedProducts
        .map((p) => p.id)
        .filter((p) => data?.products.map((m) => m.id).indexOf(p) === -1)
        .join(", ");

      const removed = data?.products
        .map((p) => p.id)
        .filter((p) => selectedProducts.map((m) => m.id).indexOf(p) === -1)
        .join(", ");

      setProgress(true);

      if (imgFile === dataImg) {
        const idx = data.thumbnail.lastIndexOf("/") || 1;
        const imageName = data.thumbnail.slice(idx + 1);
        onSend(data.id, {
          ImgName: imageName,
          name,
          productsToAdd: added,
          productsToRemove: removed,
          description,
          position: position.toString(),
        })
          .then((data) => onClose())
          .catch((error) => console.log(error))
          .finally(() => setProgress(false));
      } else {
        const imageToUpload = {
          File: imgFile,
          Folder: directoryId,
        };
        const formData = new FormData();
        (Object.keys(imageToUpload) as Array<
          keyof { File: File; Folder: number }
        >).map((k: keyof { File: File; Folder: number }) => {
          const id = data.id;
          // @ts-ignore
          formData.append(k as string, imageToUpload[k]);
          API.post("/files/images/upload", formData)
            .then(({ data }) => {
              onSend(id, {
                ImgName: data,
                name,
                productsToAdd: added,
                productsToRemove: removed,
                description,
                position: position.toString(),
              })
                .then((data) => onClose())
                .catch((error) => console.log(error))
                .finally(() => setProgress(false));
            })
            .catch((error) => {
              alertError(
                alertContext,
                getErrorMsg(error.response, "Ошибка загрузки изображения")
              );
            });
          return true;
        });
      }
    }
  };

  const safeToExit =
    typeof data !== "undefined"
      ? (data.name === name || name === "") &&
        (dataImg === imgFile || typeof imgFile === "undefined") &&
        (data.description === description ||
          (data.description === null && description === "")) &&
        (data.position === position || position === 0) &&
        data.products
          .map((p) => p.id)
          .sort()
          .join("") ===
          selectedProducts
            .map((p) => p.id)
            .sort()
            .join("")
      : true;

  const isImageFormatWrong = () =>
    imgFile?.name?.slice(imgFile?.name?.length - 4, imgFile?.name?.length) !==
      ".png" && imgFile?.name;

  return (
    <Dialog
      disableBackdropClick
      onBackdropClick={() =>
        progress ? null : safeToExit ? onClose() : setAlerted(true)
      }
      open={typeof dataProp !== "undefined"}
      aria-labelledby="form-dialog-title"
      maxWidth="lg"
    >
      <DialogTitle id="form-dialog-title">Редактирование фильтра</DialogTitle>
      <DialogContent>
        <div className={classes.imgWrapper}>
          <input
            accept="image/*"
            className={classes.imageDisplay}
            id="contained-button-file"
            type="file"
            ref={fileRef}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              e.target.files?.[0] ? setImgFile(e.target.files[0]) : null
            }
          />
          <Avatar
            alt={imgFile ? URL.createObjectURL(imgFile) : ""}
            src={imgFile ? URL.createObjectURL(imgFile) : ""}
            variant="rounded"
            className={classes.large}
          >
            {progressImg && <CircularProgress />}
          </Avatar>
          {isImageFormatWrong() && (
            <p className={classes.requiredWarning}>
              Выберите изображение в "png" формате
            </p>
          )}
          <div className={classes.imgControls}>
            <label htmlFor="contained-button-file">
              <IconButton
                component="span"
                onClick={(e: React.MouseEvent<HTMLSpanElement>) =>
                  e.stopPropagation()
                }
              >
                <PublishIcon />
              </IconButton>
            </label>
          </div>
        </div>
        <TextField
          margin="dense"
          id="name"
          label="Наименование"
          inputProps={{ maxLength: 100 }}
          value={name}
          onChange={(e) => setName(e.target.value)}
          type="text"
          fullWidth
          onBlur={() => setBlur({ ...blur, name: true })}
          error={!name && blur.name}
          helperText={!name && blur.name ? "Обязательное поле" : ""}
          required
        />
        <TextField
          margin="dense"
          id="description"
          label="Описание"
          value={description}
          onChange={(e) => {
            if (getSizeOfStringInBytes(e.target.value) <= 4000)
              setDescription(e.target.value);
          }}
          type="text"
          fullWidth
        />
        <TextField
          margin="dense"
          id="position"
          label="Позиция"
          value={position}
          onChange={(e) =>
            setPosition(
              e.target.value !== ""
                ? e.target.value.match(/^\d+$/)
                  ? parseInt(e.target.value)
                  : position
                : 0
            )
          }
          type="text"
          fullWidth
          onBlur={() => setBlur({ ...blur, position: true })}
          error={!position && blur.position}
          helperText={!position && blur.position ? "Обязательное поле" : ""}
          required
        />
        <p className={classes.colorInactive}>Товары:</p>
        <TransferList
          products={products}
          selectedProducts={data?.products}
          getData={(data: ProductRow[]) => setSelectedProducts(data)}
        />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {alerted ? (
          <Alert
            severity="warning"
            action={
              <>
                <Button color="inherit" size="small" onClick={onClose}>
                  Да
                </Button>
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => setAlerted(false)}
                >
                  Нет
                </Button>
              </>
            }
          >
            Вы действительно хотите отменить все изменения и выйти? Введенные
            данные будут утеряны
          </Alert>
        ) : (
          <>
            <Button onClick={onClose} color="primary" disabled={progress}>
              Отмена
            </Button>
            <Button
              onClick={onSubmit}
              color="primary"
              type="submit"
              disabled={
                !!isImageFormatWrong() ||
                typeof imgFile === "undefined" ||
                name === "" ||
                position === 0 ||
                safeToExit ||
                progress
              }
            >
              Подтвердить
            </Button>
          </>
        )}
      </DialogActions>
      {progress && <ProgressBar bottom />}
    </Dialog>
  );
};

export default EditFilterForm;
