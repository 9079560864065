import { Button, makeStyles } from "@material-ui/core";
import React, { FC, useContext, useEffect, useState } from "react";
import API from "../../../_shared/axios";
import SyncTable from "../syncTable";
import SyncTableRowAddress from "./syncTableRowAddress";
import { AlertContextType } from "../../../_shared/types";
import { AlertContext } from "../../_shared/ToastList";
import { alertError, alertSuccess, getErrorMsg } from "../../../_shared/utils";
import { TouchedAlert } from "../index";
import { useDebounce } from "@react-hook/debounce";

interface iSyncItem {
  syncId: number;
  syncName: string;
  id: number;
  name: string;
  relation: { syncAddressId: number; addressId: number };
}

interface iSyncAddressItem {
  syncId: number;
  syncName: string;
  id: number;
  name: string;
}

interface iAddressPost {
  syncAddressId: number;
  addressId: number;
}

const useStyles = makeStyles({
  page: {
    margin: 0,
  },
  content: {
    padding: 50,
    "& .MuiTableCell-body": {
      cursor: "pointer",
    },
  },
  heading: {
    display: "flex",
    alignItems: "center",
  },
});

const AddressPage: FC = () => {
  const classes = useStyles();
  const alertContext = useContext<AlertContextType>(AlertContext);
  const touchedContext = useContext(TouchedAlert);

  const [backendData, setBackendData] = useState<any[] | null>(null);
  const [oneC_data, setOneC_data] = useState<iSyncItem[]>([]);
  const [changedData, setChangedData] = useState<iAddressPost[]>([]);
  const [unusedBackendData, setUnusedBackendData] = useState<any>(null);
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [search1CString, setSearch1CString] = useState("");
  const [searchBackendString, setSearchBackendString] = useState("");
  // 0 - no filter, 1 - synced, 2 - not synced
  const [filter, setFilter] = useState(0);
  const [filters, setFilters] = useDebounce(
    {
      page: 0,
      pageSize: 25,
      search1CString: "",
      searchBackendString: "",
      filter: 0,
    },
    500
  );

  // useEffect(() => {
  //   API.get(`/customers?pageNumber=1&FilterParams[0].ColumnName=phoneNumber&FilterParams[0].FilterOption=3&FilterParams[0].filterValue=${searchString}`).then((data) => {
  //     setBackendData(
  //       data.data.items.map((item: any) => {
  //         return {
  //           ...item,
  //           fullName: item.phoneNumber,
  //         };
  //       })
  //     );
  //   });
  // }, [searchString]);
  const load = () => {
    const uriStrings = [];
    let indx = 0;

    if (search1CString) {
      uriStrings.push(
        `&FilterParams[${indx}].ColumnName=syncName` +
          `&FilterParams[${indx}].FilterOption=3` +
          `&FilterParams[${indx}].filterValue=${search1CString}`
      );
      indx += 1;
    }
    if (filter !== 0) {
      uriStrings.push(
        `${
          filter === 1
            ? `&FilterParams[${indx}].ColumnName=id` +
              `&FilterParams[${indx}].FilterOption=12`
            : filter === 2
            ? `&FilterParams[${indx}].ColumnName=id` +
              `&FilterParams[${indx}].FilterOption=11`
            : ""
        }`
      );
    }

    let urlParams =
      `pageNumber=${page + 1}` +
      `&pageSize=${pageSize}` +
      `${uriStrings.join("")}`;

    API.get(`/syncAddressList?${urlParams}`)
      .then((data) => {
        setTotalItems(data.data.totalItems);
        setOneC_data(
          data.data.items
            .map((item: any) => {
              return {
                ...item,
                relation: {
                  name: item.syncName,
                  fullName: item.totalAddress || "",
                },
              };
            })
            .sort((a: any, b: any) => (a?.name === null ? 1 : -1))
        );
      })
      .catch((error) => {
        console.log(error);
        alertError(
          alertContext,
          getErrorMsg(
            error.response,
            "Неизвестная ошибка получения списка синхронизации"
          )
        );
      });
  };

  useEffect(() => {
    load();
  }, [filters]);

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    setFilters({ filter, page, pageSize, search1CString, searchBackendString });
  }, [filter, page, pageSize, search1CString, searchBackendString]);

  const transformDataForPost = (
    backendObj: any,
    oneC_obj: any,
    index: number
  ) => {
    touchedContext.setter(true);
    setChangedData((prevStat: iAddressPost[]) => {
      return [
        ...prevStat,
        { syncAddressId: oneC_obj.syncId, addressId: backendObj?.id },
      ];
    });
    setOneC_data((prevStat) => {
      const obj3: iSyncItem = {
        ...oneC_obj,
        id: backendObj?.id || null,
        name: backendObj?.id ? backendObj.name : null,
      };

      const arr = [...prevStat];
      arr.splice(index, 1, obj3);

      return [...arr];
    });
  };

  const postData = () => {
    console.log(changedData);
    API.post("/mapSyncAddresses", changedData)
      .then((data) => {
        touchedContext.setter(false);
        alertSuccess(alertContext, "Успешно синхронизировано");
      })
      .catch((error) => {
        console.log(error);
        alertError(
          alertContext,
          getErrorMsg(error.response, "Неизвестная ошибка синхронизации")
        );
      });
  };

  useEffect(() => {
    setUnusedBackendData(
      backendData
        ?.map((i: any) => {
          const user = oneC_data?.find((j: any) => {
            return j.id === i.id;
          });
          console.log(i);
          if (!user) {
            return i;
          }
        })
        .filter((k: any) => k !== undefined)
    );
  }, [backendData, oneC_data]);

  return (
    <div className={classes.content}>
      <div className={classes.heading}>
        <h2>
          Синхронизация &nbsp;&nbsp;{" "}
          <Button variant="outlined" color="primary" onClick={() => postData()}>
            Синхронизировать
          </Button>
        </h2>
      </div>
      <SyncTable<iSyncItem, any>
        changeDatHandler={(v: any, name: any, index: number) =>
          transformDataForPost(v, name, index)
        }
        data={oneC_data}
        totalItems={totalItems}
        filteredGetData={[]}
        title={"Адреса"}
        CustomRow={SyncTableRowAddress}
        page={page}
        pageChange={(page) => setPage(page)}
        pageSize={pageSize}
        pageSizeChange={(pageSize) => setPageSize(pageSize)}
        search1CString={search1CString}
        setSearch1CString={(str) => {
          setPage(0);
          setSearch1CString(str);
        }}
        searchBackendString={searchBackendString}
        setSearchBackendString={(str) => {
          setPage(0);
          setSearchBackendString(str);
        }}
        filter={filter}
        setFilter={(val) => {
          setPage(0);
          setFilter(val);
        }}
      />
    </div>
  );
};
export default AddressPage;
