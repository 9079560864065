import React, { FC, useContext, useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  AlertContextType,
  Ingredient,
  IngredientCreate,
} from "../../_shared/types";
import { TableBody, FormControlLabel, Checkbox } from "@material-ui/core";
import { AlertContext } from "../_shared/ToastList";
import API from "../../_shared/axios";
import NewIngredientForm from "./NewIngredientForm";
import { alertError, alertSuccess, getErrorMsg } from "../../_shared/utils";
import EditIngredientForm from "./EditIngridientForm";
import { makeStyles } from "@material-ui/styles";
import { ProgressBar } from "../_shared/ProgressBar";

const columns = [
  { id: "name", label: "Наименование" },
  {
    id: "position",
    label: "Позиция",
  },
  {
    id: "isAvailable",
    label: "Доступность",
  },
];

const useStyles = makeStyles({
  page: {
    height: "calc(100% - 64px)",
  },
  ingredientCell: {
    minWidth: 100,
  },
  content: {
    padding: 50,
    maxWidth: 1140,
    width: "100%",
    margin: "0 auto 20px auto",
    "& .MuiTableCell-body": {
      cursor: "pointer",
    },
  },
});

const IngredientsPage: FC = () => {
  // const classes = useStyles();
  const [editIngredientId, setIngredientId] = useState<number | undefined>(
    undefined
  );
  const [rows, setRows] = useState<Ingredient[]>([]);
  const [progress, setProgress] = useState(false);

  const classes = useStyles();
  const alertContext = useContext<AlertContextType>(AlertContext);

  useEffect(() => {
    setProgress(true);
    API.get(`/ingredients`)
      .then(({ data }) => {
        setRows(data);
        setProgress(false);
      })
      .catch((error) => {
        alertError(
          alertContext,
          getErrorMsg(error.response, "Ошибка получения списка ингредиентов")
        );
        setProgress(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onIngridientCreate = (data: IngredientCreate) =>
    new Promise((resolve, reject) =>
      API.post("/ingredients", data)
        .then(({ data }) => {
          alertSuccess(alertContext, "Ингредиент добавлен");
          API.get(`/ingredients`)
            .then(({ data }) => {
              setRows(data);
              resolve(data);
            })
            .catch((error) => {
              alertError(
                alertContext,
                getErrorMsg(
                  error.response,
                  "Ошибка обновления списка ингредиентов"
                )
              );
              reject(error);
            });
        })
        .catch((error) => {
          alertError(
            alertContext,
            getErrorMsg(error.response, "Ошибка добавления ингредиента")
          );
          reject(error);
        })
    );

  const onIngridientEdit = (id: number, ingredient: Ingredient) => {
    return new Promise((resolve, reject) =>
      API.patch(`/ingredients/${id}`, ingredient)
        .then(({ data }) => {
          API.get(`/ingredients`)
            .then(({ data }) => {
              setRows(data);
              alertSuccess(alertContext, "Успешное редактирование ингредиента");
              resolve(data);
            })
            .catch((error) => {
              alertError(
                alertContext,
                getErrorMsg(
                  error.response,
                  "Ошибка обновления списка ингредиентов"
                )
              );
              reject(error);
            });
        })
        .catch((error) => {
          alertError(
            alertContext,
            getErrorMsg(error.response, "Ошибка редактирования ингредиента")
          );
          reject(error);
        })
    );
  };

  return (
    <div className={classes.page}>
      <EditIngredientForm
        onIngredientEdit={onIngridientEdit}
        ingredientId={editIngredientId}
        onClose={() => setIngredientId(undefined)}
      />
      <div className={classes.content}>
        <NewIngredientForm onIngredientCreate={onIngridientCreate} />
        {progress ? (
          <ProgressBar />
        ) : (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      className={classes.ingredientCell}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, key) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={key}
                      onClick={() => setIngredientId(row.id)}
                    >
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.position}</TableCell>
                      <TableCell>
                        <FormControlLabel
                          disabled
                          checked={row.isActive}
                          control={<Checkbox name="checkedD" />}
                          label=""
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </div>
  );
};

export default IngredientsPage;
