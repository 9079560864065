import React, { useState, useEffect, useContext } from "react";
import { AlertContextType, UserCookie } from "../../_shared/types";
import {
  Button,
  FormGroup,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import {
  KladrObject,
  KladrObjectCreate,
  KladrObjectType,
} from "./_shared/types";
import API from "../../_shared/axios";
import { AlertContext } from "../_shared/ToastList";
import { alertError, alertSuccess, getStreetsData } from "../../_shared/utils";
import NewStreetForm from "./NewStreetForm";
import RefreshForm from "./RefreshForm";
import { ProgressBar } from "../_shared/ProgressBar";
import EditStreetForm from "./EditStreetForm";
import { makeStyles } from "@material-ui/styles";
import cookies from "../../../cookies";
import { Shop } from "../_shared/types";

const useStyles = makeStyles({
  page: { margin: 0 },
  content: {
    padding: 50,
    "& .MuiTableCell-body": {
      cursor: "pointer",
    },
  },
  search: {
    display: "flex",
    alignItems: "center",
    "& > .MuiTextField-root": {
      marginTop: 0,
    },
  },
});

const StreetsPage = () => {
  const [rows, setRows] = useState<
    { district: KladrObject | undefined; streets: KladrObject[] }[]
  >([]);
  const [data, setData] = useState<KladrObject | undefined>();
  const [myCityId, setMyCityId] = useState<number | undefined>(undefined);
  const [aroundTheClockShops, setAroundTheClockShops] = useState<Shop[]>([]);
  const [district, setDistrict] = useState<KladrObject | undefined>();
  const [kladrObjectTypes, setKladrObjectTypes] = useState<KladrObjectType[]>(
    []
  );
  const [search, setSearch] = useState("");
  const [progress, setProgress] = useState(false);

  const classes = useStyles();
  const alertContext = useContext<AlertContextType>(AlertContext);

  const user: UserCookie | undefined = cookies.get("user");
  const myCity = user?.city.id;

  useEffect(() => {
    setProgress(true);
    API.get(`/kladrObjects/types`)
      .then(({ data }: { data: KladrObjectType[] }) => {
        const types = data;
        setKladrObjectTypes(types);
        API.get(`/kladrObjects`)
          .then(({ data }) => {
            const myCityIdData =
              data.find((el: { id: number; name: string }) => el.id === myCity)
                .id || 1;
            setMyCityId(myCityIdData);
            API.get(`/kladrObjects/${myCityIdData}/children?level=2`)
              .then(({ data }: { data: KladrObject[] }) => {
                const rowsData = getStreetsData(data, types);
                API.get("/shops")
                  .then(({ data }: { data: Shop[] }) => {
                    const shopsData = data;
                    let currentShopsCounter = 0;
                    let currentShops: Shop[] = [];
                    shopsData.forEach((shop) =>
                      API.get(`/shops/${shop.id}`)
                        .then(({ data }: { data: Shop }) => {
                          currentShops = [...currentShops, data];
                          currentShopsCounter++;
                          if (currentShopsCounter === shopsData.length)
                            setAroundTheClockShops(
                              currentShops.filter(
                                (el) =>
                                  el.isAroundTheClockDelivery ||
                                  el.isAroundTheClockPickup
                              )
                            );
                        })
                        .catch(() =>
                          alertError(
                            alertContext,
                            "Ошибка получения точки продаж"
                          )
                        )
                    );
                  })
                  .catch(() =>
                    alertError(
                      alertContext,
                      "Ошибка получения списка точек продаж"
                    )
                  );
                setRows(rowsData);
                setProgress(false);
              })
              .catch((error) => {
                alertError(alertContext, "Ошибка получения списка улиц");
                setProgress(false);
              });
          })
          .catch((error) => {
            alertError(alertContext, "Ошибка получения всех кладр-объектов");
            setProgress(false);
          });
      })
      .catch((error) => {
        alertError(
          alertContext,
          "Ошибка получения списка типов кладр-объектов"
        );
        setProgress(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSend = (data: KladrObjectCreate) =>
    new Promise((resolve, reject) =>
      API.post("/kladrObjects", data)
        .then(({ data }) => {
          alertSuccess(alertContext, "Улица добавлена");
          API.get(`/kladrObjects/${myCityId}/children?level=2`)
            .then(({ data }: { data: KladrObject[] }) => {
              setRows(getStreetsData(data, kladrObjectTypes));
              setProgress(false);
              resolve(data);
            })
            .catch((error) => {
              alertError(alertContext, "Ошибка получения списка улиц");
              setProgress(false);
              reject(error);
            });
        })
        .catch((error) => {
          alertError(alertContext, "Ошибка добавления улицы");
          reject(error);
        })
    );

  const onRefresh = (cityId: number) =>
    new Promise((resolve, reject) =>
      API.post(`/kladrObjects/refresh?cityId=${cityId}`, data)
        .then(({ data }) => {
          const refreshData = data;
          alertSuccess(alertContext, "Обновление улиц произошло успешно");
          API.get(`/kladrObjects/${myCityId}/children?level=2`)
            .then(({ data }: { data: KladrObject[] }) => {
              setRows(getStreetsData(data, kladrObjectTypes));
              setProgress(false);
              resolve(refreshData);
            })
            .catch((error) => {
              alertError(alertContext, "Ошибка получения списка улиц");
              setProgress(false);
              reject(error);
            });
        })
        .catch((error) => {
          console.log(error);
          alertError(alertContext, "Ошибка обновления улиц");
          reject(error);
        })
    );

  const onEdit = (id: number, data: KladrObjectCreate) =>
    new Promise((resolve, reject) =>
      API.patch(`/kladrObjects/${id}`, data)
        .then(({ data }) => {
          alertSuccess(alertContext, "Успешное редактирование улицы");
          API.get(`/kladrObjects/${myCityId}/children?level=2`)
            .then(({ data }: { data: KladrObject[] }) => {
              resolve(data);
              setRows(getStreetsData(data, kladrObjectTypes));
            })
            .catch((error) => {
              alertError(alertContext, "Ошибка обновления списка улиц");
            });
        })
        .catch((error) => {
          alertError(alertContext, "Ошибка редактирования улицы");
          reject(error);
        })
    );

  const getStreets = (streets: any[]) =>
    search.length
      ? streets.filter((el: any) =>
          el.name.toLowerCase().includes(search.toLowerCase())
        )
      : streets;

  return (
    <div className={classes.page}>
      <EditStreetForm
        districtData={district}
        onSend={onEdit}
        data={data}
        onClose={() => setData(undefined)}
      />
      <div className={classes.content}>
        <FormGroup
          aria-label="position"
          style={{ justifyContent: "space-between" }}
          row
        >
          <h2 className={classes.search}>
            Улицы &nbsp;&nbsp;
            <TextField
              label="Поиск"
              margin="normal"
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            &nbsp;&nbsp;
            <NewStreetForm onSend={onSend} />
          </h2>
          <RefreshForm setRows={setRows} onRefresh={onRefresh} rows={rows} />
        </FormGroup>
        {progress ? (
          <ProgressBar />
        ) : (
          <TableContainer component={Paper}>
            <Table aria-label="simple table" className="clientTable">
              <TableHead>
                <TableRow>
                  <TableCell>Район</TableCell>
                  <TableCell align="left">Наименование</TableCell>
                  <TableCell align="left">Тип</TableCell>
                  <TableCell align="left">Привязанная точка</TableCell>
                  <TableCell align="left">Круглосуточная точка</TableCell>
                  <TableCell align="left"> </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((object) =>
                  getStreets(object.streets).map((row, key) => (
                    <TableRow
                      key={key}
                      hover
                      onClick={() => {
                        setData(row);
                        if (object.district) {
                          setDistrict(object.district);
                        } else setDistrict(undefined);
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {object.district ? object.district.name : "-"}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.name ? row.name : "-"}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.kladrObjectTypeId
                          ? kladrObjectTypes
                              .filter((el) => el.level === 10)
                              .find((el) => el.id === row.kladrObjectTypeId)!
                              .nameRu
                          : "-"}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.shop ? row.shop.name : "-"}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.aroundTheClockShop
                          ? aroundTheClockShops.find(
                              (el) => el.id === row.aroundTheClockShopId
                            )
                            ? row.aroundTheClockShop.name
                            : "-"
                          : "-"}
                      </TableCell>
                      <TableCell align="left">
                        <Button
                          style={{ maxWidth: "9rem", minWidth: "9rem" }}
                          variant="outlined"
                          color="primary"
                          onClick={(e) => {
                            e.stopPropagation();
                            API.delete(`kladrObjects/delete`, {
                              data: {
                                kladrObjectIds: [row.id],
                              },
                            })
                              .then(() => {
                                alertSuccess(alertContext, "Улица удалена");
                                API.get(
                                  `/kladrObjects/${myCityId}/children?level=2`
                                )
                                  .then(({ data }: { data: KladrObject[] }) => {
                                    setRows(
                                      getStreetsData(data, kladrObjectTypes)
                                    );
                                  })
                                  .catch((error) => {
                                    alertError(
                                      alertContext,
                                      "Ошибка обновления списка улиц"
                                    );
                                  });
                              })
                              .catch(() =>
                                alertError(
                                  alertContext,
                                  "Ошибка удаления улицы"
                                )
                              );
                          }}
                        >
                          Удалить
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </div>
  );
};

export default StreetsPage;
