import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import cookies from "../../../../../cookies";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ReplyIcon from "@material-ui/icons/Reply";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import { useHistory } from "react-router";
import { UserCookie } from "../../../../_shared/types";
import { getMonthNameByNumber, getUserName } from "../../../../_shared/utils";
import styles from "./index.module.scss";
import API from "../../../../_shared/axios";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import moment from "moment/moment";

const cx = classNames.bind(styles);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function OrderDialog({
  children,
  id,
  createdAt,
}: {
  children: React.ReactNode;
  id: number;
  createdAt: string;
}) {
  const history = useHistory();

  const user: UserCookie | undefined = cookies.get("user");
  const userName = getUserName(user);

  const [parsedDate, setParsedDate] = useState("");

  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    history.push("/main/dispatcher");
  };

  useEffect(() => {
    const date = moment(createdAt);
    setParsedDate(
      `${date.format("D")} ${getMonthNameByNumber(
        Number(date.format("M")) - 1
      )} ${date.format("HH:mm")}`
    );
  }, [createdAt]);

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        TransitionComponent={Transition}
        className={cx("dialog")}
        PaperProps={{
          style: {
            backgroundColor: "#f3f3f3",
            boxShadow: "none",
          },
        }}
      >
        <AppBar>
          <Toolbar className={cx("header")}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="back"
              onClick={handleClose}
            >
              <ReplyIcon />
            </IconButton>
            <Typography variant="h6" className={cx("title")}>
              {isNaN(id) ? "Новый заказ" : `Заказ №${id} от ${parsedDate}`}
            </Typography>
            <Typography variant="h6" noWrap>
              {userName}
            </Typography>
            <IconButton
              color="inherit"
              onClick={() => {
                API.post(`/signalRSend`, {
                  userId: user?.id,
                  userName: user?.firstName,
                  status: 3,
                }).finally(() =>
                  ["auth", "user"].map((c) => cookies.remove(c, { path: "/" }))
                );
              }}
            >
              <ExitToAppIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div
          style={{ scrollbarWidth: "none", backgroundColor: "#f3f3f3" }}
          className={cx("content")}
        >
          {children}
        </div>
      </Dialog>
    </div>
  );
}
