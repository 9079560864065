import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles({
  formControl: {
    minWidth: 120,
  },
  wrapper: {
    display: "inline",
  },
  showInMenu: { marginTop: 10 },
});
