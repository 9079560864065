import { makeStyles } from "@material-ui/styles";

export default makeStyles({
  dialogActions: {
    "& > div": {
      width: "100%",
    },
  },
  normal: {
    "& input": {
      transition: "color 150ms ease-in-out",
    },
    "& > p": {
      position: "absolute",
      top: "100%",
    },
  },
  timePickerWidth: {
    maxWidth: "30%",
  },
  requiredWarning: {
    color: "#f44336",
    margin: "4px 0 0 0",
    fontSize: "0.75rem",
    letterSpacing: "0.03333em",
    lineHeight: "1.66",
  },
  transparent: {
    "& input": {
      color: "transparent",
      userSelect: "none",
    },
    "& > p": {
      position: "absolute",
      top: "100%",
    },
  },
});
