import React, { FC, useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Checkbox,
  IconButton,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Collapse,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { AlertContextType, UserCookie } from "../../_shared/types";
import { AlertContext } from "../_shared/ToastList";
import { alertError, getStreetsData } from "../../_shared/utils";
import API from "../../_shared/axios";
import { Street, KladrObject, KladrObjectType } from "./_shared/types";
import RefreshIcon from "@material-ui/icons/Refresh";
import DeleteIcon from "@material-ui/icons/Delete";
import RestoreIcon from "@material-ui/icons/Restore";
import cookies from "../../../cookies";

const useStyles = makeStyles((theme) => ({
  list: {
    scrollbarWidth: "none",
    "& ::-webkit-scrollbar": {
      display: "none",
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const RefreshForm: FC<{
  setRows: (
    data: { district: KladrObject | undefined; streets: KladrObject[] }[]
  ) => void;
  onRefresh: (data: number) => Promise<any>;
  rows: { district: KladrObject | undefined; streets: KladrObject[] }[];
}> = ({ setRows, onRefresh, rows }) => {
  const alertContext = useContext<AlertContextType>(AlertContext);

  const [deletedOpen, setDeletedOpen] = useState(false);
  const [wrongOpen, setWrongOpen] = useState(false);
  const [update, setResp] = useState<any>(undefined);
  const [checkedRestore, setCheckedRestore] = useState<boolean[]>([]);
  const [checkedDelete, setCheckedDelete] = useState<boolean[]>([]);
  const [modal, setModal] = useState(false);
  const [blur, setBlur] = useState({
    cityId: false,
    name: false,
    streetTypeId: false,
  });
  const [progress, setProgress] = useState(false);

  const [cities, setCities] = useState<KladrObject[]>([]);
  const [ptzId, setPtzId] = useState<number | undefined>(undefined);
  const [cityId, setCityId] = useState(0);

  const classes = useStyles();

  const user: UserCookie | undefined = cookies.get("user");

  const onOpen = () => {
    setDeletedOpen(false);
    setWrongOpen(false);
    setResp(undefined);
    setCheckedRestore([]);
    setCheckedDelete([]);
    setPtzId(undefined);
    setCityId(0);

    setCities([]);
    API.get(encodeURI(`/kladrObjectsByLevel?level=30`)).then(
      ({ data }: { data: KladrObject[] }) => {
        setCities(data);
        if (user?.city.id) setCityId(user?.city.id);
        setPtzId(user?.city.id);
      }
    );

    setModal(true);
  };

  useEffect(() => {
    if (ptzId !== undefined) setCityId(ptzId);
  }, [ptzId]);

  const onSubmit = () => {
    setProgress(true);
    onRefresh(cityId)
      .then((data) => {
        const refreshData = data;
        const streets: any[] = rows
          .map((el) => el.streets)
          .reduce((sum, acc) => [...sum, ...acc], []);
        const refreshDataWithStreetTypes = {
          ...refreshData,
          forDelete:
            refreshData.forDelete.length === 0
              ? []
              : refreshData.forDelete.map((del: any) => {
                  return {
                    ...del,
                    streetType:
                      streets.find(
                        (street) =>
                          street.name.toLowerCase() === del.name.toLowerCase()
                      )?.kladrObjectType.nameRu || "",
                  };
                }),
          forRestore:
            refreshData.forRestore.length === 0
              ? []
              : refreshData.forRestore.map((res: any) => {
                  return {
                    ...res,
                    streetType:
                      streets.find(
                        (street) =>
                          street.name.toLowerCase() === res.name.toLowerCase()
                      )?.kladrObjectType.nameRu || "",
                  };
                }),
        };
        setModal(false);
        setCheckedRestore(
          Array(refreshDataWithStreetTypes.forRestore.length).fill(false)
        );
        setCheckedDelete(
          Array(refreshDataWithStreetTypes.forDelete.length).fill(false)
        );
        setResp(refreshDataWithStreetTypes);
      })
      .catch((error) => console.log(error))
      .finally(() => setProgress(false));
  };

  const onClose = () => {
    setProgress(true);
    API.get(`/kladrObjects/1/children?level=2`)
      .then(({ data }: { data: KladrObject[] }) => {
        const kladrObjects = data;
        API.get(`/kladrObjects/types`)
          .then(({ data }: { data: KladrObjectType[] }) => {
            setRows(getStreetsData(kladrObjects, data));
          })
          .catch((error) => {
            alertError(
              alertContext,
              "Ошибка получения списка типов кладр-объектов"
            );
          });
      })
      .catch((error) => {
        alertError(alertContext, "Ошибка обновления списка улиц");
      })
      .finally(() => {
        setProgress(false);
        setResp(undefined);
      });
  };

  const checkedSomeRestore = checkedRestore.filter((c) => c).length !== 0;
  const checkedSomeDelete = checkedDelete.filter((c) => c).length !== 0;
  const checkedAllRestore = checkedRestore.filter((c) => !c).length === 0;
  const checkedAllDelete = checkedDelete.filter((c) => !c).length === 0;

  return (
    <>
      <FormControlLabel
        control={
          <IconButton onClick={onOpen}>
            <RefreshIcon />
          </IconButton>
        }
        label=""
      />
      <Dialog
        disableBackdropClick
        onBackdropClick={() => setModal(false)}
        open={modal}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">Обновление списка улиц</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel id="simple-select-cityid">Город *</InputLabel>
            <Select
              labelId="simple-select-city"
              id="simple-select-city"
              value={cityId}
              onChange={(e) => setCityId(Number(e.target.value))}
              disabled={cities.length === 0}
              onBlur={() => setBlur({ ...blur, cityId: true })}
            >
              {cities.map((c, idx) => (
                <MenuItem key={idx} value={c.id}>
                  {c.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setModal(false)}
            color="primary"
            disabled={progress}
          >
            Отмена
          </Button>
          <Button
            onClick={onSubmit}
            color="primary"
            type="submit"
            disabled={progress}
          >
            Обновить
          </Button>
        </DialogActions>
        {progress && (
          <LinearProgress
            style={{ position: "absolute", bottom: "0px", width: "100%" }}
          />
        )}
      </Dialog>
      <Dialog
        open={!!update}
        onClose={onClose}
        onBackdropClick={onClose}
        aria-labelledby="simple-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="simple-dialog-title">Улицы обновлены</DialogTitle>
        <DialogContent className={classes.list}>
          <List>
            <ListItem>
              <ListItemText primary={`Добавлено: ${update?.addedCount}`} />
            </ListItem>
            <ListItem>
              <ListItemText primary={`Обновлено: ${update?.updatedCount}`} />
            </ListItem>
            {update?.forRestore.length !== 0 && (
              <ListItem button onClick={() => setDeletedOpen(!deletedOpen)}>
                <ListItemText
                  primary={`Кандидатов на восстановление: ${update?.forRestore.length}`}
                />
                <ListItemSecondaryAction style={{ marginRight: "32px" }}>
                  <Checkbox
                    onClick={(e) => {
                      e.stopPropagation();
                      setCheckedRestore(
                        Array(update?.forRestore.length).fill(
                          checkedAllRestore ? false : true
                        )
                      );
                    }}
                    checked={checkedAllRestore}
                  />
                  <IconButton
                    edge="end"
                    disabled={!checkedSomeRestore || progress}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (update) {
                        setProgress(true);

                        API.post(`/kladrObjects/restore`, {
                          kladrObjectIds: update.forRestore
                            .filter((s: Street, i: number) => checkedRestore[i])
                            .map((s: Street) => s.id),
                        })
                          .then(() =>
                            onRefresh(cityId)
                              .then((data) => {
                                setCheckedRestore(
                                  Array(data.forRestore.length).fill(false)
                                );
                                setCheckedDelete(
                                  Array(data.forDelete.length).fill(false)
                                );
                                setResp(data);
                              })
                              .catch((error) => console.log(error))
                          )
                          .catch((error) =>
                            alertError(
                              alertContext,
                              "Ошибка восстановления улицы"
                            )
                          )
                          .finally(() => setProgress(false));
                      }
                    }}
                  >
                    <RestoreIcon />
                  </IconButton>
                </ListItemSecondaryAction>
                {deletedOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
            )}
            <Collapse in={deletedOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {update?.forRestore.map((s: any, key: number) => (
                  <ListItem key={key} className={classes.nested}>
                    <ListItemText primary={`${s.streetType} ${s.name}`} />
                    <ListItemSecondaryAction style={{ marginRight: "32px" }}>
                      <Checkbox
                        onClick={(e) =>
                          setCheckedRestore(
                            checkedRestore.map((c, i) => (i === key ? !c : c))
                          )
                        }
                        checked={checkedRestore[key]}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Collapse>
            {update?.forDelete.length !== 0 && (
              <ListItem button onClick={() => setWrongOpen(!wrongOpen)}>
                <ListItemText
                  primary={`Кандидатов на удаление: ${update?.forDelete.length}`}
                />
                <ListItemSecondaryAction style={{ marginRight: "32px" }}>
                  <Checkbox
                    onClick={(e) => {
                      e.stopPropagation();
                      setCheckedDelete(
                        Array(update?.forDelete.length).fill(
                          checkedAllDelete ? false : true
                        )
                      );
                    }}
                    checked={checkedAllDelete}
                  />
                  <IconButton
                    edge="end"
                    disabled={!checkedSomeDelete || progress}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (update) {
                        setProgress(true);

                        API.delete(`kladrObjects/delete`, {
                          data: {
                            kladrObjectIds: update.forDelete
                              .filter(
                                (s: Street, i: number) => checkedDelete[i]
                              )
                              .map((s: Street) => s.id),
                          },
                        })
                          .then(() =>
                            onRefresh(cityId)
                              .then((data) => {
                                setCheckedRestore(
                                  Array(data.forRestore.length).fill(false)
                                );
                                setCheckedDelete(
                                  Array(data.forDelete.length).fill(false)
                                );
                                setResp(data);
                              })
                              .catch((error) => console.log(error))
                          )
                          .catch((error) =>
                            alertError(alertContext, "Ошибка удаления улицы")
                          )
                          .finally(() => setProgress(false));
                      }
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
                {wrongOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
            )}
            <Collapse in={wrongOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {update?.forDelete.map((s: any, key: number) => (
                  <ListItem key={key} className={classes.nested}>
                    <ListItemText primary={`${s.streetType} ${s.name}`} />
                    <ListItemSecondaryAction style={{ marginRight: "32px" }}>
                      <Checkbox
                        onClick={(e) =>
                          setCheckedDelete(
                            checkedDelete.map((c, i) => (i === key ? !c : c))
                          )
                        }
                        checked={checkedDelete[key]}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={onClose}
            disabled={progress}
          >
            Ok
          </Button>
        </DialogActions>
        {progress && (
          <LinearProgress
            style={{ position: "absolute", bottom: "0px", width: "100%" }}
          />
        )}
      </Dialog>
    </>
  );
};

export default RefreshForm;
