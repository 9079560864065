import React, { FC, useContext, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/styles";
import { Alert } from "@material-ui/lab";
import {
  AlertContextType,
  Category,
  CategoryCreate,
  ProductRow,
} from "../../_shared/types";
import {
  alertError,
  isObjectsEqual,
  getSizeOfStringInBytes,
} from "../../_shared/utils";
import { ProgressBar } from "../_shared/ProgressBar";
import TransferList from "../FiltersPage/_shared/TransferList";
import API from "../../_shared/axios";
import { AlertContext } from "../_shared/ToastList";
import { MenuItem, Select } from "@material-ui/core";

const useStyles = makeStyles({
  formControl: {
    minWidth: 120,
  },
  colorError: { color: "#f44336" },
  alert: { width: "100%" },
  colorBlack: { color: "rgba(0, 0, 0, 0.87)" },
  requiredWarning: {
    color: "#f44336",
    margin: "4px 0 0 0",
    fontSize: "0.75rem",
    letterSpacing: "0.03333em",
    lineHeight: "1.66",
  },
  colorInactive: { color: "rgba(0, 0, 0, 0.54)" },
  wrapper: {
    display: "inline",
  },
  dialogContent: {
    scrollbarWidth: "none",
  },
});

const NewPromotionCategoryForm: FC<{
  onCategoryCreate: (category: CategoryCreate) => Promise<any>;
}> = ({ onCategoryCreate }) => {
  const [open, setOpen] = React.useState(false);

  const categoryInit = {
    name: "",
    categoryId: 0,
    description: "",
  };
  const [category, setCategory] = useState(categoryInit);
  const [categories, setCategories] = useState<
    { id: number; name: string; isActive: boolean }[]
  >([]);
  const [
    isNeedClearSelectedProducts,
    setIsNeedClearSelectedProducts,
  ] = useState<boolean | undefined>(undefined);
  const [progress, setProgress] = useState(false);
  const [products, setProducts] = useState<ProductRow[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<ProductRow[]>([]);

  useEffect(() => {
    if (isNeedClearSelectedProducts) setIsNeedClearSelectedProducts(false);
  }, [isNeedClearSelectedProducts]);

  const isBlurredInit = {
    name: false,
    categoryId: false,
    selectedProducts: false,
  };
  const [isBlurred, setIsBlurred] = useState(isBlurredInit);
  const setBlurredField = (field: string) => {
    setIsBlurred({ ...isBlurred, [field]: true });
  };

  const alertContext = useContext<AlertContextType>(AlertContext);

  const handleClickOpen = () => {
    API.get("/products")
      .then(({ data }) => setProducts(data))
      .catch((error) =>
        alertError(alertContext, "Ошибка получения списка товаров")
      );
    setIsBlurred(isBlurredInit);
    setCategory(categoryInit);
    setOpen(true);
    setAlerted(false);
    API.get(`/categories`)
      .then(({ data }: { data: Category[] }) => {
        setCategories(
          data.map((el) => {
            return { id: el.id, name: el.name, isActive: !el.isActive };
          })
        );
      })
      .catch((error) =>
        alertError(alertContext, "Ошибка получения списка категорий")
      );
  };

  const isProductsError = () =>
    selectedProducts.length === 0 && isBlurred.selectedProducts;

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (selectedProducts.length > 0) setBlurredField("selectedProducts");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProducts]);

  const classes = useStyles();

  const [alerted, setAlerted] = useState(false);

  return (
    <div className={classes.wrapper}>
      <h2>
        Категории акций &nbsp; &nbsp;
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          Добавить категорию
        </Button>
      </h2>
      <Dialog
        maxWidth="lg"
        disableBackdropClick
        onBackdropClick={() =>
          progress
            ? null
            : isObjectsEqual(category, categoryInit)
            ? setOpen(false)
            : setAlerted(true)
        }
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Новая категория</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <form>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Наименование"
              inputProps={{ maxLength: 100 }}
              value={category.name}
              onChange={(e) =>
                setCategory({ ...category, name: e.target.value })
              }
              type="text"
              fullWidth
              onBlur={() => setBlurredField("name")}
              error={!category.name && isBlurred.name}
              helperText={
                !category.name && isBlurred.name ? "Обязательное поле" : ""
              }
              required
            />
            <TextField
              margin="dense"
              id="description"
              label="Описание"
              value={category.description}
              onChange={(e) => {
                if (getSizeOfStringInBytes(e.target.value) <= 4000)
                  setCategory({ ...category, description: e.target.value });
              }}
              type="text"
              fullWidth
            />
            <br />
            <br />
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={category.categoryId}
              inputProps={{ "aria-label": "Without label" }}
              onBlur={() => setBlurredField("categoryId")}
              error={category.categoryId === 0 && isBlurred.categoryId}
              defaultValue={0}
              required
              fullWidth
              className={[
                category.categoryId === 0 && isBlurred.categoryId
                  ? classes.colorError
                  : classes.colorBlack,
              ].join(" ")}
              onChange={(e: any) => {
                setIsNeedClearSelectedProducts(true);
                setCategory({
                  ...category,
                  categoryId: e.target.value,
                });
              }}
            >
              <MenuItem value={0} disabled>
                Категория *
              </MenuItem>
              {categories.map((el, key) => (
                <MenuItem value={el.id} key={key} disabled={el.isActive}>
                  {el.name}
                </MenuItem>
              ))}
            </Select>
            {category.categoryId === 0 && isBlurred.categoryId && (
              <p className={classes.requiredWarning}>Обязательное поле</p>
            )}
            <br />
            <br />
            <p
              className={
                isProductsError() ? classes.colorError : classes.colorInactive
              }
            >
              {category.categoryId === 0
                ? "Товары (необходимо выбрать категорию) *"
                : "Товары *"}
            </p>
            {isProductsError() && (
              <p className={classes.requiredWarning}>Обязательное поле</p>
            )}
            <TransferList
              products={products.filter(
                (el) => el.categoryId === category.categoryId
              )}
              isNeedClearSelectedProducts={isNeedClearSelectedProducts}
              getData={(data: ProductRow[]) => {
                setSelectedProducts(data);
              }}
            />
          </form>
        </DialogContent>
        <DialogActions>
          {alerted ? (
            <Alert
              className={classes.alert}
              severity="warning"
              action={
                <>
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => setOpen(false)}
                  >
                    Да
                  </Button>
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => setAlerted(false)}
                  >
                    Нет
                  </Button>
                </>
              }
            >
              Вы действительно хотите отменить все изменения и выйти? Введенные
              данные будут утеряны
            </Alert>
          ) : (
            <>
              <Button onClick={handleClose} color="primary" disabled={progress}>
                Отмена
              </Button>
              <Button
                onClick={() => {
                  const { name, description, categoryId } = category;
                  const categoryToPost = {
                    name,
                    description,
                    categoryId,
                    isActive: true,
                    productIds: selectedProducts.map((el) => el.id),
                  };
                  setProgress(true);
                  // @ts-ignore
                  onCategoryCreate(categoryToPost)
                    .then((data) => setOpen(false))
                    .catch((error) => console.log(error))
                    .finally(() => setProgress(false));
                }}
                color="primary"
                type="submit"
                disabled={
                  category.name === "" ||
                  category.categoryId === 0 ||
                  selectedProducts.length === 0 ||
                  progress
                }
              >
                Создать
              </Button>
            </>
          )}
        </DialogActions>
        {progress && <ProgressBar bottom />}
      </Dialog>
    </div>
  );
};

export default NewPromotionCategoryForm;
