import React, { useState, useEffect, FC } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import {
  AuthCookie,
  UserCookie,
  AlertProps,
  AlertType,
  AlertData,
} from "./_shared/types";
import ProtectedRoute from "./ProtectedRoute";
import AuthPage from "./AuthPage";
import MainPage from "./MainPage";
import cookies from "../cookies";
import ToastList, { AlertContext } from "./MainPage/_shared/ToastList";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  app: {
    width: "100%",
    height: "100%",
  },
  toastListWrapper: {
    display: "flex",
    justifyContent: "center",
  },
});

const App: FC = () => {
  const [, setChange] = useState<any>();
  useEffect(() => {
    if (setChange) cookies.addChangeListener((data) => setChange(data));
  }, [setChange]);

  const auth: AuthCookie | undefined = cookies.get("auth");
  const user: UserCookie | undefined = cookies.get("user");

  // cookies.set('auth', { accessToken: 'awrfwasddsa' });
  // cookies.set('user', {
  //   roles: ['SuperAdmin', 'Admin'],
  //   city: { name: 'Петрозаводск' },
  //   firstName: 'firstName',
  //   lastName: 'lastName',
  //   patronymic: 'patronymic',
  // });

  const [alertData, setAlertData] = useState<AlertProps>({
    id: 0,
    text: "",
    type: AlertType.SUCCESS,
    isDeleted: true,
  });

  const showAlert = (data: AlertData) =>
    setAlertData({
      id: alertData.id + 1,
      text: data.text,
      type: data.type,
      isDeleted: false,
    });

  const routes = [
    {
      component: MainPage,
      path: "/main",
    },
  ];

  const classes = useStyles();

  return (
    <div className={classes.app}>
      <AlertContext.Provider value={{ showAlert }}>
        <Switch>
          <Route
            exact
            path="/"
            render={() =>
              auth?.accessToken && user?.roles.length ? (
                <Redirect to={"/main"} />
              ) : (
                <Redirect to={"/auth"} />
              )
            }
          />
          <Route path="/auth" exact component={AuthPage} />
          {routes.map((rdata, key) => (
            <ProtectedRoute {...rdata} key={key} />
          ))}
          <Route render={() => <Redirect to={"/"} />} />
        </Switch>
      </AlertContext.Provider>
      <div className={classes.toastListWrapper}>
        <ToastList alertData={alertData} />
      </div>
    </div>
  );
};

export default App;
