import React, { FC, useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { DatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { makeStyles } from "@material-ui/styles";
import EventIcon from "@material-ui/icons/Event";
import ClearIcon from "@material-ui/icons/Clear";
import { ProgressBar } from "../../_shared/ProgressBar";
import InputMask from "react-input-mask";
import { getClearPhoneNumber, isPhoneNotValid } from "../../../_shared/utils";

const useStyles = makeStyles({
  normal: {
    "& input": {
      transition: "color 150ms ease-in-out",
    },
  },
  transparent: {
    "& input": {
      color: "transparent",
    },
  },
});

const baseDate = new Date();

const NewCustomerForm: FC<{
  onSend: (data: {
    phoneNumber: string;
    lastName: string;
    firstName: string;
    patronymic: string;
    email?: string;
    birthDay?: string;
  }) => Promise<any>;
}> = ({ onSend }) => {
  const [modal, setModal] = useState(false);
  const [alerted, setAlerted] = useState(false);
  const [blur, setBlur] = useState(false);
  const [progress, setProgress] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [patronymic, setPatronymic] = useState("");
  const [birthDay, setBirthDay] = useState<Date>(baseDate);
  const [email, setEmail] = useState("");

  const classes = useStyles();

  const onOpen = () => {
    setAlerted(false);
    setBlur(false);
    setFirstName("");
    setLastName("");
    setPatronymic("");
    setBirthDay(baseDate);
    setPhoneNumber("");
    setEmail("");
    setModal(true);
  };

  const onSubmit = () => {
    setProgress(true);
    onSend({
      firstName,
      lastName,
      patronymic,
      birthDay: birthDay !== baseDate ? birthDay.toISOString() : undefined,
      phoneNumber: getClearPhoneNumber(phoneNumber),
      email: email || undefined,
    })
      .then((data) => setModal(false))
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setProgress(false));
  };

  const safeToExit =
    (firstName + lastName + patronymic + phoneNumber + email).length === 0 &&
    birthDay === baseDate;

  return (
    <>
      <Button variant="outlined" color="primary" onClick={onOpen}>
        Добавить клиента
      </Button>
      <Dialog
        disableBackdropClick
        onBackdropClick={() =>
          progress ? null : safeToExit ? setModal(false) : setAlerted(true)
        }
        open={modal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Новый клиент</DialogTitle>
        <DialogContent>
          <DatePicker
            variant="inline"
            format="DD.MM.YYYY"
            label="Дата рождения"
            value={birthDay}
            onChange={(date: MaterialUiPickersDate) => {
              if (date) setBirthDay(date.toDate());
            }}
            className={
              birthDay !== baseDate ? classes.normal : classes.transparent
            }
            fullWidth
            InputLabelProps={{
              shrink: birthDay !== baseDate,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {birthDay !== baseDate && (
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setBirthDay(baseDate);
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  )}
                  <IconButton>
                    <EventIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            margin="dense"
            id="lastName"
            label="Фамилия"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            type="text"
            fullWidth
          />
          <TextField
            margin="dense"
            id="firstName"
            label="Имя"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            type="text"
            fullWidth
          />
          <TextField
            margin="dense"
            id="patronymic"
            label="Отчество"
            value={patronymic}
            onChange={(e) => setPatronymic(e.target.value)}
            type="text"
            fullWidth
          />
          <TextField
            margin="dense"
            id="email"
            label="Электронная почта"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="text"
            fullWidth
          />
          <InputMask
            mask="+7(999)999-99-99"
            value={phoneNumber}
            onBlur={() => setBlur(true)}
            onChange={(e) => {
              setPhoneNumber(e.target.value);
            }}
          >
            {() => (
              <TextField
                margin={"dense"}
                id={"servicePhone"}
                label={"Контактный телефон"}
                name={"servicePhone"}
                required
                error={blur && (!phoneNumber || isPhoneNotValid(phoneNumber))}
                helperText={
                  !phoneNumber && blur
                    ? "Поле обязательно для заполнения"
                    : isPhoneNotValid(phoneNumber)
                    ? "Номер невалидный"
                    : ""
                }
                type="text"
                fullWidth
              />
            )}
          </InputMask>
        </DialogContent>
        <DialogActions>
          {alerted ? (
            <Alert
              severity="warning"
              action={
                <>
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => setModal(false)}
                  >
                    Да
                  </Button>
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => setAlerted(false)}
                  >
                    Нет
                  </Button>
                </>
              }
            >
              Вы действительно хотите отменить все изменения и выйти? Введенные
              данные будут утеряны
            </Alert>
          ) : (
            <>
              <Button
                onClick={() => setModal(false)}
                color="primary"
                disabled={progress}
              >
                Отмена
              </Button>
              <Button
                onClick={onSubmit}
                color="primary"
                type="submit"
                disabled={
                  phoneNumber.length === 0 ||
                  isPhoneNotValid(phoneNumber) ||
                  progress
                }
              >
                Создать
              </Button>
            </>
          )}
        </DialogActions>
        {progress && <ProgressBar bottom />}
      </Dialog>
    </>
  );
};

export default NewCustomerForm;
