import React, { FC } from "react";
import { Box, Paper, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/styles/makeStyles/makeStyles";
import MoneyIcon from "@material-ui/icons/Money";
import { useHistory } from "react-router";
import { Route, Switch, useRouteMatch } from "react-router-dom";

const useStyles = makeStyles({
  rootGrid: {
    height: "calc(100% - 64px)",
  },
  buttonBox: {
    height: "350px",
    width: "350px",
    border: "solid 5px #3F51B5",
    borderRadius: "20px",
    backgroundColor: "#fff",
    padding: "10px",
    cursor: "pointer",
  },
  fullH: {
    height: "100%",
  },
});

const CashierMenu: FC = ({}) => {
  const classes = useStyles();

  const history = useHistory();
  const match = useRouteMatch();

  return (
    <Box className={classes.rootGrid}>
      <Grid
        container
        alignContent={"center"}
        justify={"center"}
        alignItems={"center"}
        className={classes.rootGrid}
      >
        <Box
          className={classes.buttonBox}
          onClick={() => history.push(`${match.path}/promocode`)}
        >
          <Grid
            className={classes.fullH}
            container
            alignContent={"center"}
            justify={"center"}
            alignItems={"center"}
            direction={"column"}
          >
            <MoneyIcon fontSize={"large"} />
            <Typography variant={"h5"} align={"center"}>
              Использовать Промокод
            </Typography>
          </Grid>
        </Box>
      </Grid>
    </Box>
  );
};

export default CashierMenu;
