import React, { FC } from "react";
import { Box, Checkbox, Divider, Grid, Paper } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { PromocodeExtra } from "./index";
import { ProductRow } from "../../../_shared/types";
import makeStyles from "@material-ui/core/styles/makeStyles";

export type GiftInfoProps = {
  data: PromocodeExtra;
  prods: { [key: string]: ProductRow };
};

const useStyles = makeStyles({
  part: {
    margin: "10px 0 10px 0",
    padding: "10px 0 10px 0",
  },
  checkLabel: {
    width: "300px",
    height: "50px",
    padding: "5px 0 5px 0",
  },
  paperPart: {
    padding: "15px",
    margin: "15px",
  },
  img: {
    width: "60px",
    height: "60px",
  },
  variantName: {
    margin: "7px",
    border: "solid 2px royalblue",
    borderRadius: "8px",
    padding: "3px 7px 3px 7px",
    backgroundColor: "#fafafa",
  },
  productItem: {
    backgroundColor: "#f1f1f1",
    margin: "10px",
    padding: "10px",
  },
});

// TODO: conditional propsType based on "type"
const GiftsInfo: FC<GiftInfoProps> = ({ data, prods }) => {
  const classes = useStyles();

  return (
    <Box>
      <Grid container direction={"column"}>
        <Paper className={classes.paperPart}>
          <Typography variant={"h4"}>Условия использования: </Typography>
          <Grid
            container
            direction={"row"}
            justify={"space-between"}
            className={classes.checkLabel}
            alignItems={"center"}
          >
            <Typography component={"span"} variant={"body1"}>
              Доступен при самовывозе:{" "}
            </Typography>
            <Checkbox
              color={"primary"}
              checked={data.forPickup}
              onChange={(e) => void 0}
            />
          </Grid>
          <Grid
            container
            direction={"row"}
            justify={"space-between"}
            className={classes.checkLabel}
            alignItems={"center"}
          >
            <Typography component={"span"} variant={"body1"}>
              Доступен при доставке:{" "}
            </Typography>
            <Checkbox
              color={"primary"}
              checked={data.forDelivery}
              onChange={(e) => void 0}
            />
          </Grid>
          {data.minPrice ? (
            <Typography variant={"h6"}>
              минимальная сумма заказа: {data.minPrice}
            </Typography>
          ) : (
            <>
              {data.productSets.map((pr) =>
                // @ts-ignore
                pr.productId.map((p) => (
                  <Paper className={classes.productItem}>
                    <Grid container direction={"row"} justify={"space-between"}>
                      <Typography component={"span"} variant={"h6"}>
                        {prods[p].name}
                      </Typography>
                      <Typography variant={"subtitle1"} component={"span"}>
                        Нужное кол-во: {pr.count}
                      </Typography>
                      <img className={classes.img} src={prods[p].thumbnail} />
                    </Grid>
                    <Typography variant={"body1"}>
                      Возможные варианты:
                    </Typography>
                    {/* @ts-ignore */}
                    {pr.productSetVariantsList.length && (
                      <Grid direction={"row"} wrap={"wrap"} container>
                        {/* @ts-ignore */}
                        {pr.productSetVariantsList.map((v) => (
                          <Typography
                            key={v.id}
                            className={classes.variantName}
                            component={"span"}
                            variant={"subtitle2"}
                          >
                            {v.values.map((val: any) => (
                              <>{`${val.optionData}: ${val.optionValueData} `}</>
                            ))}
                          </Typography>
                        ))}
                      </Grid>
                    )}
                  </Paper>
                ))
              )}
            </>
          )}
        </Paper>
        <Paper className={classes.paperPart}>
          <Typography variant={"h4"}>Доступные подарки: </Typography>
          {data.discount ? (
            <Typography variant={"h6"}>
              Размер скидки: {data.discount}
            </Typography>
          ) : (
            <>
              {data.presents.map((pr) =>
                // @ts-ignore
                pr.productId.map((p) => (
                  <Paper className={classes.productItem}>
                    <Grid container direction={"row"} justify={"space-between"}>
                      <Typography component={"span"} variant={"h6"}>
                        {prods[p].name}
                      </Typography>
                      <Typography variant={"subtitle1"} component={"span"}>
                        Доступное кол-во: {pr.count}
                      </Typography>
                      <img className={classes.img} src={prods[p].thumbnail} />
                    </Grid>
                    <Typography variant={"body1"}>
                      Возможные варианты:
                    </Typography>
                    <Grid direction={"row"} wrap={"wrap"} container>
                      {pr.variantsList.map((v) => (
                        <Typography
                          className={classes.variantName}
                          component={"span"}
                          variant={"subtitle2"}
                        >
                          {v.values.map((val) => (
                            <>{`${val.optionData}: ${val.optionValueData} `}</>
                          ))}
                        </Typography>
                      ))}
                    </Grid>
                  </Paper>
                ))
              )}
            </>
          )}
        </Paper>
      </Grid>
    </Box>
  );
};

export default GiftsInfo;
