import { makeStyles, Modal } from "@material-ui/core";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import CenteredTabs from "./__shared/CenteredTabs";
import { Route, Switch, useHistory } from "react-router";
import EmployeePage from "./EmployeePage";
import ProductsPage from "./ProductsPage";
import CustomersPage from "./CustomerPage";
import AddressPage from "./AddressPage";
import { Redirect, Prompt, useRouteMatch } from "react-router-dom";
import ShopsSyncPage from "./ShopsSyncPage";
import PaymentTypesSyncPage from "./PaymentTypesSyncPage";
import ExtraPositionsSyncPage from "./ExtraPositionsSyncPage";
import SpecsSyncPage from "./SpecsSyncPage";

const useStyles = makeStyles({
  page: {
    margin: 0,
  },
  content: {
    padding: 50,
    "& .MuiTableCell-body": {
      cursor: "pointer",
    },
  },
  heading: {
    display: "flex",
    alignItems: "center",
  },
});

export const TouchedAlert = React.createContext({
  value: false,
  setter: (val: boolean): void => undefined,
});

const RouteWrap: FC<{
  setTouched: () => void;
  Component: FC<{ setTouched: () => void }>;
}> = ({ setTouched, Component }) => {
  return <Component setTouched={setTouched} />;
};

const SyncPage: FC<{ match: { path: string } }> = ({ match }) => {
  const tabs = useMemo(
    () => [
      {
        label: "Сотрудник",
        route: match.path + "/employee",
        component: EmployeePage,
      },
      {
        label: "Клиенты",
        route: match.path + "/customers",
        component: CustomersPage,
      },
      {
        label: "Адреса",
        route: match.path + "/addresses",
        component: AddressPage,
      },
      {
        label: "Продукты",
        route: match.path + "/products",
        component: ProductsPage,
      },
      {
        label: "Типы оплаты",
        route: match.path + "/payments",
        component: PaymentTypesSyncPage,
      },
      {
        label: "Ингредиенты",
        route: match.path + "/ings",
        component: ExtraPositionsSyncPage,
      },
      {
        label: "Специфики",
        route: match.path + "/specs",
        component: SpecsSyncPage,
      },
      {
        label: "Точки продаж",
        route: match.path + "/shops",
        component: ShopsSyncPage,
      },
    ],
    []
  );

  const history = useHistory();
  const classes = useStyles();

  const [touched, setTouched] = useState(false);
  const [alert, setAlert] = useState(false);

  const touchedSetter = useCallback((val: boolean) => {
    setTouched(val);
  }, []);

  useEffect(() => {
    setTouched(false);
  }, [match]);

  useEffect(() => {
    window.addEventListener("beforeunload", function (e) {
      // Check if any of the input fields are filled
      if (touched) {
        // Cancel the event and show alert that
        // the unsaved changes would be lost
        e.preventDefault();
        e.returnValue = "";
        window.onbeforeunload = () => true;
      }
    });
  }, [touched]);

  return (
    <div className={classes.page}>
      <TouchedAlert.Provider value={{ value: touched, setter: touchedSetter }}>
        <CenteredTabs
          tabs={tabs}
          clickHandler={(route) => history.push(route)}
        />
        <Prompt
          when={touched}
          message="У вас есть несохраненные изменения, вы точно хотите уйти со страницы"
        />
        <Switch>
          {tabs.map((tab: any) => {
            return (
              <Route
                path={tab.route}
                render={() => (
                  <RouteWrap
                    setTouched={() => setTouched(true)}
                    Component={tab.component}
                  />
                )}
              />
            );
          })}
          <Redirect to={tabs[0].route} />
        </Switch>
      </TouchedAlert.Provider>
    </div>
  );
};
export default SyncPage;
