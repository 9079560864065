import React, { FC, useContext, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Avatar,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Alert } from "@material-ui/lab";
import {
  AlertContextType,
  ProductSet,
  Promotion,
  PromotionCategoryRibbon,
  PromotionCreate,
} from "../../_shared/types";
import PublishIcon from "@material-ui/icons/Publish";
import {
  alertError,
  getUnitedDateTime,
  getFloatFromString,
  getIntFromString,
  isObjectsEqual,
  getPromotionFormattedDate,
  getImageUrl,
  copyObject,
  getSizeOfStringInBytes,
} from "../../_shared/utils";
import API from "../../_shared/axios";
import { AlertContext } from "../_shared/ToastList";
import { ProgressBar } from "../_shared/ProgressBar";
import MultipleCheckboxDate from "../_shared/MultipleCheckboxDate";
import { DatePicker, TimePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import ProductSetList from "./ProductSetList";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles({
  formControl: {
    minWidth: 120,
  },
  pickers: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    "& > div": {
      minWidth: 250,
      width: "45%",
    },
  },
  imageLabel: {
    color: "rgba(0, 0, 0, 0.54)",
  },
  checkboxesBlock: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    "& > div": {
      marginRight: 10,
    },
  },
  variantWrapper: {
    padding: "8px 16px",
    border: "1px solid #ccc",
    borderRadius: 4,
    marginRight: 5,
    marginTop: 5,
  },
  variants: {
    marginTop: 10,
    display: "flex",
    flexWrap: "wrap",
  },
  requiredWarning: {
    color: "#f44336",
    margin: "4px 0 0 0",
    fontSize: "0.75rem",
    letterSpacing: "0.03333em",
    lineHeight: "1.66",
  },
  colorError: { color: "#f44336" },
  colorBlack: { color: "rgba(0, 0, 0, 0.87)" },
  mt20: { marginTop: 20 },
  addedIngredient: {
    fontWeight: 400,
  },
  alert: {
    width: "100%",
  },
  carbohydrates: { width: "32%" },
  accordionDetails: { display: "flex", alignItems: "center" },
  detail: { paddingLeft: 15 },
  imageDisplay: {
    display: "none",
  },
  dialogTitle: {
    paddingBottom: 0,
  },
  wrapper: {
    display: "inline",
  },
  large: {
    width: "100%",
    height: "220px",
    "& img": {
      objectFit: "contain",
    },
  },
  imgWrapper: {
    position: "relative",
    margin: "auto",
    width: "50%",
    "& img": {
      objectFit: "contain",
    },
  },
  imgControls: {
    position: "absolute",
    right: "1rem",
    bottom: "1rem",
  },
  normal: {
    "& input": {
      transition: "color 150ms ease-in-out",
    },
  },
  accordionSummary: {
    fontSize: 16,
  },
  detailsWrapper: {
    width: "60%",
    display: "flex",
    alignItems: "center",
  },
  transparent: {
    "& input": {
      color: "transparent",
    },
  },
  marginWidth: {
    marginRight: "2% !important",
    width: "32%",
  },
  width32: {
    width: "32%",
  },
  options: {
    width: "100%",
    flexDirection: "column",
    marginBottom: 5,
    "& > div": {
      "& .MuiAccordionSummary-content": {
        margin: "0 !important",
      },
      "& .MuiAccordionDetails-root": {
        padding: "0 0 0 20px !important",
        "&:last-child": {
          paddingBottom: "10px !important",
        },
      },
    },
  },
  top30: {
    marginTop: 10,
  },
  deletedIngredients: {
    "& > div": {
      borderRadius: 5,
    },
  },
  entityList: {
    "& > div": {
      margin: "10px 10px",
      color: "rgba(0, 0, 0, 0.87) !important",
      "& > span": {
        fontWeight: "bold !important",
        fontSize: "14px !important",
      },
      "& > .MuiDivider-root": {
        marginTop: 10,
      },
    },
  },
});

const EditPromotionForm: FC<{
  promotionId?: number;
  onPromotionEdit: (id: number, promotion: PromotionCreate) => Promise<any>;
  onClose: () => void;
}> = ({ onPromotionEdit, promotionId, onClose }) => {
  const alertContext = useContext<AlertContextType>(AlertContext);
  const [directoryId, setDirectoryId] = useState<number>();
  const [images, setImages] = useState<{
    mainImgFile: undefined | File;
    sliderImgFile: undefined | File;
  }>({ mainImgFile: undefined, sliderImgFile: undefined });
  const [oldImages, setOldImages] = useState<{
    mainImgFile: undefined | File;
    sliderImgFile: undefined | File;
  }>({ mainImgFile: undefined, sliderImgFile: undefined });
  const [progress, setProgress] = useState(false);
  const [productId, setProductId] = useState(0);
  const workDaysInit = ["0", "1", "2", "3", "4"];
  const [workDays, setWorkDays] = useState<string[]>(workDaysInit);
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [startTime, setStartTime] = useState<Date | undefined>(undefined);
  const [endTime, setEndTime] = useState<Date | undefined>(undefined);
  const [oldStartDate, setOldStartDate] = useState<Date | undefined>(undefined);
  const [oldEndDate, setOldEndDate] = useState<Date | undefined>(undefined);
  const [oldStartTime, setOldStartTime] = useState<Date | undefined>(undefined);
  const [oldEndTime, setOldEndTime] = useState<Date | undefined>(undefined);
  const [promotionCategories, setPromotionCategories] = useState<
    PromotionCategoryRibbon[]
  >([]);

  const promotionInit: Promotion = {
    id: 1,
    thumbnail: "",
    name: "",
    description: "",
    mainImgName: "",
    sliderImgName: "",
    price: "0",
    position: 0,
    discount: 0,
    isActive: true,
    goByClick: true,
    worksOnHoliday: true,
    worksOnPreHoliday: true,
    startDate: "",
    startTime: "",
    availableOnBanner: false,
    endDate: "",
    promotionDaysList: [0],
    productSetsList: [],
    endTime: "",
  };

  const [promotion, setPromotion] = useState(promotionInit);
  const [oldPromotion, setOldPromotion] = useState(promotionInit);

  useEffect(() => {
    setPromotion({
      ...promotion,
      promotionDaysList: workDays.map((el) => +el),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workDays]);

  useEffect(() => {
    if (promotionId) {
      setAlerted(false);
      setImages({ mainImgFile: undefined, sliderImgFile: undefined });
      setOldImages({ mainImgFile: undefined, sliderImgFile: undefined });

      API.get("/promotionCategories")
        .then(({ data }) => {
          const categoriesIds = data
            .filter((el: { isActive: boolean }) => el.isActive)
            .map((el: { id: number }) => el.id);
          for (let id of categoriesIds) {
            API.get(`/promotionCategories/${id}`)
              .then(({ data }) => {
                if (data.isActive)
                  setPromotionCategories((promotionCategories) => [
                    ...promotionCategories,
                    {
                      ...data,
                      products: data.products.filter((el: any) => el.isActive),
                    },
                  ]);
              })
              .catch((error) =>
                alertError(alertContext, "Ошибка получения категории акции")
              );
          }
        })
        .catch((error) =>
          alertError(alertContext, "Ошибка получения списка категорий акций")
        );
      API.get("/files/images/directories")
        .then(({ data }) =>
          setDirectoryId(
            data.find(
              (el: { id: number; value: string; description: string }) =>
                el.value === "Promotions"
            ).id
          )
        )
        .catch((error) =>
          alertError(
            alertContext,
            "Ошибка получения списка директорий изображений"
          )
        );

      API.get(`/promotions/${promotionId}`)
        .then(({ data }: { data: Promotion }) => {
          if (data.startDate) {
            const date = new Date(data.startDate);
            setStartDate(date);
            setOldStartDate(date);
          }
          if (data.endDate) {
            const date = new Date(data.endDate);
            setEndDate(date);
            setOldEndDate(date);
          }
          if (data.startTime) {
            const date = new Date(data.startTime);
            setStartTime(date);
            setOldStartTime(date);
          }
          if (data.endTime) {
            const date = new Date(data.endTime);
            setEndTime(date);
            setOldEndTime(date);
          }
          const newPromotionData = {
            ...data,
            productSetsList: !data.goByClick ? [] : data.productSetsList,
            price: data.price ? data.price.toString() : "0",
            discount: data.discount ? data.discount : 0,
          };
          setPromotion(newPromotionData);
          setOldPromotion(newPromotionData);
          setWorkDays(data.promotionDaysList.map((el) => el.toString()));
        })
        .catch((error) => alertError(alertContext, "Ошибка получения акции"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promotionId]);

  const isDatesWrong = () => {
    let wrong = false;
    if (
      startDate &&
      endDate &&
      (!startTime || !endTime) &&
      startDate.getDate() > endDate.getDate()
    )
      wrong = true;
    if (
      startTime &&
      endTime &&
      (!startDate || !endDate) &&
      startTime.getTime() > endTime.getTime()
    )
      wrong = true;
    if (startDate && endDate && startTime && endTime) {
      const unitedStartDate = getUnitedDateTime(startDate, startTime);
      const unitedEndDate = getUnitedDateTime(endDate, endTime);
      if (unitedStartDate.getTime() > unitedEndDate.getTime()) wrong = true;
    }
    return wrong;
  };

  const isOnlyFreeProducts = () =>
    promotion.productSetsList.length > 0 &&
    promotion.productSetsList.filter((el) => el.isFree).length ===
      promotion.productSetsList.length;

  const getVariantLabel = (
    categoryId: number,
    count: number,
    productId?: number,
    entity?: {
      price: number;
      variantId: number;
      values: {
        optionId: number;
        optionData: string;
        optionValueId: number;
        optionValueData: string;
      }[];
    }
  ) => {
    let result = "";
    if (entity)
      for (let value of entity.values) {
        result = result + `${value.optionData}: ${value.optionValueData}; `;
      }
    result = result.slice(0, -2) + ` (${count})`;
    const categoryName = promotionCategories.find((el) => el.id === categoryId)
      ?.name;
    const productName = productId
      ? promotionCategories
          .find((el) => el.id === categoryId)
          ?.products.find((el) => el.id === productId)?.name
      : undefined;
    result = productName
      ? `${categoryName}, ${productName}, ${result}`
      : `${categoryName}, ${result}`;
    return result;
  };

  const handleClose = () => {
    setPromotionCategories([]);
    setWorkDays(workDaysInit);
    setStartDate(undefined);
    setEndDate(undefined);
    setStartTime(undefined);
    setEndTime(undefined);
    setOldStartDate(undefined);
    setOldEndDate(undefined);
    setOldStartTime(undefined);
    setOldEndTime(undefined);
    setAlerted(false);
    setPromotion(promotionInit);
    setPromotionCategories([]);
    onClose();
  };

  const classes = useStyles();

  const [alerted, setAlerted] = useState(false);

  const isMainImageFormatWrong = () =>
    images.mainImgFile?.name?.slice(
      images.mainImgFile?.name?.length - 4,
      images.mainImgFile?.name?.length
    ) !== ".png" && images.mainImgFile?.name;

  const isSliderImageFormatWrong = () =>
    images.sliderImgFile?.name?.slice(
      images.sliderImgFile?.name?.length - 4,
      images.sliderImgFile?.name?.length
    ) !== ".png" && images.sliderImgFile?.name;

  const getVariantsChipList = (
    entity: {
      promotionCategoryId: number;
      productId?: number;
      count: number;
      isFree: boolean;
      productSetVariantsList: {
        price: number;
        variantId: number;
        values: {
          optionId: number;
          optionData: string;
          optionValueId: number;
          optionValueData: string;
        }[];
      }[];
      id?: number;
    },
    index: number,
    length: number
  ) => {
    return entity.productSetVariantsList.length === 0 ? (
      <>
        <div className={classes.entityList}>
          <Chip
            label={getVariantLabel(
              entity.promotionCategoryId,
              entity.count,
              entity.productId
            )}
            onDelete={() => {
              let curSetsList = copyObject(promotion.productSetsList);
              curSetsList = curSetsList.filter(
                (el: ProductSet, idx: number) => idx !== index
              );
              setPromotion({ ...promotion, productSetsList: curSetsList });
            }}
            color="primary"
            variant="outlined"
          />
        </div>
        {length !== 1 && index !== length - 1 && <Divider />}
      </>
    ) : (
      <div>
        {entity.productSetVariantsList.map((variant, key) => (
          <span className={classes.entityList} key={key}>
            <Chip
              label={getVariantLabel(
                entity.promotionCategoryId,
                entity.count,
                entity.productId,
                variant
              )}
              onDelete={() => {
                let curSetsList = copyObject(promotion.productSetsList);
                const newVariantsList = entity.productSetVariantsList.filter(
                  (el) => el.variantId !== variant.variantId
                );
                if (newVariantsList.length > 0) {
                  curSetsList[index].productSetVariantsList = newVariantsList;
                  setPromotion({
                    ...promotion,
                    productSetsList: curSetsList,
                    goByClick: curSetsList.length > 0,
                  });
                } else {
                  curSetsList = curSetsList.filter(
                    (el: ProductSet, idx: number) => idx !== index
                  );
                  setPromotion({
                    ...promotion,
                    productSetsList: curSetsList,
                    goByClick: curSetsList.length > 0,
                  });
                }
              }}
              color="primary"
              variant="outlined"
            />
          </span>
        ))}
        {length !== 1 && index !== length - 1 && <Divider />}
      </div>
    );
  };

  const isDatesValid = () => {
    if (!startDate || !endDate) return true;
    const startCountOfDays = Math.floor(startDate.getTime() / 8.64e7);
    const endCountOfDays = Math.floor(endDate.getTime() / 8.64e7);
    return startCountOfDays <= endCountOfDays;
  };
  const isTimesValid = () => {
    if (!startTime || !endTime || !startDate || !endDate) return true;
    const startCountOfDays = Math.floor(startDate.getTime() / 8.64e7);
    const endCountOfDays = Math.floor(endDate.getTime() / 8.64e7);
    const startHours = startTime.getHours();
    const startMinutes = startTime.getMinutes();
    const endHours = endTime.getHours();
    const endMinutes = endTime.getMinutes();
    return !isDatesValid()
      ? true
      : startCountOfDays !== endCountOfDays
      ? true
      : startHours < endHours
      ? true
      : startMinutes < endMinutes;
  };

  return (
    <div className={classes.wrapper}>
      <Dialog
        disableBackdropClick
        onBackdropClick={() =>
          progress
            ? null
            : isObjectsEqual(promotion, oldPromotion) &&
              isObjectsEqual(
                workDays.map((el) => +el),
                promotion.promotionDaysList
              ) &&
              images.mainImgFile === oldImages.mainImgFile &&
              images.sliderImgFile === oldImages.sliderImgFile &&
              startDate === oldStartDate &&
              startTime === oldStartTime &&
              endDate === oldEndDate &&
              endTime === oldEndTime
            ? handleClose()
            : setAlerted(true)
        }
        open={!!promotionId}
        onClose={handleClose}
        maxWidth={"lg"}
        fullWidth
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
          Редактирование акции
        </DialogTitle>
        <DialogContent>
          <form>
            <div className={classes.imgWrapper}>
              <label className={classes.imageLabel}>Главное изображение</label>
              <input
                accept="image/*"
                className={classes.imageDisplay}
                id="main-image"
                type="file"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e.target.files?.[0])
                    setImages({
                      mainImgFile: e.target.files[0],
                      sliderImgFile: images.sliderImgFile,
                    });
                }}
              />
              <Avatar
                alt={
                  images.mainImgFile
                    ? URL.createObjectURL(images.mainImgFile)
                    : promotion.mainImgName !== "" && promotion.mainImgName
                    ? getImageUrl(promotion.mainImgName, "promotions")
                    : ""
                }
                src={
                  images.mainImgFile
                    ? URL.createObjectURL(images.mainImgFile)
                    : promotion.mainImgName !== "" && promotion.mainImgName
                    ? getImageUrl(promotion.mainImgName, "promotions")
                    : ""
                }
                variant="rounded"
                className={classes.large}
              />
              {isMainImageFormatWrong() && (
                <p className={classes.requiredWarning}>
                  Выберите изображение в "png" формате
                </p>
              )}
              <div className={classes.imgControls}>
                <label htmlFor="main-image">
                  <IconButton
                    component="span"
                    onClick={(e: React.MouseEvent<HTMLSpanElement>) =>
                      e.stopPropagation()
                    }
                  >
                    <PublishIcon />
                  </IconButton>
                </label>
              </div>
            </div>
            <br />
            <div className={classes.imgWrapper}>
              <label className={classes.imageLabel}>
                Изображение для слайдера
              </label>
              <input
                accept="image/*"
                className={classes.imageDisplay}
                id="slider-image"
                type="file"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e.target.files?.[0])
                    setImages({
                      mainImgFile: images.mainImgFile,
                      sliderImgFile: e.target.files[0],
                    });
                }}
              />
              <Avatar
                alt={
                  images.sliderImgFile
                    ? URL.createObjectURL(images.sliderImgFile)
                    : promotion.sliderImgName !== "" && promotion.sliderImgName
                    ? getImageUrl(promotion.sliderImgName, "promotions")
                    : ""
                }
                src={
                  images.sliderImgFile
                    ? URL.createObjectURL(images.sliderImgFile)
                    : promotion.sliderImgName !== "" && promotion.sliderImgName
                    ? getImageUrl(promotion.sliderImgName, "promotions")
                    : ""
                }
                variant="rounded"
                className={classes.large}
              />
              {isSliderImageFormatWrong() && (
                <p className={classes.requiredWarning}>
                  Выберите изображение в "png" формате
                </p>
              )}
              <div className={classes.imgControls}>
                <label htmlFor="slider-image">
                  <IconButton
                    component="span"
                    onClick={(e: React.MouseEvent<HTMLSpanElement>) =>
                      e.stopPropagation()
                    }
                  >
                    <PublishIcon />
                  </IconButton>
                </label>
              </div>
            </div>

            <TextField
              margin="dense"
              label="Наименование"
              inputProps={{ maxLength: 100 }}
              value={promotion.name}
              onChange={(e) =>
                setPromotion({ ...promotion, name: e.target.value })
              }
              type="text"
              fullWidth
              className={classes.top30}
              error={!promotion.name}
              helperText={!promotion.name ? "Обязательное поле" : ""}
              required
            />
            <TextField
              margin="dense"
              label="Описание"
              className={classes.top30}
              value={promotion.description}
              onChange={(e) => {
                if (getSizeOfStringInBytes(e.target.value) <= 4000)
                  setPromotion({ ...promotion, description: e.target.value });
              }}
              type="text"
              fullWidth
              multiline
            />
            <TextField
              margin="dense"
              id="position"
              label="Позиция"
              value={promotion.position}
              type="text"
              onChange={(e) =>
                e.target.value === " "
                  ? null
                  : setPromotion({
                      ...promotion,
                      position: getIntFromString(e.target.value),
                    })
              }
              fullWidth
              error={!promotion.position}
              helperText={!promotion.position ? "Обязательное поле" : ""}
              required
            />
            <TextField
              margin="dense"
              type="text"
              className={classes.top30}
              label="Цена"
              inputProps={{ maxLength: 10 }}
              disabled={promotion.discount !== 0}
              value={promotion.price}
              onChange={(e) =>
                e.target.value === " "
                  ? null
                  : setPromotion({
                      ...promotion,
                      price: getFloatFromString(e.target.value),
                    })
              }
              fullWidth
              error={promotion.price === "0" && promotion.discount === 0}
              helperText={
                promotion.price === "0" && promotion.discount === 0
                  ? "Обязательное поле"
                  : ""
              }
              required
            />
            <TextField
              className={classes.top30}
              id="tickets"
              label="Размер скидки"
              inputProps={{ maxLength: 10 }}
              disabled={promotion.price !== "0"}
              value={promotion.discount}
              onChange={(e) =>
                e.target.value === " "
                  ? null
                  : setPromotion({
                      ...promotion,
                      discount: getIntFromString(e.target.value),
                    })
              }
              required
              error={!promotion.discount && promotion.price === "0"}
              helperText={
                !promotion.discount && promotion.price === "0"
                  ? "Поле обязательно для заполнения"
                  : ""
              }
              fullWidth
              type="text"
            />
            <br />
            <br />
            <br />
            <MultipleCheckboxDate data={workDays} setState={setWorkDays} />
            <br />
            <div className={classes.pickers}>
              <DatePicker
                variant="inline"
                format="DD.MM.YYYY"
                label="Дата начала"
                InputProps={{
                  endAdornment: startDate && (
                    <InputAdornment position="end">
                      <IconButton
                        size={"small"}
                        onClick={(e) => {
                          e.stopPropagation();
                          setStartDate(undefined);
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                value={startDate}
                className={
                  startDate && !isNaN(startDate.getTime())
                    ? classes.normal
                    : classes.transparent
                }
                error={!isDatesValid()}
                helperText={
                  !isDatesValid() && "должно быть меньше даты окончания"
                }
                onChange={(date: MaterialUiPickersDate) => {
                  if (date) setStartDate(date.toDate());
                }}
                fullWidth
              />

              <DatePicker
                variant="inline"
                format="DD.MM.YYYY"
                label="Дата окончания"
                value={endDate}
                error={!isDatesValid()}
                helperText={!isDatesValid() && "должно быть больше даты начала"}
                className={
                  endDate && !isNaN(endDate.getTime())
                    ? classes.normal
                    : classes.transparent
                }
                InputProps={{
                  endAdornment: endDate && (
                    <InputAdornment position="end">
                      <IconButton
                        size={"small"}
                        onClick={(e) => {
                          e.stopPropagation();
                          setEndDate(undefined);
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={(date: MaterialUiPickersDate) => {
                  if (date) setEndDate(date.toDate());
                }}
                fullWidth
              />
              <TimePicker
                margin="dense"
                variant="inline"
                label="Время начала"
                ampm={false}
                InputProps={{
                  endAdornment: startTime && (
                    <InputAdornment position="end">
                      <IconButton
                        size={"small"}
                        onClick={(e) => {
                          e.stopPropagation();
                          setStartTime(undefined);
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                className={
                  startTime && !isNaN(startTime.getTime())
                    ? classes.normal
                    : classes.transparent
                }
                error={!isTimesValid()}
                helperText={
                  !isTimesValid() && "должно быть меньше времени окончания"
                }
                value={startTime}
                onChange={(date) => {
                  if (date) setStartTime(date.toDate());
                }}
              />
              <TimePicker
                margin="dense"
                variant="inline"
                label="Время окончания"
                ampm={false}
                InputProps={{
                  endAdornment: endTime && (
                    <InputAdornment position="end">
                      <IconButton
                        size={"small"}
                        onClick={(e) => {
                          e.stopPropagation();
                          setEndTime(undefined);
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={!isTimesValid()}
                helperText={
                  !isTimesValid() && "должно быть больше времени начала"
                }
                className={
                  endTime && !isNaN(endTime.getTime())
                    ? classes.normal
                    : classes.transparent
                }
                value={endTime}
                onChange={(date) => {
                  if (date) setEndTime(date.toDate());
                }}
              />
            </div>
            <br />
            <ProductSetList
              data={promotionCategories}
              title={promotion.goByClick ? "Выбор товаров *" : "Выбор товаров"}
              onConfirm={(data: ProductSet[]) => {
                let curProductId = productId;
                const newData = data.map((el) => {
                  curProductId = curProductId + 1;
                  return { ...el, id: curProductId };
                });
                setProductId(curProductId + 1);
                // @ts-ignore
                setPromotion({
                  ...promotion,
                  productSetsList: [...promotion.productSetsList, ...newData],
                  goByClick:
                    [...promotion.productSetsList, ...newData].length > 0,
                });
                setProductId(productId + 1);
              }}
            >
              <div className={classes.entityList}>
                {promotion.productSetsList.map((entity, index) =>
                  getVariantsChipList(
                    entity,
                    index,
                    promotion.productSetsList.length
                  )
                )}
              </div>
            </ProductSetList>
            <br />
            <div className={classes.checkboxesBlock}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={promotion.goByClick}
                      checked={promotion.goByClick}
                      onChange={(e) =>
                        setPromotion({
                          ...promotion,
                          goByClick: !promotion.goByClick,
                        })
                      }
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Переход по клику"
                />
              </FormControl>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={promotion.worksOnHoliday}
                      checked={promotion.worksOnHoliday}
                      onChange={(e) =>
                        setPromotion({
                          ...promotion,
                          worksOnHoliday: !promotion.worksOnHoliday,
                        })
                      }
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Работает в праздничные дни"
                />
              </FormControl>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={promotion.worksOnPreHoliday}
                      checked={promotion.worksOnPreHoliday}
                      onChange={(e) =>
                        setPromotion({
                          ...promotion,
                          worksOnPreHoliday: !promotion.worksOnPreHoliday,
                        })
                      }
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Работает в предпраздничные дни"
                />
              </FormControl>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={promotion.availableOnBanner}
                      checked={promotion.availableOnBanner}
                      onChange={(e) =>
                        setPromotion({
                          ...promotion,
                          availableOnBanner: !promotion.availableOnBanner,
                        })
                      }
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Баннер"
                />
              </FormControl>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={promotion.isActive}
                      checked={promotion.isActive}
                      onChange={(e) =>
                        setPromotion({
                          ...promotion,
                          isActive: !promotion.isActive,
                        })
                      }
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Акция активна"
                />
              </FormControl>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          {alerted ? (
            <Alert
              className={classes.alert}
              severity="warning"
              action={
                <>
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => handleClose()}
                  >
                    Да
                  </Button>
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => setAlerted(false)}
                  >
                    Нет
                  </Button>
                </>
              }
            >
              Вы действительно хотите отменить все изменения и выйти? Введенные
              данные будут утеряны
            </Alert>
          ) : (
            <>
              <Button onClick={handleClose} color="primary" disabled={progress}>
                Отмена
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={
                  (promotion.goByClick &&
                    promotion.productSetsList.length === 0) ||
                  !!isMainImageFormatWrong() ||
                  !!isSliderImageFormatWrong() ||
                  (isObjectsEqual(promotion, oldPromotion) &&
                    isObjectsEqual(
                      workDays.map((el) => +el),
                      promotion.promotionDaysList
                    ) &&
                    images.mainImgFile === oldImages.mainImgFile &&
                    images.sliderImgFile === oldImages.sliderImgFile &&
                    startDate === oldStartDate &&
                    startTime === oldStartTime &&
                    endDate === oldEndDate &&
                    endTime === oldEndTime) ||
                  promotion.name === "" ||
                  promotion.position === 0 ||
                  (promotion.price === "0" && promotion.discount === 0) ||
                  isDatesWrong() ||
                  isOnlyFreeProducts() ||
                  progress
                }
                onClick={() => {
                  setProgress(true);
                  let dataToEdit = {
                    ...promotion,
                    price:
                      promotion.price !== "0" ? promotion.price : undefined,
                    discount:
                      promotion.discount !== 0 ? promotion.discount : undefined,
                    productSetList: promotion.productSetsList
                      // .filter((el) => el.productSetVariantsList.length > 0)
                      .map((el) => {
                        return {
                          ...el,
                          variantList: el.productSetVariantsList.map(
                            (el) => el.variantId
                          ),
                        };
                      }),
                    availableOnBanner: promotion.availableOnBanner,
                    productSetsList: undefined,
                    startDate: getPromotionFormattedDate(startDate),
                    startTime: getPromotionFormattedDate(startTime),
                    endDate: getPromotionFormattedDate(endDate),
                    endTime: getPromotionFormattedDate(endTime),
                  };
                  if (!promotion.goByClick) {
                    dataToEdit.productSetList = [];
                    setPromotion({ ...promotion, productSetsList: [] });
                  }
                  if (promotion.mainImgName)
                    dataToEdit.mainImgName = promotion.mainImgName;
                  if (promotion.sliderImgName)
                    dataToEdit.sliderImgName = promotion.sliderImgName;
                  // если не выбраны новые изображения
                  if (
                    images.mainImgFile === undefined &&
                    images.sliderImgFile === undefined
                  ) {
                    onPromotionEdit(promotionId ? promotionId : 1, dataToEdit)
                      .then((data) => handleClose())
                      .catch((error) => console.log(error))
                      .finally(() => setProgress(false));
                  } else {
                    const firstImageName = images.mainImgFile
                      ? "mainImgName"
                      : "sliderImgName";
                    const firstImageToUpload = {
                      File: images.mainImgFile
                        ? images.mainImgFile
                        : images.sliderImgFile,
                      Folder: directoryId,
                    };
                    const formData = new FormData();
                    (Object.keys(firstImageToUpload) as Array<
                      keyof { File: File; Folder: number }
                    >).map((k: keyof { File: File; Folder: number }) =>
                      // @ts-ignore
                      formData.append(k as string, firstImageToUpload[k])
                    );
                    API.post("/files/images/upload", formData)
                      .then(({ data }) => {
                        dataToEdit[firstImageName] = data;
                        if (images.mainImgFile && images.sliderImgFile) {
                          const secondImageName =
                            firstImageName === "mainImgName"
                              ? "sliderImgName"
                              : "mainImgName";
                          const secondImageToUpload = {
                            File:
                              firstImageToUpload.File === images.mainImgFile
                                ? images.sliderImgFile
                                : images.mainImgFile,
                            Folder: directoryId,
                          };
                          const formData = new FormData();
                          (Object.keys(secondImageToUpload) as Array<
                            keyof { File: File; Folder: number }
                          >).map((k: keyof { File: File; Folder: number }) =>
                            // @ts-ignore
                            formData.append(k as string, secondImageToUpload[k])
                          );
                          API.post("/files/images/upload", formData)
                            .then(({ data }) => {
                              dataToEdit[secondImageName] = data;
                              onPromotionEdit(
                                promotionId ? promotionId : 1,
                                dataToEdit
                              )
                                .then((data) => handleClose())
                                .catch((error) => console.log(error))
                                .finally(() => setProgress(false));
                            })
                            .catch((error) => {
                              alertError(
                                alertContext,
                                "Ошибка загрузки изображения"
                              );
                            });
                        } else {
                          dataToEdit[firstImageName] = data;
                          onPromotionEdit(
                            promotionId ? promotionId : 1,
                            dataToEdit
                          )
                            .then((data) => handleClose())
                            .catch((error) => console.log(error))
                            .finally(() => setProgress(false));
                        }
                      })
                      .catch((error) => {
                        alertError(alertContext, "Ошибка загрузки изображения");
                      });
                  }
                }}
              >
                Подтвердить
              </Button>
            </>
          )}
        </DialogActions>
        {progress && <ProgressBar bottom />}
      </Dialog>
    </div>
  );
};

export default EditPromotionForm;
