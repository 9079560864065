import React, { FC } from "react";
import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const ActionGroup: FC<{
  save: () => void;
  cancel: () => void;
  isDisable: boolean;
}> = ({ save, cancel, isDisable }) => {
  return (
    <Grid
      container
      direction={"row"}
      alignItems={"center"}
      justify={"flex-end"}
    >
      <Button color={"primary"} onClick={() => cancel()}>
        Отмена
      </Button>
      <Button
        color={"primary"}
        onClick={() => save()}
        style={{ marginRight: "10px" }}
        disabled={isDisable}
      >
        Подтвердить
      </Button>
    </Grid>
  );
};

export default ActionGroup;
