import React, { FC, useContext, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Alert } from "@material-ui/lab";
import { AlertContextType, Category } from "../../_shared/types";
import API from "../../_shared/axios";
import { AlertContext } from "../_shared/ToastList";
import {
  alertError,
  getIntFromString,
  isObjectsEqual,
  getSizeOfStringInBytes,
} from "../../_shared/utils";
import { ProgressBar } from "../_shared/ProgressBar";
import { Checkbox, FormControl, FormControlLabel } from "@material-ui/core";
import { useStyles } from "./styles";

const EditCategoryForm: FC<{
  categoryId?: number;
  onCategoryEdit: (id: number, employee: Category) => Promise<any>;
  onClose: () => void;
}> = ({ categoryId, onCategoryEdit, onClose }) => {
  const categoryInit = {
    id: 666666,
    name: "",
    description: "",
    position: 0,
    isActive: true,
    showInMenu: false,
  };
  const [category, setCategory] = useState(categoryInit);
  const [oldCategory, setOldCategory] = useState(categoryInit);

  const [progress, setProgress] = useState(false);

  const alertContext = useContext<AlertContextType>(AlertContext);

  const classes = useStyles();

  useEffect(() => {
    if (categoryId) {
      API.get(`/categories/${categoryId}`)
        .then(({ data }: { data: Category }) => {
          const { id, name, position, isActive, showInMenu } = data;
          const newCategory = {
            id,
            name,
            description: data.description ? data.description : "",
            position,
            isActive,
            showInMenu,
          };
          setCategory(newCategory);
          setOldCategory(newCategory);
          setAlerted(false);
        })
        .catch((error) =>
          alertError(alertContext, "Ошибка получения категории")
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId]);

  const [alerted, setAlerted] = useState(false);

  const requiredFieldEmpty = () =>
    category.name === "" || category.position === 0;

  return (
    <Dialog
      disableBackdropClick
      onBackdropClick={() =>
        progress
          ? null
          : isObjectsEqual(category, oldCategory)
          ? onClose()
          : setAlerted(true)
      }
      open={!!categoryId}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Редактирование категории</DialogTitle>
      <DialogContent>
        <form>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Наименование"
            inputProps={{ maxLength: 100 }}
            value={category.name}
            onChange={(e) => setCategory({ ...category, name: e.target.value })}
            type="text"
            fullWidth
            error={!category.name}
            helperText={!category.name ? "Обязательное поле" : ""}
            required
          />
          <TextField
            margin="dense"
            id="description"
            label="Описание"
            value={category.description}
            onChange={(e) => {
              if (getSizeOfStringInBytes(e.target.value) <= 4000)
                setCategory({ ...category, description: e.target.value });
            }}
            type="text"
            fullWidth
          />
          <TextField
            margin="dense"
            id="position"
            label="Позиция"
            value={category.position}
            onChange={(e) =>
              e.target.value === " "
                ? null
                : setCategory({
                    ...category,
                    position: getIntFromString(e.target.value),
                  })
            }
            type="text"
            fullWidth
            error={!category.position}
            helperText={!category.position ? "Обязательное поле" : ""}
            required
          />
          <FormControl className={classes.showInMenu}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={category.showInMenu}
                  onChange={(e) =>
                    setCategory({
                      ...category,
                      showInMenu: !category.showInMenu,
                    })
                  }
                  name="showInMenu"
                  color="primary"
                />
              }
              label="Показывать в меню"
            />
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        {alerted ? (
          <Alert
            severity="warning"
            action={
              <>
                <Button color="inherit" size="small" onClick={() => onClose()}>
                  Да
                </Button>
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => setAlerted(false)}
                >
                  Нет
                </Button>
              </>
            }
          >
            Вы действительно хотите отменить все изменения и выйти? Введенные
            данные будут утеряны
          </Alert>
        ) : (
          <>
            <Button onClick={onClose} color="primary" disabled={progress}>
              Отмена
            </Button>
            <Button
              onClick={() => {
                setProgress(true);
                onCategoryEdit(categoryId ? categoryId : 1, category)
                  .then(onClose)
                  .catch((error) => console.log(error))
                  .finally(() => setProgress(false));
              }}
              color="primary"
              type="submit"
              disabled={
                requiredFieldEmpty() ||
                isObjectsEqual(category, oldCategory) ||
                progress
              }
            >
              Подтвердить
            </Button>
          </>
        )}
      </DialogActions>
      {progress && <ProgressBar bottom />}
    </Dialog>
  );
};

export default EditCategoryForm;
