import React, { FC, useState, useRef, useEffect, useContext } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Avatar,
} from "@material-ui/core";
import PublishIcon from "@material-ui/icons/Publish";
import { Alert } from "@material-ui/lab";
import { MPData } from "./_shared/types";
import { ProductRow, AlertContextType } from "../../_shared/types";
import { AlertContext } from "../_shared/ToastList";
import API from "../../_shared/axios";
import styles from "./_shared/styles";
import TransferList from "./_shared/TransferList";
import {
  alertError,
  getErrorMsg,
  getSizeOfStringInBytes,
} from "../../_shared/utils";
import { ProgressBar } from "../_shared/ProgressBar";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  imageDisplay: {
    display: "none",
  },
  colorInactive: { color: "rgba(0, 0, 0, 0.54)" },
});

const NewFilterForm: FC<{
  onSend: (data: MPData) => Promise<any>;
}> = ({ onSend }) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const [modal, setModal] = useState(false);
  const [alerted, setAlerted] = useState(false);
  const [blur, setBlur] = useState({
    imgFile: false,
    name: false,
    position: false,
  });
  const [progress, setProgress] = useState(false);

  const [imgFile, setImgFile] = useState<File | undefined>(undefined);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [position, setPosition] = useState(0);
  const [products, setProducts] = useState<ProductRow[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<ProductRow[]>([]);
  const [directoryId, setDirectoryId] = useState<number>();

  const alertContext = useContext<AlertContextType>(AlertContext);
  const commonClasses = styles();
  const classes = useStyles();

  useEffect(() => {
    if (modal) {
      setProgress(true);
      API.get(`/products`)
        .then(({ data }) => setProducts(data))
        .catch((error) =>
          alertError(
            alertContext,
            getErrorMsg(error.response, "Ошибка загрузки списка продуктов")
          )
        )
        .finally(() => setProgress(false));
      API.get("/files/images/directories")
        .then(({ data }) =>
          setDirectoryId(
            data.find(
              (el: { id: number; value: string; description: string }) =>
                el.value === "Filters"
            ).id
          )
        )
        .catch((error) =>
          alertError(
            alertContext,
            getErrorMsg(
              error.response,
              "Ошибка получения списка директорий изображений"
            )
          )
        );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  const onOpen = () => {
    setImgFile(undefined);
    setName("");
    setDescription("");
    setPosition(0);
    setProducts([]);
    setSelectedProducts([]);

    setModal(true);
    setAlerted(false);
    setBlur({
      imgFile: false,
      name: false,
      position: false,
    });
    setProgress(false);
  };

  const onSubmit = () => {
    if (imgFile) {
      setProgress(true);
      const imageToUpload = {
        File: imgFile,
        Folder: directoryId,
      };
      const formData = new FormData();
      (Object.keys(imageToUpload) as Array<
        keyof { File: File; Folder: number }
      >).map((k: keyof { File: File; Folder: number }) =>
        // @ts-ignore
        formData.append(k as string, imageToUpload[k])
      );
      API.post("/files/images/upload", formData)
        .then(({ data }) => {
          onSend({
            ImgName: data,
            name,
            description,
            productsToAdd: selectedProducts.map((p) => p.id).join(", "),
            position: position.toString(),
          })
            .then((data) => setModal(false))
            .catch((error) => console.log(error))
            .finally(() => setProgress(false));
        })
        .catch((error) => {
          alertError(
            alertContext,
            getErrorMsg(error.response, "Ошибка загрузки изображения")
          );
        });
    }
  };

  const safeToExit =
    (name + description).length === 0 &&
    position === 0 &&
    typeof imgFile === "undefined" &&
    selectedProducts.length === 0;

  const isImageFormatWrong = () =>
    imgFile?.name?.slice(imgFile?.name?.length - 4, imgFile?.name?.length) !==
      ".png" && imgFile?.name;

  return (
    <>
      <Button variant="outlined" color="primary" onClick={onOpen}>
        Добавить фильтр
      </Button>
      <Dialog
        disableBackdropClick
        onBackdropClick={() =>
          progress ? null : safeToExit ? setModal(false) : setAlerted(true)
        }
        open={modal}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">Новый фильтр</DialogTitle>
        <DialogContent>
          <div className={commonClasses.imgWrapper}>
            <input
              accept="image/*"
              className={classes.imageDisplay}
              id="contained-button-file"
              type="file"
              ref={fileRef}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                e.target.files?.[0] ? setImgFile(e.target.files[0]) : null
              }
            />
            <Avatar
              alt={imgFile ? URL.createObjectURL(imgFile) : ""}
              src={imgFile ? URL.createObjectURL(imgFile) : ""}
              variant="rounded"
              className={commonClasses.large}
            />
            {isImageFormatWrong() && (
              <p className={commonClasses.requiredWarning}>
                Выберите изображение в "png" формате
              </p>
            )}
            <div className={commonClasses.imgControls}>
              <label htmlFor="contained-button-file">
                <IconButton
                  component="span"
                  onClick={(e: React.MouseEvent<HTMLSpanElement>) =>
                    e.stopPropagation()
                  }
                >
                  <PublishIcon />
                </IconButton>
              </label>
            </div>
          </div>
          <TextField
            margin="dense"
            id="name"
            label="Наименование"
            inputProps={{ maxLength: 100 }}
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
            fullWidth
            onBlur={() => setBlur({ ...blur, name: true })}
            error={!name && blur.name}
            helperText={!name && blur.name ? "Обязательное поле" : ""}
            required
          />
          <TextField
            margin="dense"
            id="description"
            label="Описание"
            value={description}
            onChange={(e) => {
              if (getSizeOfStringInBytes(e.target.value) <= 4000)
                setDescription(e.target.value);
            }}
            type="text"
            fullWidth
          />
          <TextField
            margin="dense"
            id="position"
            label="Позиция"
            value={position}
            onChange={(e) =>
              setPosition(
                e.target.value !== ""
                  ? e.target.value.match(/^\d+$/)
                    ? parseInt(e.target.value)
                    : position
                  : 0
              )
            }
            type="text"
            fullWidth
            onBlur={() => setBlur({ ...blur, position: true })}
            error={!position && blur.position}
            helperText={!position && blur.position ? "Обязательное поле" : ""}
            required
          />
          <p className={classes.colorInactive}>Товары:</p>
          <TransferList
            products={products}
            getData={(data: ProductRow[]) => setSelectedProducts(data)}
          />
        </DialogContent>
        <DialogActions className={commonClasses.dialogActions}>
          {alerted ? (
            <Alert
              severity="warning"
              action={
                <>
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => setModal(false)}
                  >
                    Да
                  </Button>
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => setAlerted(false)}
                  >
                    Нет
                  </Button>
                </>
              }
            >
              Вы действительно хотите отменить все изменения и выйти? Введенные
              данные будут утеряны
            </Alert>
          ) : (
            <>
              <Button
                onClick={() => setModal(false)}
                color="primary"
                disabled={progress}
              >
                Отмена
              </Button>
              <Button
                onClick={onSubmit}
                color="primary"
                type="submit"
                disabled={
                  !!isImageFormatWrong() ||
                  typeof imgFile === "undefined" ||
                  name === "" ||
                  position === 0 ||
                  safeToExit ||
                  progress
                }
              >
                Создать
              </Button>
            </>
          )}
        </DialogActions>
        {progress && <ProgressBar bottom />}
      </Dialog>
    </>
  );
};

export default NewFilterForm;
