import React, { FC, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { createContext } from "react";
import Toast from "./Toast";
import {
  AlertProps,
  AlertContextType,
  AlertData,
} from "../../../_shared/types";

const useStyles = makeStyles({
  alertWrapper: {
    zIndex: 9999999,
    position: "fixed",
    bottom: "30px",
  },
});

export const AlertContext = createContext<AlertContextType>({
  showAlert: (alertData: AlertData) => {},
});

const ToastList: FC<{ alertData: AlertProps }> = ({ alertData }) => {
  const [alertArray, setAlertArray] = useState<AlertProps[]>([]);
  const [deletedIds, setDeletedIds] = useState<number[]>([]);

  const classes = useStyles();

  const removeAlert = (id: number) => {
    for (let index = 0; index < alertArray.length; index++) {
      if (alertArray[index].id === id) {
        setDeletedIds([...deletedIds, id]);
      }
    }
  };

  useEffect(() => {
    if (!alertArray.includes(alertData)) {
      setAlertArray([...alertArray, alertData]);
    }
  }, [alertData, alertArray]);

  return (
    <div className={classes.alertWrapper}>
      {alertArray.map((alert, key) =>
        !deletedIds.includes(alert.id) && alert.text !== "" ? (
          <Toast key={key} alert={alert} removeAlert={removeAlert} />
        ) : null
      )}
    </div>
  );
};

export default ToastList;
