import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import BlockIcon from "@material-ui/icons/Block";
import {
  AlertContextType,
  Banner,
  Category,
  CategoryCreate,
  ProductRow,
} from "../../_shared/types";
import useStyles from "../_shared/styles";
import {
  TableBody,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import NewCategoryForm from "./NewCategoryForm";
import { AlertContext } from "../_shared/ToastList";
import EditCategoryForm from "./EditCategoryForm";
import DropdownOptions from "../_shared/DropdownOptions";
import API from "../../_shared/axios";
import {
  alertError,
  alertSuccess,
  alertWarning,
  getErrorMsg,
} from "../../_shared/utils";
import { makeStyles } from "@material-ui/styles";
import { ProgressBar } from "../_shared/ProgressBar";
import Transition from "../_shared/Transition";

const columns = [
  { id: "name", label: "Наименование" },
  {
    id: "description",
    label: "Описание",
  },
  {
    id: "position",
    label: "Позиция",
  },
  {
    id: "dropdownOptions",
    label: "",
  },
];

const styles = makeStyles({
  page: {
    height: "calc(100% - 64px)",
  },
  minWidth: { minWidth: 100 },
  content: {
    padding: 50,
    maxWidth: 1140,
    width: "100%",
    margin: "0 auto 20px auto",
    "& .MuiTableCell-body": {
      cursor: "pointer",
    },
  },
});

const CategoriesPage: FC = () => {
  const [editCategoryId, setEditCategoryId] = useState<number | undefined>(
    undefined
  );
  const commonClasses = useStyles();
  const classes = styles();
  const [rows, setRows] = useState<Category[]>([]);
  const [usedCategories, setUsedCategories] = useState<number[]>([]);
  const [banners, setBanners] = useState<Banner[]>([]);
  const [slideOpen, setSlideOpen] = useState(false);
  const [slideData, setSlideData] = useState<{
    category: Category;
    banners: string;
    isDeleting?: boolean;
  }>();
  const [progress, setProgress] = useState(false);

  const alertContext = useContext<AlertContextType>(AlertContext);

  useEffect(() => {
    setProgress(true);
    API.get(`/categories`)
      .then(({ data: categoriesData }) => {
        setProgress(false);
        setRows(categoriesData);
        API.get(`/products`)
          .then(({ data }) => {
            let newUsedCategories: number[] = [];
            for (let i = 0; i < categoriesData.length; i++) {
              if (
                data
                  .map((el: ProductRow) => el.categoryId)
                  .includes(categoriesData[i].id)
              )
                newUsedCategories = [
                  ...newUsedCategories,
                  categoriesData[i].id,
                ];
            }
            setUsedCategories(newUsedCategories);
          })
          .catch((error) => {
            alertError(
              alertContext,
              getErrorMsg(error.response, "Ошибка получения списка товаров")
            );
          });
      })
      .catch((error) => {
        setProgress(false);
        alertError(
          alertContext,
          getErrorMsg(error.response, "Ошибка получения списка категорий")
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCategoryCreate = (data: CategoryCreate) =>
    new Promise((resolve, reject) =>
      API.post("/categories", data)
        .then(({ data }) => {
          alertSuccess(alertContext, "Категория добавлена");
          API.get(`/categories`)
            .then(({ data }) => {
              setRows(data);
              resolve(data);
            })
            .catch((error) => {
              alertError(
                alertContext,
                getErrorMsg(
                  error.response,
                  "Ошибка обновления списка категорий"
                )
              );
              reject(error);
            });
        })
        .catch((error) => {
          alertError(
            alertContext,
            getErrorMsg(error.response, "Ошибка добавления категории")
          );
          reject(error);
        })
    );

  const onCategoryEdit = (id: number, category: Category) =>
    new Promise((resolve, reject) =>
      API.patch(`/categories/${id}`, category)
        .then(({ data }) => {
          API.get(`/categories`)
            .then(({ data }) => {
              setRows(data);
              alertSuccess(alertContext, "Успешное редактирование категории");
              resolve(data);
            })
            .catch((error) => {
              alertError(
                alertContext,
                getErrorMsg(
                  error.response,
                  "Ошибка обновления списка категорий"
                )
              );
              reject(error);
            });
        })
        .catch((error) => {
          alertError(
            alertContext,
            getErrorMsg(error.response, "Ошибка редактирования категории")
          );
          reject(error);
        })
    );

  const onCategoryDelete = useCallback(
    (row: Category) => {
      API.get("/banners")
        .then(({ data }) => {
          let bannersData = data.filter(
            (el: Banner) => el.categoryId === row.id
          );
          setBanners(bannersData);
          bannersData = bannersData.map((el: Banner) => el.name);
          if (bannersData.length === 0)
            API.delete(`/categories/${row.id}`)
              .then(() => {
                alertSuccess(alertContext, "Категория удалена");
                API.get(`/categories`)
                  .then(({ data }) => {
                    setRows(data);
                  })
                  .catch((error) => {
                    alertError(
                      alertContext,
                      getErrorMsg(
                        error.response,
                        "Ошибка обновления списка категорий"
                      )
                    );
                  });
              })
              .catch((error) =>
                alertError(
                  alertContext,
                  getErrorMsg(error.response, "Ошибка удаления категории")
                )
              );
          else {
            setSlideData({
              category: row,
              banners: bannersData.join(", "),
              isDeleting: true,
            });
            setSlideOpen(true);
          }
        })
        .catch((error) =>
          alertError(
            alertContext,
            getErrorMsg(error.response, "Ошибка получения списка баннеров")
          )
        );
    },
    [alertContext]
  );

  const onCategoryBlock = useCallback(
    (row: Category) => {
      API.get("/banners")
        .then(({ data }) => {
          let bannersData = data.filter(
            (el: Banner) =>
              el.categoryId === row.id && el.isActive === row.isActive
          );
          setBanners(bannersData);
          bannersData = bannersData.map((el: Banner) => el.name);
          if (bannersData.length === 0)
            API.post(
              `/categories/${row.id}/${row.isActive ? "block" : "unblock"}`
            )
              .then(() =>
                API.get(`/categories`)
                  .then(({ data }) => setRows(data))
                  .catch((error) =>
                    alertError(
                      alertContext,
                      getErrorMsg(
                        error.response,
                        "Ошибка обновления списка категорий"
                      )
                    )
                  )
              )
              .catch((error) =>
                alertError(
                  alertContext,
                  getErrorMsg(error.response, "Ошибка блокировки категории")
                )
              );
          else {
            setSlideData({
              category: row,
              banners: bannersData.join(", "),
              isDeleting: false,
            });
            setSlideOpen(true);
          }
        })
        .catch((error) =>
          alertError(
            alertContext,
            getErrorMsg(error.response, "Ошибка получения списка баннеров")
          )
        );
    },
    [alertContext]
  );

  const dropdownMenuOptions = useCallback(
    (row: Category) => [
      {
        icon: <BlockIcon fontSize={"small"} />,
        text: row.isActive ? "Заблокировать" : "Разблокировать",
        event: () => onCategoryBlock(row),
      },
      {
        icon: <DeleteIcon fontSize={"small"} />,
        text: "Удалить",
        event: () =>
          usedCategories.includes(row.id)
            ? alertWarning(
                alertContext,
                "Операция невозможна: категория используется товарами"
              )
            : onCategoryDelete(row),
      },
    ],
    [onCategoryBlock, onCategoryDelete, usedCategories, alertContext]
  );

  return (
    <div className={classes.page}>
      <EditCategoryForm
        onCategoryEdit={onCategoryEdit}
        categoryId={editCategoryId}
        onClose={() => setEditCategoryId(undefined)}
      />
      <Dialog
        open={slideOpen}
        // @ts-ignore
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setSlideOpen(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Внимание"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {!slideData?.isDeleting && !slideData?.category.isActive
              ? `Вместе с категорией можно разблокировать следующие привязанные баннеры: ${slideData?.banners}`
              : slideData?.isDeleting
              ? `Вместе с категорией будут удалены следующие баннеры ${slideData?.banners}`
              : `Вместе с категорией будут заблокированы следующие баннеры ${slideData?.banners}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (!slideData?.isDeleting && !slideData?.category.isActive)
                API.post(`/categories/${slideData?.category.id}/unblock`)
                  .then(() => {
                    API.get(`/categories`)
                      .then(({ data }) => setRows(data))
                      .catch((error) =>
                        alertError(
                          alertContext,
                          getErrorMsg(
                            error.response,
                            "Ошибка обновления списка категорий"
                          )
                        )
                      );
                  })
                  .catch((error) => {
                    alertError(
                      alertContext,
                      getErrorMsg(
                        error.response,
                        "Ошибка разблокировки категории"
                      )
                    );
                  });
              setSlideOpen(false);
            }}
            color="primary"
          >
            {slideData?.category.isActive
              ? "Отменить блокировку категории"
              : "Разблокировать только категорию"}
          </Button>
          <Button
            onClick={() => {
              setSlideOpen(false);
              slideData?.isDeleting
                ? API.delete(`/categories/${slideData.category.id}`)
                    .then(() => {
                      alertSuccess(alertContext, "Категория удалена");
                      API.get(`/categories`)
                        .then(({ data }) => {
                          setRows(data);
                        })
                        .catch((error) => {
                          alertError(
                            alertContext,
                            getErrorMsg(
                              error.response,
                              "Ошибка обновления списка категорий"
                            )
                          );
                        });
                    })
                    .catch((error) =>
                      alertError(
                        alertContext,
                        getErrorMsg(error.response, "Ошибка удаления категории")
                      )
                    )
                : API.post(
                    `/categories/${slideData?.category.id}/${
                      slideData?.category.isActive ? "block" : "unblock"
                    }`
                  )
                    .then(() => {
                      if (
                        !slideData?.isDeleting &&
                        !slideData?.category.isActive
                      ) {
                        for (let i = 0; i < banners.length; i++) {
                          API.post(`/banners/${banners[i].id}/unblock`)
                            .then(() => {})
                            .catch((error) =>
                              alertError(
                                alertContext,
                                getErrorMsg(
                                  error.response,
                                  "Ошибка разблокировки баннера"
                                )
                              )
                            );
                        }
                      }
                      API.get(`/categories`)
                        .then(({ data }) => setRows(data))
                        .catch((error) =>
                          alertError(
                            alertContext,
                            getErrorMsg(
                              error.response,
                              "Ошибка обновления списка категорий"
                            )
                          )
                        );
                    })
                    .catch((error) =>
                      alertError(
                        alertContext,
                        getErrorMsg(
                          error.response,
                          "Ошибка блокировки категории"
                        )
                      )
                    );
            }}
            color="primary"
          >
            Подтвердить
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classes.content}>
        <NewCategoryForm onCategoryCreate={onCategoryCreate} />
        {progress ? (
          <ProgressBar />
        ) : (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} className={classes.minWidth}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, key) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={key}
                      className={
                        !row.isActive ? commonClasses.blocked : undefined
                      }
                      onClick={() => setEditCategoryId(row.id)}
                    >
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.description}</TableCell>
                      <TableCell>{row.position}</TableCell>
                      <TableCell>
                        <DropdownOptions
                          options={() => dropdownMenuOptions(row)}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </div>
  );
};

export default CategoriesPage;
