import {
  AlertContextType,
  AlertType,
  DispatcherProduct,
  OrderCommonPromotionItem,
} from "./types";
import {
  KladrObject,
  KladrObjectType,
} from "../MainPage/StreetsPage/_shared/types";
import moment, { Moment } from "moment/moment";

enum UserAgent {
  Chrome,
  Safari,
  Firefox,
  Unknown,
}

const getAgent = () => {
  let chromeAgent = navigator.userAgent.indexOf("Chrome") > -1;
  let safariAgent = navigator.userAgent.indexOf("Safari") > -1;
  let firefoxAgent = navigator.userAgent.indexOf("Firefox") > -1;

  switch (true) {
    case chromeAgent && safariAgent:
      return UserAgent.Chrome;
    case safariAgent:
      return UserAgent.Safari;
    case firefoxAgent:
      return UserAgent.Firefox;
    default:
      return UserAgent.Unknown;
  }
};

const getOffset = () => {
  switch (getAgent()) {
    case UserAgent.Safari:
      return 0;
    case UserAgent.Chrome:
      return 3;
    case UserAgent.Firefox:
      return 3;
    default:
      return 0;
  }
};

export const renderDate = (
  date: string | Date,
  forPromotionSchedule?: boolean,
  withoutOffset?: boolean
) => {
  const newDate = new Date(date);
  const hours =
    newDate.getHours() +
    (forPromotionSchedule || withoutOffset ? 0 : getOffset());

  const minutes = newDate.getMinutes();
  const h = hours < 10 ? `0${hours}` : "" + hours;
  const m = minutes < 10 ? `0${minutes}` : "" + minutes;

  return forPromotionSchedule
    ? `${h}:${m}:00`
    : `${new Date(date).toLocaleDateString("ru-RU")} ${h}:${m}`;
};

export const renderReadyTime = (date: Date) => {
  const newDate = new Date(date);
  const days = newDate.getDate();
  const month = newDate.getMonth();
  const hours = newDate.getHours();
  const minutes = newDate.getMinutes();
  const h = hours < 10 ? `0${hours}` : "" + hours;
  const m = minutes < 10 ? `0${minutes}` : "" + minutes;
  let monthName = "";
  switch (month) {
    case 0:
      monthName = "января";
      break;
    case 1:
      monthName = "февраля";
      break;
    case 2:
      monthName = "марта";
      break;
    case 3:
      monthName = "апреля";
      break;
    case 4:
      monthName = "мая";
      break;
    case 5:
      monthName = "июня";
      break;
    case 6:
      monthName = "июля";
      break;
    case 7:
      monthName = "августа";
      break;
    case 8:
      monthName = "сентября";
      break;
    case 9:
      monthName = "октября";
      break;
    case 10:
      monthName = "ноября";
      break;
    case 11:
      monthName = "декабря";
      break;
  }
  return `${days} ${monthName} ${h}:${m}`;
};

export const round15MinutesMoment = (date: Moment) => {
  // if (date.minutes() < 7) {
  //   date.minutes(0);
  // } else if (date.minutes() < 23) {
  //   date.minutes(15);
  // } else if (date.minutes() < 37) {
  //   date.minutes(30);
  // } else if (date.minutes() < 52) {
  //   date.minutes(45);
  // } else {
  //   date.minutes(0);
  //   date.add(1, "hour");
  // }
  if (date.minutes() < 15) {
    date.minutes(15);
  } else if (date.minutes() < 30) {
    date.minutes(30);
  } else if (date.minutes() < 45) {
    date.minutes(45);
  } else {
    date.minutes(0);
    date.add(1, "hour");
  }
};

export const getIngredientsLabel = (
  ingredients:
    | DispatcherProduct["ingredientsToAdd"]
    | DispatcherProduct["ingredientsToRemove"]
) =>
  ingredients
    ? ingredients
        // @ts-ignore
        .reduce((sum, acc) => {
          let quan: string = "";
          if (acc.quantity) {
            quan = `(${acc.quantity})`;
          }
          return sum + `${acc.name}${quan}, `;
        }, "")
        .slice(0, -2)
    : "";

export const getMonthNumberByName = (name: string) => {
  let result = 0;
  switch (name) {
    case "янв":
      result = 0;
      break;
    case "фев":
      result = 1;
      break;
    case "мар":
      result = 2;
      break;
    case "апр":
      result = 3;
      break;
    case "май":
      result = 4;
      break;
    case "июн":
      result = 5;
      break;
    case "июл":
      result = 6;
      break;
    case "авн":
      result = 7;
      break;
    case "сен":
      result = 8;
      break;
    case "окт":
      result = 9;
      break;
    case "ноя":
      result = 10;
      break;
    case "дек":
      result = 11;
      break;
  }
  return result;
};
export const getMonthNameByNumber = (number: number) => {
  let result = "0";
  switch (number) {
    case 0:
      result = "янв";
      break;
    case 1:
      result = "фев";
      break;
    case 2:
      result = "мар";
      break;
    case 3:
      result = "апр";
      break;
    case 4:
      result = "мая";
      break;
    case 5:
      result = "июн";
      break;
    case 6:
      result = "июл";
      break;
    case 7:
      result = "авг";
      break;
    case 8:
      result = "сен";
      break;
    case 9:
      result = "окт";
      break;
    case 10:
      result = "ноя";
      break;
    case 11:
      result = "дек";
      break;
  }
  return result;
};

export const readyTimeToDate = (time: String) =>
  new Date(
    new Date().getFullYear(),
    getMonthNumberByName(time.slice(3, 6)),
    +time.slice(0, 2),
    +time.slice(10, 12),
    +time.slice(13, 15)
  );

export const blobToFile = (theBlob: Blob) => {
  return { ...theBlob, lastModified: Date.now(), name: "fileName" } as File;
};

export const getFloatFromString = (str: string | number) => {
  const string = str.toString();
  const exceptLast = string.slice(0, -1);
  const lastNumber = string.slice(-1);
  const thirdNumberFromEnd = string.slice(-4, -3);

  return string === "00" || string === ""
    ? "0"
    : !isNaN(+str)
    ? lastNumber === "."
      ? string
      : thirdNumberFromEnd === "."
      ? exceptLast
      : lastNumber === "0"
      ? string
      : getStringWithoutZeroAndMinusInStart(parseFloat(string).toString())
    : "0";
};

export const getIntFromString = (string: string, canBeEmpty?: boolean) =>
  !isNaN(+string) && !string.includes("-") && string !== ""
    ? parseInt(string)
    : 0;

const getStringWithoutZeroAndMinusInStart = (string: string) => {
  return string.slice(0, 1) === "-" ? string.slice(-string.length + 1) : string;
};

export const renderPrice = (price: number | string) => {
  const priceStr = price.toString();
  const index = priceStr.indexOf(".");
  return index !== -1 && index === priceStr.length - 2
    ? priceStr + "0"
    : priceStr;
};

export const alertError = (alertContext: AlertContextType, message: string) =>
  alertContext.showAlert({
    type: AlertType.ERROR,
    text: message,
  });

export const alertSuccess = (alertContext: AlertContextType, message: string) =>
  alertContext.showAlert({
    type: AlertType.SUCCESS,
    text: message,
  });

export const alertWarning = (alertContext: AlertContextType, message: string) =>
  alertContext.showAlert({
    type: AlertType.WARNING,
    text: message,
  });

export const getImageUrl = (url: string, folder: string) =>
  url.includes("http") ? url : `/images/${folder}/${url}`;

const pad = (s: number) => (s < 10 ? "0" + s : s);
export const getUnitedDateTime = (inputDate: Date, inputTime: Date) => {
  const d = new Date(inputDate);
  const t = new Date(inputTime);
  const dateString = [
    d.getFullYear(),
    pad(d.getMonth() + 1),
    pad(d.getDate()),
  ].join("-");
  const timeString = [
    pad(t.getHours()),
    pad(t.getMinutes()),
    pad(t.getSeconds()),
  ].join(":");
  return new Date(`${dateString}T${timeString}Z`);
};

export const getPromotionFormattedDate = (inputDate: Date | undefined) => {
  if (!inputDate) return undefined;
  const d = new Date(inputDate);
  const dateString = [
    d.getFullYear(),
    pad(d.getMonth() + 1),
    pad(d.getDate()),
  ].join("-");
  const timeString = [
    pad(d.getHours()),
    pad(d.getMinutes()),
    pad(d.getSeconds()),
  ].join(":");
  return `${dateString} ${timeString}`;
};

export const copyObject = (object: {}) => JSON.parse(JSON.stringify(object));

export const downloadFile = (data: any, filename: string, type: string) => {
  const file = new Blob([data], { type: type });
  if (window.navigator.msSaveOrOpenBlob)
    // IE10+
    window.navigator.msSaveOrOpenBlob(file, filename);
  else {
    // Others
    const a = document.createElement("a"),
      url = URL.createObjectURL(file);
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
};

export const getStreetsData = (
  data: KladrObject[],
  types: KladrObjectType[]
) => {
  let rowsData: {
    district: KladrObject | undefined;
    streets: KladrObject[];
  }[] = [];
  let districtTypeId =
    types.find((el) => el.nameRu.toLowerCase() === "район")?.id || 2;
  for (let dataEl of data) {
    if (dataEl.kladrObjectTypeId === districtTypeId)
      rowsData = [
        ...rowsData,
        { district: dataEl, streets: dataEl.childrenList },
      ];
    else rowsData = [...rowsData, { district: undefined, streets: [dataEl] }];
  }
  return rowsData;
};

export const isPhoneNotValid = (phone: string) =>
  phone
    .toString()
    .split("")
    .filter((el) => !isNaN(+el)).length !== 11;

export const getClearPhoneNumber = (phone: string) =>
  phone.toString().replaceAll("-", "").replaceAll("(", "").replaceAll(")", "");

export const getPhoneNumberWithoutUnderscore = (phone: string) =>
  phone.toString().replaceAll("_", "");

export const getFormattedPhoneNumber = (phone: string) =>
  phone.slice(0, 2) +
  " (" +
  phone.slice(2, 5) +
  ") " +
  phone.slice(5, 8) +
  "-" +
  phone.slice(8, 10) +
  "-" +
  phone.slice(10, 12);

export const getTimePassed = (time: string) => {
  const hour = 1000 * 60 * 60;
  if (Math.abs(moment().diff(moment(time))) > hour) {
    return moment(time).format("DD.MM.YYYY HH:mm");
  }
  const timeEnd = Date.now();
  const timeStart = new Date(time).getTime();
  // const timeStart = new Date(time).getTime() + 3 * (1000 * 60 * 60);
  let diff = timeEnd - timeStart;
  const diffInDays = Math.floor(diff / (1000 * 60 * 60 * 24));
  diff = diff - diffInDays * 1000 * 60 * 60 * 24;
  const diffInHours = Math.floor(diff / (1000 * 60 * 60));
  diff = diff - diffInHours * 1000 * 60 * 60;
  const diffInMinutes = Math.floor(diff / (1000 * 60));
  const daysPart = diffInDays === 0 ? "" : `${diffInDays} дн `;
  const hoursPart = diffInHours === 0 ? "" : `${diffInHours} ч `;
  const minutesPart =
    diffInMinutes <= 0 && diffInHours <= 0 && diffInDays <= 0
      ? "только что"
      : diffInMinutes === 0
      ? " назад"
      : `${diffInMinutes} мин назад`;
  if (daysPart.includes("-")) return "только что";
  return `${daysPart} ${hoursPart} ${minutesPart}`;
};

export const getTimeLeft = (time: string) => {
  const hour = 1000 * 60 * 60;
  if (Math.abs(moment().diff(moment(time))) > hour) {
    return moment(time).format("DD.MM.YYYY HH:MM");
  }
  const now = moment();
  const orderTime = moment(time);
  const diffMS = orderTime.diff(now);
  const m = moment.duration(diffMS);
  if (diffMS > 0) {
    const minutes = Math.floor(m.asMinutes());
    const hours = Math.floor(minutes / 60);
    const minutesLeft = minutes - hours * 60;

    return `через ${hours}:${
      minutesLeft < 10 ? "0" + String(minutesLeft) : minutesLeft
    }`;
  } else {
    const minutes = Math.floor(m.asMinutes());
    const hours = Math.floor(minutes / 60);
    const minutesLeft = minutes - hours * 60;

    return `${Math.abs(hours)}:${
      minutesLeft < 10 ? "0" + String(minutesLeft) : minutesLeft
    } назад`;
  }
};

export const isObjectsEqual = (obj: {}, objInit: {}) => {
  return JSON.stringify(obj) === JSON.stringify(objInit);
};
export const removeDuplicates = <T>(arr: T[]) =>
  arr.filter((item: T, index: number) => arr.indexOf(item) === index);
export const mergePromotionItems = (arr: OrderCommonPromotionItem[]) => {
  let resultWithDuplicates = arr.map((el) => {
    return { ...el, quantity: 0 };
  });
  let result: OrderCommonPromotionItem[] = [];
  resultWithDuplicates.forEach((el) => {
    if (!result.find((res) => isObjectsEqual(res, el)))
      result = [...result, el];
  });
  result.forEach((res: OrderCommonPromotionItem, index: number) => {
    arr.forEach((item: OrderCommonPromotionItem) => {
      if (
        isObjectsEqual(
          { ...item, quantity: undefined },
          { ...res, quantity: undefined }
        )
      ) {
        result[index].quantity += item.quantity;
      }
    });
  });
  return result;
};

export const getSizeOfStringInBytes = (text: string) => new Blob([text]).size;

export const getUserName = (
  user:
    | {
        lastName: string;
        firstName: string;
        patronymic: string;
      }
    | undefined
) => {
  const lastName = user?.lastName;
  const firstName = user?.firstName.slice(0, 1);
  const patronymic = user?.patronymic.slice(0, 1);
  return `${lastName} ${firstName}.${patronymic}`;
};

export const getClientName = (
  user:
    | {
        lastName: string;
        firstName: string;
        patronymic: string;
        phoneNumber: string;
      }
    | undefined
) => {
  let result = "";
  if (!user?.lastName) return user?.phoneNumber;
  result += user.lastName;
  if (user.firstName && user.patronymic) {
    const FN = user.firstName.slice(0, 1).toUpperCase();
    const PA = user.patronymic.slice(0, 1).toUpperCase();
    result += ` ${FN}. ${PA}`;
  }
  result += ` (${user.phoneNumber})`;
  return result;
};

export const compareProducts = (a: any, b: any) => {
  const ingadd =
    a.ingredientsToAdd.length === b.ingredientsToAdd.length &&
    a.ingredientsToAdd.filter(
      (i: any) =>
        b.ingredientsToAdd.findIndex(
          (j: any) =>
            i.ingredientId === j.ingredientId && i.quantity === j.quantity
        ) < 0
    ).length === 0;

  const ingrem =
    a.ingredientsToRemove.length === b.ingredientsToRemove.length &&
    a.ingredientsToRemove.filter(
      (i: any) => b.ingredientsToRemove.findIndex((j: any) => i === j) < 0
    ).length === 0;

  return (
    a.productId === b.productId &&
    a.variantId === b.variantId &&
    ingadd &&
    ingrem
  );
};

export const toISOString = (dateObject: Date) => {
  const month = dateObject.getMonth();
  const hours = dateObject.getHours();
  const minutes = dateObject.getMinutes();
  const day = dateObject.getDate();
  const d = day < 10 ? `0${day}` : "" + day;
  const h = hours < 10 ? `0${hours}` : "" + hours;
  const m = minutes < 10 ? `0${minutes}` : "" + minutes;
  const mon = month + 1 < 10 ? `0${month + 1}` : "" + (month + 1);

  return `${dateObject.getFullYear()}-${mon}-${d}T${h}:${m}:00.000`;
};

export const getDateOfWeek = (day: number) => {
  switch (day) {
    case 0:
      return "mon";
    case 1:
      return "tue";
    case 2:
      return "wed";
    case 3:
      return "thu";
    case 4:
      return "fri";
    case 5:
      return "sat";
    case 6:
      return "sun";
    default:
      return "sun";
  }
};

export function buildQueryParamsJSON<T>(params: Record<string, any>): string {
  const query: string = Object.keys(params)
    .map((key) => {
      const value = params[key];
      if (
        value === null ||
        value === undefined ||
        (Array.isArray(value) && !value.length)
      ) {
        return "";
      }
      if (Array.isArray(value)) {
        return value
          .map((val) => `${key}=${encodeURIComponent(val)}`)
          .join("&");
      }
      return `${key}=${encodeURIComponent(value)}`;
    })
    .join("&");

  return query ? "?" + query : "";
}

export function getErrorMsg(respBody: any, def?: string) {
  if (respBody) {
    if (respBody.data) {
      if (respBody.data.errors) {
        return respBody.errors[0];
      }
    }
  }
  return def;
}

export function getMultipleErrorMsgs(respBody: any, def?: string) {
  if (respBody) {
    if (respBody.data) {
      if (respBody.data.errors) {
        return respBody.errors.join(". ");
      }
    }
  }
  return def;
}
