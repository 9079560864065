import React, { FC, useContext, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Alert } from "@material-ui/lab";
import {
  AlertContextType,
  Category,
  ProductRow,
  PromotionCategory,
} from "../../_shared/types";
import API from "../../_shared/axios";
import { AlertContext } from "../_shared/ToastList";
import {
  alertError,
  isObjectsEqual,
  getSizeOfStringInBytes,
} from "../../_shared/utils";
import { ProgressBar } from "../_shared/ProgressBar";
import { makeStyles } from "@material-ui/styles";
import { MenuItem, Select } from "@material-ui/core";
import TransferList from "../FiltersPage/_shared/TransferList";

const useStyles = makeStyles({
  alert: { width: "100%" },
  colorInactive: { color: "rgba(0, 0, 0, 0.54)" },
  requiredWarning: {
    color: "#f44336",
    margin: "4px 0 0 0",
    fontSize: "0.75rem",
    letterSpacing: "0.03333em",
    lineHeight: "1.66",
  },
  dialogContent: {
    scrollbarWidth: "none",
  },
  colorError: { color: "#f44336" },
  colorBlack: { color: "rgba(0, 0, 0, 0.87)" },
});

const EditPromotionCategoryForm: FC<{
  categoryId?: number;
  onCategoryEdit: (id: number, employee: Category) => Promise<any>;
  onClose: () => void;
}> = ({ categoryId, onCategoryEdit, onClose }) => {
  const classes = useStyles();
  const categoryInit = {
    id: 666666,
    name: "",
    description: "",
    products: [],
    isActive: true,
    categoryId: 0,
  };
  const [category, setCategory] = useState(categoryInit);
  const [oldCategory, setOldCategory] = useState(categoryInit);
  const [
    isNeedClearSelectedProducts,
    setIsNeedClearSelectedProducts,
  ] = useState<boolean | undefined>(undefined);
  const [categories, setCategories] = useState<
    { id: number; name: string; isActive: boolean }[]
  >([]);
  const [products, setProducts] = useState<ProductRow[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<ProductRow[]>([]);
  const [oldSelectedProducts, setOldSelectedProducts] = useState<ProductRow[]>(
    []
  );
  const [progress, setProgress] = useState(false);

  const alertContext = useContext<AlertContextType>(AlertContext);

  useEffect(() => {
    if (isNeedClearSelectedProducts) setIsNeedClearSelectedProducts(false);
  }, [isNeedClearSelectedProducts]);

  useEffect(() => {
    if (categoryId) {
      API.get(`/categories`)
        .then(({ data }: { data: Category[] }) => {
          const newCategories = data.map((el) => {
            return { id: el.id, name: el.name, isActive: el.isActive };
          });
          setCategories(newCategories);
          API.get("/products")
            .then(({ data }) => {
              setProducts(data);
              API.get(`/promotionCategories/${categoryId}`)
                .then(({ data }: { data: PromotionCategory }) => {
                  const { id, name, isActive, products } = data;
                  const newCategory = {
                    id,
                    name,
                    productIds: data.products.map((el) => el.id),
                    description: data.description ? data.description : "",
                    isActive,
                    products,
                    categoryId: newCategories
                      .filter((el) => el.isActive)
                      .map((el) => el.id)
                      .includes(data.category.id)
                      ? data.category.id
                      : 0,
                  };
                  setSelectedProducts(data.products);
                  setOldSelectedProducts(data.products);
                  // @ts-ignore
                  setCategory(newCategory);
                  // @ts-ignore
                  setOldCategory(newCategory);
                  setAlerted(false);
                })
                .catch((error) =>
                  alertError(alertContext, "Ошибка получения категории")
                );
            })
            .catch((error) =>
              alertError(alertContext, "Ошибка получения списка товаров")
            );
        })
        .catch((error) =>
          alertError(alertContext, "Ошибка получения списка категорий")
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId]);

  const [alerted, setAlerted] = useState(false);

  const requiredFieldEmpty = () =>
    category.name === "" ||
    category.categoryId === 0 ||
    selectedProducts.length === 0;

  return (
    <Dialog
      disableBackdropClick
      onBackdropClick={() =>
        progress
          ? null
          : isObjectsEqual(category, oldCategory) &&
            isObjectsEqual(oldSelectedProducts, selectedProducts)
          ? onClose()
          : setAlerted(true)
      }
      open={!!categoryId}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="lg"
    >
      <DialogTitle id="form-dialog-title">Редактирование категории</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <form>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Наименование"
            inputProps={{ maxLength: 100 }}
            value={category.name}
            onChange={(e) => setCategory({ ...category, name: e.target.value })}
            type="text"
            fullWidth
            error={!category.name}
            helperText={!category.name ? "Обязательное поле" : ""}
            required
          />
          <TextField
            margin="dense"
            id="description"
            label="Описание"
            value={category.description}
            onChange={(e) => {
              if (getSizeOfStringInBytes(e.target.value) <= 4000)
                setCategory({ ...category, description: e.target.value });
            }}
            type="text"
            fullWidth
          />
          <br />
          <br />
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={category.categoryId}
            inputProps={{ "aria-label": "Without label" }}
            error={categoryId === 0}
            defaultValue={0}
            required
            fullWidth
            className={[
              category.categoryId === 0
                ? classes.colorError
                : classes.colorBlack,
            ].join(" ")}
            onChange={(e: any) => {
              setIsNeedClearSelectedProducts(true);
              setCategory({
                ...category,
                categoryId: e.target.value,
              });
            }}
          >
            <MenuItem value={0} disabled>
              Категория *
            </MenuItem>
            {categories.map((el, key) => (
              <MenuItem value={el.id} key={key} disabled={!el.isActive}>
                {el.name}
              </MenuItem>
            ))}
          </Select>
          {category.categoryId === 0 && (
            <p className={classes.requiredWarning}>Обязательное поле</p>
          )}
          <br />
          <br />
          <p
            className={
              selectedProducts.length === 0
                ? classes.colorError
                : classes.colorInactive
            }
          >
            Товары *
          </p>
          {selectedProducts.length === 0 && (
            <p className={classes.requiredWarning}>Обязательное поле</p>
          )}
          <TransferList
            products={products.filter(
              (el) => el.categoryId === category.categoryId
            )}
            isNeedClearSelectedProducts={isNeedClearSelectedProducts}
            selectedProducts={category.products}
            getData={(data: ProductRow[]) => {
              setSelectedProducts(data);
            }}
          />
        </form>
      </DialogContent>
      <DialogActions>
        {alerted ? (
          <Alert
            className={classes.alert}
            severity="warning"
            action={
              <>
                <Button color="inherit" size="small" onClick={() => onClose()}>
                  Да
                </Button>
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => setAlerted(false)}
                >
                  Нет
                </Button>
              </>
            }
          >
            Вы действительно хотите отменить все изменения и выйти? Введенные
            данные будут утеряны
          </Alert>
        ) : (
          <>
            <Button onClick={onClose} color="primary" disabled={progress}>
              Отмена
            </Button>
            <Button
              onClick={() => {
                const {
                  name,
                  description,
                  categoryId,
                  id,
                  isActive,
                } = category;
                const categoryToUpdate = {
                  name,
                  description,
                  categoryId,
                  isActive,
                  productIds: selectedProducts.map((el) => el.id),
                };
                setProgress(true);
                // @ts-ignore
                onCategoryEdit(id ? id : 1, categoryToUpdate)
                  .then(onClose)
                  .catch((error) => console.log(error))
                  .finally(() => setProgress(false));
              }}
              color="primary"
              type="submit"
              disabled={
                requiredFieldEmpty() ||
                (isObjectsEqual(category, oldCategory) &&
                  isObjectsEqual(oldSelectedProducts, selectedProducts)) ||
                progress
              }
            >
              Подтвердить
            </Button>
          </>
        )}
      </DialogActions>
      {progress && <ProgressBar bottom />}
    </Dialog>
  );
};

export default EditPromotionCategoryForm;
