import React, { FC, useCallback, useEffect, useState } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import SidebarNav from "./SidebarNav";
import ProtectedRoute from "../ProtectedRoute";
import { AuthCookie, UserCookie } from "../_shared/types";
import cookies from "../../cookies";
import * as signalR from "@microsoft/signalr";
import { copyObject, removeDuplicates } from "../_shared/utils";
import { StatusContext } from "../_shared/StatusContext";
import API from "../_shared/axios";
import Howler from "./Howler";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import ArchiveIcon from "@material-ui/icons/Archive";
import LocalGroceryStoreIcon from "@material-ui/icons/LocalGroceryStore";
import CategoryIcon from "@material-ui/icons/Category";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import ViewQuiltIcon from "@material-ui/icons/ViewQuilt";
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";
import SyncIcon from '@material-ui/icons/Sync';
import PeopleIcon from "@material-ui/icons/People";
import AddIcCallIcon from "@material-ui/icons/AddIcCall";
import FilterListIcon from "@material-ui/icons/FilterList";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import CameraIcon from "@material-ui/icons/Camera";
import ScheduleIcon from "@material-ui/icons/Schedule";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import EventNoteIcon from "@material-ui/icons/EventNote";
import StoreIcon from "@material-ui/icons/Store";
import MapIcon from "@material-ui/icons/Map";
import SettingsIcon from "@material-ui/icons/Settings";
import OrdersPage from "./OrdersPage";
import OrdersArchivePage from "./OrdersArchivePage";
import ProductsPage from "./ProductsPage";
import CategoriesPage from "./CategoriesPage";
import IngredientsPage from "./IngredientsPage";
import OptionsPage from "./OptionsPage";
import BannersPage from "./BannersPage";
import UsersPage from "./UsersPage";
import FiltersPage from "./FiltersPage";
import PromotionsPage from "./PromotionsPage";
import PromotionCategoriesPage from "./PromotionCategoriesPage";
import PromotionSchedulePage from "./PromotionSchedulePage";
import ShopsPage from "./ShopsPage";
import StreetsPage from "./StreetsPage";
import SettingsPage from "./SettingsPage";
import { OrdersContext } from "./OrdersPage/OrdersContext";
import DispatcherPage from "./DispatcherPage";
import PromocodesPage from "./PromocodesPage";
import CashierPage from "./CashierPage";
import LogsPage from "./LogsPage";
import SyncPage from "./SyncPage";

const MainPage: FC = () => {
  const match = useRouteMatch();

  const auth: AuthCookie | undefined = cookies.get("auth");
  const user: UserCookie | undefined = cookies.get("user");
  const token = auth?.accessToken || "";

  const sidebarRoutes = [
    {
      label: "Заказы",
      path: `${match.path}/orders`,
      roleId: 3,
      icon: <AllInboxIcon />,
    },
    {
      label: "Архив заказов",
      path: `${match.path}/orders-archive`,
      roleId: 3,
      icon: <ArchiveIcon />,
    },
    {
      label: "Диспетчерская",
      path: `${match.path}/dispatcher`,
      roleId: 3,
      icon: <AddIcCallIcon />,
    },
    {
      label: "Касса",
      path: `${match.path}/cash`,
      roleId: 3,
      icon: <LocalAtmIcon />,
    },
    {
      label: "Товары",
      path: `${match.path}/products`,
      roleId: 2,
      icon: <LocalGroceryStoreIcon />,
    },
    {
      label: "Категории",
      path: `${match.path}/categories`,
      roleId: 2,
      icon: <CategoryIcon />,
    },
    {
      label: "Ингредиенты",
      path: `${match.path}/ingredients`,
      roleId: 2,
      icon: <AccountTreeIcon />,
    },
    {
      label: "Опции",
      path: `${match.path}/options`,
      roleId: 2,
      icon: <ViewQuiltIcon />,
    },
    {
      label: "Баннеры",
      path: `${match.path}/banners`,
      roleId: 2,
      icon: <ViewCarouselIcon />,
    },
    {
      label: "Пользователи",
      path: `${match.path}/users`,
      roleId: 3,
      icon: <PeopleIcon />,
    },
    {
      label: "Фильтры",
      path: `${match.path}/filters`,
      roleId: 2,
      icon: <FilterListIcon />,
    },
    {
      label: "Акции",
      path: `${match.path}/promotions`,
      roleId: 2,
      icon: <CardGiftcardIcon />,
    },
    {
      label: "Категории акций",
      path: `${match.path}/promotion-categories`,
      roleId: 2,
      icon: <CameraIcon />,
    },
    {
      label: "График работы акций",
      path: `${match.path}/promotion-schedule`,
      roleId: 2,
      icon: <ScheduleIcon />,
    },
    {
      label: "Журнал",
      path: `${match.path}/logs`,
      roleId: 2,
      icon: <EventNoteIcon />,
    },
    {
      label: "Промокоды",
      path: `${match.path}/promocodes`,
      roleId: 2,
      icon: <CardGiftcardIcon />,
    },
    {
      label: "Точки продаж",
      path: `${match.path}/shops`,
      roleId: 1,
      icon: <StoreIcon />,
    },
    {
      label: "Синхронизация",
      path: `${match.path}/sync`,
      roleId: 2,
      icon: <SyncIcon />,
    },
    {
      label: "Улицы",
      path: `${match.path}/streets`,
      roleId: 1,
      icon: <MapIcon />,
    },
    {
      label: "Настройки",
      path: `${match.path}/settings`,
      roleId: 2,
      icon: <SettingsIcon />,
    },
  ];

  const routes = [
    {
      component: OrdersPage,
      path: `${match.path}/orders`,
      roles: ["SuperAdmin", "Admin", "Operator"],
    },
    {
      component: OrdersArchivePage,
      path: `${match.path}/orders-archive`,
      roles: ["SuperAdmin", "Admin", "Operator"],
    },
    {
      component: DispatcherPage,
      path: `${match.path}/dispatcher`,
      roles: ["SuperAdmin", "Admin", "Operator"],
    },
    {
      component: CashierPage,
      path: `${match.path}/cash`,
      roles: ["SuperAdmin", "Admin", "Operator"],
    },
    {
      component: ProductsPage,
      path: `${match.path}/products`,
      roles: ["SuperAdmin", "Admin"],
    },
    {
      component: CategoriesPage,
      path: `${match.path}/categories`,
      roles: ["SuperAdmin", "Admin"],
    },
    {
      component: IngredientsPage,
      path: `${match.path}/ingredients`,
      roles: ["SuperAdmin", "Admin"],
    },
    {
      component: OptionsPage,
      path: `${match.path}/options`,
      roles: ["SuperAdmin", "Admin"],
    },
    {
      component: BannersPage,
      path: `${match.path}/banners`,
      roles: ["SuperAdmin", "Admin"],
    },
    {
      component: UsersPage,
      path: `${match.path}/users`,
      roles: ["SuperAdmin", "Admin", "Operator"],
    },
    {
      component: LogsPage,
      path: `${match.path}/logs`,
      roles: ["SuperAdmin", "Admin"],
    },
    {
      component: FiltersPage,
      path: `${match.path}/filters`,
      roles: ["SuperAdmin", "Admin"],
    },
    {
      component: PromotionsPage,
      path: `${match.path}/promotions`,
      roles: ["SuperAdmin", "Admin"],
    },
    {
      component: PromotionCategoriesPage,
      path: `${match.path}/promotion-categories`,
      roles: ["SuperAdmin", "Admin"],
    },
    {
      component: PromotionSchedulePage,
      path: `${match.path}/promotion-schedule`,
      roles: ["SuperAdmin", "Admin"],
    },
    {
      component: ShopsPage,
      path: `${match.path}/shops`,
      roles: ["SuperAdmin"],
    },
    {
      component: StreetsPage,
      path: `${match.path}/streets`,
      roles: ["SuperAdmin", "Admin"],
    },
    {
      component: SettingsPage,
      path: `${match.path}/settings`,
      roles: ["SuperAdmin", "Admin"],
    },
    {
      component: SyncPage,
      path: `${match.path}/sync`,
      roles: ["SuperAdmin", "Admin"],
    },
    {
      component: PromocodesPage,
      path: `${match.path}/promocodes`,
      roles: ["SuperAdmin", "Admin"],
    },
  ];

  const [isNeedToUpdate, setIsNeedToUpdate] = useState(false);

  const updateOrders = useCallback(() => {
    setIsNeedToUpdate(true);
  }, [setIsNeedToUpdate]);

  const cancelUpdateOrders = useCallback(() => {
    setIsNeedToUpdate(false);
  }, [setIsNeedToUpdate]);

  // SignalR
  const [isConnectedInit, setIsConnectedInit] = useState(true);
  const [employeeStatus, setEmployeesStatus] = useState<
    | {
        online: string[];
        inactive: string[];
      }
    | undefined
  >(undefined);

  const getEmployeeStatus = () =>
    employeeStatus ? employeeStatus : { online: [], inactive: [] };

  const sendOnlineStatus = () =>
    API.post(`/signalRSend`, {
      userId: user?.id,
      userName: user?.firstName,
      status: 0,
    });

  const [isNeedToSend, setIsNeedToSend] = useState(false);

  useEffect(() => {
    API.get(`${process.env.REACT_APP_BASE_PATH}signalRStatus`)
      .then(({ data }) => {
        // console.log('data from mainpage');
        // console.log(data);
        setEmployeesStatus({
          online: data.online.map(
            (el: { employeeId: number }) => el.employeeId
          ),
          inactive: data.inactive.map(
            (el: { employeeId: number }) => el.employeeId
          ),
        });
      })
      .catch((error) => {
        setEmployeesStatus({ online: [], inactive: [] });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isNeedToSend) {
      sendOnlineStatus();
      const id = setTimeout(() => {
        clearTimeout(id);
        setIsNeedToSend(false);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNeedToSend]);

  useEffect(() => {
    const id = setInterval(() => {
      const myListener = function () {
        setIsNeedToSend(true);
        document.removeEventListener("mousemove", myListener, false);
      };
      document.addEventListener("mousemove", myListener, false);
    }, 30000);
    if (employeeStatus !== undefined && isConnectedInit) {
      setIsConnectedInit(false);
      const connection = new signalR.HubConnectionBuilder()
        .withUrl(`/statusHub`, {
          accessTokenFactory: () => token,
        })
        .configureLogging(signalR.LogLevel.Debug)
        .build();

      connection
        .start()
        .then(() => console.log("SignalR connected"))
        .catch((error) => console.error(error.toString()));
      connection.on("Receive", (response) => {
        // console.log('NEW RECEIVE');
        // console.log(response);
        // console.log('сейчас списки:');
        // console.log(employeeStatus);
        if (response.status === 0) {
          setEmployeesStatus((employeeStatus) => {
            if (employeeStatus) {
              let newEmployeesStatus = copyObject(employeeStatus);
              newEmployeesStatus.online = removeDuplicates([
                ...newEmployeesStatus.online,
                response.userId,
              ]);
              newEmployeesStatus.inactive = newEmployeesStatus.inactive.filter(
                (el: string) => el !== response.userId
              );
              // console.log('новые списки:');
              // console.log(newEmployeesStatus);
              return newEmployeesStatus;
            } else {
              return employeeStatus;
            }
          });
        } else if (response.status === 2) {
          setEmployeesStatus((employeeStatus) => {
            if (employeeStatus) {
              let newEmployeesStatus = copyObject(employeeStatus);
              newEmployeesStatus.inactive = removeDuplicates([
                ...newEmployeesStatus.inactive,
                response.userId,
              ]);
              newEmployeesStatus.online = newEmployeesStatus.online.filter(
                (el: string) => el !== response.userId
              );
              // console.log('новые списки:');
              // console.log(newEmployeesStatus);
              return newEmployeesStatus;
            } else {
              return employeeStatus;
            }
          });
        } else {
          setEmployeesStatus((employeeStatus) => {
            if (employeeStatus) {
              let newEmployeesStatus = copyObject(employeeStatus);
              newEmployeesStatus.online = newEmployeesStatus.online.filter(
                (el: string) => el !== response.userId
              );
              newEmployeesStatus.inactive = newEmployeesStatus.inactive.filter(
                (el: string) => el !== response.userId
              );
              // console.log('новые списки:');
              // console.log(newEmployeesStatus);
              return newEmployeesStatus;
            } else {
              return employeeStatus;
            }
          });
        }
      });
    }
    return () => clearInterval(id);
  }, [employeeStatus, isConnectedInit, token]);

  const routesMemoed = useCallback(() => {
    return routes.map((rdata, key) => <ProtectedRoute {...rdata} key={key} />);
  }, [routes]);

  return (
    <SidebarNav routes={sidebarRoutes}>
      <Howler updateOrders={updateOrders} />
      <StatusContext.Provider value={{ employeeStatus, getEmployeeStatus }}>
        <OrdersContext.Provider value={{ isNeedToUpdate, cancelUpdateOrders }}>
          <Switch>
            {routesMemoed()}
            <Route
              path={`${match.path}`}
              exact
              render={() => <Redirect to={`${match.path}/orders`} />}
            />
            <Route render={() => <Redirect to={`${match.path}`} />} />
          </Switch>
        </OrdersContext.Provider>
      </StatusContext.Provider>
    </SidebarNav>
  );
};

export default React.memo(MainPage);
