import React, { FC, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useHistory } from "react-router";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    borderRadius: 0,
  },
});

const CenteredTabs: FC<{
  tabs: {
    label: string;
    route: string;
  }[];
}> = ({ tabs }) => {
  const history = useHistory();
  const classes = useStyles();

  const [value, setValue] = useState(0);

  useEffect(() => {
    setValue(history.location.pathname.includes("employees") ? 0 : 1);
  }, [history]);

  return (
    <Paper className={classes.root}>
      <Tabs
        value={value}
        onChange={(event: any, newValue: any) => setValue(newValue)}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        {tabs.map((tab, key) => (
          <Tab
            label={tab.label}
            key={key}
            onClick={() => history.push(tab.route)}
          />
        ))}
      </Tabs>
    </Paper>
  );
};

export default CenteredTabs;
