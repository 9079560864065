import React, { FC, useState } from "react";
import { LabelRibbonData, PromotionCategoryRibbon } from "../../_shared/types";
import AddIcon from "@material-ui/icons/Add";
import { IconButton } from "@material-ui/core";
import CheckboxList from "./CheckboxList";
import { makeStyles } from "@material-ui/styles";
import { KladrObjectRibbon } from "../StreetsPage/_shared/types";

const useStyles = makeStyles({
  page: {
    width: "100%",
  },
  header: {
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "5px 0 10px",
  },
});

const LabelRibbon: FC<{
  title: string;
  disabled?: boolean;
  onConfirm: (
    data:
      | {
          id: number;
          name: string;
          isChecked: boolean;
          isActive: boolean;
          categories: [];
          impactOnPrice: boolean;
          optionVariants: {
            id: number;
            value: string;
            price?: string | number;
            weight?: string | number;
            isChecked?: boolean;
          }[];
        }[]
      | LabelRibbonData[]
      | PromotionCategoryRibbon[]
      | KladrObjectRibbon[]
  ) => void;
  data:
    | {
        id: number;
        name: string;
        isChecked: boolean;
        isActive: boolean;
        categories: [];
        impactOnPrice: boolean;
        optionVariants: {
          id: number;
          value: string;
          price?: string | number;
          weight?: string | number;
          isChecked?: boolean;
        }[];
      }[]
    | LabelRibbonData[]
    | PromotionCategoryRibbon[]
    | KladrObjectRibbon[];
  children?: React.ReactNode;
  withCounter?: boolean;
}> = ({ children, data, title, onConfirm, withCounter, disabled }) => {
  const [open, setOpen] = useState(false);
  // @ts-ignore
  const isAllChecked = () => data.filter((x) => !x.isChecked).length === 0;

  const classes = useStyles();

  return (
    <div className={classes.page}>
      <CheckboxList
        withCounter={withCounter}
        title={title}
        data={data}
        onConfirm={onConfirm}
        isOpen={open}
        onClose={() => setOpen(false)}
      />
      <div className={classes.header}>
        {title}
        <IconButton
          aria-label="add"
          color={"primary"}
          disabled={isAllChecked() || disabled}
          onClick={() => setOpen(true)}
        >
          <AddIcon />
        </IconButton>
      </div>
      {children}
    </div>
  );
};

export default LabelRibbon;
