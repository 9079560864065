import React, { FC } from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import TextField from "@material-ui/core/TextField";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      height: "38px",
      backgroundColor: theme.palette.primary.main,
      color: "white",
      borderRadius: "50px",
      marginRight: "10px",
      marginBottom: "10px",
      padding: "8px 18px",
      fontSize: "0.875rem",
      fontWeight: "bold",
      boxShadow:
        "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    },
    input: { "& input": { textAlign: "center" } },
    change: {
      color: "white",
    },
    span: {
      paddingLeft: "2px",
      paddingRight: "2px",
    },
  })
);

const CounterButton: FC<{
  add: () => void;
  remove: () => void;
  amount: number;
  name: string;
  max: number;
}> = ({ add, remove, amount, name, max }) => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <span className={classes.span}>{name}</span>
      <IconButton
        size={"small"}
        className={classes.change}
        onClick={remove}
        disabled={amount === 0}
      >
        <RemoveCircleOutlineIcon fontSize="small" />
      </IconButton>
      <span className={classes.span}>{amount}</span>
      <IconButton
        size={"small"}
        className={classes.change}
        onClick={amount === max ? void 0 : add}
      >
        <AddCircleOutlineIcon fontSize="small" />
      </IconButton>
    </div>
  );
};

export default CounterButton;
