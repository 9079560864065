import React, { FC, useContext, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Alert } from "@material-ui/lab";
import { AlertContextType, Category, OptionTypes } from "../../_shared/types";
import { alertError, getErrorMsg, isObjectsEqual } from "../../_shared/utils";
import API from "../../_shared/axios";
import { AlertContext } from "../_shared/ToastList";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { ProgressBar } from "../_shared/ProgressBar";

const useStyles = makeStyles({
  formControl: {
    minWidth: 120,
  },
  mt15: { marginTop: 15 },
  variantDeleteBtn: { cursor: "pointer", fontSize: 16, marginLeft: 5 },
  variantDescription: {
    fontSize: 16,
    alignSelf: "flex-start",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  optionVariantValue: {
    fontSize: 16,
    alignSelf: "flex-start",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  variantAddBtn: {
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: "auto",
    alignSelf: "flex-end",
  },
  pb32: { paddingBottom: 32 },
  pb8: { paddingBottom: 8 },
  variantDescriptionP24: { width: "60%", paddingBottom: 24, marginRight: "4%" },
  variantDescriptionP0: { width: "60%", paddingBottom: 0, marginRight: "4%" },
  variantValue: { width: "20%", minWidth: 180, marginRight: "4%" },
  mb10: { marginBottom: 10 },
  variantWrapper: { display: "flex", alignItems: "center", marginBottom: 15 },
  requiredWarning: {
    color: "#f44336",
    margin: "4px 0 0 0",
    fontSize: "0.75rem",
    letterSpacing: "0.03333em",
    lineHeight: "1.66",
  },
  mt20: { marginTop: 20 },
  colorError: { color: "#f44336" },
  colorBlack: { color: "rgba(0, 0, 0, 0.87)" },
  wrapper: {
    display: "inline",
  },
});

const NewOptionForm: FC<{
  onOptionCreate: (option: any) => Promise<any>;
}> = ({ onOptionCreate }) => {
  const [open, setOpen] = React.useState(false);

  const alertContext = useContext<AlertContextType>(AlertContext);

  const optionInit: {
    type: number;
    value: string;
    impactOnPrice: boolean;
    isActive: boolean;
    categoryIds: number[];
    optionVariants: { id: 1; value: string; description: string }[];
  } = {
    type: 0,
    value: "",
    impactOnPrice: false,
    categoryIds: [0],
    optionVariants: [],
    isActive: true,
  };
  const [option, setOption] = useState(optionInit);

  const [newVariantValue, setNewVariantValue] = useState("");
  const [categories, setCategories] = useState<
    { id: number; name: string; isActive: boolean }[]
  >([]);
  const [newVariantDescription, setNewVariantDescription] = useState("");
  const [progress, setProgress] = useState(false);

  const [types, setTypes] = useState<OptionTypes[]>([]);

  useEffect(() => {
    if (open)
      API.get(`/categories`)
        .then(({ data }: { data: Category[] }) => {
          setCategories(
            data.map((el) => {
              return { id: el.id, name: el.name, isActive: el.isActive };
            })
          );
          API.get(`/options/types`)
            .then(({ data }) => {
              setTypes(data);
            })
            .catch((error) =>
              alertError(
                alertContext,
                getErrorMsg(error.response, "Ошибка получения типов опций")
              )
            );
        })
        .catch((error) =>
          alertError(
            alertContext,
            getErrorMsg(error.response, "Ошибка получения списка категорий")
          )
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const isBlurredInit = {
    value: false,
    type: false,
    impactOnPrice: false,
    categoryIds: false,
    optionVariants: false,
  };
  const [isBlurred, setIsBlurred] = useState(isBlurredInit);

  const handleClickOpen = () => {
    setIsBlurred(isBlurredInit);
    setOpen(true);
    setAlerted(false);
    setOption(optionInit);
    setNewVariantValue("");
    setNewVariantDescription("");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const setBlurredField = (field: string) => {
    setIsBlurred({ ...isBlurred, [field]: true });
  };

  const classes = useStyles();

  const [alerted, setAlerted] = useState(false);

  return (
    <div className={classes.wrapper}>
      <h2>
        Опции &nbsp; &nbsp;
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          Добавить опцию
        </Button>
      </h2>
      <Dialog
        disableBackdropClick
        onBackdropClick={() =>
          progress
            ? null
            : isObjectsEqual(option, optionInit) &&
              newVariantValue === "" &&
              newVariantDescription === ""
            ? setOpen(false)
            : setAlerted(true)
        }
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle id="form-dialog-title">Новая опция</DialogTitle>
        <DialogContent>
          <form>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Наименование"
              inputProps={{ maxLength: 100 }}
              value={option.value}
              onChange={(e) => setOption({ ...option, value: e.target.value })}
              type="text"
              fullWidth
              onBlur={() => setBlurredField("value")}
              error={!option.value && isBlurred.value}
              helperText={
                !option.value && isBlurred.value ? "Обязательное поле" : ""
              }
              required
            />
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={option.type}
              inputProps={{ "aria-label": "Without label" }}
              displayEmpty
              onBlur={() => setBlurredField("type")}
              error={option.type === 0 && isBlurred.type}
              defaultValue={option.type}
              required
              fullWidth
              className={[
                classes.mt20,
                option.type === 0 && isBlurred.type
                  ? classes.colorError
                  : classes.colorBlack,
              ].join(" ")}
              onChange={(e: any) => {
                setOption({ ...option, type: e.target.value });
              }}
            >
              <MenuItem value={0} disabled>
                Стиль *
              </MenuItem>
              {types.map((type, index) => (
                <MenuItem key={index} value={index + 1}>
                  {type.description}
                </MenuItem>
              ))}
            </Select>
            {option.type === 0 && isBlurred.type && (
              <p className={classes.requiredWarning}>Обязательное поле</p>
            )}
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={option.categoryIds}
              inputProps={{ "aria-label": "Without label" }}
              onBlur={() => setBlurredField("categoryIds")}
              error={option.categoryIds[0] === 0 && isBlurred.categoryIds}
              defaultValue={option.type}
              required
              multiple
              MenuProps={{ variant: "menu" }}
              fullWidth
              className={[
                classes.mt20,
                option.categoryIds[0] === 0 && isBlurred.categoryIds
                  ? classes.colorError
                  : classes.colorBlack,
              ].join(" ")}
              onChange={(e: any) => {
                const newIds = e.target.value.filter((el: number) => el !== 0);
                setOption({
                  ...option,
                  categoryIds: newIds.length === 0 ? [0] : newIds,
                });
              }}
            >
              <MenuItem value={0} disabled>
                Категории *
              </MenuItem>
              {categories.map((el) => (
                <MenuItem value={el.id} disabled={!el.isActive} key={el.id}>
                  {el.name}
                </MenuItem>
              ))}
            </Select>
            {option.categoryIds[0] === 0 && isBlurred.categoryIds && (
              <p className={classes.requiredWarning}>Обязательное поле</p>
            )}
            <div className={classes.variantWrapper}>
              <TextField
                margin="dense"
                id="variantValue"
                label="Значение варианта"
                multiline
                value={newVariantValue}
                onChange={(e) => setNewVariantValue(e.target.value)}
                type="text"
                className={classes.variantValue}
                onBlur={() => setBlurredField("optionVariants")}
                error={
                  option.optionVariants.length === 0 &&
                  isBlurred.optionVariants &&
                  !newVariantValue
                }
                helperText={
                  option.optionVariants.length === 0 &&
                  isBlurred.optionVariants &&
                  !newVariantValue
                    ? "Обязательное поле"
                    : ""
                }
                required={option.optionVariants.length === 0}
              />
              <TextField
                margin="dense"
                id="variantDescription"
                label="Описание варианта"
                multiline
                className={
                  option.optionVariants.length === 0 &&
                  isBlurred.optionVariants &&
                  !newVariantValue
                    ? classes.variantDescriptionP24
                    : classes.variantDescriptionP0
                }
                value={newVariantDescription}
                onChange={(e) => setNewVariantDescription(e.target.value)}
                type="text"
              />
              <Button
                className={[
                  classes.variantAddBtn,
                  option.optionVariants.length === 0 &&
                  isBlurred.optionVariants &&
                  !newVariantValue
                    ? classes.pb32
                    : classes.pb8,
                ].join(" ")}
                onClick={() => {
                  let newVariants = option.optionVariants;
                  newVariants.push({
                    id: 1,
                    value: newVariantValue,
                    description: newVariantDescription,
                  });
                  setOption({
                    ...option,
                    optionVariants: newVariants,
                  });
                  setNewVariantValue("");
                  setNewVariantDescription("");
                }}
                color="primary"
                disabled={
                  newVariantValue === "" ||
                  option.optionVariants
                    .map((el) => el.value.toLowerCase())
                    .includes(newVariantValue.toLocaleLowerCase())
                }
              >
                Добавить
              </Button>
            </div>
            {option.optionVariants.map((variant) => (
              <div className={[classes.mb10, classes.variantWrapper].join(" ")}>
                <span className={classes.optionVariantValue}>
                  {variant.value}
                </span>
                {variant.description && (
                  <span className={classes.variantDescription}>
                    :&nbsp;{`${variant.description}`}
                  </span>
                )}
                <HighlightOffIcon
                  onClick={() =>
                    setOption({
                      ...option,
                      optionVariants: option.optionVariants.filter(
                        (el) => el.value !== variant.value
                      ),
                    })
                  }
                  className={classes.variantDeleteBtn}
                />
                <br />
              </div>
            ))}
            <FormControl className={classes.mt15}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={option.impactOnPrice}
                    defaultChecked={false}
                    onChange={() =>
                      setOption({
                        ...option,
                        impactOnPrice: !option.impactOnPrice,
                      })
                    }
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Влияет на ценообразование"
              />
            </FormControl>
            <FormControl className={classes.mt15}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={option.isActive}
                    defaultChecked={true}
                    onChange={() =>
                      setOption({
                        ...option,
                        isActive: !option.isActive,
                      })
                    }
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Опция доступна"
              />
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          {alerted ? (
            <Alert
              severity="warning"
              action={
                <>
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => setOpen(false)}
                  >
                    Да
                  </Button>
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => setAlerted(false)}
                  >
                    Нет
                  </Button>
                </>
              }
            >
              Вы действительно хотите отменить все изменения и выйти? Введенные
              данные будут утеряны
            </Alert>
          ) : (
            <>
              <Button onClick={handleClose} color="primary" disabled={progress}>
                Отмена
              </Button>
              <Button
                onClick={() => {
                  setProgress(true);
                  onOptionCreate({ ...option, type: option.type - 1 })
                    .then((data) => setOpen(false))
                    .catch((error) => console.log(error))
                    .finally(() => setProgress(false));
                }}
                color="primary"
                type="submit"
                disabled={
                  option.value === "" ||
                  option.type === 0 ||
                  option.categoryIds[0] === 0 ||
                  option.optionVariants.length === 0 ||
                  progress
                }
              >
                Создать
              </Button>
            </>
          )}
        </DialogActions>
        {progress && <ProgressBar bottom />}
      </Dialog>
    </div>
  );
};

export default NewOptionForm;
