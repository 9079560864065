import { LinearProgress } from "@material-ui/core";
import React, { FC } from "react";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  progressBar: {
    position: "absolute",
    bottom: "0px",
    width: "100%",
  },
});

export const ProgressBar: FC<{ bottom?: boolean }> = ({ bottom }) => {
  const classes = useStyles();
  return <LinearProgress className={bottom ? classes.progressBar : ""} />;
};
