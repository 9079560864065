import React, { FC, useContext, useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";

import {
  FormControl,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import API from "../../_shared/axios";
import moment from "moment";
import { useHistory } from "react-router";
import { alertError, getIntFromString } from "../../_shared/utils";
import { DateTimePicker } from "@material-ui/pickers";
import { Moment } from "moment/moment";
import { AlertContext } from "../_shared/ToastList";
import LinkIcon from "@material-ui/icons/Link";
import ClearIcon from "@material-ui/icons/Clear";

interface iCustomerEventItem {
  id: number;
  orderId: number;
  timestamp: string;
  type: number;
  msg: string;
  customerId: number;
  customerPhoneNumber: string;
}

interface iDataInfo {
  pageIndex: number;
  totalPages: number;
  totalItems: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  items: iCustomerEventItem[];
}

const useStyles = makeStyles({
  page: {
    height: "calc(100% - 64px)",
  },
  paginationPaper: { marginTop: 20 },
  count: {
    fontSize: 16,
    fontWeight: "bold",
  },
  header: {
    marginBottom: 20,
    padding: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 16,
  },
  filterInput: {
    margin: "0px 20px 20px",
    width: "230px",
  },
  cell: {
    minWidth: 155,
    "&:nth-child(3)": {
      minWidth: 185,
    },
    "&:nth-child(4), &:nth-child(5)": {
      minWidth: 80,
    },
    "&:first-child": {
      minWidth: 140,
    },
    "&:last-child": {
      minWidth: 150,
    },
  },
  web: {
    color: "#07B4D5",
  },
  android: {
    color: "#96C03B",
  },
  ios: {
    color: "#FC7A3E",
  },
  content: {
    width: "95%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "20px 30px",
    margin: "0 auto 20px auto",
    "& .MuiTableCell-body": {
      cursor: "pointer",
    },
  },
  tablePaper: {
    scrollbarWidth: "none",
    flexGrow: 1,
    overflow: "scroll",
  },
  minWidth: {
    minWidth: 130,
  },
  label: {
    cursor: "pointer",
    marginLeft: 15,
    minHeight: 32,
    borderRadius: 16,
    display: "inline-flex",
    padding: "7px 12px",
    fontSize: 14,
    fontWeight: 500,
    alignItems: "center",
  },
  labelDisabled: { backgroundColor: "#ccc" },
  labelCreated: { backgroundColor: "#90caf9" },
  labelConfirmed: { backgroundColor: "#a5d6a7" },
  labelProcess: { backgroundColor: "#ffab91" },
  tableRow: {
    "&:hover": {
      background: "#f7f7f7",
    },
  },
});

const types: { [key: number]: string } = {
  0: "Изменение статуса заказа",
  1: "Создание заказа",
  2: "Изменение заказа",
  3: "Изменение статуса оплаты",
  4: "Списание тикетов",
  5: "Начисление тикетов",
  6: "Удаление заказа",
};

const columns = [
  { id: "orderId", label: "Номер заказа" },
  { id: "timestamp", label: "Время" },
  { id: "type", label: "Тип" },
  { id: "msg", label: "Сообщение" },
  { id: "customerPhoneNumber", label: "Телефон клиента" },
];
const LogsPage: FC<{ match: { path: string } }> = ({ match }) => {
  const classes = useStyles();
  const history = useHistory();
  const alertContext = useContext(AlertContext);

  const [logList, setLogList] = useState<iCustomerEventItem[]>([]);
  const [paymentId, setPaymentId] = useState<string>("");
  const [dataInfo, setDataInfo] = useState<iDataInfo>();
  const [phone, setPhone] = useState<string>("");
  const [orderId, setOrderId] = useState<any>("");
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [typeOfLog, setTypeOfLog] = useState<string | Number>("");
  const [page, setPage] = useState(0);
  const [selectorString, setSelectorString] = useState("");
  const [dateFrom, setDateFrom] = useState<Moment | null>(null);
  const [dateTill, setDateTill] = useState<Moment | null>(null);

  useEffect(() => {
    let paramsNumber = 0;
    let queryString = "";
    if (phone) {
      queryString =
        queryString +
        `&FilterParams[${paramsNumber}].ColumnName=customerPhoneNumber&FilterParams[${paramsNumber}].FilterOption=3&FilterParams[${paramsNumber}].filterValue=${phone}`;
      paramsNumber++;
    }
    if (typeOfLog) {
      queryString =
        queryString +
        `&FilterParams[${paramsNumber}].ColumnName=type&FilterParams[${paramsNumber}].FilterOption=11&FilterParams[${paramsNumber}].filterValue=${typeOfLog}`;
      paramsNumber++;
    }
    if (orderId) {
      queryString =
        queryString +
        `&FilterParams[${paramsNumber}].ColumnName=orderId&FilterParams[${paramsNumber}].FilterOption=11&FilterParams[${paramsNumber}].filterValue=${orderId}`;
      paramsNumber++;
    }
    if (paymentId) {
      queryString =
        queryString +
        `&FilterParams[${paramsNumber}].ColumnName=msg&FilterParams[${paramsNumber}].FilterOption=3&FilterParams[${paramsNumber}].filterValue=Статус оплаты №${paymentId}`;
    }
    if (dateFrom) {
      queryString += `&FilterParams[${paramsNumber}].ColumnName=filterTimestamp&FilterParams[${paramsNumber}].FilterOption=8&FilterParams[${paramsNumber}].filterValue=${dateFrom.format(
        "DD.MM.YYYY HH:mm:ss"
      )}`;
      paramsNumber++;
    }
    if (dateTill) {
      queryString += `&FilterParams[${paramsNumber}].ColumnName=filterTimestamp&FilterParams[${paramsNumber}].FilterOption=10&FilterParams[${paramsNumber}].filterValue=${dateTill.format(
        "DD.MM.YYYY HH:mm:ss"
      )}`;
      paramsNumber++;
    }
    API.get(
      `/customerEvents?PageNumber=${
        page + 1
      }&PageSize=${rowsPerPage}${queryString}`
    )
      .then((data) => {
        setLogList(data.data.items);
        setDataInfo(data.data);
      })
      .catch((e) => {
        alertError(alertContext, "Не удалось выполнить запрос");
      });
  }, [
    page,
    rowsPerPage,
    phone,
    typeOfLog,
    orderId,
    paymentId,
    dateFrom,
    dateTill,
  ]);

  return (
    <div className={classes.page}>
      <div className={classes.content}>
        <h2 style={{ marginTop: 0 }}>Логи</h2>
        <Paper elevation={2} className={classes.header}>
          <div>
            <TextField
              className={classes.filterInput}
              size={"small"}
              id={"servicePhone"}
              label={"Клиент"}
              name={"servicePhone"}
              margin="normal"
              inputProps={{ maxLength: 11 }}
              type="text"
              value={phone}
              onChange={(e) => {
                if (e.target.value === " ") return null;
                const v = String(getIntFromString(e.target.value));
                setPhone(v === "0" ? "" : v);
                setPage(0);
              }}
            />
            <TextField
              className={classes.filterInput}
              size={"small"}
              id={"paymentId"}
              label={"Номер оплаты"}
              name={"paymentId"}
              margin="normal"
              inputProps={{ maxLength: 36 }}
              type="text"
              value={paymentId}
              // onInput={(e)=>setPaymentId((state)=>{
              //   return Number(e?.target?) ? e?.target?.value : state
              // })}
              onChange={(e) => {
                if (e.target.value === " ") return null;
                setPaymentId(e.target.value);
                setPage(0);
              }}
            />
            <TextField
              className={classes.filterInput}
              label="№ заказа"
              size={"small"}
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              type="text"
              value={orderId}
              onChange={(e) => {
                if (e.target.value === " ") return null;
                const v = String(getIntFromString(e.target.value));
                setOrderId(v === "0" ? "" : v);
                setPage(0);
              }}
            />
            <TextField
              className={classes.filterInput}
              size={"small"}
              value={selectorString}
              onChange={(e) => {
                setSelectorString(e.target.value);
                setTypeOfLog(String(e.target.value));
                setPage(0);
              }}
              select
              label="Статус"
            >
              <MenuItem value="">
                <em>Любой</em>
              </MenuItem>
              {Object.keys(types).map((item: number | string) => {
                return <MenuItem value={item}>{types[Number(item)]}</MenuItem>;
              })}
            </TextField>
            <DateTimePicker
              className={classes.filterInput}
              ampm={false}
              variant="inline"
              format="DD.MM HH:mm"
              margin="dense"
              id="date-picker-inline"
              label="От"
              value={dateFrom}
              onChange={(d) => {
                setDateFrom(d);
                setPage(0);
              }}
              InputProps={{
                endAdornment: (
                  <IconButton size={"small"} onClick={() => setDateFrom(null)}>
                    <ClearIcon />
                  </IconButton>
                ),
              }}
            />
            <DateTimePicker
              className={classes.filterInput}
              ampm={false}
              variant="inline"
              format="DD.MM HH:mm"
              margin="dense"
              id="date-picker-inline"
              label="До"
              value={dateTill}
              onChange={(d) => {
                setDateTill(d);
                setPage(0);
              }}
              InputProps={{
                endAdornment: (
                  <IconButton size={"small"} onClick={() => setDateTill(null)}>
                    <ClearIcon />
                  </IconButton>
                ),
              }}
            />
          </div>
        </Paper>
        <Paper className={classes.tablePaper}>
          <TableContainer>
            <Table aria-label="simple table" size={"small"}>
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell
                      key={column.id}
                      className={classes.cell}
                      align={index === columns.length - 1 ? "center" : "left"}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {logList.length > 0 ? (
                <TableBody>
                  {logList.map((item: iCustomerEventItem) => {
                    return (
                      <TableRow key={item.id} className={classes.tableRow}>
                        <TableCell>
                          <Link
                            onClick={() =>
                              history.push(
                                `/main/dispatcher/order-form/${item.orderId}`
                              )
                            }
                          >
                            {item.orderId}
                          </Link>
                        </TableCell>
                        <TableCell>
                          {moment(item.timestamp).format("DD.MM.YYYY HH:mm")}
                        </TableCell>
                        <TableCell>{types[Number(item.type)]}</TableCell>
                        <TableCell>{item.msg}</TableCell>
                        <TableCell>{item.customerPhoneNumber}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <></>
              )}
            </Table>
          </TableContainer>
        </Paper>
        <Paper className={classes.paginationPaper}>
          <TablePagination
            labelRowsPerPage={"Заказов на странице:"}
            labelDisplayedRows={({ from, to }) =>
              `${from}-${to} из ${dataInfo?.totalItems || 0}`
            }
            rowsPerPageOptions={[15, 30, 60, 100]}
            component="div"
            count={dataInfo?.totalItems || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={(e, newPage) => setPage(newPage ? newPage : 0)}
            onChangeRowsPerPage={(e) => {
              setRowsPerPage(Number(e.target.value));
              setPage(0);
            }}
          />
        </Paper>
      </div>
    </div>
  );
};

export default LogsPage;
