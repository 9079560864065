import React, { FC, useContext, useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  AlertContextType,
  PromotionRow,
  PromotionCreate,
} from "../../_shared/types";
import useStyles from "../_shared/styles";
import { TableBody, FormControlLabel, Checkbox } from "@material-ui/core";
import { AlertContext } from "../_shared/ToastList";
import API from "../../_shared/axios";
import { alertError, alertSuccess, getImageUrl } from "../../_shared/utils";
import { makeStyles } from "@material-ui/styles";
import { ProgressBar } from "../_shared/ProgressBar";
import NewPromotionForm from "./NewPromotionForm";
import EditPromotionForm from "./EditPromotionForm";

const columns = [
  { id: "image", label: "" },
  { id: "name", label: "Наименование" },
  {
    id: "description",
    label: "Описание",
  },
  {
    id: "openByClick",
    label: "Переход по клику",
  },
];

const styles = makeStyles({
  page: {
    height: "calc(100% - 64px)",
  },
  image: { maxWidth: 100 },
  minWidth: { minWidth: 170, "&:first-child": { minWidth: 100 } },
  content: {
    padding: 50,
    maxWidth: 1140,
    width: "100%",
    margin: "0 auto 20px auto",
    "& .MuiTableCell-body": {
      cursor: "pointer",
    },
  },
});

const PromotionsPage: FC = () => {
  const [editPromotionId, setEditPromotionId] = useState<number | undefined>(
    undefined
  );
  const commonClasses = useStyles();
  const classes = styles();
  const [rows, setRows] = useState<PromotionRow[]>([]);
  const [progress, setProgress] = useState(false);

  const alertContext = useContext<AlertContextType>(AlertContext);

  useEffect(() => {
    setProgress(true);
    API.get(`/promotions`)
      .then(({ data }) => {
        setProgress(false);
        setRows(data);
      })
      .catch((error) => {
        alertError(alertContext, "Ошибка получения списка акций");
        setProgress(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPromotionCreate = (data: PromotionCreate) =>
    new Promise((resolve, reject) =>
      API.post("/promotions", data)
        .then(({ data }) => {
          alertSuccess(alertContext, "Акция добавлена");
          API.get(`/promotions`)
            .then(({ data }) => {
              setRows(data);
              resolve(data);
            })
            .catch((error) => {
              alertError(alertContext, "Ошибка обновления списка акций");
              reject(error);
            });
        })
        .catch((error) => {
          alertError(alertContext, "Ошибка добавления акции");
          reject(error);
        })
    );

  const onPromotionEdit = (id: number, promotion: PromotionCreate) =>
    new Promise((resolve, reject) =>
      API.patch(`/promotions/${id}`, promotion)
        .then(({ data }) => {
          API.get(`/promotions`)
            .then(({ data }) => {
              setRows(data);
              alertSuccess(alertContext, "Успешное редактирование акции");
              resolve(data);
            })
            .catch((error) => {
              alertError(alertContext, "Ошибка обновления списка акций");
              reject(error);
            });
        })
        .catch((error) => {
          alertError(alertContext, "Ошибка редактирования акции");
          reject(error);
        })
    );

  return (
    <div className={classes.page}>
      <EditPromotionForm
        onPromotionEdit={onPromotionEdit}
        promotionId={editPromotionId}
        onClose={() => setEditPromotionId(undefined)}
      />
      <div className={classes.content}>
        <NewPromotionForm onPromotionCreate={onPromotionCreate} />
        {progress ? (
          <ProgressBar />
        ) : (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} className={classes.minWidth}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, key) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={key}
                      className={
                        !row.isActive ? commonClasses.blocked : undefined
                      }
                      onClick={() => setEditPromotionId(row.id)}
                    >
                      <TableCell>
                        <img
                          src={
                            row.mainImgName
                              ? getImageUrl(row.mainImgName, "promotions")
                              : row.thumbnail
                              ? getImageUrl(row.thumbnail, "promotions")
                              : ""
                          }
                          alt={"Product"}
                          className={[classes.minWidth, classes.image].join(
                            " "
                          )}
                        />
                      </TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.description}</TableCell>
                      <TableCell>
                        <FormControlLabel
                          disabled
                          checked={row.goByClick}
                          control={<Checkbox name="checkedD" />}
                          label=""
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </div>
  );
};

export default PromotionsPage;
