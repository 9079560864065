import React, { FC, useState } from "react";
import {
  DispatcherProduct,
  OrderCommonItem,
  OrderCommonPromotion,
  ProductRow,
} from "../../../../_shared/types";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import styles from "../index.module.scss";
import { getIngredientsLabel } from "../../../../_shared/utils";
import { PromotionState } from "../index";
import moment from "moment/moment";

export type OrderHistory = {
  items: Array<{
    name: string;
    options: { [key: number]: Array<string> };
    productId: number;
    promotionCategoryId: number;
    quantity: number;
    totalPrice: number;
  }>;
  name: string;
  promotionId: number;
  quantity: number;
  totalCost: number;
};

export type ArchivedOrder = {
  id: number;
  createdAt: string;
  totalCost: number;
  status: string;
  prods: Array<OrderCommonItem>;
  promos: Array<OrderCommonPromotion>;
};

const OrderHistory: FC<{
  orders: Array<ArchivedOrder>;
  addProd: (product: OrderCommonItem) => void;
  addPromo: (promo: OrderCommonPromotion) => void;
  copyOrder: (order: ArchivedOrder) => void;
  commonProducts: Array<ProductRow>;
}> = ({ orders, addProd, addPromo, copyOrder, commonProducts }) => {
  const [openedRow, setOpenedRow] = useState<null | number>(null);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="caption table" size="small">
        <TableHead>
          <TableRow>
            <TableCell>№</TableCell>
            <TableCell>Дата</TableCell>
            <TableCell>Стоимость</TableCell>
            <TableCell>Статус</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((order, index) => (
            <React.Fragment key={order.id}>
              <TableRow
                onClick={() => setOpenedRow(openedRow === index ? null : index)}
                hover
                className={styles.pointer}
              >
                <TableCell>{order.id}</TableCell>
                <TableCell>
                  {moment(order.createdAt).format("DD MMM hh:mm")}
                </TableCell>
                <TableCell>{order.totalCost}</TableCell>
                <TableCell>{order.status}</TableCell>
                <TableCell>
                  <Button
                    variant={"contained"}
                    color={"primary"}
                    size={"small"}
                    onClick={() => copyOrder(order)}
                  >
                    Копировать
                  </Button>
                </TableCell>
              </TableRow>
              <TableRow>
                {index === openedRow && (
                  <TableCell colSpan={5} style={{ backgroundColor: "#efefef" }}>
                    {order.promos.length ? (
                      <Table aria-label="caption table" size="small">
                        <TableHead>
                          <Typography
                            style={{ marginTop: "15px" }}
                            variant={"body1"}
                          >
                            Акции
                          </Typography>
                          <TableRow>
                            <TableCell>№</TableCell>
                            <TableCell>Наименование</TableCell>
                            <TableCell>Количество</TableCell>
                            <TableCell>К оплате</TableCell>
                            <TableCell />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {order.promos.map((row, index) => (
                            <React.Fragment key={index}>
                              <TableRow hover className={styles.pointer}>
                                <TableCell component="th" scope="row">
                                  {index + 1}
                                </TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.quantity}</TableCell>
                                <TableCell>{row.totalCost}</TableCell>
                                <TableCell>
                                  <Button
                                    variant={"contained"}
                                    color={"primary"}
                                    size={"small"}
                                    onClick={() => addPromo(row)}
                                  >
                                    Добавить
                                  </Button>
                                </TableCell>
                              </TableRow>
                            </React.Fragment>
                          ))}
                        </TableBody>
                      </Table>
                    ) : (
                      <></>
                    )}
                    {order.prods.length ? (
                      <Table
                        aria-label="caption table"
                        size="small"
                        style={{ backgroundColor: "#eee" }}
                      >
                        <TableHead>
                          <Typography
                            style={{ marginTop: "15px" }}
                            variant={"body1"}
                          >
                            Товары
                          </Typography>
                          <TableRow>
                            <TableCell>№</TableCell>
                            <TableCell>Наименование</TableCell>
                            <TableCell>Добавлено</TableCell>
                            <TableCell>Убрано</TableCell>
                            <TableCell>Количество</TableCell>
                            <TableCell>К Оплате</TableCell>
                            <TableCell />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {order.prods.map((row, index) => (
                            <TableRow
                              key={index}
                              hover
                              className={styles.pointer}
                            >
                              <TableCell component="th" scope="row">
                                {index + 1}
                              </TableCell>
                              <TableCell>
                                {row.name +
                                  ` (${Object.values(row.options)[0].join(
                                    ", "
                                  )})`}
                              </TableCell>
                              <TableCell>
                                {row.ingredientsToAdd
                                  ? getIngredientsLabel(row.ingredientsToAdd)
                                  : ""}
                              </TableCell>
                              <TableCell>
                                {row.ingredientsToRemove
                                  ? getIngredientsLabel(row.ingredientsToRemove)
                                  : ""}
                              </TableCell>
                              <TableCell>{row.quantity}</TableCell>
                              <TableCell>{row.totalPrice}</TableCell>
                              <TableCell>
                                <Button
                                  variant={"contained"}
                                  color={"primary"}
                                  size={"small"}
                                  onClick={() =>
                                    addProd({
                                      ...row,
                                      ingredientsToAdd:
                                        row.ingredientsToAdd != null
                                          ? row.ingredientsToAdd
                                          : [],
                                      ingredientsToRemove:
                                        row.ingredientsToRemove != null
                                          ? row.ingredientsToRemove
                                          : [],
                                    })
                                  }
                                >
                                  Добавить
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    ) : (
                      <></>
                    )}
                  </TableCell>
                )}
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OrderHistory;
