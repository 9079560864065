import React, { FC, useContext, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Alert } from "@material-ui/lab";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import {
  AlertContextType,
  Category,
  Ingredient,
  Option,
  OptionTypeCreate,
  OptionTypes,
  ProductRow,
} from "../../_shared/types";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import API from "../../_shared/axios";
import { AlertContext } from "../_shared/ToastList";
import { ProgressBar } from "../_shared/ProgressBar";
import { alertError, getErrorMsg, isObjectsEqual } from "../../_shared/utils";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  deleteVariantBtn: { cursor: "pointer", fontSize: 16, marginLeft: 5 },
  variantDescription: {
    fontSize: 16,
    alignSelf: "flex-start",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  variantName: {
    fontSize: 16,
    alignSelf: "flex-start",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  mt10: { marginBottom: 10 },
  addVariant: {
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: "auto",
    alignSelf: "flex-end",
  },
  addVariant32: { paddingBottom: 32 },
  addVariant8: { paddingBottom: 8 },
  variantDescriptionP24: { width: "60%", paddingBottom: 24, marginRight: "4%" },
  variantDescriptionP0: { width: "60%", paddingBottom: 0, marginRight: "4%" },
  variantValue: { width: "20%", minWidth: 180, marginRight: "4%" },
  variantsWrapper: { display: "flex", alignItems: "center", marginBottom: 15 },
  requiredWarning: {
    color: "#f44336",
    margin: "4px 0 0 0",
    fontSize: "0.75rem",
    letterSpacing: "0.03333em",
    lineHeight: "1.66",
  },
  mt20: { marginTop: 20 },
  colorBlack: { color: "rgba(0, 0, 0, 0.87)" },
  colorError: { color: "#f44336" },
});

const EditOptionForm: FC<{
  optionId?: number;
  onOptionEdit: (id: number, option: any) => Promise<any>;
  onClose: () => void;
}> = ({ optionId, onOptionEdit, onClose }) => {
  const classes = useStyles();
  const [ingredients, setIngredients] = useState<Ingredient[]>([]);
  const [products, setProducts] = useState<ProductRow[]>([]);
  const [options, setOptions] = useState<Option[]>([]);
  const [type, setType] = useState(0);
  const [value, setValue] = useState("");
  const [
    isNeedToRemoveInactiveCategories,
    setIsNeedToRemoveInactiveCategories,
  ] = useState(false);
  const [impactOnPrice, setImpactOnPrice] = useState(false);
  const [impactOnPriceWarning, setImpactOnPriceWarning] = useState(false);
  const [oldImpactOnPrice, setOldImpactOnPrice] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [categories, setCategories] = useState<number[]>([]);
  const [inactiveCategories, setInactiveCategories] = useState<number[]>([]);
  const [oldCategories, setOldCategories] = useState<number[]>([]);
  const [categoryList, setCategoryList] = useState<
    { id: number; name: string; isActive: boolean }[]
  >([]);
  const [newVariantValue, setNewVariantValue] = useState("");
  const [newVariantDescription, setNewVariantDescription] = useState("");
  const [optionVariants, setOptionVariants] = useState<
    { id: number; value: string; description: string }[]
  >([]);
  const [optionVariantsToAdd, setOptionVariantsToAdd] = useState<
    { id: number; value: string; description: string }[]
  >([]);
  const [optionVariantIdsToRemove, setOptionVariantIdsToRemove] = useState<
    number[]
  >([]);
  const [progress, setProgress] = useState(false);
  const [oldOption, setOldOption] = useState<OptionTypeCreate>(
    {} as OptionTypeCreate
  );
  const [types, setTypes] = useState<OptionTypes[]>([]);

  const isOptionChanged = () => {
    return (
      type !== oldOption.type ||
      value !== oldOption.value ||
      impactOnPrice !== oldOption.impactOnPrice ||
      isActive !== oldOption.isActive ||
      !isObjectsEqual(categories, oldCategories) ||
      optionVariantIdsToRemove.length > 0 ||
      optionVariantsToAdd.length > 0
    );
  };

  const alertContext = useContext<AlertContextType>(AlertContext);

  useEffect(() => {
    if (optionId !== undefined) {
      API.get("/ingredients")
        .then(({ data: ingredientsData }) => {
          let newIngredients: Ingredient[] = [];
          const ingredientsIds = ingredientsData.map((el: Ingredient) => el.id);
          ingredientsIds.forEach((id: number) =>
            API.get(`/ingredients/${id}`)
              .then(({ data }) => {
                newIngredients = [...newIngredients, data];
                if (newIngredients.length === ingredientsData.length)
                  setIngredients(newIngredients);
              })
              .catch((error) =>
                alertError(
                  alertContext,
                  getErrorMsg(error.response, "Ошибка получения ингредиента")
                )
              )
          );
        })
        .catch((error) =>
          alertError(
            alertContext,
            getErrorMsg(error.response, "Ошибка получения списка ингредиентов")
          )
        );
      API.get("/products")
        .then(({ data: productsData }) => {
          let newProducts: ProductRow[] = [];
          const productsIds = productsData.map((el: Ingredient) => el.id);
          productsIds.forEach((id: number) =>
            API.get(`/products/${id}`)
              .then(({ data }) => {
                newProducts = [...newProducts, data];
                if (newProducts.length === productsData.length)
                  setProducts(newProducts);
              })
              .catch((error) =>
                alertError(
                  alertContext,
                  getErrorMsg(error.response, "Ошибка получения товара")
                )
              )
          );
        })
        .catch((error) =>
          alertError(
            alertContext,
            getErrorMsg(error.response, "Ошибка получения списка товаров")
          )
        );

      API.get("/options")
        .then(({ data }: { data: Option[] }) => setOptions(data))
        .catch((error) =>
          alertError(
            alertContext,
            getErrorMsg(error.response, "Ошибка получения списка опций")
          )
        );

      API.get(`/options/${optionId}`)
        .then(({ data }: { data: OptionTypeCreate }) => {
          const optionData = data;
          API.get(`/options/types`)
            .then((types) => {
              setTypes(types.data);
              API.get(`/categories`)
                .then(({ data }: { data: Category[] }) => {
                  const newCategories = data.map((el) => {
                    return {
                      id: el.id,
                      name: el.name,
                      isActive: el.isActive,
                    };
                  });
                  setCategoryList(newCategories);

                  if (optionData.categories) {
                    setInactiveCategories(
                      optionData.categories
                        .filter(
                          (el) =>
                            !newCategories
                              .filter((el) => el.isActive)
                              .map((el) => el.id)
                              .includes(el.id)
                        )
                        .map((el) => el.id)
                    );
                    if (
                      optionData.categories.length === 1 &&
                      !data.find(
                        (el) => el.id === optionData.categories![0].id
                      )!.isActive
                    ) {
                      setCategories([0]);
                      setOldCategories([0]);
                    } else {
                      const activeCategories = optionData.categories
                        .filter((el) =>
                          newCategories
                            .filter((el) => el.isActive)
                            .map((el) => el.id)
                            .includes(el.id)
                        )
                        .map((el) => el.id);
                      setCategories(
                        activeCategories.length === 0 ? [0] : activeCategories
                      );
                      setOldCategories(
                        activeCategories.length === 0 ? [0] : activeCategories
                      );
                    }
                  }

                  if (optionData.optionVariants) {
                    setOptionVariants(optionData.optionVariants);
                  }
                  setOptionVariantIdsToRemove([]);
                  setOptionVariantsToAdd([]);
                  setType(optionData.type);
                  setValue(optionData.value);
                  setIsActive(optionData.isActive);
                  setImpactOnPrice(optionData.impactOnPrice);
                  setOldImpactOnPrice(optionData.impactOnPrice);
                  setAlerted(false);
                  setNewVariantValue("");
                  setNewVariantDescription("");
                  setOldOption(optionData);
                })
                .catch((error) =>
                  alertError(
                    alertContext,
                    getErrorMsg(error.response, "Ошибка получения опции")
                  )
                );
            })
            .catch((error) =>
              alertError(
                alertContext,
                getErrorMsg(error.response, "Ошибка получения категорий")
              )
            );
        })
        .catch((error) =>
          alertError(
            alertContext,
            getErrorMsg(error.response, "Ошибка получения типов опций")
          )
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionId]);

  const [alerted, setAlerted] = useState(false);

  const closeHandler = () => {
    setCategories([]);
    setCategoryList([]);
    onClose();
  };

  const isAvailableToBeChecked = () => {
    for (let i = 0; i < ingredients.length; i++) {
      let ingredientOptions: Option[] = [];
      for (let j = 0; j < ingredients[i].categories.length; j++) {
        if (
          oldOption.categories
            ?.map((el) => el.id)
            .includes(ingredients[i].categories[j].categoryId)
        ) {
          const isCommonCategory = ingredients[i].categories[j].isCommon;
          if (isCommonCategory) {
            for (let n = 0; n < options.length; n++) {
              if (
                options[n].categories.includes(
                  ingredients[i].categories[j].categoryId
                )
              ) {
                ingredientOptions = [...ingredientOptions, options[n]];
              }
            }
          } else {
            for (
              let k = 0;
              k < ingredients[i].categories[j].variants.length;
              k++
            ) {
              for (
                let m = 0;
                m < ingredients[i].categories[j].variants[k].values.length;
                m++
              ) {
                const optionId =
                  ingredients[i].categories[j].variants[k].values[m].optionId;
                const currentOption = options.find((el) => el.id === optionId);
                if (currentOption)
                  ingredientOptions = [...ingredientOptions, currentOption];
              }
            }
          }
        }
        // берем только ценообразующие опции
        ingredientOptions = ingredientOptions.filter(
          (option) => option.impactOnPrice
        );
        if (ingredientOptions.length > 0) return false;
      }
    }
    for (let i = 0; i < products.length; i++) {
      if (
        oldOption.categories
          ?.map((el) => el.id)
          .includes(products[i].categoryId)
      ) {
        let productOptions: Option[] = [];
        for (let j = 0; j < products[i].variants.length; j++) {
          for (let k = 0; k < products[i].variants[j].values.length; k++) {
            const optionId = products[i].variants[j].values[k].optionId;
            const currentOption = options.find((el) => el.id === optionId);
            if (currentOption)
              productOptions = [...productOptions, currentOption];
          }
        }
        // берем только ценообразующие опции
        productOptions = productOptions.filter(
          (option) => option.impactOnPrice
        );
        if (productOptions.length > 0) return false;
      }
    }
    return true;
  };

  useEffect(() => {
    if (impactOnPriceWarning) {
      const id = setTimeout(() => {
        clearTimeout(id);
        setImpactOnPriceWarning(false);
      }, 3000);
    }
  }, [impactOnPriceWarning]);

  return (
    <Dialog
      disableBackdropClick
      onBackdropClick={() =>
        progress
          ? null
          : !isOptionChanged() &&
            newVariantValue === "" &&
            newVariantDescription === ""
          ? closeHandler()
          : setAlerted(true)
      }
      open={!!optionId}
      onClose={closeHandler}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth={"sm"}
    >
      <DialogTitle id="form-dialog-title">Редактирование опции</DialogTitle>
      <DialogContent>
        <form>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Наименование"
            inputProps={{ maxLength: 100 }}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            type="text"
            fullWidth
            error={!value}
            helperText={!value ? "Обязательное поле" : ""}
            required
          />
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={type + 1}
            defaultValue={type + 1}
            inputProps={{ "aria-label": "Without label" }}
            displayEmpty
            required
            fullWidth
            className={classes.mt20}
            onChange={(e: any) => setType(e.target.value - 1)}
          >
            <MenuItem value={0} disabled>
              Стиль *
            </MenuItem>
            {types.map((type, index) => (
              <MenuItem key={index} value={index + 1}>
                {types[type.id].description}
              </MenuItem>
            ))}
          </Select>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={categories}
            inputProps={{ "aria-label": "Without label" }}
            defaultValue={type}
            error={categories[0] === 0}
            required
            className={[
              classes.mt20,
              categories[0] === 0 ? classes.colorError : classes.colorBlack,
            ].join(" ")}
            multiple
            fullWidth
            MenuProps={{ variant: "menu" }}
            onChange={(e: any) => {
              const newIds = e.target.value.filter(
                (el: number) =>
                  el !== 0 &&
                  categoryList.find((cat) => cat.id === el)!.isActive
              );
              if (
                categories.filter(
                  (cat) =>
                    !categoryList
                      .filter((el) => el.isActive)
                      .map((el) => el.id)
                      .includes(cat)
                ).length === categories.length
              )
                setIsNeedToRemoveInactiveCategories(true);
              setCategories(newIds.length === 0 ? [0] : newIds);
            }}
          >
            <MenuItem value={0} disabled>
              Категории *
            </MenuItem>
            {categoryList.map((el) => (
              <MenuItem value={el.id} disabled={!el.isActive} key={el.id}>
                {el.name}
              </MenuItem>
            ))}
          </Select>
          {categories[0] === 0 && (
            <p className={classes.requiredWarning}>Обязательное поле</p>
          )}
          <div className={classes.variantsWrapper}>
            <TextField
              margin="dense"
              id="variantValue"
              label="Значение варианта"
              multiline
              value={newVariantValue}
              onChange={(e) => setNewVariantValue(e.target.value)}
              type="text"
              className={classes.variantValue}
              error={
                optionVariants.length === 0 &&
                optionVariantsToAdd.length === 0 &&
                !newVariantValue
              }
              helperText={
                optionVariants.length === 0 &&
                optionVariantsToAdd.length === 0 &&
                !newVariantValue
                  ? "Обязательное поле"
                  : ""
              }
              required={
                optionVariants.length === 0 && optionVariantsToAdd.length === 0
              }
            />
            <TextField
              margin="dense"
              id="variantDescription"
              label="Описание варианта"
              className={
                optionVariantsToAdd.length === 0 &&
                optionVariants.length === 0 &&
                !newVariantValue
                  ? classes.variantDescriptionP24
                  : classes.variantDescriptionP0
              }
              multiline
              value={newVariantDescription}
              onChange={(e) => {
                setNewVariantDescription(e.target.value);
              }}
              type="text"
            />
            <Button
              className={[
                classes.addVariant,
                optionVariantsToAdd.length === 0 &&
                optionVariants.length === 0 &&
                !newVariantValue
                  ? classes.addVariant32
                  : classes.addVariant8,
              ].join(" ")}
              onClick={() => {
                setOptionVariantsToAdd([
                  ...optionVariantsToAdd,
                  {
                    id: 666666,
                    value: newVariantValue,
                    description: newVariantDescription,
                  },
                ]);
                setNewVariantValue("");
                setNewVariantDescription("");
              }}
              color="primary"
              disabled={
                newVariantValue === "" ||
                optionVariants
                  .map((el) => el.value.toLowerCase())
                  .includes(newVariantValue.toLocaleLowerCase()) ||
                optionVariantsToAdd
                  .map((el) => el.value.toLowerCase())
                  .includes(newVariantValue.toLocaleLowerCase())
              }
            >
              Добавить
            </Button>
          </div>
          {[...optionVariants, ...optionVariantsToAdd].map((variant) => (
            <div className={[classes.variantsWrapper, classes.mt10].join(" ")}>
              <span className={classes.variantName}>{variant.value}</span>
              {variant.description && (
                <span className={classes.variantDescription}>
                  :&nbsp;{`${variant.description}`}
                </span>
              )}
              <HighlightOffIcon
                onClick={() => {
                  if (variant.id !== 666666)
                    setOptionVariantIdsToRemove([
                      ...optionVariantIdsToRemove,
                      variant.id,
                    ]);
                  setOptionVariants(
                    optionVariants.filter((el) => el.value !== variant.value)
                  );
                  setOptionVariantsToAdd(
                    optionVariantsToAdd.filter(
                      (el) => el.value !== variant.value
                    )
                  );
                }}
                className={classes.deleteVariantBtn}
              />
              <br />
            </div>
          ))}

          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  value={impactOnPrice}
                  checked={impactOnPrice}
                  defaultChecked={false}
                  onChange={() => {
                    if (
                      impactOnPrice ||
                      oldImpactOnPrice ||
                      (isAvailableToBeChecked() && !impactOnPrice)
                    )
                      setImpactOnPrice(!impactOnPrice);
                    else setImpactOnPriceWarning(true);
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Влияет на ценообразование"
            />
          </FormControl>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  value={isActive}
                  checked={isActive}
                  defaultChecked={true}
                  onChange={() => setIsActive(!isActive)}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Опция доступна"
            />
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        {alerted && (
          <Alert
            severity="warning"
            action={
              <>
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => closeHandler()}
                >
                  Да
                </Button>
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => setAlerted(false)}
                >
                  Нет
                </Button>
              </>
            }
          >
            Вы действительно хотите отменить все изменения и выйти? Введенные
            данные будут утеряны
          </Alert>
        )}
        {impactOnPriceWarning && (
          <Alert
            severity="warning"
            onClick={() => setImpactOnPriceWarning(false)}
          >
            Опция уже содержится в комбинации с другой ценообразующей опцией в
            товаре или ингредиенте
          </Alert>
        )}
        {!alerted && !impactOnPriceWarning && (
          <>
            <Button onClick={closeHandler} color="primary" disabled={progress}>
              Отмена
            </Button>
            <Button
              onClick={() => {
                setProgress(true);
                let toRemoveArr = oldCategories
                  .filter((el) => !categories.includes(el))
                  .filter((el) => el !== 0);
                if (isNeedToRemoveInactiveCategories)
                  toRemoveArr = [...toRemoveArr, ...inactiveCategories];
                onOptionEdit(optionId ? optionId : 1, {
                  value,
                  impactOnPrice,
                  categoryIdsToAdd: categories.filter(
                    (el) => !oldCategories.includes(el)
                  ),
                  categoryIdsToRemove: toRemoveArr,
                  optionVariantsToAdd,
                  optionVariantIdsToRemove,
                  type: type,
                  isActive,
                })
                  .then(closeHandler)
                  .catch((error) => console.log(error))
                  .finally(() => setProgress(false));
              }}
              color="primary"
              type="submit"
              disabled={
                !isOptionChanged() ||
                categories[0] === 0 ||
                (optionVariants.length === 0 &&
                  optionVariantsToAdd.length === 0) ||
                progress
              }
            >
              Подтвердить
            </Button>
          </>
        )}
      </DialogActions>
      {progress && <ProgressBar bottom />}
    </Dialog>
  );
};

export default EditOptionForm;
