import React, { FC, useState, useEffect, useContext } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { AlertContextType, UserCookie } from "../../_shared/types";
import { AlertContext } from "../_shared/ToastList";
import { alertError } from "../../_shared/utils";
import { Alert, Autocomplete } from "@material-ui/lab";
import API from "../../_shared/axios";
import {
  KladrObject,
  KladrObjectCreate,
  KladrObjectType,
} from "./_shared/types";
import { Shop } from "../_shared/types";
import cookies from "../../../cookies";

const NewStreetForm: FC<{
  onSend: (data: KladrObjectCreate) => Promise<any>;
}> = ({ onSend }) => {
  const alertContext = useContext<AlertContextType>(AlertContext);

  const [modal, setModal] = useState(false);
  const [alerted, setAlerted] = useState(false);
  const [blur, setBlur] = useState({
    cityId: false,
    name: false,
    kladrTypeId: false,
  });
  const [progress, setProgress] = useState(false);

  const [districts, setDistricts] = useState<KladrObject[]>([]);
  const [district, setDistrict] = useState<KladrObject | string | null>();
  const [cities, setCities] = useState<KladrObject[]>([]);
  const [ptzId, setPtzId] = useState<number | undefined>(undefined);
  const [cityId, setCityId] = useState(0);
  const [name, setName] = useState("");
  const [shops, setShops] = useState<Shop[]>([]);
  const [shopId, setShopId] = useState("");
  const [aroundTheClockShopId, setAroundTheClockShopId] = useState("");
  const [aroundTheClockShops, setAroundTheClockShops] = useState<Shop[]>([]);
  const [kladrTypeId, setKladrTypeId] = useState<number>();
  const [oldKladrTypeId, setOldKladrTypeId] = useState<number>();
  const [kladrObjectTypes, setKladrObjectTypes] = useState<KladrObjectType[]>(
    []
  );

  const onOpen = () => {
    setPtzId(undefined);
    setCityId(0);

    setCities([]);

    setShops([]);
    API.get("/shops")
      .then(({ data }: { data: Shop[] }) => {
        setShops(data);
        const shopsData = data;
        let currentShopsCounter = 0;
        let currentShops: Shop[] = [];
        shopsData.forEach((shop) =>
          API.get(`/shops/${shop.id}`)
            .then(({ data }: { data: Shop }) => {
              currentShops = [...currentShops, data];
              currentShopsCounter++;
              if (currentShopsCounter === shopsData.length)
                setAroundTheClockShops(
                  currentShops.filter(
                    (el) =>
                      el.isAroundTheClockDelivery || el.isAroundTheClockPickup
                  )
                );
            })
            .catch(() =>
              alertError(alertContext, "Ошибка получения точки продаж")
            )
        );
      })
      .catch(() =>
        alertError(alertContext, "Ошибка получения списка точек продаж")
      );

    setKladrObjectTypes([]);

    API.get(`/kladrObjects/types`)
      .then(({ data }: { data: KladrObjectType[] }) => {
        setKladrObjectTypes(data);
      })
      .catch((error) => {
        alertError(
          alertContext,
          "Ошибка получения списка типов кладр-объектов"
        );
        setProgress(false);
      });

    setAlerted(false);
    setBlur({
      cityId: false,
      name: false,
      kladrTypeId: false,
    });
    setName("");
    setKladrTypeId(7);
    setShopId("");
    setAroundTheClockShopId("");
    setModal(true);
    setDistrict(null);
  };

  const user: UserCookie | undefined = cookies.get("user");
  const myCityName = user?.city.name;

  useEffect(() => {
    if (ptzId === undefined) {
      API.get(`/kladrObjects`)
        .then(({ data }) => {
          const myCity = data.find(
            (el: { id: number; name: string }) => el.name === myCityName
          );
          setCities([myCity]);
          const myCityId = myCity.id || 5;
          setPtzId(myCityId);
          setCityId(myCityId);
          API.get(`/kladrObjects/${myCityId}/children?level=1`)
            .then(({ data }: { data: KladrObject[] }) => {
              let districtTypeId =
                kladrObjectTypes.find(
                  (el: any) => el.nameRu.toLowerCase() === "район"
                )?.id || 2;
              setDistricts(
                data.filter((el) => el.kladrObjectTypeId === districtTypeId)
              );
            })
            .catch((error) => {
              alertError(alertContext, "Ошибка получения списка улиц");
            });
        })
        .catch((error) => {
          alertError(alertContext, "Ошибка получения списка кладр-объектов");
          setProgress(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ptzId]);

  useEffect(() => {
    if (kladrObjectTypes.length > 0) {
      const streetTypeId = kladrObjectTypes.find(
        (el) => el.nameRu.toLowerCase() === "улица"
      )!.id;
      setOldKladrTypeId(streetTypeId);
      setKladrTypeId(streetTypeId);
    }
  }, [kladrObjectTypes]);

  const onSubmit = () => {
    if (kladrTypeId !== 0) {
      setProgress(true);
      const streetData: KladrObjectCreate = {
        name,
        kladrObjectTypeId: Number(kladrTypeId),
        shopId: shopId === "" ? null : Number(shopId),
        aroundTheClockShopId:
          aroundTheClockShopId === "" ? null : Number(aroundTheClockShopId),
      };
      if (district) {
        if (typeof district !== "string") streetData.parentId = district.id;
        else {
          streetData.parent = {
            parentId: cityId,
            kladrObjectTypeId: kladrObjectTypes.find(
              (el) => el.nameRu === "Район"
            )!.id,
            name: district,
          };
        }
      } else streetData.parentId = cityId;
      onSend(streetData)
        .then((data) => setModal(false))
        .catch((error) => console.log(error))
        .finally(() => setProgress(false));
    }
  };

  const safeToExit =
    cityId === ptzId &&
    kladrTypeId === oldKladrTypeId &&
    shopId === "" &&
    name === "" &&
    aroundTheClockShopId === "" &&
    district === null;

  const readyToSubmit = name !== "" && kladrTypeId !== 0;

  return (
    <>
      <Button variant="outlined" color="primary" onClick={onOpen}>
        Добавить улицу
      </Button>
      <Dialog
        disableBackdropClick
        onBackdropClick={() =>
          progress ? null : safeToExit ? setModal(false) : setAlerted(true)
        }
        open={modal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Новая улица</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel id="simple-select-cityid">Город *</InputLabel>
            <Select
              labelId="simple-select-city"
              id="simple-select-city"
              value={cityId}
              onChange={(e) => setCityId(Number(e.target.value))}
              disabled={cities.length === 0}
              onBlur={() => setBlur({ ...blur, cityId: true })}
            >
              {cities.map((c, idx) => (
                <MenuItem key={idx} value={c.id}>
                  {c.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <Autocomplete
              id="combo-box-demo"
              onChange={(event, newValue: KladrObject | null | string) => {
                if (!newValue) setDistrict(null);
                else if (typeof newValue !== "string") setDistrict(newValue);
                else {
                  const existingDistrict = districts.find(
                    (el) => el.name.toLowerCase() === newValue.toLowerCase()
                  );
                  setDistrict(existingDistrict ? existingDistrict : newValue);
                }
              }}
              freeSolo
              options={districts}
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option.name
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  onChange={(e) => {
                    const existingDistrict = districts.find(
                      (el) =>
                        el.name.toLowerCase() === e.target.value.toLowerCase()
                    );
                    setDistrict(
                      existingDistrict ? existingDistrict : e.target.value
                    );
                  }}
                  label="Район"
                />
              )}
            />
          </FormControl>
          <TextField
            margin="dense"
            id="name"
            label="Наименование"
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
            fullWidth
            onBlur={() => setBlur({ ...blur, name: true })}
            error={!name && blur.name}
            helperText={!name && blur.name ? "Обязательное поле" : ""}
            required
          />
          <FormControl fullWidth error={kladrTypeId === 0 && blur.kladrTypeId}>
            <InputLabel id="simple-select-streettypeid">Тип *</InputLabel>
            <Select
              labelId="simple-select-streettype"
              id="simple-select-streettype"
              value={kladrTypeId}
              onChange={(e) => setKladrTypeId(Number(e.target.value))}
              onBlur={() => setBlur({ ...blur, kladrTypeId: true })}
            >
              {kladrObjectTypes
                .filter((el) => el.level === 10)
                .map((s, idx) => (
                  <MenuItem key={idx} value={s.id}>
                    {s.nameRu}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText hidden={!(kladrTypeId === 0 && blur.kladrTypeId)}>
              Обязательное поле
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="simple-select-shopid">Привязанная точка</InputLabel>
            <Select
              labelId="simple-select-shop"
              id="simple-select-shop"
              value={shopId}
              onChange={(e) => setShopId(e.target.value as string)}
              disabled={shops.length === 0}
            >
              {shops.map((s, idx) => (
                <MenuItem key={idx} value={String(s.id)}>
                  {s.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="simple-select-alldayshopid">
              Круглосуточная точка
            </InputLabel>
            <Select
              labelId="simple-select-shop"
              id="simple-select-shop"
              value={aroundTheClockShopId}
              onChange={(e) =>
                setAroundTheClockShopId(e.target.value as string)
              }
              disabled={aroundTheClockShops.length === 0}
            >
              {aroundTheClockShops.map((s, idx) => (
                <MenuItem key={idx} value={String(s.id)}>
                  {s.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          {alerted ? (
            <Alert
              severity="warning"
              action={
                <>
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => setModal(false)}
                  >
                    Да
                  </Button>
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => setAlerted(false)}
                  >
                    Нет
                  </Button>
                </>
              }
            >
              Вы действительно хотите отменить все изменения и выйти? Введенные
              данные будут утеряны
            </Alert>
          ) : (
            <>
              <Button
                onClick={() => setModal(false)}
                color="primary"
                disabled={progress}
              >
                Отмена
              </Button>
              <Button
                onClick={onSubmit}
                color="primary"
                type="submit"
                disabled={!readyToSubmit || progress}
              >
                Создать
              </Button>
            </>
          )}
        </DialogActions>
        {progress && (
          <LinearProgress
            style={{ position: "absolute", bottom: "0px", width: "100%" }}
          />
        )}
      </Dialog>
    </>
  );
};

export default NewStreetForm;
