import { useState, useEffect, FC } from "react";
import classNames from "classnames/bind";
import styles from "./index.module.scss";
import React from "react";
import { DispatcherProduct, ProductRow } from "../../../../_shared/types";

const cx = classNames.bind(styles);

const getOptions = (data: any) =>
  data.variants[0]?.values.map((i: any) => ({
    option: i.optionData,
    values: [
      ...data.variants
        .map((v: any) => ({
          id: v.variantId,
          name: v.values.find((val: any) => val.optionData === i.optionData)
            .optionValueData,
          description: v.values.find(
            (val: any) => val.optionData === i.optionData
          ).optionValueDescription,
        }))
        .filter(
          (i: any, idx: any, self: any) =>
            idx ===
            self.findIndex((t: any) => t.place === i.place && t.name === i.name)
        ),
    ],
  }));

const OptionSelect: FC<{
  product: ProductRow;
  setVariant: (i: ProductRow["variants"][0]) => void;
  variant?: any;
}> = ({ product, setVariant, variant }) => {
  const [options, setOptions] = useState({});
  const [descriptions, setDescriptions] = useState({});
  const [isVariantSetted, setVariantSetted] = useState(false);

  useEffect(() => {
    if (Object.keys(options).length && variant && !isVariantSetted) {
      const tmp = getOptions(product);
      let newOptions = tmp.reduce(
        (sum: any, o: any) => ({
          ...sum,
          [o.option]: o.values.reduce(
            (sum: any, o: any, idx: any) => ({ ...sum, [o.name]: false }),
            {}
          ),
        }),
        {}
      );
      const variantValues = product.variants.find(
        (el: any) => el.variantId === variant.variantId
      )!.values;
      variantValues.forEach((value: any) => {
        newOptions[value.optionData][value.optionValueData] = true;
      });
      setVariantSetted(true);
      setOptions(newOptions);
    }
  }, [options, variant]);

  useEffect(() => {
    if (product.variants.length) {
      const tmp = getOptions(product);
      setOptions(
        tmp.reduce(
          (sum: any, o: any) => ({
            ...sum,
            [o.option]: o.values.reduce(
              (sum: any, o: any, idx: any) => ({ ...sum, [o.name]: idx === 0 }),
              {}
            ),
          }),
          {}
        )
      );

      setDescriptions(
        tmp.reduce(
          (sum: any, o: any) => ({
            ...sum,
            [o.option]: o.values.reduce(
              (sum: any, o: any, idx: any) => ({
                ...sum,
                [o.name]: o.description,
              }),
              {}
            ),
          }),
          {}
        )
      );
    } else {
      setOptions({});
      setDescriptions({});
    }
  }, [product]);

  useEffect(() => {
    if (Object.keys(options).length) {
      const vartiant = product.variants.find(
        (p) =>
          p.values
            // @ts-ignore
            .map((v: any) => options[v.optionData][v.optionValueData])
            .filter((i: any) => i).length === p.values.length
      );
      if (vartiant) {
        setVariant(vartiant);
      }
    }
  }, [options]);

  return (
    <>
      {!Object.keys(options).length ? (
        <></>
      ) : (
        Object.keys(options).map((group, key) => (
          <React.Fragment key={key}>
            <div className={styles.description}>
              {/*@ts-ignore*/}
              {Object.keys(options[group]).map((option, key) => (
                <span
                  key={key}
                  className={cx({
                    // @ts-ignore
                    selected: options[group][option],
                  })}
                >
                  {/*@ts-ignore*/}
                  {descriptions[group][option]}
                </span>
              ))}
            </div>
            <form className={styles.form}>
              {/*@ts-ignore*/}
              {Object.keys(options[group]).map((option, key) => (
                <label
                  key={key}
                  className={cx({
                    // @ts-ignore
                    selected: options[group][option],
                  })}
                >
                  <input
                    type="radio"
                    value={option}
                    checked={false}
                    onChange={() =>
                      setOptions({
                        ...options,
                        [group]: {
                          // @ts-ignore
                          ...Object.keys(options[group]).reduce(
                            (sum, opt) => ({ ...sum, [opt]: false }),
                            {}
                          ),
                          [option]: true,
                        },
                      })
                    }
                  />
                  {option}
                </label>
              ))}
            </form>
          </React.Fragment>
        ))
      )}
    </>
  );
};

export default OptionSelect;
