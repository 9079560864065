import React, { FC } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useHistory } from "react-router";
import cookies from "../../cookies";
import { UserCookie } from "../_shared/types";
import API from "../_shared/axios";
import { getUserName } from "../_shared/utils";

const drawerWidth = 270;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
  },
  toolBar: {
    display: "flex",
    justifyContent: "space-between",
    "& > div": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
  },
  userInfo: {
    marginLeft: "auto",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    scrollbarWidth: "none",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    scrollbarWidth: "none",
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    width: "0px",
    flexGrow: 1,
  },
  iconButton: {
    marginLeft: "auto",
  },
}));

const SidebarNav: FC<{
  routes: {
    label: string;
    path: string;
    roleId: number;
    icon: React.ReactNode;
  }[];
  children: React.ReactNode;
}> = ({ children, routes }) => {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState(true);

  const user: UserCookie | undefined = cookies.get("user");
  const roleId =
    user?.roles[0] === "SuperAdmin" ? 1 : user?.roles[0] === "Admin" ? 2 : 3;

  const getUserCity = () => user?.city.name;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.toolBar}>
          <div>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={open ? handleDrawerClose : handleDrawerOpen}
              edge="start"
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              Тик тайм: Админка
            </Typography>
          </div>
          <div>
            <Typography variant="h6" noWrap>
              <span>{getUserCity()}</span>&nbsp;&nbsp;&nbsp;
              <span>{getUserName(user)}</span>&nbsp;&nbsp;&nbsp;
            </Typography>
            <IconButton
              color="inherit"
              className={classes.iconButton}
              onClick={() => {
                API.post(`/signalRSend`, {
                  userId: user?.id,
                  userName: user?.firstName,
                  status: 3,
                }).finally(() =>
                  ["auth", "user"].map((c) => cookies.remove(c, { path: "/" }))
                );
              }}
            >
              <ExitToAppIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}> </div>
        <List>
          {routes.map((route) =>
            roleId <= route.roleId ? (
              <ListItem
                key={route.path}
                button
                onClick={() => history.push(route.path)}
              >
                <ListItemIcon>{route.icon}</ListItemIcon>
                <ListItemText primary={route.label} />
              </ListItem>
            ) : null
          )}
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
};

export default SidebarNav;
