import React, { FC, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Grid,
  List,
  Card,
  CardHeader,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Checkbox,
  Button,
  Divider,
} from "@material-ui/core";
import { ProductRow } from "../../../_shared/types";
import { getImageUrl } from "../../../_shared/utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: "16px",
      width: "100%",
      flexWrap: "nowrap",
    },
    avatar: { maxWidth: "75px", maxHeight: "55px" },
    cardHeader: {
      padding: theme.spacing(1, 2),
    },
    list: {
      width: 350,
      height: 260,
      backgroundColor: theme.palette.background.paper,
      overflow: "auto",
      scrollbarWidth: "none",
      "& ::-webkit-scrollbar": {
        display: "none",
      },
    },
    listItemAvatar: {
      width: "75px",
      marginRight: "5px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    controls: {
      margin: "10px",
    },
    button: {
      margin: theme.spacing(0.5, 0),
    },
  })
);

function not(a: ProductRow[], b: ProductRow[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: ProductRow[], b: ProductRow[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a: ProductRow[], b: ProductRow[]) {
  return [...a, ...not(b, a)];
}

const TransferList: FC<{
  products: ProductRow[];
  isNeedClearSelectedProducts?: boolean;
  selectedProducts?: ProductRow[];
  getData: (data: ProductRow[]) => void;
}> = ({
  products,
  selectedProducts = [],
  getData,
  isNeedClearSelectedProducts,
}) => {
  const classes = useStyles();
  const [checked, setChecked] = React.useState<ProductRow[]>([]);
  const [left, setLeft] = React.useState<ProductRow[]>([]);
  const [right, setRight] = React.useState<ProductRow[]>([]);

  useEffect(() => {
    setLeft(products.filter((i) => !right.map((r) => r.id).includes(i.id)));
  }, [products, right]);

  useEffect(() => {
    if (selectedProducts.length !== 0) setRight(selectedProducts);
  }, [selectedProducts]);

  useEffect(() => {
    getData(right);
  }, [right, getData]);

  useEffect(() => {
    if (isNeedClearSelectedProducts) setRight([]);
  }, [isNeedClearSelectedProducts]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value: ProductRow) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: ProductRow[]) =>
    intersection(checked, items).length;

  const handleToggleAll = (items: ProductRow[]) => () => {
    if (numberOfChecked(items) !== 0) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customList = (title: React.ReactNode, items: ProductRow[]) => (
    <Card elevation={0}>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{ "aria-label": "выбрано все" }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} выбрано`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map((value: ProductRow, key: number) => {
          const labelId = `transfer-list-all-item-${value.name}-label`;

          return (
            <ListItem
              key={key}
              role="listitem"
              button
              selected={checked.indexOf(value) !== -1}
              onClick={handleToggle(value)}
            >
              <ListItemAvatar className={classes.listItemAvatar}>
                <img
                  className={classes.avatar}
                  src={getImageUrl(value.thumbnail, "products")}
                  alt={getImageUrl(value.thumbnail, "products")}
                />
              </ListItemAvatar>
              <ListItemText id={labelId} primary={value.name} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <Grid
      container
      justify="space-between"
      alignItems="center"
      className={classes.root}
    >
      <Grid item>{customList("Доступно", left)}</Grid>
      <Grid item className={classes.controls}>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList("Добавлено", right)}</Grid>
    </Grid>
  );
};

export default TransferList;
