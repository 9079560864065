import { makeStyles } from "@material-ui/styles";
import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import {
  AlertContextType,
  Category,
  CategoryCreate,
} from "../../_shared/types";
import { AlertContext } from "../_shared/ToastList";
import API from "../../_shared/axios";
import { alertError, alertSuccess } from "../../_shared/utils";
import BlockIcon from "@material-ui/icons/Block";
import DeleteIcon from "@material-ui/icons/Delete";
import { ProgressBar } from "../_shared/ProgressBar";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { TableBody } from "@material-ui/core";
import DropdownOptions from "../_shared/DropdownOptions";
import useStyles from "../_shared/styles";
import NewPromotionCategoryForm from "./NewPromotionCategoryForm";
import EditPromotionCategoryForm from "./EditPromotionCategoryForm";

const styles = makeStyles({
  page: {
    height: "calc(100% - 64px)",
  },
  minWidth: { minWidth: 100 },
  content: {
    padding: 50,
    maxWidth: 1140,
    width: "100%",
    margin: "0 auto 20px auto",
    "& .MuiTableCell-body": {
      cursor: "pointer",
    },
  },
});

const columns = [
  { id: "name", label: "Наименование" },
  {
    id: "description",
    label: "Описание",
  },
  {
    id: "dropdownOptions",
    label: "",
  },
];

const PromotionCategoriesPage: FC = () => {
  const classes = styles();
  const commonClasses = useStyles();
  const alertContext = useContext<AlertContextType>(AlertContext);

  const [rows, setRows] = useState<Category[]>([]);
  const [editCategoryId, setEditCategoryId] = useState<number | undefined>(
    undefined
  );
  const [progress, setProgress] = useState(false);

  useEffect(() => {
    setProgress(true);
    API.get(`/promotionCategories`)
      .then(({ data }) => {
        setRows(data);
        setProgress(false);
      })
      .catch((error) => {
        alertError(alertContext, "Ошибка получения списка категорий акций");
        setProgress(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCategoryDelete = useCallback(
    (row: Category) => {
      API.delete(`/promotionCategories/${row.id}`)
        .then(() => {
          alertSuccess(alertContext, "Категория удалена");
          API.get(`/promotionCategories`)
            .then(({ data }) => {
              setRows(data);
            })
            .catch((error) => {
              alertError(alertContext, "Ошибка обновления списка категорий");
            });
        })
        .catch((error) => {
          const { response } = error;
          if (response.status === 400) {
            if (response.data === "Promotion Category used in the order")
              alertError(
                alertContext,
                "Категория используется в активном заказе"
              );
            else if (
              response.data === "Promotion Category used in the promotion"
            )
              alertError(alertContext, "Категория используется в акции");
            else alertError(alertContext, "Ошибка удаления категории");
          } else alertError(alertContext, "Ошибка удаления категории");
        });
    },
    [alertContext]
  );

  const onCategoryBlock = useCallback(
    (row: Category) => {
      API.post(
        `/promotionCategories/${row.id}/${row.isActive ? "block" : "unblock"}`
      )
        .then(() =>
          API.get(`/promotionCategories`)
            .then(({ data }) => setRows(data))
            .catch((error) =>
              alertError(alertContext, "Ошибка обновления списка категорий")
            )
        )
        .catch((error) =>
          alertError(alertContext, "Ошибка блокировки категории")
        );
    },
    [alertContext]
  );

  const dropdownMenuOptions = useCallback(
    (row: Category) => [
      {
        icon: <BlockIcon fontSize={"small"} />,
        text: row.isActive ? "Заблокировать" : "Разблокировать",
        event: () => onCategoryBlock(row),
      },
      {
        icon: <DeleteIcon fontSize={"small"} />,
        text: "Удалить",
        event: () => onCategoryDelete(row),
      },
    ],
    [onCategoryBlock, onCategoryDelete]
  );

  const onCategoryEdit = (id: number, category: Category) =>
    new Promise((resolve, reject) =>
      API.patch(`/promotionCategories/${id}`, category)
        .then(({ data }) => {
          API.get(`/promotionCategories`)
            .then(({ data }) => {
              setRows(data);
              alertSuccess(
                alertContext,
                "Успешное редактирование категории акции"
              );
              resolve(data);
            })
            .catch((error) => {
              alertError(
                alertContext,
                "Ошибка обновления списка категорий акциий"
              );
              reject(error);
            });
        })
        .catch((error) => {
          alertError(alertContext, "Ошибка редактирования категории акции");
          reject(error);
        })
    );

  const onCategoryCreate = (data: CategoryCreate) =>
    new Promise((resolve, reject) =>
      API.post("/promotionCategories", data)
        .then(({ data }) => {
          alertSuccess(alertContext, "Категория добавлена");
          API.get(`/promotionCategories`)
            .then(({ data }) => {
              setRows(data);
              resolve(data);
            })
            .catch((error) => {
              alertError(alertContext, "Ошибка обновления списка категорий");
              reject(error);
            });
        })
        .catch((error) => {
          alertError(alertContext, "Ошибка добавления категории");
          reject(error);
        })
    );

  return (
    <div className={classes.page}>
      <EditPromotionCategoryForm
        onCategoryEdit={onCategoryEdit}
        categoryId={editCategoryId}
        onClose={() => setEditCategoryId(undefined)}
      />
      <div className={classes.content}>
        <NewPromotionCategoryForm onCategoryCreate={onCategoryCreate} />
        {progress ? (
          <ProgressBar />
        ) : (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} className={classes.minWidth}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, key) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={key}
                      className={
                        !row.isActive ? commonClasses.blocked : undefined
                      }
                      onClick={() => setEditCategoryId(row.id)}
                    >
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.description}</TableCell>
                      <TableCell>
                        <DropdownOptions
                          options={() => dropdownMenuOptions(row)}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </div>
  );
};

export default PromotionCategoriesPage;
