import React from "react";
import { Route, Switch } from "react-router-dom";
import DispatcherOrderForm from "./OrderForm";
import OrdersPage from "./OrdersPage";

const DispatcherPage = ({ match }: { match: { path: string } }) => {
  return (
    <Switch>
      <Route path={match.path} component={() => <OrdersPage />} exact />
      <Route
        path={`${match.path}/order-form/:id`}
        component={DispatcherOrderForm}
      />
    </Switch>
  );
};

export default React.memo(DispatcherPage);
