import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import API from "../../_shared/axios";
import NewBannerForm from "./NewBannerForm";
import { AlertContextType, Banner, BannerCreate } from "../../_shared/types";
import {
  alertError,
  alertSuccess,
  alertWarning,
  getErrorMsg,
} from "../../_shared/utils";
import { AlertContext } from "../_shared/ToastList";
import BlockIcon from "@material-ui/icons/Block";
import DeleteIcon from "@material-ui/icons/Delete";
import DropdownOptions from "../_shared/DropdownOptions";
import EditBannerForm from "./EditBannerForm";

const useStyles = makeStyles({
  gray: {
    filter: "grayscale(100%)",
  },
  pageWrapper: {
    padding: "30px 50px 50px 50px",
  },
  gallery: {
    width: "100%",
    display: "grid",
    gridGap: 20,
    gridTemplateColumns: "repeat(auto-fit, minmax(350px, 1fr))",
    "& > div": {
      margin: "auto",
      maxWidth: 400,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      height: "100%",
      position: "relative",
      cursor: "pointer",
      "& span": {
        fontWeight: 500,
      },
      "& button": {
        position: "absolute",
        right: 7,
        top: 7,
        zIndex: 3,
      },
      "& img": {
        minHeight: "150px",
        maxWidth: "100%",
        objectFit: "contain",
        maxHeight: 200,
      },
      "& > div > button": {
        opacity: 0,
      },
      "&:hover > div > button": {
        transition: "opacity .4s linear",
        opacity: 1,
      },
      "&:hover:after": {
        display: "block",
        backgroundColor: "rgba(0,0,0,.4)",
      },
      "&:after": {
        transition: "background-color 50ms linear",
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 2,
      },
    },
  },
});

const BannersPage: FC = () => {
  const [rows, setRows] = useState<Banner[]>([]);
  const [editBannerId, setEditBannerId] = useState<number | undefined>(
    undefined
  );

  const classes = useStyles();

  const alertContext = useContext<AlertContextType>(AlertContext);

  const onBannerDelete = useCallback(
    (row: Banner) => {
      API.delete(`/banners/${row.id}`)
        .then(() =>
          API.get(`/banners`)
            .then(({ data }) => {
              setRows(data);
              alertSuccess(alertContext, "Баннер удален");
            })
            .catch((error) => {
              alertError(
                alertContext,
                getErrorMsg(error.response, "Ошибка обновления списка баннеров")
              );
            })
        )
        .catch((error) =>
          alertError(
            alertContext,
            getErrorMsg(error.response, "Ошибка удаления баннера")
          )
        );
    },
    [alertContext]
  );

  const onBannerBlock = useCallback(
    (row: Banner) => {
      if (row.isActive || (!row.isActive && !row.productId && !row.categoryId))
        API.post(`/banners/${row.id}/${row.isActive ? "block" : "unblock"}`)
          .then(() =>
            API.get(`/banners`)
              .then(({ data }) => setRows(data))
              .catch((error) =>
                alertError(
                  alertContext,
                  getErrorMsg(
                    error.response,
                    "Ошибка обновления списка баннеров"
                  )
                )
              )
          )
          .catch((error) =>
            alertError(
              alertContext,
              getErrorMsg(error.response, "Ошибка блокировки баннера")
            )
          );
      else {
        const { categoryId, productId } = row;
        const path = categoryId
          ? `/categories/${categoryId}`
          : productId
          ? `/products/${productId}`
          : "";
        if (path !== "") {
          const msg =
            "Ошибка получения списка " + categoryId ? "категорий" : "товаров";
          API.get(path)
            .then(({ data }) => {
              if (data.isActive) {
                API.post(`/banners/${row.id}/unblock`)
                  .then(() =>
                    API.get(`/banners`)
                      .then(({ data }) => setRows(data))
                      .catch((error) =>
                        alertError(
                          alertContext,
                          getErrorMsg(
                            error.response,
                            "Ошибка обновления списка баннеров"
                          )
                        )
                      )
                  )
                  .catch((error) =>
                    alertError(
                      alertContext,
                      getErrorMsg(
                        error.response,
                        "Ошибка разблокировки баннера"
                      )
                    )
                  );
              } else {
                const msg = categoryId
                  ? "Невозможно разблокировать баннер. Привязанная к нему категория неактивна"
                  : "Невозможно разблокировать баннер. Привязанный к нему товар неактивен";
                alertWarning(alertContext, msg);
              }
            })
            .catch((error) => {
              alertError(alertContext, msg);
            });
        }
      }
    },
    [alertContext]
  );

  const dropdownMenuOptions = useCallback(
    (row: Banner) => [
      {
        icon: <BlockIcon fontSize={"small"} />,
        text: row.isActive ? "Заблокировать" : "Разблокировать",
        event: () => onBannerBlock(row),
      },
      {
        icon: <DeleteIcon fontSize={"small"} />,
        text: "Удалить",
        event: () => onBannerDelete(row),
      },
    ],
    [onBannerBlock, onBannerDelete]
  );

  useEffect(() => {
    API.get("/banners")
      .then(({ data }) => {
        setRows(data);
      })
      .catch((error) => {
        alertError(
          alertContext,
          getErrorMsg(error.response, "Ошибка получения списка баннеров")
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBannerCreate = (data: any) =>
    new Promise((resolve, reject) => {
      API.post("/banners", data)
        .then(({ data }) => {
          alertSuccess(alertContext, "Баннер добавлен");
          API.get(`/banners`)
            .then(({ data }) => {
              setRows(data);
              resolve(data);
            })
            .catch((error) => {
              alertError(
                alertContext,
                getErrorMsg(error.response, "Ошибка обновления списка баннеров")
              );
              reject(error);
            });
        })
        .catch((error) => {
          alertError(
            alertContext,
            getErrorMsg(error.response, "Ошибка добавления баннера")
          );
          reject(error);
        });
    });

  const onBannerEdit = (id: number, data: BannerCreate) =>
    new Promise((resolve, reject) => {
      API.patch(`/banners/${id}`, data)
        .then(({ data }) => {
          alertSuccess(alertContext, "Успешное редактирование баннера");
          API.get(`/banners`)
            .then(({ data }) => {
              setRows(data);
              resolve(data);
            })
            .catch((error) => {
              alertError(
                alertContext,
                getErrorMsg(error.response, "Ошибка обновления списка баннеров")
              );
              reject(error);
            });
        })
        .catch((error) => {
          alertError(
            alertContext,
            getErrorMsg(error.response, "Ошибка редактирования баннера")
          );
          reject(error);
        });
    });

  return (
    <div className={classes.pageWrapper}>
      <EditBannerForm
        onBannerEdit={onBannerEdit}
        bannerId={editBannerId}
        onClose={() => setEditBannerId(undefined)}
      />
      <NewBannerForm onBannerCreate={onBannerCreate} />
      <div className={classes.gallery}>
        {rows.map((row) => (
          <div key={row.id} onClick={() => setEditBannerId(row.id)}>
            <DropdownOptions options={() => dropdownMenuOptions(row)} />
            <span>{row.name}</span>
            <img
              alt={row.thumbnail}
              src={row.thumbnail}
              className={!row.isActive ? classes.gray : ""}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BannersPage;
