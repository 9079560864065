import React, { FC, useContext, useEffect, useState, useRef } from "react";
import { AlertContextType, OrderRow } from "../_shared/types";
import { alertError, alertSuccess, getErrorMsg } from "../_shared/utils";
import API from "../_shared/axios";
import ReactHowler from "react-howler";
import { AlertContext } from "./_shared/ToastList";

const Howler: FC<{
  updateOrders: () => void;
}> = ({ updateOrders }) => {
  // Howler
  const [currentOrders, setCurrentOrders] = useState<OrderRow[]>([]);
  const alertContext = useContext<AlertContextType>(AlertContext);
  let howlerRef = useRef(null);

  function useInterval(callback: any, delay: any) {
    let savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        // @ts-ignore
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  useInterval(() => {
    if (howlerRef !== null && howlerRef.current !== null) {
      // @ts-ignore
      howlerRef.current.stop();
    }
    API.get(`/orders/current`)
      .then(({ data }) => {
        const newOrders = data.data.items
          .filter((el: OrderRow, index: number) => index < 20)
          .filter(
            (order: OrderRow) =>
              !currentOrders
                .map((i) => i.orderNumber)
                .includes(order.orderNumber)
          );
        if (newOrders.length) {
          setCurrentOrders(data.data.items);
          if (howlerRef !== null && howlerRef.current !== null) {
            const id = setTimeout(() => {
              clearInterval(id);
              updateOrders();
              const play = setTimeout(() => {
                clearInterval(play);
                alertSuccess(alertContext, "Получен новый заказ");
                // @ts-ignore
                howlerRef.current.play();
              }, 2 * 1000);
            }, 6 * 1000);
          }
        }
      })
      .catch((error) => {
        alertError(
          alertContext,
          getErrorMsg(error.response, "Ошибка получения списка заказов")
        );
      });
  }, 60 * 1000);

  useEffect(() => {
    API.get(`/orders/current`)
      .then(({ data }) => {
        setCurrentOrders(data.data.items);
      })
      .catch((error) => {
        alertError(
          alertContext,
          getErrorMsg(error.response, "Ошибка получения списка заказов")
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ReactHowler
      src="/assets/sounds/new-order.mp3"
      playing={false}
      ref={howlerRef}
    />
  );
};

export default React.memo(Howler);
