import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@material-ui/core";
import SyncTableRow from "./syncTableRow";
import { makeStyles } from "@material-ui/styles";
import SyncTableRowMulti from "./syncTableRowMulti";

type ISyncTableItem = {
  syncId: number;
  id: number | null;
  relation?: Object | null;
};

const styles = {
  label: {
    cursor: "pointer",
    marginLeft: 15,
    minHeight: 32,
    borderRadius: 16,
    display: "inline-flex",
    padding: "7px 12px",
    fontSize: 14,
    fontWeight: 500,
    alignItems: "center",
  },
  labelDisabled: { backgroundColor: "#ccc" },
  labelCreated: { backgroundColor: "#90caf9" },
};

const useStyles = makeStyles(styles);

function SyncTable<Item extends ISyncTableItem, SystemItem extends object>({
  data,
  totalItems,
  multipleChoice = false,
  filteredGetData,
  changeDatHandler,
  title,
  CustomRow,
  setSearchBackendString,
  searchBackendString,
  setSearch1CString,
  search1CString,
  pageSizeChange,
  pageSize,
  pageChange,
  page,
  filter,
  setFilter,
}: {
  data: Item[];
  totalItems: number;
  multipleChoice?: boolean;
  filteredGetData: SystemItem[];
  changeDatHandler: (a: SystemItem, b: Item, c: number) => void;
  title: string;
  CustomRow?: React.FC<any>;
  page: number;
  pageChange: (page: number) => void;
  pageSize: number;
  pageSizeChange: (size: number) => void;
  search1CString: string;
  setSearch1CString: (str: string) => void;
  searchBackendString: string;
  setSearchBackendString: (str: string) => void;
  filter: number;
  setFilter: (f: number) => void;
}): React.ReactElement {
  const classes = useStyles();
  const [defaultProps, setDefaultProps] = useState({
    options: filteredGetData ? filteredGetData : [],
    getOptionLabel: (option: any) => option?.fullName,
  });
  // const factorial = useMemo(() => searchHandler(str), [str]);

  useEffect(() => {
    setDefaultProps({
      options: filteredGetData,
      getOptionLabel: (option: any) => option?.fullName,
    });
  }, [filteredGetData, data]);

  return (
    <>
      <Paper>
        <span>
          <span
            className={[
              classes.label,
              filter === 1 ? classes.labelCreated : classes.labelDisabled,
            ].join(" ")}
            onClick={() => setFilter(filter === 1 ? 0 : 1)}
          >
            Синхронизированые
          </span>
          <span
            className={[
              classes.label,
              filter === 2 ? classes.labelCreated : classes.labelDisabled,
            ].join(" ")}
            onClick={() => setFilter(filter === 2 ? 0 : 2)}
          >
            Не заполненные
          </span>
        </span>
      </Paper>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TextField
                  value={search1CString}
                  onChange={(e) => setSearch1CString(e.target.value)}
                  placeholder={"Поиск"}
                  label={"1С"}
                />
              </TableCell>
              <TableCell>
                <TextField
                  value={searchBackendString}
                  onChange={(e) => setSearchBackendString(e.target.value)}
                  placeholder={"Поиск"}
                  label={"Система"}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredGetData &&
              data?.length > 0 &&
              data.map((item: Item, index: number) => {
                return (
                  <TableRow key={item.syncId}>
                    {CustomRow ? (
                      <CustomRow
                        title={title}
                        item={item}
                        index={index}
                        key={index}
                        changeDatHandler={(
                          v: SystemItem,
                          item: Item,
                          index: number
                        ) => changeDatHandler(v, item, index)}
                        defaultProps={defaultProps}
                      />
                    ) : multipleChoice ? (
                      <SyncTableRowMulti
                        title={title}
                        item={item}
                        key={index}
                        index={index}
                        changeDatHandler={(
                          v: SystemItem,
                          item: Item,
                          index: number
                        ) => changeDatHandler(v, item, index)}
                        defaultProps={defaultProps}
                      />
                    ) : (
                      <SyncTableRow
                        title={title}
                        item={item}
                        key={index}
                        index={index}
                        changeDatHandler={(
                          v: SystemItem,
                          item: Item,
                          index: number
                        ) => changeDatHandler(v, item, index)}
                        defaultProps={defaultProps}
                      />
                    )}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </Paper>
      <Paper>
        <TablePagination
          labelRowsPerPage={"Записей на странице:"}
          labelDisplayedRows={({ from, to }) =>
            `${from}-${to} из ${totalItems}`
          }
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={totalItems}
          rowsPerPage={pageSize}
          onChangeRowsPerPage={(e) => {
            pageSizeChange(Number(e.target.value));
          }}
          page={page}
          onChangePage={(e, newPage) => pageChange(newPage ? newPage : 0)}
        />
      </Paper>
    </>
  );
}

export default SyncTable;
