import React, { FC } from "react";
import { Box } from "@material-ui/core";
import makeStyles from "@material-ui/styles/makeStyles/makeStyles";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import CashierPromocode from "./CashierPromocode";
import CashierMenu from "./Menu";

const useStyles = makeStyles({
  rootGrid: {
    height: "calc(100% - 64px)",
  },
});

const CashierPage: FC = () => {
  const classes = useStyles();

  const match = useRouteMatch();

  return (
    <Box className={classes.rootGrid}>
      <Switch>
        <Route path={`${match.path}/promocode`} component={CashierPromocode} />
        <Route path={`${match.path}`} component={CashierMenu} />
      </Switch>
    </Box>
  );
};

export default CashierPage;
