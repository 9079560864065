import React, { FC, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { copyObject } from "../../_shared/utils";
import InputCounter from "./InputCounter";

const useStyles = makeStyles({
  page: {
    "& .MuiFormControlLabel-root": {
      display: "flex",
      justifyContent: "space-between !important",
    },
  },
  formGroup: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

const CheckboxList: FC<{
  title: string;
  data: any[];
  withCounter?: boolean;
  onConfirm: (data: any[]) => void;
  onClose: () => void;
  isOpen: boolean;
}> = ({ title, data, isOpen, onConfirm, onClose, withCounter }) => {
  const [list, setList] = useState<any[]>(data);

  const classes = useStyles();

  const handleCheckbox = (event: any, id: number) => {
    const newList = copyObject(list);
    for (let i = 0; i < list.length; i++) {
      if (list[i].id === id) newList[i].isChecked = event.target.checked;
    }
    setList(newList);
  };

  useEffect(() => {
    if (isOpen) {
      setList(data);
    }
  }, [data, isOpen]);

  return (
    <div className={classes.page}>
      {list.length > 0 && (
        <Dialog
          open={isOpen}
          aria-labelledby="form-dialog-title"
          onBackdropClick={() => {
            setList(data);
            onClose();
          }}
          maxWidth={"md"}
        >
          <DialogTitle id="form-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <FormGroup>
              {data.map(
                (element, key) =>
                  !element.isChecked &&
                  element.isActive && (
                    <div
                      className={classes.formGroup}
                      key={`${element.name} ${key}`}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            color={"primary"}
                            value={element.isChecked}
                            onChange={(e) => handleCheckbox(e, element.id)}
                            name={element.name}
                          />
                        }
                        label={element.name}
                      />
                      {withCounter && (
                        <InputCounter
                          maxQuantity={list[key].maxQuantity}
                          valueProp={list[key].quantity}
                          setCounter={(value) => {
                            const newList = copyObject(list);
                            for (let i = 0; i < list.length; i++) {
                              if (i === key) {
                                newList[i].quantity = value;
                                if (!newList[i].isChecked && value > 1)
                                  newList[i].isChecked = true;
                              }
                            }
                            setList(newList);
                          }}
                        />
                      )}
                    </div>
                  )
              )}
            </FormGroup>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                onConfirm(list);
                onClose();
              }}
              disabled={JSON.stringify(data) === JSON.stringify(list)}
              color="primary"
            >
              Добавить
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default CheckboxList;
