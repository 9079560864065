import React, { FC, useContext, useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  Button,
  Tabs,
  Tab,
  Divider,
  Paper,
} from "@material-ui/core";
import styles from "./index.module.scss";
import API from "../../../../_shared/axios";
import {
  AlertContextType,
  DispatcherProduct,
  ProductRow,
  ProductSet,
  PromotionCategory,
  PromotionCategoryRibbon,
  PromotionRow,
} from "../../../../_shared/types";
import { alertError, getErrorMsg } from "../../../../_shared/utils";
import { AlertContext } from "../../../_shared/ToastList";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import FlexRow from "../FlexRow";
import { PromotionState } from "../index";
import { Alert } from "@material-ui/lab";
import DialogActions from "@material-ui/core/DialogActions";
import OptionSelect from "../OptionSelect";

export type PromCategory = {
  desc: string;
  name: string;
  id: number;
  max: number;
  selectedProduct: number | null;
  products: Array<{
    id: number;
    name: string;
    price: number;
    chosen: boolean;
    count: number;
    variants: Array<{
      id: number;
      count: number;
      name: string;
      price: number;
    }>;
  }>;
};

export type CatProdIds = {
  [key: number]: {
    name: string;
    prodIds: Array<number>;
  };
};

const ProductItem: FC<{
  thumbnail?: string;
  description?: string;
  name?: string;
  onClick?: () => void;
}> = ({ name, thumbnail, description, onClick }) => {
  return (
    <div
      className={[styles.selectItem, styles.selectedSet].join(" ")}
      onClick={onClick}
    >
      <div className={styles.itemWrapper}>
        <img src={thumbnail} className={styles.selectImage} />
        <div>
          <span>
            <b>{name}</b>
          </span>
          <span title={description} className={styles.selectDescription}>
            {description}
          </span>
        </div>
      </div>
    </div>
  );
};

export default function PromotionForm({
  promotionId,
  currentPromotion,
  onClose,
  products,
  addToPromo,
}: {
  promotionId: number;
  currentPromotion: PromotionState | null;
  onClose: () => void;
  products: ProductRow[];
  addToPromo: (promotion: PromotionState) => void;
}) {
  const alertContext = useContext<AlertContextType>(AlertContext);

  const [categories, setCategories] = useState<Array<PromCategory>>();
  const [selectedCategory, selectCategory] = useState<number>();

  const [alerted, setAlerted] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [touched, setTouched] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState(-1);
  const [promotion, setPromotion] = useState<PromotionRow | null>(null);
  const [promoSet, setPromoSet] = useState<number>();
  const [catProdsIds, setCatProdsIds] = useState<CatProdIds>({});
  const [productSetsList, setProductSetsList] = useState<ProductSet[]>([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [productsInfo, setProductsInfo] = useState<{
    [key: number]: ProductRow;
  }>({});
  const [prodVariants, setProdVariants] = useState<{
    [key: number]: number | null;
  }>({});

  const addToCart = () => {
    if (categories && promotion) {
      addToPromo({
        id: promotionId,
        name: promotion.name,
        quantity: 1,
        price: promotion.price
          ? promotion.price
          : totalPrice - (promotion.discount ? promotion.discount : 0),
        // @ts-ignore
        items: categories
          .map((cat) => {
            const prodArray = cat.products
              .map((prod) => {
                if (prod.chosen) {
                  if (prod.variants.length) {
                    const variant = prod.variants.find(
                      (varnt) => varnt.count > 0
                    );
                    return {
                      id: prod.id,
                      variantId: variant?.id,
                      quantity: variant?.count,
                      promotionCategoryId: cat.id,
                    };
                  } else {
                    return {
                      id: prod.id,
                      variantId: null,
                      quantity: prod.count,
                      promotionCategoryId: cat.id,
                    };
                  }
                } else {
                  return null;
                }
              })
              .filter((v) => v != null);
            return prodArray;
          })
          .flat(),
      });
    }
  };

  const addVariant = (indx: number, prodId: number, varId: number | null) => {
    if (categories) {
      const newCats = [...categories];
      const prodIndex = newCats[indx].products.findIndex(
        (v) => v.id === prodId
      );
      const total = categories[indx].products.reduce((summ: number, curr) => {
        console.log(curr);
        return (
          summ +
          (curr.variants.length
            ? curr.variants.reduce((vars: number, v) => vars + v.count, 0)
            : curr.count)
        );
      }, 0);

      console.log(total);

      if (total < categories[indx].max) {
        if (newCats[indx].products[prodIndex].variants.length) {
          const newCats = [...categories];

          const variantIndex = newCats[indx].products[
            prodIndex
          ].variants.findIndex((v) => v.id === varId);
          newCats[indx].products[prodIndex].variants[variantIndex].count =
            newCats[indx].products[prodIndex].variants[variantIndex].count + 1;
          newCats[indx].products[prodIndex].chosen = true;
        } else {
          const newCats = [...categories];

          newCats[indx].products[prodIndex].count =
            newCats[indx].products[prodIndex].count + 1;
          newCats[indx].products[prodIndex].chosen = true;
        }
        setCategories([...newCats]);
        setTouched(true);
      }
    }
  };

  const removeVariant = (
    indx: number,
    prodId: number,
    varId: number | null
  ) => {
    if (categories) {
      const newCats = [...categories];
      const prodIndex = newCats[indx].products.findIndex(
        (v) => v.id === prodId
      );

      if (newCats[indx].products[prodIndex].variants.length) {
        const newCats = [...categories];

        const variantIndex = newCats[indx].products[
          prodIndex
        ].variants.findIndex((v) => v.id === varId);

        if (
          newCats[indx].products[prodIndex].variants[variantIndex].count > 0
        ) {
          newCats[indx].products[prodIndex].variants[variantIndex].count =
            newCats[indx].products[prodIndex].variants[variantIndex].count - 1;
        }
        if (
          newCats[indx].products[prodIndex].variants[variantIndex].count === 0
        ) {
          newCats[indx].products[prodIndex].chosen = false;
        }
      } else {
        const newCats = [...categories];
        if (newCats[indx].products[prodIndex].count > 0) {
          newCats[indx].products[prodIndex].count =
            newCats[indx].products[prodIndex].count - 1;
        }
        if (newCats[indx].products[prodIndex].count === 0) {
          newCats[indx].products[prodIndex].chosen = false;
        }
      }
      setCategories([...newCats]);
      setTouched(true);
    }
  };

  useEffect(() => {
    const d = async () => {
      const cats: Array<PromCategory> = [];
      let prodsInfo: { [key: number]: ProductRow } = {};
      let prodVars: { [key: number]: number | null } = {};
      if (promotion) {
        await Promise.all(
          promotion.productSetsList.map(async (set) => {
            const catData = await API.get(
              `/promotionCategories/${set.promotionCategoryId}`
            );
            let catInfo: Omit<PromCategory, "products"> & {
              products?: PromCategory["products"];
            };
            if (catData.status === 200) {
              catInfo = {
                name: catData.data.name,
                desc: catData.data.description,
                id: catData.data.id,
                max: set.count,
                selectedProduct: null,
              };
              if (set.productId) {
                const product = await API.get(`/products/${set.productId}`);

                if (!product.data.variants.length) {
                  prodsInfo[set.productId] = {
                    ...product.data,
                    variants: [],
                  };
                }

                const variantsToUse: Array<number> = set.productSetVariantsList.map(
                  (item) => item.variantId
                );
                prodsInfo[set.productId] = {
                  ...product.data,
                  variants: product.data.variants.filter(
                    (varnt: ProductRow["variants"][0]) =>
                      variantsToUse.some(
                        (item: number) => varnt.variantId === item
                      )
                  ),
                };

                if (product.data.variants.length) {
                  prodVars[set.productId] =
                    set.productSetVariantsList[0].variantId;
                }

                catInfo.products = [
                  {
                    id: set.productId,
                    name: products.find((v) => v.id === set.productId)!.name,
                    price: Number(
                      products.find((v) => v.id === set.productId)!.price
                    ),
                    chosen: true,
                    count: 0,
                    variants: set.productSetVariantsList.map((v, i, arr) => {
                      return {
                        id: v.variantId,
                        name: v.values
                          .map((v) => `${v.optionData}: ${v.optionValueData}`)
                          .join(" "),
                        count: arr.length === 1 ? set.count : 0,
                        price: product.data.variants?.length
                          ? product.data.variants?.find(
                              (item: ProductRow["variants"][0]) =>
                                item.variantId === v.variantId
                            ).price
                          : null,
                      };
                    }),
                  },
                ];
              } else {
                catInfo.products = await Promise.all(
                  catData.data.products.map(
                    async (
                      v: PromotionCategory["products"][0],
                      index: number
                    ) => {
                      const product = await API.get(`/products/${v.id}`);
                      const variantsToUse: Array<number> = set.productSetVariantsList.map(
                        (item) => item.variantId
                      );

                      if (!product.data.variants.length) {
                        prodsInfo[v.id] = {
                          ...product.data,
                          variants: [],
                        };
                      } else {
                        prodsInfo[v.id] = {
                          ...product.data,
                          variants: product.data.variants.filter(
                            (varnt: ProductRow["variants"][0]) =>
                              variantsToUse.some(
                                (item: number) => varnt.variantId === item
                              )
                          ),
                        };
                      }

                      if (product.data.variants.length) {
                        prodVars[v.id] =
                          set.productSetVariantsList[0].variantId;
                        return {
                          id: v.id,
                          name: v.name,
                          price: v.price,
                          chosen: false,
                          variants: set.productSetVariantsList.map(
                            (variant) => {
                              return {
                                id: variant.variantId,
                                name: variant.values
                                  .map(
                                    (v) =>
                                      `${v.optionData}: ${v.optionValueData}`
                                  )
                                  .join(" "),
                                count: 0,
                                price: product.data.variants?.length
                                  ? product.data.variants?.find(
                                      (item: ProductRow["variants"][0]) =>
                                        // @ts-ignore
                                        item.id === catData.data.variantId
                                    )?.price
                                  : null,
                              };
                            }
                          ),
                        };
                      } else {
                        prodVars[v.id] = null;

                        return {
                          id: v.id,
                          name: v.name,
                          price: v.price,
                          chosen: false,
                          count: 0,
                          variants: [],
                        };
                      }
                    }
                  )
                );
              }
              // @ts-ignore
              cats.push(catInfo);
            }
          })
        );
        if (currentPromotion && cats?.length) {
          currentPromotion.items.map((item) => {
            const cat = cats.find(
              (category) =>
                category.id === item.promotionCategoryId &&
                category.products.some((p) => p.id === item.id)
            );
            if (cat)
              cat.products.forEach((prod) => {
                if (prod.id === item.id) {
                  prod.chosen = true;
                  prod.variants.forEach((v) => {
                    if (v.id === Number(item.variantId)) {
                      v.count = item.quantity;
                    }
                  });
                  prod.count = item.quantity;
                }
              });
            return cat;
          });
        }
      }
      setProdVariants(prodVars);
      setProductsInfo(prodsInfo);
      setCategories(cats);
    };
    d();
  }, [promotion]);

  useEffect(() => {
    let total = 0;
    let calculatedPrice = 0;
    let addedFull = true;

    if (categories?.length) {
      categories.map((cat) => {
        cat.products.map((prod) => {
          if (prod.chosen) {
            if (prod.variants.length) {
              prod.variants.map((variant) => {
                if (variant.price != null) {
                  calculatedPrice += variant.price * variant.count;
                } else {
                  calculatedPrice += prod.price * variant.count;
                }
                total = total + variant.count;
                if (cat.max) {
                  if (!(variant.count === cat.max)) {
                    addedFull = false;
                  }
                }
              });
            } else {
              total = total + prod.count;
              calculatedPrice += prod.price * prod.count;
            }
          }
        });
      });
      if (!addedFull) {
        setDisabled(true);
      } else {
        const max = categories.reduce((summ, cat) => summ + cat.max, 0);
        if (total === max) {
          setDisabled(false);
        }
      }
      setTotalPrice(calculatedPrice);
    }
  }, [categories]);

  useEffect(() => {
    if (!selectedCategory && categories?.length) selectCategory(0);
  }, [categories]);

  useEffect(() => {
    const categoriesS: Array<number> = [];
    if (promotion) {
      promotion.productSetsList.map((v) => {
        categoriesS.push(v.promotionCategoryId);
      });
      const catProducts: CatProdIds = {};
      categoriesS.map((v) => {
        API.get(`/promotionCategories/${v}`).then((resp) => {
          catProducts[v] = {
            prodIds: resp.data.products.map(
              (v: PromCategory["products"][0]) => v.id
            ),
            name: resp.data.name,
          };
          setCatProdsIds({ ...catProducts });
        });
      });
    }
  }, [promotion]);

  useEffect(() => {
    setPromoSet(Number(Object.keys(catProdsIds)[0]));
  }, [catProdsIds]);

  useEffect(() => {
    API.get(`/promotions/${promotionId}`)
      .then(({ data }: { data: PromotionRow }) => {
        setProductSetsList(data.productSetsList);
        setPromotion(data);
      })
      .catch((error) => {
        alertError(
          alertContext,
          getErrorMsg(error.response, "Ошибка получения акции")
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promotionId]);

  // useEffect(() => {
  //   setSelectedProduct(products[0].id);
  // }, [products]);

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={true}
      fullWidth
      maxWidth={"md"}
      onBackdropClick={() => (touched ? setAlerted(true) : onClose())}
    >
      <DialogContent className={styles.content}>
        <div className={styles.items}>
          {products
            .filter((v) => {
              if (selectedCategory != null && categories) {
                return categories[selectedCategory].products
                  .map((prod) => prod.id)
                  .includes(v.id);
              }
            })
            .map((product, key) => (
              <div
                key={key}
                className={styles.itemWrapper}
                onClick={() => setSelectedProduct(product.id)}
              >
                <div
                  className={[
                    styles.item,
                    product.id === selectedProduct && styles.selected,
                  ].join(" ")}
                >
                  <div className={styles.imageWrapper}>
                    <img
                      alt={"thumbnail"}
                      src={product.thumbnail}
                      className={styles.image}
                    />
                  </div>
                  <div className={styles.description}>{product.name}</div>
                  <div>
                    {product.id === selectedProduct &&
                      categories &&
                      selectedCategory != null && (
                        <div style={{ padding: "5px" }}>
                          <OptionSelect
                            product={productsInfo[product.id]}
                            variant={prodVariants[product.id]}
                            setVariant={(i: ProductRow["variants"][0]) => {
                              if (i) {
                                setProdVariants((state) => {
                                  return {
                                    ...state,
                                    [product.id]: i.variantId,
                                  };
                                });
                              } else {
                                setProdVariants((state) => {
                                  return { ...state, [product.id]: null };
                                });
                              }
                            }}
                          />
                          {(function () {
                            if (
                              !categories[selectedCategory]?.products.find(
                                (p) => p.id === product.id
                              )?.variants.length
                            ) {
                              const prod = categories[
                                selectedCategory
                              ].products.find((p) => p.id === product.id);

                              return prod?.count ? (
                                <div
                                  style={{
                                    marginLeft: "10px",
                                    marginBottom: "6px",
                                  }}
                                >
                                  <FlexRow className={styles.quantityRow}>
                                    <RemoveCircleOutlineIcon
                                      onClick={() =>
                                        removeVariant(
                                          selectedCategory,
                                          product.id,
                                          null
                                        )
                                      }
                                    />
                                    {prod.count}
                                    <AddCircleOutlineIcon
                                      onClick={() =>
                                        addVariant(
                                          selectedCategory,
                                          product.id,
                                          null
                                        )
                                      }
                                    />
                                  </FlexRow>
                                  <div className={styles.productPrice}>
                                    {prod.price} ₽
                                  </div>
                                </div>
                              ) : (
                                <>
                                  <Button
                                    size={"small"}
                                    style={{
                                      marginLeft: "10px",
                                      marginBottom: "5px",
                                    }}
                                    color={"primary"}
                                    variant={"contained"}
                                    onClick={() =>
                                      addVariant(
                                        selectedCategory,
                                        product.id,
                                        null
                                      )
                                    }
                                  >
                                    Добавить
                                  </Button>
                                  <div className={styles.productPrice}>
                                    {product.price} ₽
                                  </div>
                                </>
                              );
                            }

                            const chosenVariant = categories[
                              selectedCategory
                            ]?.products
                              .find((p) => p.id === product.id)
                              ?.variants.find(
                                (v) => v.id === prodVariants[product.id]
                              );

                            // @ts-ignore
                            // @ts-ignore
                            return chosenVariant?.count ? (
                              <div
                                style={{
                                  marginLeft: "10px",
                                  marginBottom: "6px",
                                }}
                              >
                                <FlexRow className={styles.quantityRow}>
                                  <RemoveCircleOutlineIcon
                                    onClick={() =>
                                      removeVariant(
                                        selectedCategory,
                                        product.id,
                                        chosenVariant.id
                                      )
                                    }
                                  />
                                  {chosenVariant.count}
                                  <AddCircleOutlineIcon
                                    onClick={() =>
                                      addVariant(
                                        selectedCategory,
                                        product.id,
                                        chosenVariant.id
                                      )
                                    }
                                  />
                                </FlexRow>
                                <div className={styles.productPrice}>
                                  {chosenVariant.price} ₽
                                </div>
                              </div>
                            ) : (
                              <>
                                <Button
                                  size={"small"}
                                  style={{
                                    marginLeft: "10px",
                                    marginBottom: "5px",
                                  }}
                                  color={"primary"}
                                  variant={"contained"}
                                  disabled={
                                    product.hasVariants
                                      ? !categories[selectedCategory]?.products
                                          .find((p) => p.id === product.id)
                                          ?.variants.map((item) => item.id)
                                          // @ts-ignore
                                          .includes(prodVariants[product.id])
                                      : false
                                  }
                                  onClick={() =>
                                    addVariant(
                                      selectedCategory,
                                      product.id,
                                      prodVariants[product.id]
                                    )
                                  }
                                >
                                  Добавить
                                </Button>
                                <div className={styles.productPrice}>
                                  {chosenVariant
                                    ? chosenVariant.price
                                    : product.price}{" "}
                                  ₽
                                </div>
                              </>
                            );
                          })()}
                        </div>
                      )}
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className={styles.select}>
          <div className={styles.itemsSection}>
            <Typography variant={"subtitle1"}>{promotion?.name}</Typography>
            {categories != null &&
              categories.map((category, index) => (
                <Paper
                  className={
                    index === selectedCategory
                      ? `${styles.setCategory} ${styles.selectedCategory}`
                      : `${styles.setCategory} ${styles.nonSelectedCategory}`
                  }
                  onClick={() => selectCategory(index)}
                >
                  <Typography variant={"subtitle1"}>
                    {category.name}:{" "}
                    {category.products.reduce((summ: number, curr) => {
                      return (
                        summ +
                        (curr.variants.length
                          ? curr.variants.reduce(
                              (vars: number, v) => vars + v.count,
                              0
                            )
                          : curr.count)
                      );
                    }, 0)}{" "}
                    / {category.max}
                  </Typography>
                  {category.products.map((prod) => {
                    if (prod.chosen) {
                      const renderProd = products.find(
                        (rprod) => rprod.id === prod.id
                      );
                      return (
                        renderProd && (
                          <>
                            <ProductItem
                              thumbnail={renderProd.thumbnail}
                              name={renderProd.name}
                              description={renderProd.description}
                              onClick={() => setSelectedProduct(prod.id)}
                            />
                            {prod.variants.length ? (
                              prod.variants.map((variant) =>
                                variant.count ? (
                                  <div>
                                    <Divider />
                                    <div>
                                      <Typography variant={"overline"}>
                                        {variant.name}
                                      </Typography>
                                    </div>
                                    <FlexRow className={styles.quantityRow}>
                                      <RemoveCircleOutlineIcon
                                        onClick={() =>
                                          removeVariant(
                                            index,
                                            prod.id,
                                            variant.id
                                          )
                                        }
                                      />
                                      {variant.count}
                                      <AddCircleOutlineIcon
                                        onClick={() =>
                                          addVariant(index, prod.id, variant.id)
                                        }
                                      />
                                    </FlexRow>
                                  </div>
                                ) : (
                                  <></>
                                )
                              )
                            ) : prod.count ? (
                              <div>
                                <div>{prod.name}</div>
                                <FlexRow className={styles.quantityRow}>
                                  <RemoveCircleOutlineIcon
                                    onClick={() =>
                                      removeVariant(index, prod.id, null)
                                    }
                                  />
                                  {prod.count}
                                  <AddCircleOutlineIcon
                                    onClick={() =>
                                      addVariant(index, prod.id, null)
                                    }
                                  />
                                </FlexRow>
                              </div>
                            ) : (
                              <></>
                            )}
                          </>
                        )
                      );
                    }
                  })}
                </Paper>
              ))}
          </div>
          {promotion != null && (
            <div className={styles.actions}>
              <Typography>
                <b>
                  {promotion.price
                    ? promotion.price
                    : // @ts-ignore
                    totalPrice - promotion.discount > 0
                    ? // @ts-ignore
                      totalPrice - promotion.discount
                    : 0}{" "}
                  ₽
                </b>
              </Typography>
              <Button
                disabled={disabled}
                size="small"
                className={styles.actionBtn}
                autoFocus
                // onClick={handleClose}
                color="primary"
                variant="contained"
                onClick={() => addToCart()}
              >
                {currentPromotion ? "Сохранить" : "Добавить"}
              </Button>
            </div>
          )}
        </div>
      </DialogContent>
      {alerted && (
        <DialogActions>
          <Alert
            severity="warning"
            action={
              <>
                <Button color="inherit" size="small" onClick={() => onClose()}>
                  Да
                </Button>
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => setAlerted(false)}
                >
                  Нет
                </Button>
              </>
            }
          >
            Вы действительно хотите отменить все изменения и выйти? Введенные
            данные будут утеряны
          </Alert>
        </DialogActions>
      )}
    </Dialog>
  );
}
