import React from "react";
import { Typography } from "@material-ui/core";
import styles from "./index.module.scss";

export default function InfoBlock({
  title,
  children,
  titleLabel,
  style,
}: {
  title: string;
  children?: React.ReactNode;
  titleLabel?: React.ReactNode;
  style?: React.CSSProperties;
}) {
  return (
    <div style={style} className={[styles.infoBlock, "className"].join(" ")}>
      <Typography color="inherit" align="left" className={styles.title}>
        {title}
        {titleLabel}
      </Typography>
      {children}
    </div>
  );
}
