import React, { FC, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormHelperText from "@material-ui/core/FormHelperText";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Alert } from "@material-ui/lab";
import { EmployeeCreate, UserCookie } from "../../../_shared/types";
import { isObjectsEqual } from "../../../_shared/utils";
import { ProgressBar } from "../../_shared/ProgressBar";
import cookies from "../../../../cookies";

const useStyles = makeStyles({
  formControl: {
    minWidth: 120,
  },
  opacity1: { opacity: 1 },
  opacity0: { opacity: 0 },
  mt20: { marginTop: 20 },
  colorInactive: { color: "rgba(0, 0, 0, 0.54)" },
  colorError: { color: "#f44336" },
  blackField: { color: "rgba(0, 0, 0, 0.87)" },
  wrapper: {
    display: "inline",
  },
});

const NewEmployeeForm: FC<{
  onEmployeeCreate: (employee: EmployeeCreate) => Promise<any>;
}> = ({ onEmployeeCreate }) => {
  const [open, setOpen] = React.useState(false);

  const employeeInit = {
    roleId: 0,
    lastName: "",
    firstName: "",
    patronymic: "",
    password: "",
  };
  const [employee, setEmployee] = useState<EmployeeCreate>(employeeInit);

  const [progress, setProgress] = useState(false);

  const isBlurredInit = {
    lastName: false,
    firstName: false,
    patronymic: false,
    password: false,
    roleId: false,
  };
  const [isBlurred, setIsBlurred] = useState(isBlurredInit);

  const handleClickOpen = () => {
    setIsBlurred(isBlurredInit);
    setOpen(true);
    setAlerted(false);
    setEmployee(employeeInit);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const setBlurredField = (field: string) => {
    setIsBlurred({ ...isBlurred, [field]: true });
  };

  const classes = useStyles();

  const [alerted, setAlerted] = useState(false);

  const user: UserCookie | undefined = cookies.get("user");

  useEffect(() => {
    if (employee.roleId === 5 && employee.password !== null) {
      setEmployee((state) => {
        return { ...state, password: null };
      });
    }
  }, [employee]);

  return (
    <div className={classes.wrapper}>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Добавить сотрудника
      </Button>
      <Dialog
        disableBackdropClick
        onBackdropClick={() =>
          progress
            ? null
            : isObjectsEqual(employee, employeeInit)
            ? setOpen(false)
            : setAlerted(true)
        }
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Новый сотрудник</DialogTitle>
        <DialogContent>
          <form>
            <TextField
              autoFocus
              margin="dense"
              id="lastName"
              label="Фамилия"
              value={employee.lastName}
              onChange={(e) =>
                setEmployee({ ...employee, lastName: e.target.value })
              }
              type="text"
              fullWidth
              onBlur={() => setBlurredField("lastName")}
              error={!employee.lastName && isBlurred.lastName}
              helperText={
                !employee.lastName && isBlurred.lastName
                  ? "Обязательное поле"
                  : ""
              }
              required
            />
            <TextField
              margin="dense"
              id="firstName"
              label="Имя"
              value={employee.firstName}
              onChange={(e) =>
                setEmployee({ ...employee, firstName: e.target.value })
              }
              type="text"
              fullWidth
              onBlur={() => setBlurredField("firstName")}
              error={!employee.firstName && isBlurred.firstName}
              helperText={
                !employee.firstName && isBlurred.firstName
                  ? "Обязательное поле"
                  : ""
              }
              required
            />
            <TextField
              margin="dense"
              id="patronymic"
              label="Отчество"
              value={employee.patronymic}
              onChange={(e) =>
                setEmployee({ ...employee, patronymic: e.target.value })
              }
              type="text"
              fullWidth
              onBlur={() => setBlurredField("patronymic")}
              error={!employee.patronymic && isBlurred.patronymic}
              helperText={
                !employee.patronymic && isBlurred.patronymic
                  ? "Обязательное поле"
                  : ""
              }
              required
            />
            <TextField
              margin="dense"
              id="password"
              label="Пароль"
              value={employee.password}
              onChange={(e) =>
                setEmployee({ ...employee, password: e.target.value })
              }
              type="text"
              fullWidth
              onBlur={() => setBlurredField("password")}
              error={!employee.password && isBlurred.password}
              helperText={
                !employee.password && isBlurred.password
                  ? "Обязательное поле"
                  : ""
              }
              required={employee.roleId !== 5}
            />
            <FormControl
              fullWidth
              component="fieldset"
              className={classes.formControl}
            >
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={employee.roleId}
                inputProps={{ "aria-label": "Without label" }}
                displayEmpty
                onBlur={() => setBlurredField("roleId")}
                error={employee.roleId === 0 && isBlurred.roleId}
                defaultValue={employee.roleId}
                required
                onChange={(e: any) =>
                  setEmployee({ ...employee, roleId: e.target.value })
                }
                className={[
                  employee.roleId === 0 && !isBlurred.roleId
                    ? classes.colorInactive
                    : employee.roleId === 0 && isBlurred.roleId
                    ? classes.colorError
                    : classes.blackField,
                  classes.mt20,
                ].join(" ")}
              >
                <MenuItem value={0} disabled>
                  Роль *
                </MenuItem>
                <MenuItem value={1}>Суперадминистратор</MenuItem>
                <MenuItem value={2}>Администратор</MenuItem>
                <MenuItem value={3}>Оператор</MenuItem>
                <MenuItem value={5}>Курьер</MenuItem>
              </Select>
              <FormHelperText
                className={[
                  classes.colorError,
                  employee.roleId === 0 && isBlurred.roleId
                    ? classes.opacity1
                    : classes.opacity0,
                ].join(" ")}
              >
                Обязательное поле
              </FormHelperText>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          {alerted ? (
            <Alert
              severity="warning"
              action={
                <>
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => setOpen(false)}
                  >
                    Да
                  </Button>
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => setAlerted(false)}
                  >
                    Нет
                  </Button>
                </>
              }
            >
              Вы действительно хотите отменить все изменения и выйти? Введенные
              данные будут утеряны
            </Alert>
          ) : (
            <>
              <Button onClick={handleClose} color="primary" disabled={progress}>
                Отмена
              </Button>
              <Button
                onClick={() => {
                  setProgress(true);
                  onEmployeeCreate({ ...employee, cityId: user?.city.id })
                    .then((data) => setOpen(false))
                    .catch((error) => console.log(error))
                    .finally(() => setProgress(false));
                }}
                color="primary"
                type="submit"
                disabled={
                  !(employee.roleId === 5 && employee.lastName) &&
                  (employee.lastName === "" ||
                    employee.firstName === "" ||
                    employee.patronymic === "" ||
                    employee.password === "" ||
                    employee.roleId === 0 ||
                    progress)
                }
              >
                Создать
              </Button>
            </>
          )}
        </DialogActions>
        {progress && <ProgressBar bottom />}
      </Dialog>
    </div>
  );
};

export default NewEmployeeForm;
