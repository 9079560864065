import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  Button,
  TableBody,
  TableContainer,
  TablePagination,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { AlertContextType, OrderRow } from "../../../_shared/types";
import { AlertContext } from "../../_shared/ToastList";
import { OrdersContext } from "../../OrdersPage/OrdersContext";
import API from "../../../_shared/axios";
import {
  alertError,
  getIntFromString,
  getFormattedPhoneNumber,
  getTimePassed,
  getTimeLeft,
  getErrorMsg,
} from "../../../_shared/utils";
import { getOrdersQuery } from "../../OrdersPage/shared";
import { ProgressBar } from "../../_shared/ProgressBar";
import OrderForm from "../../OrdersPage/OrderForm";
import { Route, useRouteMatch } from "react-router-dom";
import { useHistory } from "react-router-dom";
import DispatcherOrderForm from "../OrderForm";
import { useDebounce } from "@react-hook/debounce";
import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";
import AndroidIcon from "@material-ui/icons/Android";
import AppleIcon from "@material-ui/icons/Apple";

const columns = [
  { id: "orderNumber", label: "Номер заказа" },
  { id: "clientPhoneNumber", label: "Клиент" },
  {
    id: "createdDate",
    label: "Создан",
  },
  { id: "source", label: "Источник", maxWidth: "70px" },
  {
    id: "readyTime",
    label: "Время Доставки",
  },
  {
    id: "customerAddress",
    label: "Адрес Доставки",
  },
  {
    id: "price",
    label: "Стоимость",
    maxWidth: "50px",
  },
  {
    id: "waitingTime",
    label: "Время выполнения",
  },
  {
    id: "status",
    label: "Статус",
  },
];

const useStyles = makeStyles({
  page: {
    height: "calc(100% - 64px)",
  },
  paginationPaper: { marginTop: 20 },
  count: {
    fontSize: 16,
    fontWeight: "bold",
  },
  header: {
    marginBottom: 20,
    padding: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 16,
  },
  phoneNumber: {
    margin: "0px 20px 20px",
  },
  cell: {
    minWidth: 155,
    "&:first-child": {
      minWidth: 140,
    },
    "&:nth-child(2), &:nth-child(3)": {
      minWidth: 170,
    },
    "&:nth-child(4), &:nth-child(5)": {
      minWidth: 120,
    },
    "&:last-child": {
      minWidth: 150,
    },
  },
  web: {
    color: "#07B4D5",
  },
  android: {
    color: "#96C03B",
  },
  ios: {
    color: "#FC7A3E",
  },
  content: {
    width: "95%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "20px 30px",
    margin: "0 auto 20px auto",
    "& .MuiTableCell-body": {
      cursor: "pointer",
    },
  },
  tablePaper: {
    scrollbarWidth: "none",
    flexGrow: 1,
    overflow: "scroll",
  },
  minWidth: {
    minWidth: 130,
  },
  label: {
    cursor: "pointer",
    marginLeft: 15,
    minHeight: 32,
    borderRadius: 16,
    display: "inline-flex",
    padding: "7px 12px",
    fontSize: 14,
    fontWeight: 500,
    alignItems: "center",
  },
  labelDisabled: { backgroundColor: "#ccc" },
  labelCreated: { backgroundColor: "#90caf9" },
  labelConfirmed: { backgroundColor: "#a5d6a7" },
  labelProcess: { backgroundColor: "#ffab91" },
});

const OrdersPage: FC = () => {
  const [rows, setRows] = useState<OrderRow[]>([]);
  const [newOrders, setNewOrders] = useState(0);
  const [clientSearch, setClientSearch] = useState("");
  const [clientFilter, setClientFilter] = useDebounce(
    {
      clientSearch: "",
      orderSearch: "",
      orderTypeSearch: [0, 0, 0],
      page: 0,
      rowsPerPage: 25,
    },
    400
  );
  const [orderSearch, setOrderSearch] = useState("");
  const [orderSearchDeb, setOrderSearchDeb] = useDebounce("", 500);
  const [ordersTypeSearch, setOrdersTypeSearch] = useState([0, 0, 0]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [ordersInProcessing, setOrdersInProcessing] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [progress, setProgress] = useState(false);
  const [orderId, setOrderId] = useState<number | undefined>(undefined);

  const classes = useStyles();
  const alertContext = useContext<AlertContextType>(AlertContext);
  const ordersContext = useContext(OrdersContext);

  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    if (ordersContext && ordersContext.isNeedToUpdate) {
      updateList();
      ordersContext.cancelUpdateOrders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersContext]);

  const updateList = useCallback(() => {
    API.get(
      getOrdersQuery(
        clientSearch,
        ordersTypeSearch,
        page + 1,
        rowsPerPage,
        "current",
        orderSearch
      )
    )
      .then(({ data }) => {
        const orders = data.data.items;
        setRows(orders);
        setNewOrders(data.newOrdersCount);
        setOrdersInProcessing(data.ordersInProcessingCount);
        setTotalOrders(data.data.totalItems);
        setProgress(false);
      })
      .catch((error) => {
        alertError(
          alertContext,
          getErrorMsg(error.response, "Ошибка получения списка заказов")
        );
        setProgress(false);
      });
  }, [clientSearch, orderSearch, ordersTypeSearch, page, rowsPerPage]);

  useEffect(() => {
    updateList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientFilter]);

  useEffect(() => {
    setPage(0);
    setClientFilter((state) => {
      return { ...state, page: 0 };
    });
  }, [clientSearch, ordersTypeSearch, orderSearchDeb]);

  const getOrderStatusLabel = (row: OrderRow) => (
    <span
      className={[
        classes.label,
        row.status.toLowerCase() === "создан"
          ? classes.labelCreated
          : row.status.toLowerCase() === "подтвержден"
          ? classes.labelConfirmed
          : classes.labelProcess,
      ].join(" ")}
    >
      {row.status}
    </span>
  );

  const changeSearchType = (index: number) => {
    let newOrdersTypeSearch = [0, 0, 0];
    newOrdersTypeSearch[index] = ordersTypeSearch[index] === 0 ? 1 : 0;
    setOrdersTypeSearch(newOrdersTypeSearch);
    setClientFilter((state) => {
      return { ...state, ordersTypeSearch: newOrdersTypeSearch };
    });
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
    setClientFilter((state) => {
      return { ...state, page: newPage };
    });
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setClientFilter((state) => {
      return {
        ...state,
        rowsPerPage: parseInt(event.target.value, 10),
        page: 0,
      };
    });
  };

  useEffect(() => {
    if (orderId) history.push(`${match.path}/order-form/${orderId}`);
  }, [history, match.path, orderId]);

  return (
    <div className={classes.page}>
      <OrderForm
        updateOrdersList={updateList}
        orderId={orderId}
        onClose={() => setOrderId(undefined)}
      />
      <Route path="/order-form/:id" exact component={DispatcherOrderForm} />
      <div className={classes.content}>
        <h2 style={{ marginTop: 0 }}>Заказы</h2>
        <Paper elevation={2} className={classes.header}>
          <span>
            <span>Новые заказы:</span>&nbsp;
            <span className={classes.count}>{newOrders}</span>
          </span>
          <span>
            <span>Заказы в работе:</span>&nbsp;
            <span className={classes.count}>{ordersInProcessing} </span>
          </span>
          <span>
            <span>Всего активных заказов:</span>&nbsp;
            <span className={classes.count}>{totalOrders}</span>
          </span>
        </Paper>
        <Paper elevation={2} className={classes.header}>
          <div>
            <TextField
              className={classes.phoneNumber}
              id={"servicePhone"}
              label={"Клиент"}
              name={"servicePhone"}
              margin="normal"
              inputProps={{ maxLength: 11 }}
              type="text"
              value={clientSearch}
              onChange={(e) => {
                // if (e.target.value) return null;
                const v = String(getIntFromString(e.target.value));
                setClientSearch(v === "0" ? "" : v);
                setClientFilter((state) => {
                  return { ...state, clientSearch: v === "0" ? "" : v };
                });
              }}
            />
            <TextField
              label="№ заказа"
              inputProps={{ maxLength: 8 }}
              value={orderSearch}
              onChange={(e) => {
                if (e.target.value === " ") return null;
                const v = String(getIntFromString(e.target.value));
                setOrderSearch(v === "0" ? "" : v);
                setOrderSearchDeb(v === "0" ? "" : v);
                setClientFilter((state) => {
                  return { ...state, orderSearch: v === "0" ? "" : v };
                });
              }}
            />
          </div>
          <span>
            <span
              className={[
                classes.label,
                ordersTypeSearch[0] === 1
                  ? classes.labelCreated
                  : classes.labelDisabled,
              ].join(" ")}
              onClick={() => changeSearchType(0)}
            >
              Созданные
            </span>
            <span
              className={[
                classes.label,
                ordersTypeSearch[1] === 1
                  ? classes.labelConfirmed
                  : classes.labelDisabled,
              ].join(" ")}
              onClick={() => changeSearchType(1)}
            >
              Подтвержденные
            </span>
            <span
              className={[
                classes.label,
                ordersTypeSearch[2] === 1
                  ? classes.labelProcess
                  : classes.labelDisabled,
              ].join(" ")}
              onClick={() => changeSearchType(2)}
            >
              Обрабатываются
            </span>
          </span>
        </Paper>
        <Button
          size="small"
          // onClick={handleClose}
          color="primary"
          variant="contained"
          style={{ width: "150px" }}
          onClick={() => history.push(`${match.path}/order-form/new`)}
        >
          Создать заказ
        </Button>
        {progress ? (
          <div className={classes.content}>
            <ProgressBar />
          </div>
        ) : (
          <Paper className={classes.tablePaper}>
            <TableContainer>
              <Table aria-label="simple table" size={"small"}>
                <TableHead>
                  <TableRow>
                    {columns.map((column, index) => (
                      <TableCell
                        key={column.id}
                        className={classes.cell}
                        style={{
                          width: column.maxWidth ? column.maxWidth : "unset",
                        }}
                        align={index === columns.length - 1 ? "center" : "left"}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, key) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={key}
                        onClick={() => setOrderId(row.id)}
                      >
                        <TableCell>{row.orderNumber}</TableCell>
                        <TableCell>
                          {getFormattedPhoneNumber(row.customerPhoneNumber)}
                        </TableCell>
                        <TableCell className={classes.minWidth}>
                          {getTimePassed(row.createdAt)}
                        </TableCell>
                        <TableCell style={{ width: "70px" }}>
                          {row.sourceId === 0 ? (
                            <DesktopWindowsIcon className={classes.web} />
                          ) : row.sourceId === 1 ? (
                            <AndroidIcon className={classes.android} />
                          ) : (
                            <AppleIcon className={classes.ios} />
                          )}
                        </TableCell>
                        <TableCell className={classes.minWidth}>
                          {row.readyTime
                            ? getTimeLeft(row.readyTime)
                            : "как можно скорее"}
                        </TableCell>
                        <TableCell>
                          {row.customerAddress
                            ? row.customerAddress
                            : "Самовывоз"}
                        </TableCell>
                        <TableCell>{row.totalCost} &#8381;</TableCell>
                        <TableCell>
                          {row.waitingTime ? row.waitingTime : "-"}
                        </TableCell>
                        <TableCell
                          align={"center"}
                          style={{ wordBreak: "normal" }}
                        >
                          {getOrderStatusLabel(row)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}

        <Paper className={classes.paginationPaper}>
          <TablePagination
            labelRowsPerPage={"Заказов на странице:"}
            labelDisplayedRows={({ from, to }) =>
              `${from}-${to} из ${totalOrders}`
            }
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={totalOrders}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </div>
  );
};

export default OrdersPage;
