import React, { FC, useContext, useEffect, useState } from "react";
import {
  ActivityStatus,
  AlertContextType,
  Employee,
  EmployeeCreate,
  EmployeeEdit,
} from "../../../_shared/types";
import {
  alertError,
  alertSuccess,
  getErrorMsg,
  renderDate,
} from "../../../_shared/utils";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import NewEmployeeForm from "./NewEmployeeForm";
import API from "../../../_shared/axios";
import { ToggleButton } from "@material-ui/lab";
import EditEmployeeForm from "./EditEmployeeForm";
import cookies from "../../../../cookies";
import useStyles from "../../_shared/styles";
import { AlertContext } from "../../_shared/ToastList";
import { makeStyles } from "@material-ui/styles";
import { ProgressBar } from "../../_shared/ProgressBar";
import { StatusContext } from "../../../_shared/StatusContext";

const styles = makeStyles({
  page: { margin: 0 },
  content: {
    padding: "25px 50px 50px",
    maxWidth: 1140,
    margin: "0 auto",
    "& .MuiTableCell-body": {
      cursor: "pointer",
    },
  },
  nameBlockWrapper: {
    display: "flex",
    alignItems: "center",
    "& > div:first-child": {
      marginRight: 13,
    },
  },
  indicator: {
    width: 8,
    height: 8,
    borderRadius: "50%",
  },
  onlineIndicator: {
    backgroundColor: "rgb(25, 196, 172)",
  },
  offlineIndicator: {
    backgroundColor: "rgba(0, 0, 0, 0.12)",
    // backgroundColor: '#e34c26',
  },
  afkIndicator: {
    backgroundColor: "#f1e05a",
  },
  toggleButton: { maxWidth: "9rem", minWidth: "9rem", maxHeight: "2.5rem" },
  clientTable: {
    "& th": {
      minWidth: 160,
    },
  },
});

const EmployeesPage: FC = () => {
  const [editEmployeeId, setEditEmployeeId] = useState<number | undefined>(
    undefined
  );
  const [progress, setProgress] = useState(false);

  const classes = styles();
  const blocked = useStyles().blocked;

  const alertContext = useContext<AlertContextType>(AlertContext);

  const [rows, setRows] = useState<Employee[]>([]);

  const [employeeStatus, setEmployeesStatus] = useState<
    | {
        online: string[];
        inactive: string[];
      }
    | undefined
  >(undefined);

  const statusContext = useContext(StatusContext);

  useEffect(() => {
    setProgress(true);
    API.get(`/employees`)
      .then(({ data }) => {
        setRows(data);
        setProgress(false);
      })
      .catch((error) => {
        alertError(
          alertContext,
          getErrorMsg(error.response, "Ошибка получения списка сотрудников")
        );
        setProgress(false);
      });
    API.get(`${process.env.REACT_APP_BASE_PATH}signalRStatus`).then(
      ({ data }) => {
        setEmployeesStatus({
          online: data.online.map(
            (el: { employeeId: number }) => el.employeeId
          ),
          inactive: data.inactive.map(
            (el: { employeeId: number }) => el.employeeId
          ),
        });
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setEmployeesStatus(statusContext.employeeStatus);
  }, [statusContext]);

  const onEmployeeCreate = (data: EmployeeCreate) =>
    new Promise((resolve, reject) =>
      API.post("/employees", data)
        .then(({ data }) => {
          alertSuccess(alertContext, "Сотрудник добавлен");
          API.get(`/employees`)
            .then(({ data }) => {
              setRows(data);
              resolve(data);
            })
            .catch((error) => {
              alertError(
                alertContext,
                getErrorMsg(
                  error.response,
                  "Ошибка обновления списка сотрудников"
                )
              );
              reject(error);
            });
        })
        .catch((error) => {
          alertError(
            alertContext,
            getErrorMsg(error.response, "Ошибка добавления сотрудника")
          );
          reject(error);
        })
    );

  const onEmployeeEdit = (id: number, employee: EmployeeEdit) =>
    new Promise((resolve, reject) =>
      API.patch(`/employees/${id}`, employee)
        .then(({ data }) => {
          alertSuccess(alertContext, "Успешное редактирование сотрудника");
          API.get(`/employees`)
            .then(({ data }) => {
              setRows(data);
              resolve(data);
            })
            .catch((error) => {
              alertError(
                alertContext,
                getErrorMsg(
                  error.response,
                  "Ошибка обновления списка сотрудника"
                )
              );
              reject(error);
            });
        })
        .catch((error) => {
          alertError(
            alertContext,
            getErrorMsg(error.response, "Ошибка редактирования сотрудника")
          );
          reject(error);
        })
    );

  const myLogin = cookies.get("user").login;

  const getEmployeeActivityClass = (id: number) =>
    employeeStatus?.online.includes(String(id))
      ? classes.onlineIndicator
      : employeeStatus?.inactive.includes(String(id))
      ? classes.afkIndicator
      : classes.offlineIndicator;

  return (
    <div className={classes.page}>
      <EditEmployeeForm
        onEmployeeEdit={onEmployeeEdit}
        employeeId={editEmployeeId}
        onClose={() => setEditEmployeeId(undefined)}
      />
      <div className={classes.content}>
        <h2>
          Сотрудники &nbsp;&nbsp;{""}
          <NewEmployeeForm onEmployeeCreate={onEmployeeCreate} />
        </h2>
        {progress ? (
          <ProgressBar />
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table aria-label="simple table" className={classes.clientTable}>
                <TableHead>
                  <TableRow>
                    <TableCell>ФИО</TableCell>
                    <TableCell align="left">Логин</TableCell>
                    <TableCell align="left">Роль сотрудника</TableCell>
                    <TableCell align="left">Последняя активность</TableCell>
                    <TableCell align="left">Статус сотрудника</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow
                      key={row.id}
                      hover
                      className={!row.isActive ? blocked : undefined}
                      onClick={() =>
                        row.login !== myLogin ? setEditEmployeeId(row.id) : null
                      }
                    >
                      <TableCell component="th" scope="row">
                        <div className={classes.nameBlockWrapper}>
                          <div>
                            <div
                              className={[
                                classes.indicator,
                                getEmployeeActivityClass(row.id),
                              ].join(" ")}
                            />
                          </div>
                          <div>{`${row.lastName} ${row.firstName} ${row.patronymic}`}</div>
                        </div>
                      </TableCell>
                      <TableCell align="left">{row.login}</TableCell>
                      <TableCell align="left">
                        {row.roles.length === 1
                          ? row.roles[0]
                          : row.roles.concat(", ").slice(-1)}
                      </TableCell>
                      <TableCell align="left">
                        {row.lastActive
                          ? renderDate(row.lastActive, false, true)
                          : ""}
                      </TableCell>
                      <TableCell align="left">
                        <ToggleButton
                          className={classes.toggleButton}
                          selected={row.isActive}
                          value={row.isActive}
                          onChange={(e) => {
                            e.stopPropagation();
                            API.post(
                              `/employees/${row.id}/${
                                row.isActive ? "block" : "unblock"
                              }`
                            )
                              .then(() =>
                                API.get(`/employees`)
                                  .then(({ data }) => setRows(data))
                                  .catch((error) => {
                                    alertError(
                                      alertContext,
                                      getErrorMsg(
                                        error.response,
                                        "Ошибка обновления списка сотрудников"
                                      )
                                    );
                                  })
                              )
                              .catch((error) => {
                                alertError(
                                  alertContext,
                                  getErrorMsg(
                                    error.response,
                                    "Ошибка блокировки сотрудников"
                                  )
                                );
                              });
                          }}
                        >
                          {ActivityStatus[row.isActive.toString()]}
                        </ToggleButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </div>
    </div>
  );
};

export default EmployeesPage;
