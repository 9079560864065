import React, { FC } from "react";
import Grid from "@material-ui/core/Grid";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  toggleButton: {
    color: "rgba(0, 0, 0, 0.87)",
    backgroundColor: "#e0e0e0 !important",
    borderRadius: "25% !important",
    width: 40,
    height: 40,
    margin: "0 5px 0 0 !important",
    "&.Mui-selected": {
      color: "white",
      backgroundColor: "#3f51b5 !important",
    },
  },
});

const MultipleCheckboxDate: FC<{
  data: string[];
  setState: React.Dispatch<React.SetStateAction<string[]>>;
}> = ({ data, setState }) => {
  const handleFormat = (event: object, newData: any) => {
    if (newData.length) {
      setState(newData);
    }
  };

  const classes = useStyles();

  return (
    <Grid item sm={12} md={6}>
      <ToggleButtonGroup
        value={data}
        onChange={handleFormat}
        aria-label="device"
      >
        <ToggleButton
          value="1"
          aria-label="monday"
          className={classes.toggleButton}
        >
          ПН
        </ToggleButton>
        <ToggleButton
          value="2"
          aria-label="tuesday"
          className={classes.toggleButton}
        >
          ВТ
        </ToggleButton>
        <ToggleButton
          value="3"
          aria-label="wednesday"
          className={classes.toggleButton}
        >
          СР
        </ToggleButton>
        <ToggleButton
          value="4"
          aria-label="thursday"
          className={classes.toggleButton}
        >
          ЧТ
        </ToggleButton>
        <ToggleButton
          value="5"
          aria-label="friday"
          className={classes.toggleButton}
        >
          ПТ
        </ToggleButton>
        <ToggleButton
          value="6"
          aria-label="saturday"
          className={classes.toggleButton}
        >
          СБ
        </ToggleButton>
        <ToggleButton
          value="0"
          aria-label="sunday"
          className={classes.toggleButton}
        >
          ВС
        </ToggleButton>
      </ToggleButtonGroup>
    </Grid>
  );
};

export default MultipleCheckboxDate;
