import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { FC } from "react";
import { OrderCommonPromoCodeItem } from "../../_shared/types";
import { OrderCommonPromoCode } from "../../_shared/types";

const useStyles = makeStyles({
  promoCode: {
    "& td": {
      fontWeight: 500,
    },
  },
  firtCellOfPresent: {
    paddingLeft: "30px",
  },
});

const PromoCodeInfoTable: FC<{
  promoCode: OrderCommonPromoCode;
}> = ({ promoCode }) => {
  const classes = useStyles();
  console.log(promoCode);
  let promoCodeColumns;
  if (promoCode?.presents?.length > 0) {
    promoCodeColumns = [
      { id: "id", label: "ID" },
      { id: "name", label: "Наименование" },
      { id: "count", label: "Количество" },
      { id: "option", label: "Опция" },
      { id: "value", label: "Значение" },
    ];
  } else {
    promoCodeColumns = [
      { id: "id", label: "ID" },
      { id: "name", label: "Наименование" },
      { id: "value", label: "Значение" },
      {
        id: "discount",
        label: "Скидка",
      },
    ];
  }

  return (
    <Table aria-label="simple table">
      <TableHead>
        <TableRow>
          {promoCodeColumns.map((column) => (
            <TableCell key={column.id}>{column.label}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow className={classes.promoCode}>
          {promoCodeColumns.map((column) => {
            return (
              <TableCell key={column.id}>
                {column.id in promoCode ? promoCode[column.id] : "-"}
              </TableCell>
            );
          })}
        </TableRow>
        {promoCode?.presents ? (
          promoCode?.presents.map(
            (i: OrderCommonPromoCodeItem, index: number) => {
              return (
                <TableRow key={index}>
                  <TableCell>''</TableCell>
                  <TableCell className={classes.firtCellOfPresent}>
                    {i?.name}
                  </TableCell>
                  <TableCell>{i?.count}</TableCell>
                  <TableCell>
                    {i.variant?.values
                      ? i.variant?.values.map((i) => `${i.optionValueData} `)
                      : "-"}
                  </TableCell>
                  <TableCell>{"-"}</TableCell>
                </TableRow>
              );
            }
          )
        ) : (
          <></>
        )}
      </TableBody>
    </Table>
  );
};
export default PromoCodeInfoTable;
