import { WorkingTime } from "../../_shared/types";

export const getTTStatus = (timetable: WorkingTime, roundTheClock: boolean) => {
  const ttComplete =
    Object.keys(timetable)
      .map(
        (key) =>
          !isNaN(timetable[key as keyof WorkingTime].from.getTime()) &&
          !isNaN(timetable[key as keyof WorkingTime].till.getTime())
      )
      .filter((b) => b === false).length === 0 || roundTheClock;

  const ttIsBlank =
    Object.keys(timetable)
      .map(
        (key) =>
          isNaN(timetable[key as keyof WorkingTime].from.getTime()) &&
          isNaN(timetable[key as keyof WorkingTime].till.getTime())
      )
      .filter((b) => b === false).length === 0;

  return { ttComplete, ttIsBlank };
};
