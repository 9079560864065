import { FormControl, MenuItem, Select, TextField } from "@material-ui/core";
import React from "react";

export default function CustomSelect({
  value,
  onChange,
  data,
  className,
  open,
  disabled,
}: {
  value: number | null;
  onChange: (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => void;
  data: { id: number; value: string }[];
  className?: string;
  open?: boolean;
  disabled?: boolean;
}) {
  return (
    <FormControl>
      {value && (
        <Select
          variant={"outlined"}
          style={{ height: "40px" }}
          disabled={!data.length || disabled}
          open={open}
          value={value}
          onChange={onChange}
          className={className}
        >
          {data.map((i) => (
            <MenuItem key={i.id} value={i.id}>
              {i.value}
            </MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
  );
}
