import { Button, makeStyles } from "@material-ui/core";
import React, { FC, useContext, useEffect, useState } from "react";
import API from "../../_shared/axios";
import {
  AlertContextType,
  PresentVariantValue,
  ProductRow,
} from "../../_shared/types";
import SyncTable from "./syncTable";
import { AlertContext } from "../_shared/ToastList";
import { alertError, alertSuccess, getErrorMsg } from "../../_shared/utils";
import { TouchedAlert } from "./index";
import { useDebounce } from "@react-hook/debounce";

export interface ISyncItemProductTransformed {
  syncId: number;
  id: number;
  syncName: string;
  productId?: number | null;
  variantId?: number | null;
  relation?: ProductRowTransormed | null;
}

type iVariants = {
  values: PresentVariantValue[];
  variantId: number;
};

export type ProductRowTransormed = ProductRow & {
  fullName: string;
  variantId?: number | null;
};

type SyncPostProductData = {
  variantId?: number | null;
  productId?: number | null;
  syncProductId: number;
};

const useStyles = makeStyles({
  page: {
    margin: 0,
  },
  content: {
    padding: 50,
    "& .MuiTableCell-body": {
      cursor: "pointer",
    },
  },
  heading: {
    display: "flex",
    alignItems: "center",
  },
});

const ProductsPage: FC = () => {
  const classes = useStyles();
  const alertContext = useContext<AlertContextType>(AlertContext);
  const touchedContext = useContext(TouchedAlert);

  const [backendData, setBackendData] = useState<
    ProductRowTransormed[] | undefined
  >(undefined);
  const [oneC_data, setOneC_data] = useState<ISyncItemProductTransformed[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [changedData, setChangedData] = useState<SyncPostProductData[]>([]);
  const [unusedBackendData, setUnusedBackendData] = useState<
    ProductRowTransormed[]
  >([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [search1CString, setSearch1CString] = useState("");
  const [searchBackendString, setSearchBackendString] = useState("");
  // 0 - no filter, 1 - synced, 2 - not synced
  const [filter, setFilter] = useState(0);
  const [filters, setFilters] = useDebounce(
    {
      page: 0,
      pageSize: 25,
      search1CString: "",
      searchBackendString: "",
      filter: 0,
    },
    500
  );

  useEffect(() => {
    setFilters({ filter, page, pageSize, search1CString, searchBackendString });
  }, [filter, page, pageSize, search1CString, searchBackendString]);

  useEffect(() => {
    const getData = async () => {
      const arr: ProductRowTransormed[] = [];
      await API.get("/products")
        .then((data) => {
          const products = Promise.all(
            data.data.map(async (i: ProductRow) => {
              try {
                const req: ProductRow = await (
                  await API.get(`/products/${i.id}`)
                ).data;
                if (req.variants.length > 0) {
                  const productWithVariants = req.variants.map(
                    (prod: iVariants) => {
                      return {
                        ...req,
                        fullName:
                          req.name +
                          " " +
                          prod.values
                            .map(
                              (item) =>
                                item.optionData + " " + item.optionValueData
                            )
                            .join(" "),
                        variantId: prod.variantId,
                      };
                    }
                  );
                  arr.push(...productWithVariants);
                  return productWithVariants;
                }
                arr.push({ ...req, variantId: null, fullName: req.name });
                return [req];
              } catch (e) {
                return undefined;
              }
            })
          );
          products.then((value) => {
            setBackendData(arr);

            API.get(`/syncProductList?page=1&pageSize=999999`)
              .then((syncResp) => {
                const usedProds = syncResp.data.items.filter(
                  (v: any) => v.productId !== null
                );
                setUnusedBackendData(
                  arr.filter(
                    (prod) =>
                      !usedProds.some((usedProd: any) =>
                        usedProd.variantId
                          ? usedProd.productId === prod.id &&
                            usedProd.variantId === prod.variantId
                          : usedProd.productId === prod.id
                      )
                  )
                );
              })
              .catch((error) => {
                console.log(error);
                alertError(
                  alertContext,
                  getErrorMsg(
                    error.response,
                    "Неизвестная ошибка получения списка синхронизации"
                  )
                );
              });
          });
        })
        .catch((error) => {
          console.log(error);
          alertError(
            alertContext,
            getErrorMsg(
              error.response,
              "Неизвестная ошибка получения списка продуктов"
            )
          );
        });
    };
    getData();
  }, []);

  const load = () => {
    if (Array.isArray(backendData)) {
      const uriStrings = [];
      let indx = 0;

      if (search1CString) {
        uriStrings.push(
          `&FilterParams[${indx}].ColumnName=syncName` +
            `&FilterParams[${indx}].FilterOption=3` +
            `&FilterParams[${indx}].filterValue=${search1CString}`
        );
        indx += 1;
      }
      if (filter !== 0) {
        uriStrings.push(
          `${
            filter === 1
              ? `&FilterParams[${indx}].ColumnName=productId` +
                `&FilterParams[${indx}].FilterOption=12`
              : filter === 2
              ? `&FilterParams[${indx}].ColumnName=productId` +
                `&FilterParams[${indx}].FilterOption=11`
              : ""
          }`
        );
      }

      let urlParams =
        `pageNumber=${page + 1}` +
        `&pageSize=${pageSize}` +
        `${uriStrings.join("")}`;

      API.get(`/syncProductList?${urlParams}`)
        .then((data) => {
          setTotalItems(data.data.totalItems);
          setOneC_data(
            data.data.items
              .map((item: ISyncItemProductTransformed) => {
                return {
                  ...item,
                  relation: backendData.find((j) => {
                    if (item.variantId) {
                      return (
                        j?.id === item?.productId &&
                        j.variantId === item?.variantId
                      );
                    } else {
                      return j?.id === item?.productId;
                    }
                  }),
                };
              })
              .sort((a: ISyncItemProductTransformed) =>
                a?.productId === null ? 1 : -1
              )
          );
          // console.log(oneC_data);
        })
        .catch((error) => {
          console.log(error);
          alertError(
            alertContext,
            getErrorMsg(
              error.response,
              "Неизвестная ошибка получения списка синхронизации"
            )
          );
        });
    }
  };

  useEffect(() => {
    load();
  }, [backendData, filters]);

  const transformDataForPost = (
    backendObj: ProductRowTransormed | null | undefined,
    oneC_obj: ISyncItemProductTransformed,
    index: number
  ) => {
    touchedContext.setter(true);
    const idx = changedData.findIndex(
      (i) => i.syncProductId === oneC_obj.syncId
    );
    if (idx !== -1) {
      setChangedData((prevStat: SyncPostProductData[]) => {
        prevStat[idx] = {
          syncProductId: oneC_obj.syncId,
          productId: backendObj ? backendObj.id : null,
          variantId: backendObj ? backendObj?.variantId : null,
        };
        return [...prevStat];
      });
    } else {
      setChangedData((prevStat: SyncPostProductData[]) => {
        return [
          ...prevStat,
          {
            syncProductId: oneC_obj.syncId,
            productId: backendObj ? backendObj.id : null,
            variantId: backendObj ? backendObj?.variantId : null,
          },
        ];
      });
    }

    setOneC_data((prevState) => {
      const idx = prevState.findIndex((s) => {
        return s.syncId === oneC_obj.syncId;
      });
      const obj3: ISyncItemProductTransformed = {
        ...oneC_obj,
        relation: backendObj,
        productId: backendObj ? backendObj.id : null,
        variantId: backendObj ? backendObj.variantId : null,
      };
      if (idx !== -1) {
        prevState[idx] = { ...obj3 };
      } else {
        prevState = [...prevState, { ...obj3 }];
      }

      return [...prevState];
    });
  };

  const postData = () => {
    API.post("/mapSyncProducts", changedData)
      .then((data) => {
        touchedContext.setter(false);
        alertSuccess(alertContext, "Данные успешно синхронизированы");
      })
      .catch((error) => {
        console.log(error);
        alertError(
          alertContext,
          getErrorMsg(error.response, "Неизвестная ошибка синхронизации")
        );
      });
  };

  useEffect(() => {
    const prep = backendData?.filter((backendItem: ProductRowTransormed) => {
      return !oneC_data.some((item) => {
        if (item.variantId) {
          return (
            backendItem?.id === item?.productId &&
            backendItem.variantId === item?.variantId
          );
        } else {
          return backendItem?.id === item?.productId;
        }
      });
    });
    if (prep) {
      setUnusedBackendData([...prep]);
    }
  }, [backendData, oneC_data, changedData]);

  return (
    <div className={classes.content}>
      <div className={classes.heading}>
        <h2>
          Синхронизация &nbsp;&nbsp;{" "}
          <Button variant="outlined" color="primary" onClick={() => postData()}>
            Синхронизировать
          </Button>
        </h2>
      </div>
      <SyncTable<ISyncItemProductTransformed, ProductRowTransormed>
        title="Продукты"
        changeDatHandler={(
          v: ProductRowTransormed,
          name: ISyncItemProductTransformed,
          index: number
        ) => transformDataForPost(v, name, index)}
        filteredGetData={unusedBackendData}
        data={oneC_data}
        totalItems={totalItems}
        page={page}
        pageChange={(page) => setPage(page)}
        pageSize={pageSize}
        pageSizeChange={(pageSize) => setPageSize(pageSize)}
        search1CString={search1CString}
        setSearch1CString={(str) => {
          setPage(0);
          setSearch1CString(str);
        }}
        searchBackendString={searchBackendString}
        setSearchBackendString={(str) => {
          setPage(0);
          setSearchBackendString(str);
        }}
        filter={filter}
        setFilter={(val) => {
          setPage(0);
          setFilter(val);
        }}
      />
    </div>
  );
};
export default ProductsPage;
