import React, { FC, useContext, useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Button,
  Checkbox,
  Chip,
  DialogActions,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { alertError, copyObject, getIntFromString } from "../../_shared/utils";
import {
  AlertContextType,
  ProductRow,
  ProductSet,
  PromotionCategoryRibbon,
} from "../../_shared/types";
import TextField from "@material-ui/core/TextField";
import API from "../../_shared/axios";
import { AlertContext } from "../_shared/ToastList";

const useStyles = makeStyles({
  page: {
    "& .MuiFormControlLabel-root": {
      display: "flex",
      justifyContent: "space-between !important",
    },
  },
  entityList: {
    "& > div": {
      margin: "0 10px 25px 0",
      color: "rgba(0, 0, 0, 0.87) !important",
      padding: 5,
      "& > span": {
        fontWeight: "bold !important",
        fontSize: "14px !important",
      },
    },
  },
  colorBlack: { color: "rgba(0, 0, 0, 0.87)" },
  colorError: { color: "#f44336" },
  mt10: {
    marginTop: 10,
  },
  mt30: {
    marginTop: 30,
  },
});

const SelectPromotionProductForm: FC<{
  title: string;
  data: PromotionCategoryRibbon[];
  onConfirm: (data: ProductSet[]) => void;
  onClose: () => void;
  isOpen: boolean;
}> = ({ title, data, isOpen, onConfirm, onClose }) => {
  const [promotionCategory, setPromotionCategory] = useState(0);
  const [product, setProduct] = useState(0);
  const [productsCount, setProductsCount] = useState(0);
  const [isFree, setIsFree] = useState(false);
  const [variantList, setVariantList] = useState<
    {
      price: number;
      variantId: number;
      values: {
        optionId: number;
        optionData: string;
        optionValueId: number;
        optionValueData: string;
      }[];
    }[]
  >([]);
  const [oldVariantList, setOldVariantList] = useState<
    {
      price: number;
      variantId: number;
      values: {
        optionId: number;
        optionData: string;
        optionValueId: number;
        optionValueData: string;
      }[];
    }[]
  >([]);

  const classes = useStyles();
  const alertContext = useContext<AlertContextType>(AlertContext);

  useEffect(() => {
    if (isOpen) {
      setVariantList([]);
      setIsBlurred(isBlurredInit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isOpen]);

  const getFilteredVariants = () => {
    let productIds: number[] = [];
    if (product === 0) {
      productIds = data
        .find((el) => el.id === promotionCategory)!
        .products.map((el) => el.id);
    } else {
      productIds = [
        data
          .find((el) => el.id === promotionCategory)!
          .products.find((el) => el.id === product)!.id,
      ];
    }
    let responseGetCount = 0;
    let productsVariants: {
      variantId: number;
      price: number;
      values: { optionId: number; optionValueId: number }[];
    }[] = [];
    let productsArr: ProductRow[] = [];
    productIds.forEach((id) =>
      API.get(`/products/${id}`)
        .then(({ data }: { data: ProductRow }) => {
          responseGetCount = responseGetCount + 1;
          if (data.isActive) {
            productsVariants = [...productsVariants, ...data.variants];
            productsArr = [...productsArr, data];
          }
          if (responseGetCount === productIds.length) {
            const filteredDuplicateVariants = productsVariants.filter(
              (el, index) =>
                productsVariants
                  .map((el) => el.variantId)
                  .indexOf(el.variantId) === index
            );
            const productsArrVariantsIds = productsArr.map((el) =>
              el.variants.map((el) => el.variantId)
            );
            const filteredUnitedVariants = filteredDuplicateVariants.filter(
              (el) => {
                let united = true;
                for (let ids of productsArrVariantsIds) {
                  if (!ids.includes(el.variantId)) united = false;
                }
                return united;
              }
            );
            // @ts-ignore
            setVariantList(filteredUnitedVariants);
            // @ts-ignore
            setOldVariantList(filteredUnitedVariants);
          }
        })
        .catch((error) => alertError(alertContext, "Ошибка получения товара"))
    );
  };

  const getProductSetLabel = (entity: {
    price: number;
    variantId: number;
    values: {
      optionId: number;
      optionData: string;
      optionValueId: number;
      optionValueData: string;
    }[];
  }) => {
    let result = "";
    for (let value of entity.values) {
      result = result + `${value.optionData}: ${value.optionValueData}; `;
    }
    return result.slice(0, -2);
  };

  const isBlurredInit = {
    promotionCategory: false,
    productsCount: false,
  };
  const [isBlurred, setIsBlurred] = useState(isBlurredInit);
  const setBlurredField = (field: string) => {
    setIsBlurred({ ...isBlurred, [field]: true });
  };

  const handleClose = () => {
    setPromotionCategory(0);
    setProduct(0);
    setProductsCount(0);
    onClose();
  };

  useEffect(() => {
    if (product !== 0 || promotionCategory !== 0) getFilteredVariants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, promotionCategory]);

  return (
    <div className={classes.page}>
      <Dialog
        open={isOpen}
        aria-labelledby="form-dialog-title"
        onBackdropClick={() => handleClose()}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={promotionCategory}
            inputProps={{ "aria-label": "Without label" }}
            onBlur={() => setBlurredField("promotionCategory")}
            error={promotionCategory === 0 && isBlurred.promotionCategory}
            required
            fullWidth
            defaultValue={undefined}
            className={[
              promotionCategory === 0 && isBlurred.promotionCategory
                ? classes.colorError
                : classes.colorBlack,
            ].join(" ")}
            onChange={(e: any) => {
              setPromotionCategory(e.target.value);
              setProduct(0);
            }}
          >
            <MenuItem value={0} disabled>
              Категория акции *
            </MenuItem>
            {data.map((el, index) => (
              <MenuItem key={index} value={el.id}>
                {el.name}
              </MenuItem>
            ))}
          </Select>
          {promotionCategory !== 0 && (
            <Select
              className={classes.mt30}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={product}
              inputProps={{ "aria-label": "Without label" }}
              fullWidth
              defaultValue={0}
              onChange={(e: any) => setProduct(e.target.value)}
            >
              <MenuItem value={0}>Товар</MenuItem>
              {data
                .find((el) => el.id === promotionCategory)!
                .products.filter((el) => el.isActive)
                .map((el, index) => (
                  <MenuItem key={index} value={el.id}>
                    {el.name}
                  </MenuItem>
                ))}
            </Select>
          )}
          <TextField
            label="Количество"
            fullWidth
            inputProps={{ maxLength: 10 }}
            type="text"
            required
            onBlur={() => setBlurredField("productsCount")}
            error={productsCount === 0 && isBlurred.productsCount}
            className={[
              classes.mt10,
              productsCount === 0 && isBlurred.productsCount
                ? classes.colorError
                : classes.colorBlack,
            ].join(" ")}
            value={productsCount}
            onChange={(e) =>
              e.target.value === " "
                ? null
                : setProductsCount(getIntFromString(e.target.value))
            }
          />
          <FormControl className={classes.mt10}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isFree}
                  onChange={(e) => setIsFree(!isFree)}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Бесплатно"
            />
          </FormControl>
          <br />
          <br />
          {promotionCategory !== 0 && (
            <div className={classes.entityList}>
              {variantList.map((entity, key) => (
                <Chip
                  key={key}
                  label={getProductSetLabel(entity)}
                  onDelete={() => {
                    const curVariants = copyObject(variantList);
                    curVariants.splice(key, 1);
                    setVariantList(curVariants);
                  }}
                  color="primary"
                  variant="outlined"
                />
              ))}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={
              productsCount === 0 ||
              promotionCategory === 0 ||
              (variantList.length < 1 && oldVariantList.length > 0)
            }
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              if (product !== 0)
                onConfirm([
                  {
                    promotionCategoryId: promotionCategory,
                    productId: product,
                    count: productsCount,
                    isFree: isFree,
                    productSetVariantsList: variantList,
                  },
                ]);
              else {
                onConfirm([
                  {
                    promotionCategoryId: promotionCategory,
                    count: productsCount,
                    isFree: isFree,
                    productSetVariantsList: variantList,
                  },
                ]);
                // const dataToConfirm = data
                //   .find((el) => el.id === promotionCategory)!
                //   .products.filter((el) => el.isActive)
                //   .map((el) => {
                //     return {
                //       promotionCategoryId: promotionCategory,
                //       productId: el.id,
                //       count: productsCount,
                //       isFree: isFree,
                //       productSetVariantsList: variantList,
                //     };
                //   });
                // onConfirm(dataToConfirm);
              }
              handleClose();
            }}
            color="primary"
          >
            Добавить
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SelectPromotionProductForm;
