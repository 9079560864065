import { Redirect, Route } from "react-router-dom";
import React, { ComponentType, FC } from "react";
import cookies from "../cookies";

const ProtectedRoute: FC<{
  component: ComponentType<any>;
  path: string;
  exact?: boolean;
  roles?: string[];
}> = ({ component: Component, path, exact = false, roles = ["Any"] }) => {
  const auth = cookies.get("auth");
  const user = cookies.get("user");

  return auth?.accessToken &&
    (roles?.includes(user.roles[0]) || roles[0] === "Any") ? (
    <Route path={path} component={Component} exact={exact} />
  ) : auth ? (
    <div>Не достаточно прав</div>
  ) : (
    <Redirect to={"/auth"} />
  );
};

export default ProtectedRoute;
