import { TableCell, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { FC, useEffect, useState } from "react";
import API from "../../../_shared/axios";
import { Client } from "../../../_shared/types";

const SyncTableRowAddress: FC<
  {
    item: {
      syncId: number;
      id: number | null;
      isAutoMapped: boolean;
      customerId: number;
      syncName: string;
      totalAddress: null | string;
    };
  } & any
> = ({ item, changeDatHandler, index }) => {
  const [listData, setListData] = useState([]);
  const [defaultProps1, setDefaultProps] = useState({
    options: listData,
    getOptionLabel: (option: any) => option?.fullName,
  });

  useEffect(() => {
    console.log("here", listData);
    setDefaultProps({
      options: listData,
      getOptionLabel: (option: any) => option?.fullName,
    });
  }, [listData]);

  const [searchString, setSearchString] = useState("");

  useEffect(() => {});

  useEffect(() => {
    if (item.customerId) {
      API.get(
        `/syncPageCustomerAddresses/${item.customerId}?` +
          `FilterParams[0].ColumnName=syncId` +
          `&FilterParams[0].FilterOption=11`
      ).then((data) => {
        console.log(data.data);
        setListData(
          data.data.items.map((item: any) => {
            return {
              ...item,
              fullName: item.fullAddress,
            };
          })
        );
      });
    }
  }, [searchString]);

  return (
    <>
      <TableCell>{item?.syncName}</TableCell>
      <TableCell>
        <Autocomplete
          freeSolo
          {...defaultProps1}
          renderInput={(params) => (
            <TextField
              {...params}
              style={{ width: "450px" }}
              variant={"outlined"}
              size={"small"}
              label={"Клиент"}
              onChange={(e) => {
                setSearchString(e.target.value);
              }}
            />
          )}
          // getOptionLabel={(option: any) => option?.fullName}
          // options={filteredGetData.filter((i:any)=>i.id !== filteredGetData.find((j: any) => j?.id).id)}
          value={item.relation}
          onChange={(e, v) => changeDatHandler(v, item, index)}
        />
      </TableCell>
    </>
  );
};

export default SyncTableRowAddress;
