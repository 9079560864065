import { Button, makeStyles, Paper, TablePagination } from "@material-ui/core";
import React, { FC, useContext, useEffect, useState } from "react";
import API from "../../_shared/axios";
import { AlertContextType, Ingredient } from "../../_shared/types";
import SyncTable from "./syncTable";
import { AlertContext } from "../_shared/ToastList";
import { alertError, alertSuccess, getErrorMsg } from "../../_shared/utils";
import { TouchedAlert } from "./index";
import { useDebounce } from "@react-hook/debounce";

export interface iSyncItemExtraPosition {
  syncId: number;
  syncName: string;
  id: number | null;
  name?: string | null;
  variantId: number[];
  relation?: {
    id?: number | null;
    variantId: number | null;
    fullName: string;
  }[];
}

interface iExtraPosPost {
  syncExtraPositionId: number;
  ingredientId: number | null;
  variantId: number[];
}
const useStyles = makeStyles({
  page: {
    margin: 0,
  },
  content: {
    padding: 50,
    "& .MuiTableCell-body": {
      cursor: "pointer",
    },
  },
  heading: {
    display: "flex",
    alignItems: "center",
  },
  paginationPaper: { marginTop: 20 },
  count: {
    fontSize: 16,
    fontWeight: "bold",
  },
});

export type ExtraPositionListItemTransformed = Ingredient & {
  fullName: string;
  variantId: number | null;
};

const ExtraPositionsSyncPage: FC = () => {
  const classes = useStyles();
  const alertContext = useContext<AlertContextType>(AlertContext);
  const touchedContext = useContext(TouchedAlert);

  const [backendData, setBackendData] = useState<
    ExtraPositionListItemTransformed[]
  >([]);
  const [oneC_data, setOneC_data] = useState<iSyncItemExtraPosition[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [changedData, setChangedData] = useState<iExtraPosPost[]>([]);
  const [unusedBackendData, setUnusedBackendData] = useState<
    ExtraPositionListItemTransformed[]
  >([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [search1CString, setSearch1CString] = useState("");
  const [searchBackendString, setSearchBackendString] = useState("");
  // 0 - no filter, 1 - synced, 2 - not synced
  const [filter, setFilter] = useState(0);
  const [filters, setFilters] = useDebounce(
    {
      page: 0,
      pageSize: 25,
      search1CString: "",
      searchBackendString: "",
      filter: 0,
    },
    500
  );

  const load = () => {
    if (Array.isArray(backendData)) {
      const uriStrings = [];
      let indx = 0;
      if (search1CString) {
        uriStrings.push(
          `&FilterParams[${indx}].ColumnName=syncName` +
            `&FilterParams[${indx}].FilterOption=3` +
            `&FilterParams[${indx}].filterValue=${search1CString}`
        );
        indx += 1;
      }
      if (filter !== 0) {
        uriStrings.push(
          `${
            filter === 1
              ? `&FilterParams[${indx}].ColumnName=ingredientId` +
                `&FilterParams[${indx}].FilterOption=12`
              : filter === 2
              ? `&FilterParams[${indx}].ColumnName=ingredientId` +
                `&FilterParams[${indx}].FilterOption=11`
              : ""
          }`
        );
      }

      let urlParams =
        `pageNumber=${page + 1}` +
        `&pageSize=${pageSize}` +
        `${uriStrings.join("")}`;

      API.get(`/syncExtraPositionList?${urlParams}`)
        .then((data) => {
          setTotalItems(data.data.totalItems);
          setOneC_data(
            data.data.items.map((item: iSyncItemExtraPosition) => {
              const rel = backendData.filter(
                (j: ExtraPositionListItemTransformed) => {
                  if (item.variantId.length > 0 && j.variantId) {
                    return (
                      //@ts-ignore
                      j?.id === item?.ingredientId &&
                      item?.variantId.includes(j.variantId)
                    );
                  } else {
                    //@ts-ignore
                    return j?.id === item?.ingredientId;
                  }
                }
              );
              return {
                ...item,
                //@ts-ignore
                id: item.ingredientId,
                variantId: item.variantId,
                relation: rel.length ? [...rel] : rel,
              };
            })
          );
        })
        .catch((error) => {
          console.log(error);
          alertError(
            alertContext,
            getErrorMsg(
              error.response,
              "Неизвестная ошибка получения списка синхронизации"
            )
          );
        });
    }
  };

  useEffect(() => {
    API.get("/ingredientsWithOptionCombinationNames")
      .then((data) => {
        const toSet: ExtraPositionListItemTransformed[] = [];
        data.data.map((item: Ingredient) => {
          if (item.variants.length) {
            item.variants.forEach((i: any) => {
              toSet.push({
                ...item,
                fullName:
                  item.name +
                  " " +
                  i.optionCombinations
                    .map(
                      (item: any) =>
                        item.valueByOption + " " + item.valueByOptionValue
                    )
                    .join(" "),
                variantId: i.variantId,
                variants: undefined,
              });
            });
          } else {
            toSet.push({
              ...item,
              fullName: item.name,
              variantId: null,
              variants: undefined,
            });
          }
        });
        setBackendData(toSet);

        API.get(`/syncExtraPositionList?page=1&pageSize=9999999`)
          .then((syncResp) => {
            const usedIngs = syncResp.data.items.filter(
              (v: any) => v.ingredientId !== null
            );
            setUnusedBackendData(
              toSet.filter(
                (ing: any) =>
                  !usedIngs.some((usedIng: any) =>
                    usedIngs.variantId
                      ? ing.id === usedIngs.ingredientId &&
                        ing.variantId === usedIngs.variantId
                      : ing.id === usedIngs.ingredientId
                  )
              )
            );
          })
          .catch((error) => {
            console.log(error);
            alertError(
              alertContext,
              getErrorMsg(
                error.response,
                "Неизвестная ошибка получения списка синхронизации"
              )
            );
          });
      })
      .catch((error) => {
        console.log(error);
        alertError(
          alertContext,
          getErrorMsg(
            error.response,
            "Неизвестная ошибка получения списка ингредиентов"
          )
        );
      });
  }, []);

  useEffect(() => {
    load();
  }, [backendData, filters]);

  useEffect(() => {
    setFilters({ filter, page, pageSize, search1CString, searchBackendString });
  }, [filter, page, pageSize, search1CString, searchBackendString]);

  const transformDataForPost = (
    backendObj: ExtraPositionListItemTransformed[],
    oneC_obj: iSyncItemExtraPosition,
    index: number
  ) => {
    touchedContext.setter(true);
    //@ts-ignore
    setChangedData((prevStat: iExtraPosPost[]) => {
      const idx = prevStat?.findIndex(
        (v) => v.syncExtraPositionId === oneC_obj.syncId
      );
      if (backendObj.length > 0) {
        if (idx !== -1 && idx !== undefined) {
          prevStat[idx] = {
            syncExtraPositionId: oneC_obj.syncId,
            ingredientId: backendObj[0].id || null,
            //@ts-ignore
            variantId: backendObj.every((v) => v.variantId != null)
              ? [...backendObj.map((v) => v.variantId)]
              : prevStat[idx].variantId,
          };
          return [...prevStat];
        }
        return [
          ...prevStat,
          {
            syncExtraPositionId: oneC_obj.syncId,
            ingredientId: backendObj[0].id || null,
            variantId: backendObj.every((v) => v.variantId)
              ? [...backendObj.map((v) => v.variantId)]
              : [],
          },
        ];
      } else {
        prevStat[idx] = {
          syncExtraPositionId: oneC_obj.syncId,
          ingredientId: null,
          variantId: [],
        };
        return [...prevStat];
      }
    });

    setOneC_data((prevStat) => {
      const obj3: iSyncItemExtraPosition = {
        ...oneC_obj,
        relation: backendObj,
        id: backendObj[0]?.id || null,
        name: backendObj[0] ? backendObj[0].fullName : null,
      };

      const arr = [...prevStat];
      arr.splice(index, 1, obj3);

      return [...arr];
    });
  };

  const postData = () => {
    API.post("/mapSyncExtraPositions", changedData)
      .then((data) => {
        touchedContext.setter(false);
        alertSuccess(alertContext, "Успешно синхронизировано");
      })
      .catch((error) => {
        console.log(error);
        alertError(
          alertContext,
          getErrorMsg(error.response, "Неизвестная ошибка синхронизации")
        );
      });
  };

  useEffect(() => {
    const prep = backendData?.filter((j: ExtraPositionListItemTransformed) => {
      return !oneC_data.some((item) => {
        if (item.relation) {
          console.log(item.relation);
          if (j.variantId) {
            return (
              item.relation.map((v) => v.id).includes(j?.id) &&
              j.variantId === item.relation[0]?.variantId
            );
          } else {
            return j?.id === item.relation[0]?.id;
          }
        } else {
          return false;
        }
      });
    });
    if (prep) {
      setUnusedBackendData([...prep]);
    }
  }, [oneC_data, changedData]);

  return (
    <div className={classes.content}>
      <div className={classes.heading}>
        <h2>
          Синхронизация &nbsp;&nbsp;{" "}
          <Button variant="outlined" color="primary" onClick={() => postData()}>
            Синхронизировать
          </Button>
        </h2>
      </div>
      <SyncTable<iSyncItemExtraPosition, ExtraPositionListItemTransformed>
        multipleChoice={true}
        title="Опция"
        //@ts-ignore
        changeDatHandler={(
          v: ExtraPositionListItemTransformed[],
          name: iSyncItemExtraPosition,
          index: number
        ) => transformDataForPost(v, name, index)}
        filteredGetData={unusedBackendData}
        data={oneC_data}
        totalItems={totalItems}
        page={page}
        pageChange={(page) => setPage(page)}
        pageSize={pageSize}
        pageSizeChange={(pageSize) => setPageSize(pageSize)}
        search1CString={search1CString}
        setSearch1CString={(str) => {
          setPage(0);
          setSearch1CString(str);
        }}
        searchBackendString={searchBackendString}
        setSearchBackendString={(str) => {
          setPage(0);
          setSearchBackendString(str);
        }}
        filter={filter}
        setFilter={(val) => {
          setPage(0);
          setFilter(val);
        }}
      />
    </div>
  );
};
export default ExtraPositionsSyncPage;
