import React, { FC, useContext, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Avatar,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Alert } from "@material-ui/lab";
import {
  AlertContextType,
  Category,
  CurrentOption,
  Ingredient,
  LabelRibbonData,
  Option,
  PCData,
} from "../../_shared/types";
import PublishIcon from "@material-ui/icons/Publish";
import {
  alertError,
  getFloatFromString,
  getIntFromString,
  getSizeOfStringInBytes,
  isObjectsEqual,
} from "../../_shared/utils";
import API from "../../_shared/axios";
import { Filter } from "../FiltersPage/_shared/types";
import { AlertContext } from "../_shared/ToastList";
import LabelRibbon from "../_shared/LabelRibbon";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { ProgressBar } from "../_shared/ProgressBar";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles({
  formControl: {
    minWidth: 120,
  },
  variantWrapper: {
    padding: "8px 30px 8px 16px",
    border: "1px solid #ccc",
    borderRadius: 4,
    marginRight: 10,
    marginTop: 10,
    marginBottom: 10,
    maxWidth: 234,
    position: "relative",
  },
  variantDeleteIcon: {
    position: "absolute",
    top: 8,
    right: 6,
    zIndex: 100,
    cursor: "pointer",
  },
  variants: {
    marginTop: 10,
    display: "flex",
    flexWrap: "wrap",
  },
  requiredWarning: {
    color: "#f44336",
    margin: "4px 0 0 0",
    fontSize: "0.75rem",
    letterSpacing: "0.03333em",
    lineHeight: "1.66",
  },
  colorError: { color: "#f44336" },
  colorBlack: { color: "rgba(0, 0, 0, 0.87)" },
  mt20: { marginTop: 20 },
  addedIngredient: {
    fontWeight: 400,
  },
  alert: {
    width: "100%",
  },
  carbohydrates: { width: "32%" },
  accordionDetails: { display: "flex", alignItems: "center" },
  detail: { paddingLeft: 15 },
  imageDisplay: {
    display: "none",
  },
  dialogTitle: {
    paddingBottom: 0,
  },
  wrapper: {
    display: "inline",
  },
  large: {
    width: "100%",
    height: "220px",
    "& img": {
      objectFit: "contain",
    },
  },
  imgWrapper: {
    position: "relative",
    margin: "auto",
    width: "50%",
    "& img": {
      objectFit: "contain",
    },
  },
  imgControls: {
    position: "absolute",
    right: "1rem",
    bottom: "1rem",
  },
  normal: {
    "& input": {
      transition: "color 150ms ease-in-out",
    },
  },
  accordionSummary: {
    fontSize: 16,
  },
  detailsWrapper: {
    width: "60%",
    display: "flex",
    alignItems: "center",
  },
  transparent: {
    "& input": {
      color: "transparent",
    },
  },
  marginWidth: {
    marginRight: "2% !important",
    width: "32%",
  },
  width32: {
    width: "32%",
  },
  options: {
    width: "100%",
    flexDirection: "column",
    marginBottom: 5,
    "& > div": {
      "& .MuiAccordionSummary-content": {
        margin: "0 !important",
        width: "100% !important",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      },
      "& .MuiAccordionDetails-root": {
        padding: "0 0 0 20px !important",
        "&:last-child": {
          paddingBottom: "10px !important",
        },
      },
    },
  },
  top30: {
    marginTop: 10,
  },
  deletedIngredients: {
    "& > div": {
      borderRadius: 5,
    },
  },
  entityList: {
    "& > div": {
      margin: "0 10px 25px 0",
      color: "rgba(0, 0, 0, 0.87) !important",
      padding: 5,
      "& > span": {
        fontWeight: "bold !important",
        fontSize: "14px !important",
      },
    },
  },
});

const NewProductForm: FC<{
  onProductCreate: (product: PCData) => Promise<any>;
}> = ({ onProductCreate }) => {
  const [open, setOpen] = useState(false);
  const [thumbnail, setThumbnail] = useState<undefined | File>(undefined);
  const [progress, setProgress] = useState(false);

  const productInit = {
    name: "",
    categoryId: 0,
    description: "",
    price: "0",
    position: 0,
    weight: "0",
    calories: "0",
    proteins: "0",
    fats: "0",
    carbohydrates: "0",
    hasFreeSauce: true,
    isRecommended: true,
    isActive: true,
    isPickupOnly: false,
  };
  const [product, setProduct] = useState(productInit);
  const [variantsNames, setVariantsNames] = useState<
    {
      optionName: string | undefined;
      optionValueName: string | undefined;
      optionId: number;
    }[][]
  >([]);
  const [productOptionVariants, setProductOptionVariants] = useState<
    {
      values: { optionId: number; optionValueId: number }[];
      price: number | string;
      weight: number | string;
    }[]
  >([]);
  const [options, setOptions] = useState<
    {
      id: number;
      name: string;
      isChecked: boolean;
      isActive: boolean;
      categories: number[];
      impactOnPrice: boolean;
      optionVariants: {
        id: number;
        value: string;
        price?: string | number;
        weight?: string | number;
        isChecked?: boolean;
      }[];
    }[]
  >([]);
  const [currentOptions, setCurrentOptions] = useState<
    {
      id: number;
      name: string;
      isChecked: boolean;
      categories: number[];
      impactOnPrice: boolean;
      optionVariants: {
        id: number;
        value: string;
        price?: string | number;
        weight?: string | number;
        isChecked?: boolean;
      }[];
    }[]
  >([]);
  const [categories, setCategories] = useState<
    { id: number; name: string; isActive: boolean }[]
  >([]);
  const [filters, setFilters] = useState<LabelRibbonData[]>([]);
  const [deletedIngredients, setDeletedIngredients] = useState<
    LabelRibbonData[]
  >([]);
  const [addedIngredients, setAddedIngredients] = useState<LabelRibbonData[]>(
    []
  );
  const [ingredients, setIngredients] = useState<LabelRibbonData[]>([]);
  const [directoryId, setDirectoryId] = useState<number>();

  const isBlurredInit = {
    thumbnail: false,
    Name: false,
    CategoryId: false,
    Price: false,
    Position: false,
  };
  const [isBlurred, setIsBlurred] = useState(isBlurredInit);
  const alertContext = useContext<AlertContextType>(AlertContext);

  const handleClickOpen = () => {
    setIsBlurred(isBlurredInit);
    setOpen(true);
    setAlerted(false);
    setProduct(productInit);
    setThumbnail(undefined);
    setFilters([]);
    API.get(`/filters`)
      .then(({ data }: { data: Filter[] }) => {
        const availableData = data.filter((el) => el.isActive);
        let newFilters = [];
        for (let i = 0; i < availableData.length; i++) {
          newFilters.push({
            id: availableData[i].id ? availableData[i].id : 0,
            name: availableData[i].name,
            isChecked: false,
            isActive: true,
          });
        }
        setFilters(newFilters);
      })
      .catch((error) =>
        alertError(alertContext, "Ошибка получения списка фильтров")
      );
    API.get(`/options`)
      .then(({ data }: { data: Option[] }) => {
        const availableData = data.filter((el) => el.isActive);
        let newOptions = [];
        for (let i = 0; i < availableData.length; i++) {
          newOptions.push({
            id: availableData[i].id ? availableData[i].id : 0,
            name: availableData[i].value,
            categories: availableData[i].categories,
            optionVariants: availableData[i].optionVariants.map((el) => {
              return { ...el, isChecked: false };
            }),
            impactOnPrice: availableData[i].impactOnPrice || false,
            isChecked: false,
            isActive: true,
          });
        }
        setOptions(newOptions);
      })
      .catch((error) =>
        alertError(alertContext, "Ошибка получения списка опций")
      );
    API.get(`/categories`)
      .then(({ data }: { data: Category[] }) => {
        setCategories(
          data.map((el) => {
            return { id: el.id, name: el.name, isActive: el.isActive };
          })
        );
      })
      .catch((error) =>
        alertError(alertContext, "Ошибка получения списка категорий")
      );
    API.get("/files/images/directories")
      .then(({ data }) =>
        setDirectoryId(
          data.find(
            (el: { id: number; value: string; description: string }) =>
              el.value === "Products"
          ).id
        )
      )
      .catch((error) =>
        alertError(
          alertContext,
          "Ошибка получения списка директорий изображений"
        )
      );

    API.get(`/ingredients`)
      .then(({ data }: { data: Ingredient[] }) => {
        const ingredientsIds = data.map((el) => el.id);
        for (let i = 0; i < ingredientsIds.length; i++) {
          API.get(`/ingredients/${ingredientsIds[i]}`)
            .then(({ data }: { data: Ingredient }) => {
              if (data.isAvailable) {
                const newIngredient = {
                  id: data.id,
                  name: data.name,
                  categories: data.categories,
                  isChecked: false,
                  isActive: true,
                };
                setIngredients((ingredients) => [
                  ...ingredients,
                  newIngredient,
                ]);
              }
            })
            .catch((error) =>
              alertError(alertContext, "Ошибка получения ингредиента")
            );
        }
      })
      .catch((error) =>
        alertError(alertContext, "Ошибка получения списка ингредиентов")
      );
  };

  const handleClose = () => {
    setCurrentOptions([]);
    setVariantsNames([]);
    setIngredients([]);
    setAddedIngredients([]);
    setDeletedIngredients([]);
    setOpen(false);
  };

  const setBlurredField = (field: string) => {
    setIsBlurred({ ...isBlurred, [field]: true });
  };

  const classes = useStyles();

  const [alerted, setAlerted] = useState(false);

  const isImageFormatWrong = () =>
    thumbnail?.name?.slice(
      thumbnail?.name?.length - 4,
      thumbnail?.name?.length
    ) !== ".png" && thumbnail?.name;

  const isImpactOnPriceVariantAvailable = (variant: {
    optionName: string | undefined;
    optionValueName: string | undefined;
    optionId: number;
  }) => {
    let result = false;
    for (let i = 0; i < variantsNames.length; i++) {
      for (let j = 0; j < variantsNames[i].length; j++)
        if (
          options.find((option) => option.id === variantsNames[i][j].optionId)
            ?.impactOnPrice
        )
          result = true;
    }
    return result;
  };

  const isCurrentOptionDisabled = (optionId: number) => {
    const selectedOptions = currentOptions.filter(
      (el) =>
        el.optionVariants.filter((variant) => variant.isChecked).length > 0
    );
    const currentImpact = currentOptions.find((el) => el.id === optionId)!
      .impactOnPrice;
    const selectedImpactOnPrice = selectedOptions.find(
      (el) => el.impactOnPrice
    );
    return !selectedImpactOnPrice
      ? false
      : selectedImpactOnPrice.id !== optionId && currentImpact;
  };

  const variantIsCheckedOnChange = (newCurrentOptions: CurrentOption[]) => {
    const getValues = (
      combinations: {
        optionId: number;
        optionValueId: number;
      }[]
    ) => {
      // ПОЛУЧАЕМ ВСЕ НЕ ПРОЙДЕННЫЕ ЕЩЁ ОПЦИИ, КОТОРЫЕ СОДЕРЖАТ ВЫДЕЛЕННЫЕ ВАРИАНТЫ
      const otherOptions = newCurrentOptions
        .filter((el) => el.isChecked)
        .filter((el) => !combinations.map((el) => el.optionId).includes(el.id))
        .filter(
          (el) => el.optionVariants.filter((el) => el.isChecked).length > 0
        );
      if (otherOptions.length === 0) variants = [...variants, combinations];
      else {
        for (let option of otherOptions) {
          for (let variant of option.optionVariants.filter(
            (el) => el.isChecked
          )) {
            getValues([
              ...combinations,
              {
                optionId: option.id,
                optionValueId: variant.id,
              },
            ]);
          }
        }
      }
    };

    // итоговый объект вариантов
    let variants: any = [];
    let combinations: {
      optionId: number;
      optionValueId: number;
    }[] = [];

    // ПРОХОДИМ ПО ВСЕМ ОПЦИЯМ, КОТОРЫЕ СОДЕРЖАТ ВЫДЕЛЕННЫЕ ВАРИАНТЫ
    for (let option of newCurrentOptions.filter(
      (el) => el.optionVariants.filter((el) => el.isChecked).length > 0
    )) {
      // ПРОХОДИМ ПО ВСЕМ ВЫДЕЛЕННЫМ ВАРИАНТАМ В КАЖДОЙ ОПЦИИ
      for (let variant of option.optionVariants.filter((el) => el.isChecked)) {
        // ПОЛУЧАЕМ КОМБИНАЦИИ ДЛЯ ТЕКУЩЕГО ВАРИАНТА ОПЦИИ И ДОБАВЛЯЕМ ИХ В ОБЩИЙ МАССИВ ВАРИАНТОВ
        combinations = [
          {
            optionId: option.id,
            optionValueId: variant.id,
          },
        ];
        getValues(combinations);
      }
    }
    for (let i = 0; i < variants.length; i++) {
      const currentValues = variants[i];
      variants[i] = currentValues.sort((a: any, b: any) =>
        a.optionId > b.optionId ? 1 : b.optionId > a.optionId ? -1 : 0
      );
    }
    variants = variants
      .filter(
        (el: any, index: number) =>
          variants
            .map((variant: any) => JSON.stringify(variant))
            .indexOf(JSON.stringify(el)) === index
      )
      .filter((el: any) => el);

    const newProductOptionVariants = variants.map((el: any) => {
      let impactOnPrice = false;
      for (let obj of el) {
        if (
          // @ts-ignore
          options.find((el) => el.id === obj.optionId).impactOnPrice
        )
          impactOnPrice = true;
      }
      return {
        price: impactOnPrice ? "0" : undefined,
        values: el,
        weight: "",
      };
    });
    for (let i = 0; i < newProductOptionVariants.length; i++) {
      for (let j = 0; j < productOptionVariants.length; j++) {
        if (
          isObjectsEqual(
            productOptionVariants[j].values.filter(
              (el) =>
                newCurrentOptions.find((option) => option.id === el.optionId)!
                  .impactOnPrice
            ),
            newProductOptionVariants[i].values.filter(
              (el: { optionId: number; optionValueId: number }) =>
                newCurrentOptions.find((option) => option.id === el.optionId)!
                  .impactOnPrice
            )
          )
        ) {
          newProductOptionVariants[i].price = productOptionVariants[j].price;
          newProductOptionVariants[i].weight = productOptionVariants[j].weight;
        }
      }
    }
    setProductOptionVariants(newProductOptionVariants);
    let variantNames: {
      optionName: string | undefined;
      optionValueName: string | undefined;
      optionId: number;
    }[][] = [];
    for (let variant of variants) {
      let newVariant: {
        optionName: string | undefined;
        optionValueName: string | undefined;
        optionId: number;
      }[] = [];
      for (let option of variant) {
        const currentOption = options.find((el) => el.id === option.optionId);
        const optionName = currentOption?.name;
        const optionValueName = currentOption?.optionVariants.find(
          (el) => el.id === option.optionValueId
        )?.value;
        newVariant = [
          ...newVariant,
          {
            optionId: option.optionId,
            optionName,
            optionValueName,
          },
        ];
      }
      variantNames = [...variantNames, newVariant];
    }
    setVariantsNames(variantNames);
  };

  const isPizza = () =>
    categories
      .find((el) => el.id === product.categoryId)
      ?.name.toLowerCase() === "пицца";

  return (
    <div className={classes.wrapper}>
      <h2>
        Товары &nbsp; &nbsp;
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          Добавить товар
        </Button>
      </h2>

      <Dialog
        disableBackdropClick
        onBackdropClick={() =>
          progress
            ? null
            : isObjectsEqual(product, productInit) &&
              deletedIngredients.filter((el) => el.isChecked).length === 0 &&
              addedIngredients.filter((el) => el.isChecked).length === 0 &&
              filters.filter((el) => el.isChecked).length === 0 &&
              thumbnail === undefined
            ? handleClose()
            : setAlerted(true)
        }
        open={open}
        onClose={handleClose}
        maxWidth={"lg"}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
          Новый товар
        </DialogTitle>
        <DialogContent>
          <form>
            <div className={classes.imgWrapper}>
              <input
                accept="image/*"
                className={classes.imageDisplay}
                id="contained-button-file"
                type="file"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e.target.files?.[0]) setThumbnail(e.target.files[0]);
                }}
              />
              <Avatar
                alt={thumbnail ? URL.createObjectURL(thumbnail) : ""}
                src={thumbnail ? URL.createObjectURL(thumbnail) : ""}
                variant="rounded"
                className={classes.large}
              />
              {isImageFormatWrong() && (
                <p className={classes.requiredWarning}>
                  Выберите изображение в "png" формате
                </p>
              )}
              <div className={classes.imgControls}>
                <label htmlFor="contained-button-file">
                  <IconButton
                    component="span"
                    onClick={(e: React.MouseEvent<HTMLSpanElement>) =>
                      e.stopPropagation()
                    }
                  >
                    <PublishIcon />
                  </IconButton>
                </label>
              </div>
            </div>
            <TextField
              margin="dense"
              label="Наименование"
              inputProps={{ maxLength: 100 }}
              value={product.name}
              onChange={(e) => setProduct({ ...product, name: e.target.value })}
              type="text"
              fullWidth
              className={classes.top30}
              onBlur={() => setBlurredField("Name")}
              error={!product.name && isBlurred.Name}
              helperText={
                !product.name && isBlurred.Name ? "Обязательное поле" : ""
              }
              required
            />
            <TextField
              margin="dense"
              label="Описание"
              className={classes.top30}
              value={product.description}
              onChange={(e) => {
                if (getSizeOfStringInBytes(e.target.value) <= 4000)
                  setProduct({ ...product, description: e.target.value });
              }}
              type="text"
              fullWidth
              multiline
            />
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={product.categoryId}
              inputProps={{ "aria-label": "Without label" }}
              onBlur={() => setBlurredField("CategoryId")}
              error={product.categoryId === 0 && isBlurred.CategoryId}
              defaultValue={0}
              required
              fullWidth
              className={[
                classes.mt20,
                product.categoryId === 0 && isBlurred.CategoryId
                  ? classes.colorError
                  : classes.colorBlack,
              ].join(" ")}
              onChange={(e: any) => {
                setProductOptionVariants([]);
                setCurrentOptions(
                  options
                    .filter((el) => el.categories?.includes(e.target.value))
                    .map((option) => {
                      return {
                        ...option,
                        optionVariants: option.optionVariants.map((variant) => {
                          return { ...variant, isChecked: false };
                        }),
                      };
                    })
                );
                setVariantsNames([]);
                setProduct({
                  ...product,
                  categoryId: e.target.value,
                });
                const filteredIngredients = ingredients.filter((el) =>
                  el.categories
                    ?.map((el) => el.categoryId)
                    .includes(e.target.value)
                );
                setDeletedIngredients(filteredIngredients);
                setAddedIngredients(filteredIngredients);
              }}
            >
              <MenuItem value={0} disabled>
                Категория *
              </MenuItem>
              {categories.map((el) => (
                <MenuItem key={el.id} value={el.id} disabled={!el.isActive}>
                  {el.name}
                </MenuItem>
              ))}
            </Select>
            {product.categoryId === 0 && isBlurred.CategoryId && (
              <p className={classes.requiredWarning}>Обязательное поле</p>
            )}
            <TextField
              margin="dense"
              type="text"
              inputProps={{ maxLength: 10 }}
              className={classes.top30}
              label="Позиция"
              value={product.position}
              onChange={(e) =>
                e.target.value === " "
                  ? null
                  : setProduct({
                      ...product,
                      position: getIntFromString(e.target.value),
                    })
              }
              fullWidth
              onBlur={() => setBlurredField("Position")}
              error={!product.position && isBlurred.Position}
              helperText={
                !product.position && isBlurred.Position
                  ? "Обязательное поле"
                  : ""
              }
              required
            />
            <TextField
              margin="dense"
              type="text"
              className={classes.top30}
              label="Цена"
              inputProps={{ maxLength: 10 }}
              value={product.price}
              onBlur={() => setBlurredField("Price")}
              onChange={(e) =>
                e.target.value === " "
                  ? null
                  : setProduct({
                      ...product,
                      price: getFloatFromString(e.target.value),
                    })
              }
              fullWidth
              error={product.price === "0" && isBlurred.Price}
              helperText={
                product.price === "0" && isBlurred.Price
                  ? "Обязательное поле"
                  : ""
              }
              required
            />

            <TextField
              margin="dense"
              type="text"
              label="Калорийность"
              inputProps={{ maxLength: 10 }}
              className={classes.top30}
              value={product.calories}
              onChange={(e) =>
                e.target.value === " "
                  ? null
                  : setProduct({
                      ...product,
                      calories: getFloatFromString(e.target.value),
                    })
              }
              fullWidth
            />
            <TextField
              margin="dense"
              type="text"
              label="Вес"
              inputProps={{ maxLength: 10 }}
              className={classes.top30}
              value={product.weight}
              onChange={(e) =>
                e.target.value === " "
                  ? null
                  : setProduct({
                      ...product,
                      weight: getFloatFromString(e.target.value),
                    })
              }
              fullWidth
            />
            <TextField
              margin="dense"
              type="text"
              label="Белки"
              inputProps={{ maxLength: 10 }}
              className={classes.marginWidth}
              value={product.proteins}
              onChange={(e) =>
                e.target.value === " "
                  ? null
                  : setProduct({
                      ...product,
                      proteins: getFloatFromString(e.target.value),
                    })
              }
            />
            <TextField
              margin="dense"
              type="text"
              label="Жиры"
              inputProps={{ maxLength: 10 }}
              className={classes.marginWidth}
              value={product.fats}
              onChange={(e) =>
                e.target.value === " "
                  ? null
                  : setProduct({
                      ...product,
                      fats: getFloatFromString(e.target.value),
                    })
              }
            />
            <TextField
              margin="dense"
              type="text"
              label="Углеводы"
              inputProps={{ maxLength: 10 }}
              className={classes.carbohydrates}
              value={product.carbohydrates}
              onChange={(e) =>
                e.target.value === " "
                  ? null
                  : setProduct({
                      ...product,
                      carbohydrates: getFloatFromString(e.target.value),
                    })
              }
            />
            {/*<FormControl*/}
            {/*  className={[*/}
            {/*    classes.formControl,*/}
            {/*    classes.marginWidth,*/}
            {/*    classes.mt20,*/}
            {/*  ].join(' ')}*/}
            {/*>*/}
            {/*  <FormControlLabel*/}
            {/*    control={*/}
            {/*      <Checkbox*/}
            {/*        value={product.hasFreeSauce}*/}
            {/*        defaultChecked={true}*/}
            {/*        onChange={(e) =>*/}
            {/*          setProduct({*/}
            {/*            ...product,*/}
            {/*            hasFreeSauce: !product.hasFreeSauce,*/}
            {/*          })*/}
            {/*        }*/}
            {/*        name="checkedB"*/}
            {/*        color="primary"*/}
            {/*      />*/}
            {/*    }*/}
            {/*    label="Есть бесплатный соус"*/}
            {/*  />*/}
            {/*</FormControl>*/}
            <FormControl
              className={[
                classes.formControl,
                classes.marginWidth,
                classes.mt20,
              ].join(" ")}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    value={product.isRecommended}
                    defaultChecked={true}
                    onChange={(e) =>
                      setProduct({
                        ...product,
                        isRecommended: !product.isRecommended,
                      })
                    }
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Товар рекомендован"
              />
            </FormControl>
            <FormControl
              className={[
                classes.formControl,
                classes.marginWidth,
                classes.mt20,
              ].join(" ")}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    value={product.isPickupOnly}
                    checked={product.isPickupOnly}
                    onChange={() =>
                      setProduct({
                        ...product,
                        isPickupOnly: !product.isPickupOnly,
                      })
                    }
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Только для самовывоза"
              />
            </FormControl>
            <FormControl
              className={[
                classes.formControl,
                classes.mt20,
                classes.width32,
              ].join(" ")}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    value={product.isActive}
                    defaultChecked={true}
                    onChange={(e) =>
                      setProduct({
                        ...product,
                        isActive: !product.isActive,
                      })
                    }
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Товар активен"
              />
            </FormControl>
            <LabelRibbon
              data={filters}
              title={"Фильтры"}
              onConfirm={(data: LabelRibbonData[]) => setFilters(data)}
            >
              <div className={classes.entityList}>
                {filters
                  .filter((el) => el.isChecked)
                  .map((entity, key) => (
                    <Chip
                      key={key}
                      label={entity.name}
                      onDelete={() => {
                        setFilters(
                          filters.map((el) =>
                            el.id !== entity.id
                              ? el
                              : { ...el, isChecked: !el.isChecked }
                          )
                        );
                      }}
                      color="primary"
                      variant="outlined"
                    />
                  ))}
              </div>
            </LabelRibbon>
            <Divider />
            <LabelRibbon
              data={deletedIngredients}
              title={
                product.categoryId !== 0
                  ? "Удаляемые ингредиенты"
                  : "Удаляемые ингредиенты (выберите категорию)"
              }
              onConfirm={(data: LabelRibbonData[]) =>
                setDeletedIngredients(data)
              }
            >
              <div className={classes.entityList}>
                {deletedIngredients
                  .filter((el) => el.isChecked)
                  .map((entity, key) => (
                    <Chip
                      key={key}
                      label={entity.name}
                      onDelete={() => {
                        setDeletedIngredients(
                          deletedIngredients.map((el) =>
                            el.id !== entity.id
                              ? el
                              : { ...el, isChecked: !el.isChecked }
                          )
                        );
                      }}
                      color="primary"
                      variant="outlined"
                    />
                  ))}
              </div>
            </LabelRibbon>
            <Divider />
            <LabelRibbon
              data={addedIngredients}
              title={
                product.categoryId !== 0
                  ? "Добавляемые ингредиенты"
                  : "Добавляемые ингредиенты (выберите категорию)"
              }
              onConfirm={(data: LabelRibbonData[]) => setAddedIngredients(data)}
            >
              <div className={classes.entityList}>
                {addedIngredients
                  .filter((el) => el.isChecked)
                  .map((entity, key) => (
                    <Chip
                      key={key}
                      label={entity.name}
                      onDelete={() => {
                        setAddedIngredients(
                          addedIngredients.map((el) =>
                            el.id !== entity.id
                              ? el
                              : { ...el, isChecked: !el.isChecked }
                          )
                        );
                      }}
                      color="primary"
                      variant="outlined"
                    />
                  ))}
              </div>
            </LabelRibbon>
            <Divider />
            <LabelRibbon
              // @ts-ignore
              data={currentOptions}
              title={
                product.categoryId !== 0
                  ? "Опции"
                  : "Опции (выберите категорию)"
              }
              // @ts-ignore
              onConfirm={(
                data: {
                  id: number;
                  name: string;
                  isChecked: boolean;
                  categories: [];
                  impactOnPrice: boolean;
                  optionVariants: {
                    id: number;
                    value: string;
                    price?: string | number;
                    weight?: string | number;
                    isChecked?: boolean;
                  }[];
                }[]
              ) => setCurrentOptions(data)}
            />
            {currentOptions.map(
              (option, optionIndex) =>
                option.isChecked && (
                  <div className={classes.options} key={optionIndex}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        id="additional-actions1-header"
                        className={classes.accordionSummary}
                      >
                        <span>{option.name}</span>
                        <DeleteIcon
                          fontSize={"small"}
                          color={"primary"}
                          onClick={() => {
                            const newCurrentOptions = currentOptions.map((el) =>
                              el.id !== option.id
                                ? el
                                : {
                                    ...el,
                                    isChecked: false,
                                    optionVariants: el.optionVariants.map(
                                      (el) => {
                                        return { ...el, isChecked: false };
                                      }
                                    ),
                                  }
                            );
                            setCurrentOptions(newCurrentOptions);
                            variantIsCheckedOnChange(newCurrentOptions);
                          }}
                        />
                      </AccordionSummary>
                      {option.optionVariants.map((variant, variantIndex) => (
                        <AccordionDetails
                          key={variantIndex}
                          className={classes.accordionDetails}
                        >
                          <div className={classes.detailsWrapper}>
                            <FormControlLabel
                              className={classes.detail}
                              aria-label="Acknowledge"
                              onClick={(event) => event.stopPropagation()}
                              onFocus={(event) => event.stopPropagation()}
                              control={
                                <Checkbox
                                  disabled={isCurrentOptionDisabled(option.id)}
                                  color={"primary"}
                                  checked={variant.isChecked}
                                  onChange={(e) => {
                                    const newCurrentOptions = [
                                      ...currentOptions,
                                    ];
                                    newCurrentOptions[
                                      optionIndex
                                    ].optionVariants[
                                      variantIndex
                                    ].isChecked = !newCurrentOptions[
                                      optionIndex
                                    ].optionVariants[variantIndex].isChecked;
                                    setCurrentOptions(newCurrentOptions);

                                    variantIsCheckedOnChange(newCurrentOptions);
                                  }}
                                />
                              }
                              label={variant.value}
                            />
                          </div>
                        </AccordionDetails>
                      ))}
                    </Accordion>
                  </div>
                )
            )}
            {currentOptions.filter((option) => option.isChecked).length ===
              0 && <Divider />}
            {productOptionVariants.length > 0 &&
              productOptionVariants.filter((el) => el.values.length > 0)
                .length > 0 && (
                <div className={classes.variants}>
                  {variantsNames.map(
                    (
                      variant: {
                        optionName: string | undefined;
                        optionValueName: string | undefined;
                        optionId: number;
                      }[],
                      key
                    ) => (
                      <div key={key} className={classes.variantWrapper}>
                        <div className={classes.variantDeleteIcon}>
                          <DeleteIcon
                            fontSize={"small"}
                            color={"primary"}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              setProductOptionVariants(
                                productOptionVariants.filter(
                                  (el, index) => index !== key
                                )
                              );
                              const newVariantsNames = variantsNames.filter(
                                (el, index) => index !== key
                              );
                              setVariantsNames(newVariantsNames);
                              let newCurrentOptions = currentOptions;
                              for (let i = 0; i < variant.length; i++) {
                                const deletedOptionName = variant[i].optionName;
                                const deletedOptionValueName =
                                  variant[i].optionValueName;
                                const otherVariantsNames = variantsNames.filter(
                                  (el, index) => index !== key
                                );
                                if (
                                  !otherVariantsNames.find(
                                    (el) =>
                                      el.filter(
                                        (el) =>
                                          el.optionName === deletedOptionName &&
                                          el.optionValueName ===
                                            deletedOptionValueName
                                      ).length > 0
                                  )
                                ) {
                                  newCurrentOptions = newCurrentOptions.map(
                                    (option) => {
                                      return {
                                        ...option,
                                        optionVariants: option.optionVariants.map(
                                          (variant) =>
                                            variant.value ===
                                              deletedOptionValueName &&
                                            option.name === deletedOptionName
                                              ? { ...variant, isChecked: false }
                                              : variant
                                        ),
                                      };
                                    }
                                  );
                                }
                              }
                              setCurrentOptions(newCurrentOptions);
                            }}
                          />
                        </div>
                        {variant.map((el: any, index: number) => (
                          <span key={index}>
                            <span>{el.optionName.toLowerCase()}:&nbsp;</span>
                            <span>{el.optionValueName.toLowerCase()}</span>
                            {index === variant.length - 1 && (
                              <TextField
                                margin="dense"
                                type="text"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment
                                      position="start"
                                      style={{ paddingBottom: 5 }}
                                    >
                                      {isPizza() ? "Вес *" : "Вес"}
                                    </InputAdornment>
                                  ),
                                }}
                                inputProps={{ maxLength: 9 }}
                                value={productOptionVariants[key].weight}
                                onChange={(e) => {
                                  if (e.target.value === " ") return null;
                                  let newValue = e.target.value.length
                                    ? getIntFromString(e.target.value, true)
                                    : "";
                                  if (
                                    newValue === "" ||
                                    (typeof newValue === "number" &&
                                      !isNaN(newValue))
                                  )
                                    setProductOptionVariants(
                                      productOptionVariants.map((el, index) => {
                                        return index === key
                                          ? {
                                              ...el,
                                              weight: newValue,
                                            }
                                          : el;
                                      })
                                    );
                                }}
                                fullWidth
                                required
                              />
                            )}
                            {isImpactOnPriceVariantAvailable(el) &&
                              index === variant.length - 1 && (
                                <TextField
                                  margin="dense"
                                  type="text"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment
                                        position="start"
                                        style={{ paddingBottom: 5 }}
                                      >
                                        Цена *
                                      </InputAdornment>
                                    ),
                                  }}
                                  inputProps={{ maxLength: 8 }}
                                  value={productOptionVariants[key].price}
                                  onChange={(e) =>
                                    e.target.value === " "
                                      ? null
                                      : setProductOptionVariants(
                                          productOptionVariants.map(
                                            (el, index) => {
                                              return index === key
                                                ? {
                                                    ...el,
                                                    price: getFloatFromString(
                                                      e.target.value
                                                    ),
                                                  }
                                                : el;
                                            }
                                          )
                                        )
                                  }
                                  fullWidth
                                  required
                                />
                              )}
                            <br />
                          </span>
                        ))}
                      </div>
                    )
                  )}
                </div>
              )}
          </form>
        </DialogContent>
        <DialogActions>
          {alerted ? (
            <Alert
              className={classes.alert}
              severity="warning"
              action={
                <>
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => handleClose()}
                  >
                    Да
                  </Button>
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => setAlerted(false)}
                  >
                    Нет
                  </Button>
                </>
              }
            >
              Вы действительно хотите отменить все изменения и выйти? Введенные
              данные будут утеряны
            </Alert>
          ) : (
            <>
              <Button onClick={handleClose} color="primary" disabled={progress}>
                Отмена
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={
                  !!isImageFormatWrong() ||
                  product.name === "" ||
                  thumbnail === undefined ||
                  (currentOptions.filter((el) => el.isChecked).length > 0 &&
                    productOptionVariants.filter((el) => el.values.length > 0)
                      .length === 0) ||
                  productOptionVariants.filter(
                    (el) => !!el.price && el.price.toString() === "0"
                  ).length > 0 ||
                  (isPizza() &&
                    productOptionVariants.filter(
                      (el) => !!el.weight && +el.weight === 0
                    ).length > 0) ||
                  product.price === "0" ||
                  product.categoryId === 0 ||
                  product.position === 0 ||
                  progress
                }
                onClick={() => {
                  setProgress(true);
                  if (thumbnail) {
                    const imageToUpload = {
                      File: thumbnail,
                      Folder: directoryId,
                    };
                    const formData = new FormData();
                    (Object.keys(imageToUpload) as Array<
                      keyof { File: File; Folder: number }
                    >).map((k: keyof { File: File; Folder: number }) =>
                      // @ts-ignore
                      formData.append(k as string, imageToUpload[k])
                    );
                    API.post("/files/images/upload", formData)
                      .then(({ data }) => {
                        const newFilters = filters
                          .filter((el) => el.isChecked)
                          .map((el) => el.id);
                        const newProduct: any = {
                          name: product.name,
                          imgName: data,
                          description: product.description,
                          categoryId: product.categoryId,
                          position: product.position,
                          price: product.price,
                          calories:
                            +product.calories !== 0 ? +product.calories : null,
                          proteins:
                            +product.proteins !== 0 ? +product.proteins : null,
                          fats: +product.fats !== 0 ? +product.fats : null,
                          carbohydrates:
                            +product.carbohydrates !== 0
                              ? +product.carbohydrates
                              : null,
                          weight:
                            +product.weight !== 0 ? +product.weight : null,
                          hasFreeSauce: product.hasFreeSauce,
                          isRecommended: product.isRecommended,
                          isPickupOnly: product.isPickupOnly,
                          variants: productOptionVariants.map((el) => ({
                            ...el,
                            weight:
                              el.weight && +el.weight !== 0
                                ? el.weight
                                : undefined,
                          })),
                          isActive: product.isActive,
                        };
                        if (newFilters.length > 0)
                          newProduct.filtersToAdd = newFilters;
                        const delIngredients = deletedIngredients.filter(
                          (el) => el.isChecked
                        );
                        if (delIngredients.length > 0)
                          newProduct.addIngredientsToRemoveList = delIngredients.map(
                            (el) => el.id
                          );
                        const addIngredients = addedIngredients.filter(
                          (el) => el.isChecked
                        );
                        if (addIngredients.length > 0)
                          newProduct.addIngredientsToAddList = addIngredients.map(
                            (el) => el.id
                          );
                        onProductCreate(newProduct)
                          .then((data) => handleClose())
                          .catch((error) => console.log(error))
                          .finally(() => setProgress(false));
                      })
                      .catch((error) => {
                        alertError(alertContext, "Ошибка загрузки изображения");
                      });
                  }
                }}
              >
                Создать
              </Button>
            </>
          )}
        </DialogActions>
        {progress && <ProgressBar bottom />}
      </Dialog>
    </div>
  );
};

export default NewProductForm;
