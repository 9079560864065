import React, { FC, useContext, useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Alert } from "@material-ui/lab";
import { alertError, getErrorMsg, isObjectsEqual } from "../../_shared/utils";
import { ProgressBar } from "../_shared/ProgressBar";
import {
  Avatar,
  FormControl,
  IconButton,
  MenuItem,
  Select,
} from "@material-ui/core";
import API from "../../_shared/axios";
import {
  AlertContextType,
  BannerCreate,
  Category,
  ProductRow,
} from "../../_shared/types";
import { AlertContext } from "../_shared/ToastList";
import PublishIcon from "@material-ui/icons/Publish";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  mt20: { marginTop: 20 },
  requiredWarning: {
    color: "#f44336",
    margin: "4px 0 0 0",
    fontSize: "0.75rem",
    letterSpacing: "0.03333em",
    lineHeight: "1.66",
  },
  large: {
    width: "100%",
    height: "220px",
    "& img": {
      objectFit: "contain",
    },
  },
  imageDisplay: {
    display: "none",
  },
  imgWrapper: {
    position: "relative",
    margin: "auto",
    width: "50%",
    "& img": {
      objectFit: "contain",
    },
  },
  imgControls: {
    position: "absolute",
    right: "1rem",
    bottom: "1rem",
  },
});

const NewBannerForm: FC<{
  onBannerCreate: (banner: BannerCreate) => Promise<any>;
}> = ({ onBannerCreate }) => {
  const classes = useStyles();
  const fileRef = useRef<HTMLInputElement>(null);

  const alertContext = useContext<AlertContextType>(AlertContext);
  const [open, setOpen] = useState(false);
  const [categories, setCategories] = useState<Category[]>([]);
  const [products, setProducts] = useState<ProductRow[]>([]);
  const [directoryId, setDirectoryId] = useState<number>();

  const bannerInit: {
    name: string;
    imgFile: File | undefined;
    categoryId: number;
    productId: number;
  } = {
    name: "",
    imgFile: undefined,
    categoryId: 0,
    productId: 0,
  };
  const [banner, setBanner] = useState(bannerInit);

  const [progress, setProgress] = useState(false);
  const [alerted, setAlerted] = useState(false);

  const isBlurredInit = {
    name: false,
    imgFile: false,
  };
  const [isBlurred, setIsBlurred] = useState(isBlurredInit);

  const handleClickOpen = () => {
    setIsBlurred(isBlurredInit);
    setBanner(bannerInit);
    setOpen(true);
    setAlerted(false);
    API.get("/files/images/directories")
      .then(({ data }) =>
        setDirectoryId(
          data.find(
            (el: { id: number; value: string; description: string }) =>
              el.value === "Banners"
          ).id
        )
      )
      .catch((error) =>
        alertError(
          alertContext,
          "Ошибка получения списка директорий изображений"
        )
      );
    API.get("/categories")
      .then(({ data }: { data: Category[] }) => {
        setCategories(data);
        API.get("/products")
          .then(({ data }: { data: ProductRow[] }) => {
            setProducts(data);
          })
          .catch((error) =>
            alertError(
              alertContext,
              getErrorMsg(error.response, "Ошибка получения списка товаров")
            )
          );
      })
      .catch((error) =>
        alertError(
          alertContext,
          getErrorMsg(error.response, "Ошибка получения списка категорий")
        )
      );
  };

  const handleClose = () => {
    setOpen(false);
  };

  const setBlurredField = (field: string) => {
    setIsBlurred({ ...isBlurred, [field]: true });
  };

  const isImageFormatWrong = () =>
    banner.imgFile?.name?.slice(
      banner.imgFile?.name?.length - 4,
      banner.imgFile?.name?.length
    ) !== ".png" && banner.imgFile?.name;

  return (
    <div>
      <h2>
        Баннеры &nbsp; &nbsp;
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          Добавить баннер
        </Button>
      </h2>
      <Dialog
        fullWidth={true}
        disableBackdropClick
        onBackdropClick={() =>
          progress
            ? null
            : isObjectsEqual(banner, bannerInit)
            ? setOpen(false)
            : setAlerted(true)
        }
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth={"md"}
      >
        <DialogTitle id="form-dialog-title">Новый баннер</DialogTitle>
        <DialogContent>
          <form>
            <div className={classes.imgWrapper}>
              <input
                accept="image/*"
                className={classes.imageDisplay}
                id="contained-button-file"
                type="file"
                ref={fileRef}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e.target.files?.[0])
                    setBanner({ ...banner, imgFile: e.target.files[0] });
                }}
              />
              <Avatar
                alt={banner.imgFile ? URL.createObjectURL(banner.imgFile) : ""}
                src={banner.imgFile ? URL.createObjectURL(banner.imgFile) : ""}
                variant="rounded"
                className={classes.large}
              />
              {isImageFormatWrong() && (
                <p className={classes.requiredWarning}>
                  Выберите изображение в "png" формате
                </p>
              )}
              <div className={classes.imgControls}>
                <label htmlFor="contained-button-file">
                  <IconButton
                    component="span"
                    onClick={(e: React.MouseEvent<HTMLSpanElement>) =>
                      e.stopPropagation()
                    }
                  >
                    <PublishIcon />
                  </IconButton>
                </label>
              </div>
            </div>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Наименование"
              inputProps={{ maxLength: 100 }}
              value={banner.name}
              onChange={(e) => setBanner({ ...banner, name: e.target.value })}
              type="text"
              fullWidth
              className={classes.mt20}
              onBlur={() => setBlurredField("name")}
              error={!banner.name && isBlurred.name}
              helperText={
                !banner.name && isBlurred.name ? "Обязательное поле" : ""
              }
              required
            />
            <FormControl
              fullWidth
              component="fieldset"
              className={classes.mt20}
              disabled={banner.productId !== 0}
            >
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={banner.categoryId}
                defaultValue={undefined}
                inputProps={{ "aria-label": "Without label" }}
                displayEmpty
                onChange={(e: any) =>
                  setBanner({
                    ...banner,
                    categoryId: e.target.value,
                  })
                }
              >
                <MenuItem value={0}>Категория</MenuItem>
                {categories.map((el, key) => (
                  <MenuItem key={key} value={el.id} disabled={!el.isActive}>
                    {el.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              fullWidth
              component="fieldset"
              className={classes.mt20}
              disabled={banner.categoryId !== 0}
            >
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={banner.productId}
                defaultValue={undefined}
                inputProps={{ "aria-label": "Without label" }}
                displayEmpty
                onChange={(e: any) =>
                  setBanner({ ...banner, productId: e.target.value })
                }
              >
                <MenuItem value={0}>Товар</MenuItem>
                {products.map((el, key) => (
                  <MenuItem key={key} value={el.id} disabled={!el.isActive}>
                    {el.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          {alerted ? (
            <Alert
              severity="warning"
              action={
                <>
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => setOpen(false)}
                  >
                    Да
                  </Button>
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => setAlerted(false)}
                  >
                    Нет
                  </Button>
                </>
              }
            >
              Вы действительно хотите отменить все изменения и выйти? Введенные
              данные будут утеряны
            </Alert>
          ) : (
            <>
              <Button onClick={handleClose} color="primary" disabled={progress}>
                Отмена
              </Button>
              <Button
                onClick={() => {
                  if (banner.imgFile) {
                    setProgress(true);

                    const imageToUpload = {
                      File: banner.imgFile,
                      Folder: directoryId,
                    };
                    const formData = new FormData();
                    (Object.keys(imageToUpload) as Array<
                      keyof { File: File; Folder: number }
                    >).map((k: keyof { File: File; Folder: number }) =>
                      // @ts-ignore
                      formData.append(k as string, imageToUpload[k])
                    );
                    API.post("/files/images/upload", formData)
                      .then(({ data }) => {
                        const { name, categoryId, productId } = banner;
                        const newBanner = {
                          name,
                          imgName: data,
                          categoryId: categoryId.toString(),
                          productId: productId.toString(),
                          isActive: "true",
                        };
                        if (categoryId === 0) delete newBanner.categoryId;
                        if (productId === 0) delete newBanner.productId;
                        onBannerCreate(newBanner)
                          .then((data) => setOpen(false))
                          .catch((error) => console.log(error))
                          .finally(() => setProgress(false));
                      })
                      .catch((error) => {
                        alertError(
                          alertContext,
                          getErrorMsg(
                            error.response,
                            "Ошибка загрузки изображения"
                          )
                        );
                      });
                  }
                }}
                color="primary"
                type="submit"
                disabled={
                  !!isImageFormatWrong() ||
                  banner.name === "" ||
                  banner.imgFile === undefined ||
                  progress
                }
              >
                Создать
              </Button>
            </>
          )}
        </DialogActions>
        {progress && <ProgressBar bottom />}
      </Dialog>
    </div>
  );
};

export default NewBannerForm;
