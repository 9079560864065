import React, { FC, useContext, useEffect, useState } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import { Chip, MenuItem, Select } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {
  AlertContextType,
  Ingredient,
  LabelRibbonData,
  AddedIngredient,
} from "../../_shared/types";
import API from "../../_shared/axios";
import { alertError, getErrorMsg } from "../../_shared/utils";
import { AlertContext } from "../_shared/ToastList";
import { makeStyles } from "@material-ui/styles";
import LabelRibbon from "../_shared/LabelRibbon";

const useStyles = makeStyles({
  mt20: { marginTop: 20 },
  mt10: { marginTop: 10 },
  selectItem: {
    display: "flex",
    justifyContent: "space-between",
  },
  entityList: {
    "& > div": {
      margin: "0 10px 25px 0",
      color: "rgba(0, 0, 0, 0.87) !important",
      "& > span": {
        fontWeight: "bold !important",
        fontSize: "14px !important",
      },
    },
  },
  ingredientItem: {
    position: "relative",
  },
  inputCounter: {
    position: "absolute",
    right: 0,
    top: 0,
    paddingRight: 10,
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
});

const ProductConfiguration: FC<{
  currentSelectedType: string;
  currentProductVariants: number[];
  item:
    | {
        index: number;
        id: number;
        ingredientsToAdd?: {
          name: string;
          quantity: number;
          ingredientId: number;
        }[];
        ingredientsToRemove?: {
          name: string;
          quantity: number;
          ingredientId: number;
        }[];
        variants: { variantId: number; price: number }[];
        name: string;
        options: { [id: string]: string[] };
        productId: number;
        variantId: number;
        quantity: number;
        totalPrice: number | string;
      }
    | undefined;
  onClose: () => void;
  setCurrentItem: (
    item:
      | {
          index: number;
          id: number;
          ingredientsToAdd?: {
            name: string;
            quantity: number;
            ingredientId: number;
          }[];
          ingredientsToRemove?: {
            name: string;
            quantity: number;
            ingredientId: number;
          }[];
          name: string;

          variantId: number;
          options: { [id: string]: string[] };
          productId: number;
          quantity: number;
          totalPrice: number | string;
          isOptionsError: boolean;
        }
      | undefined
  ) => void;
  currentItemOpen: boolean;
}> = ({
  item,
  onClose,
  setCurrentItem,
  currentItemOpen,
  currentSelectedType,
  currentProductVariants,
}) => {
  const [itemState, setItemState] = useState(item);
  const [addedIngredientsList, setAddedIngredientsList] = useState<
    AddedIngredient[]
  >([]);
  const [removedIngredientsList, setRemovedIngredientsList] = useState<
    {
      id: number;
      name: string;
      isFree: boolean;
      position: number;
      isActive: boolean;
      categories: null;
    }[]
  >([]);
  const [removedIngredientsState, setRemovedIngredientsState] = useState<
    {
      id: number;
      name: string;
      isFree: boolean;
      position: number;
      isActive: boolean;
      categories: null;
    }[]
  >([]);
  const [variantsData, setVariantsData] = useState<
    {
      variantId: number;
      price: number;
      values: {
        optionId: number;
        optionData: string;
        optionValueData: string;
        optionValueId: number;
      }[];
    }[]
  >([]);

  const alertContext = useContext<AlertContextType>(AlertContext);

  const classes = useStyles();

  useEffect(() => {
    if (item !== undefined) {
      setItemState(item);
      API.get(`/ingredients`)
        .then(({ data }) => {
          const ingredients = data.map((el: Ingredient) => {
            return {
              id: el.id,
              name: el.name,
              isFree: false,
              maxQuantity: el.maxQuantity,
              position: el.position,
              isActive: true,
              categories: null,
            };
          });
          API.get(`/products/${item.productId}`)
            .then(({ data: productData }) => {
              setVariantsData(
                currentProductVariants.length > 0
                  ? productData.variants.filter((variant: any) =>
                      currentProductVariants.includes(variant.variantId)
                    )
                  : productData.variants
              );
              const itemAddedIds = item.ingredientsToAdd
                ? item.ingredientsToAdd.map((el: any) => el.ingredientId)
                : [];
              const addedIds = productData.ingredientsForAdding.map(
                (el: any) => el.id
              );
              const removedIds = productData.ingredientsForRemoving.map(
                (el: any) => el.id
              );
              const newAddedIngredientsList = ingredients
                .filter((el: any) => addedIds.includes(el.id))
                .map((el: any) => {
                  const curIngredient = item.ingredientsToAdd
                    ? item.ingredientsToAdd.find(
                        (ing: any) => ing.ingredientId === el.id
                      )
                    : null;
                  const curIngredientQuantity = curIngredient
                    ? curIngredient.quantity
                      ? curIngredient.quantity
                      : 1
                    : 1;
                  return {
                    id: el.id,
                    isActive: el.isActive,
                    name: el.name,
                    isChecked: itemAddedIds.includes(el.id),
                    quantity: curIngredientQuantity,
                    maxQuantity: el.maxQuantity ? el.maxQuantity : 100,
                  };
                });
              setAddedIngredientsList(newAddedIngredientsList);
              const RemovedIngredientsList = ingredients.filter((el: any) =>
                removedIds.includes(el.id)
              );
              setRemovedIngredientsList(RemovedIngredientsList);
            })
            .catch((error) =>
              alertError(
                alertContext,
                getErrorMsg(error.response, "Ошибка получения товара")
              )
            );
          setRemovedIngredientsState(
            item.ingredientsToRemove
              ? ingredients.filter((el: Ingredient) =>
                  item
                    .ingredientsToRemove!.map((el) => el.ingredientId)
                    .includes(el.id)
                )
              : []
          );
        })
        .catch((error) =>
          alertError(
            alertContext,
            getErrorMsg(error.response, "Ошибка получения списка ингредиентов")
          )
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  return (
    <Dialog
      disableBackdropClick
      onBackdropClick={() => onClose()}
      open={currentItemOpen}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth={"sm"}
    >
      <DialogTitle id="form-dialog-title">Конфигурирование товара</DialogTitle>
      <DialogContent>
        <Select
          fullWidth
          labelId="simple-select-city"
          value={
            itemState?.options && Object.keys(itemState?.options!)[0].length > 0
              ? Object.keys(itemState?.options!)[0]
              : 0
          }
          onChange={(e: any) => {
            const variantId = e.target.value;
            let variantValues: string[] = [];
            const currentVariant = variantsData.find(
              (el) => el.variantId === variantId
            );
            currentVariant!.values.map(
              (el) => (variantValues = [...variantValues, el.optionValueData])
            );
            const currentDict: any = {};
            currentDict[String(variantId)] = variantValues;
            let totalPrice = Number(
              itemState!.quantity *
                itemState!.variants.find(
                  (el) => el.variantId === e.target.value
                )!.price
                ? itemState!.variants.find(
                    (el) => el.variantId === e.target.value
                  )!.price
                : item!.totalPrice
            ).toFixed(2);
            if (totalPrice.toString().slice(-2) === "00")
              totalPrice = totalPrice.toString().slice(0, -3);
            setItemState({
              ...itemState!,
              totalPrice,
              variantId: e.target.value,
              options: currentDict,
            });
          }}
        >
          <MenuItem value={0} disabled>
            Вариации
          </MenuItem>
          {variantsData.map((c, idx) => (
            <MenuItem key={idx} value={c.variantId}>
              {c.values
                .reduce((acc, cur) => `${cur.optionValueData}, ` + acc, "")
                .slice(0, -2)}
            </MenuItem>
          ))}
        </Select>
        <br />
        <br />
        {currentSelectedType === "items" && (
          <>
            <LabelRibbon
              withCounter
              data={addedIngredientsList.map((el) => {
                const { id, name, quantity, maxQuantity } = el;
                return {
                  id,
                  name,
                  maxQuantity,
                  quantity,
                  isChecked: el.isChecked,
                  isActive: el.isActive,
                };
              })}
              title={"Добавляемые ингредиенты"}
              onConfirm={(data: LabelRibbonData[]) =>
                // @ts-ignore
                setAddedIngredientsList(data)
              }
            >
              {addedIngredientsList.filter((el) => el.isChecked).length > 0 && (
                <div className={classes.entityList}>
                  {addedIngredientsList
                    .filter((el) => el.isChecked)
                    .map((entity, key) => (
                      <Chip
                        key={key}
                        label={`${entity.name} (${entity.quantity})`}
                        onDelete={() => {
                          setAddedIngredientsList(
                            addedIngredientsList.map((el) =>
                              el.id !== entity.id
                                ? el
                                : { ...el, isChecked: !el.isChecked }
                            )
                          );
                        }}
                        color="primary"
                        variant="outlined"
                      />
                    ))}
                </div>
              )}
            </LabelRibbon>
            <Select
              className={classes.mt10}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={
                removedIngredientsState.map((el) => el.id).length > 0
                  ? removedIngredientsState.map((el) => el.id)
                  : [0]
              }
              inputProps={{ "aria-label": "Without label" }}
              multiple
              fullWidth
              MenuProps={{ variant: "menu" }}
              onChange={(e: any) => {
                const newIds = e.target.value.filter((el: number) => el !== 0);
                setRemovedIngredientsState(
                  removedIngredientsList.filter((el) => newIds.includes(el.id))
                );
              }}
            >
              <MenuItem value={0} disabled>
                Удаляемые ингредиенты
              </MenuItem>
              {removedIngredientsList.map((el) => (
                <MenuItem value={el.id} disabled={!el.isActive} key={el.id}>
                  {el.name}
                </MenuItem>
              ))}
            </Select>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Отмена
        </Button>
        <Button
          onClick={() => {
            setCurrentItem({
              ...itemState!,
              ingredientsToAdd: addedIngredientsList
                .filter((el) => el.isChecked)
                .map((ing) => {
                  return {
                    ingredientId: ing.id,
                    name: ing.name,
                    quantity: ing.quantity,
                  };
                }),
              ingredientsToRemove: removedIngredientsState.map((ing) => {
                return { ingredientId: ing.id, name: ing.name, quantity: 1 };
              }),
              isOptionsError:
                variantsData.length === 0
                  ? false
                  : itemState?.variantId
                  ? false
                  : !item?.options,
            });
            onClose();
          }}
          color="primary"
          type="submit"
        >
          Подтвердить
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductConfiguration;
