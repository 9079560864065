import { Button, makeStyles } from "@material-ui/core";
import React, { FC, useContext, useEffect, useState } from "react";
import API from "../../_shared/axios";
import { AlertContextType, EmployeeListItem } from "../../_shared/types";
import SyncTable from "./syncTable";
import { alertError, alertSuccess, getErrorMsg } from "../../_shared/utils";
import { AlertContext } from "../_shared/ToastList";
import { TouchedAlert } from "./index";
import { useDebounce } from "@react-hook/debounce";

export interface iSyncItemEmployee {
  syncId: number;
  syncName: string;
  id: number | null;
  name?: string | null;
  relation?: {
    id?: number;
    fullName: string;
  };
}

interface iEmployeePost {
  syncEmployeeId: number;
  employeeId: number | null;
}
const useStyles = makeStyles({
  page: {
    margin: 0,
  },
  content: {
    padding: 50,
    "& .MuiTableCell-body": {
      cursor: "pointer",
    },
  },
  heading: {
    display: "flex",
    alignItems: "center",
  },
  paginationPaper: { marginTop: 20 },
  count: {
    fontSize: 16,
    fontWeight: "bold",
  },
});

export type EmployeeListItemTransformed = EmployeeListItem & {
  fullName: string;
};

const EmployeePage: FC = () => {
  const classes = useStyles();
  const alertContext = useContext<AlertContextType>(AlertContext);
  const touchedContext = useContext(TouchedAlert);

  const [backendData, setBackendData] = useState<EmployeeListItemTransformed[]>(
    []
  );
  const [oneC_data, setOneC_data] = useState<iSyncItemEmployee[]>([]);
  const [changedData, setChangedData] = useState<iEmployeePost[]>([]);
  const [unusedBackendData, setUnusedBackendData] = useState<
    EmployeeListItemTransformed[]
  >([]);
  const [page, setPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [search1CString, setSearch1CString] = useState("");
  const [searchBackendString, setSearchBackendString] = useState("");
  // 0 - no filter, 1 - synced, 2 - not synced
  const [filter, setFilter] = useState(0);
  const [filters, setFilters] = useDebounce(
    {
      page: 0,
      pageSize: 25,
      search1CString: "",
      searchBackendString: "",
      filter: 0,
    },
    500
  );

  useEffect(() => {
    API.get("/employees")
      .then((data) => {
        API.get(`/syncEmployeeList?page=1&pageSize=9999999`)
          .then((syncResp) => {
            const usedIds = syncResp.data.items.filter(
              (v: any) => v.id !== null
            );
            setUnusedBackendData(
              data.data
                .filter(
                  (employee: EmployeeListItem) =>
                    !usedIds.some((emp: any) => emp.id === employee.id)
                )
                .map((employee: EmployeeListItem) => ({
                  ...employee,
                  fullName: employee.firstName + " " + employee.lastName,
                }))
            );

            setBackendData(
              data.data.map(
                (item: EmployeeListItem): EmployeeListItemTransformed => {
                  return {
                    ...item,
                    fullName: item.firstName + " " + item.lastName,
                  };
                }
              )
            );
          })
          .catch((error) => {
            console.log(error);

            alertError(
              alertContext,
              getErrorMsg(
                error.response,
                "Неизвестная ошибка получения списка синхронизации"
              )
            );
          });
      })
      .catch((error) => {
        console.log(error);
        alertError(
          alertContext,
          getErrorMsg(
            error.response,
            "Неизвестная ошибка получения списка работников"
          )
        );
      });
  }, []);

  const load = () => {
    if (Array.isArray(backendData)) {
      // `pageNumber=${clientsPage}&FilterParams[0].ColumnName=phoneNumber&FilterParams[0].FilterOption=3&FilterParams[0].filterValue=${clientSearchString}`

      const uriStrings = [];
      let indx = 0;
      if (searchBackendString) {
        uriStrings.push(
          `&FilterParams[${indx}].ColumnName=name` +
            `&FilterParams[${indx}].FilterOption=3` +
            `&FilterParams[${indx}].filterValue=${searchBackendString}`
        );
        indx += 1;
      }
      if (search1CString) {
        uriStrings.push(
          `&FilterParams[${indx}].ColumnName=syncName` +
            `&FilterParams[${indx}].FilterOption=3` +
            `&FilterParams[${indx}].filterValue=${search1CString}`
        );
        indx += 1;
      }
      if (filter !== 0) {
        uriStrings.push(
          `${
            filter === 1
              ? `&FilterParams[${indx}].ColumnName=id` +
                `&FilterParams[${indx}].FilterOption=12`
              : filter === 2
              ? `&FilterParams[${indx}].ColumnName=id` +
                `&FilterParams[${indx}].FilterOption=11`
              : ""
          }`
        );
      }

      let urlParams =
        `pageNumber=${page + 1}` +
        `&pageSize=${pageSize}` +
        `${uriStrings.join("")}`;

      API.get(`/syncEmployeeList?${urlParams}`)
        .then((data) => {
          setTotalItems(data.data.totalItems);
          setOneC_data(
            data.data.items
              .map((item: iSyncItemEmployee) => {
                return {
                  ...item,
                  relation: backendData.find(
                    (j: EmployeeListItemTransformed) => j?.id === item?.id
                  ),
                };
              })
              .sort((a: iSyncItemEmployee) => (a?.name === null ? 1 : -1))
          );
        })
        .catch((error) => {
          console.log(error);
          alertError(
            alertContext,
            getErrorMsg(
              error.response,
              "Неизвестная ошибка получения списка синзронизации"
            )
          );
        });
    }
  };

  useEffect(() => {
    load();
  }, [backendData, filters]);

  useEffect(() => {
    setFilters({ filter, page, pageSize, search1CString, searchBackendString });
  }, [filter, page, pageSize, search1CString, searchBackendString]);

  const transformDataForPost = (
    backendObj: EmployeeListItemTransformed,
    oneC_obj: iSyncItemEmployee,
    index: number
  ) => {
    touchedContext.setter(true);
    setChangedData((prevStat: iEmployeePost[]) => {
      return [
        ...prevStat,
        { syncEmployeeId: oneC_obj.syncId, employeeId: backendObj?.id || null },
      ];
    });
    setOneC_data((prevStat) => {
      const obj3: iSyncItemEmployee = {
        ...oneC_obj,
        id: backendObj?.id || null,
        name: backendObj?.id ? backendObj.fullName : null,
        relation: backendObj,
      };

      const arr = [...prevStat];
      arr.splice(index, 1, obj3);

      return [...arr];
    });
  };

  const postData = () => {
    API.post("/mapSyncEmployees", changedData)
      .then((data) => {
        touchedContext.setter(false);
        alertSuccess(alertContext, "Данные успешно синхронизированы");
      })
      .catch((error) => {
        console.log(error);
        alertError(
          alertContext,
          getErrorMsg(error.response, "Неизвестная ошибка синхронизации")
        );
      });
  };

  useEffect(() => {
    setUnusedBackendData(
      unusedBackendData?.filter((i: EmployeeListItemTransformed) => {
        // return i
        const user = oneC_data?.find((j: iSyncItemEmployee) => {
          return j.id === i.id;
        });
        return user?.id !== i.id;
      })
    );
  }, [changedData]);

  return (
    <div className={classes.content}>
      <div className={classes.heading}>
        <h2>
          Синхронизация &nbsp;&nbsp;{" "}
          <Button variant="outlined" color="primary" onClick={() => postData()}>
            Синхронизировать
          </Button>
        </h2>
      </div>
      <SyncTable<iSyncItemEmployee, EmployeeListItemTransformed>
        title="Сотрудник"
        changeDatHandler={(
          v: EmployeeListItemTransformed,
          name: iSyncItemEmployee,
          index: number
        ) => transformDataForPost(v, name, index)}
        filteredGetData={unusedBackendData}
        data={oneC_data}
        page={page}
        totalItems={totalItems}
        pageChange={(page) => setPage(page)}
        pageSize={pageSize}
        pageSizeChange={(pageSize) => setPageSize(pageSize)}
        search1CString={search1CString}
        setSearch1CString={(str) => {
          setPage(0);
          setSearch1CString(str);
        }}
        searchBackendString={searchBackendString}
        setSearchBackendString={(str) => {
          setPage(0);
          setSearchBackendString(str);
        }}
        filter={filter}
        setFilter={(val) => {
          setPage(0);
          setFilter(val);
        }}
      />
    </div>
  );
};
export default EmployeePage;
