import { makeStyles } from "@material-ui/styles";

export default makeStyles({
  blocked: {
    background: "#fafafa",
    "& th": {
      color: "gray",
      "& img": {
        filter: "grayscale(0.8)",
      },
    },
    "& td": {
      color: "gray",
      "& img": {
        filter: "grayscale(0.8)",
      },
    },
  },
});
