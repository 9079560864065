import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Paper,
  Table,
  TableBody,
  TableContainer,
  Typography,
  Checkbox,
  TablePagination,
  Button,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import API from "../../_shared/axios";
import { PromocodeListed } from "../../_shared/types";
import { Search } from "@material-ui/icons";
import PromocodesForm from "./PromocodeForm";

const useStyles = makeStyles({
  pointer: { cursor: "pointer" },
  table: {
    marginTop: "30px",
    marginBottom: "50px",
  },
  head: {},
  header: {
    marginTop: "50px",
    marginBottom: "10px",
  },
  buttonHeight: {
    height: "40px",
  },
  width100: {
    width: "100%",
  },
  content: {
    padding: 50,
    maxWidth: 1140,
    margin: "0 auto",
    "& .MuiTableCell-body": {
      cursor: "pointer",
    },
    "& th": {
      backgroundColor: "white",
    },
  },
});

const PromocodesPage = () => {
  const classes = useStyles();

  const [codes, setCodes] = useState<Array<PromocodeListed>>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [codeSearch, setCodeSearch] = useState("");
  const [promocodeId, setPromocodeId] = useState<number | undefined | "new">();
  const [codesFilter, setCodesFilter] = useState<Array<PromocodeListed>>([]);
  const updateCodesList = () => {
    API.get("/promoCodes")
      .then((resp) => {
        resp.data.reverse();
        setCodes(resp.data);
      })
      .catch((resp) => console.log(resp));
  };
  useEffect(() => {
    updateCodesList();
  }, []);
  useEffect(() => {
    setCodesFilter(
      codes.filter((c) => c.name.toLowerCase().includes(codeSearch))
    );
  }, [codes, codeSearch]);
  useEffect(() => {
    setCodeSearch(codeSearch.toLowerCase());
    API.get(
      `/promoCodes?FilterParams[0].ColumnName=Name&FilterParams[0].FilterOption=3&FilterParams[0].filterValue=${codeSearch}`
    )
      .then((resp) => {
        resp.data.reverse();
        setCodes(resp.data);
      })
      .catch((resp) => console.log(resp));
  }, [codeSearch]);

  return (
    <Container className={classes.content}>
      <PromocodesForm
        onEditOrCreate={() => updateCodesList()}
        id={promocodeId}
        onClose={() => setPromocodeId(undefined)}
      />
      {/* <Typography className={classes.header} variant={"h6"}>
        Промокоды
      </Typography> */}
      <div style={{ display: "inline" }}>
        <h2>
          Промокоды &nbsp; &nbsp;{" "}
          <Button
            className={classes.buttonHeight}
            variant="outlined"
            color="primary"
            onClick={(e) => setPromocodeId("new")}
          >
            Добавить промокод
          </Button>
        </h2>
      </div>
      <Paper className={classes.head}>
        <TextField
          type={"text"}
          value={codeSearch}
          className={classes.width100}
          onChange={(e) => {
            setPage(0);
            setCodeSearch(e.target.value);
          }}
          placeholder={"Поиск"}
          variant={"outlined"}
          size={"small"}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Paper>

      <Paper className={classes.table}>
        <TableContainer>
          <Table size={"small"}>
            <TableHead>
              <TableRow>
                <TableCell>Название</TableCell>
                <TableCell>Индивидуальный</TableCell>
                <TableCell>Доступен на сайте</TableCell>
                <TableCell>Доступен в приложении</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {codesFilter
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((c) => (
                  <TableRow
                    hover
                    key={c.id}
                    className={classes.pointer}
                    onClick={(e) => setPromocodeId(c.id)}
                  >
                    <TableCell>{c.name}</TableCell>
                    <TableCell>
                      <Checkbox
                        size={"small"}
                        color={"primary"}
                        disabled
                        checked={c.individual}
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        color={"primary"}
                        size={"small"}
                        checked={c.forSite}
                        disabled
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        color={"primary"}
                        size={"small"}
                        checked={c.forApplications}
                        disabled
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            labelRowsPerPage={"Промокодов на странице:"}
            labelDisplayedRows={({ from, to }) =>
              `${from}-${to} из ${codesFilter.length}`
            }
            rowsPerPageOptions={[10, 15, 25, 50]}
            component="div"
            count={codesFilter.length}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={(e) => {
              setRowsPerPage(Number(e.target.value));
              setPage(0);
            }}
            page={page}
            onChangePage={(e, newPage) => setPage(newPage ? newPage : 0)}
          />
        </TableContainer>
      </Paper>
    </Container>
  );
};

export default PromocodesPage;
