import React, { FC } from "react";
import { Button, Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";

const ModalCloseConfirm: FC<{
  cancel: () => void;
  hideAlert: () => void;
}> = ({ cancel, hideAlert }) => {
  const useStyles = makeStyles({
    alert: {
      width: "100%",
    },
  });
  const classes = useStyles();

  return (
    <Grid container direction={"column"} alignItems={"center"}>
      <Alert
        severity="warning"
        className={classes.alert}
        action={
          <>
            <Button color="inherit" size="small" onClick={() => cancel()}>
              Да
            </Button>
            <Button color="inherit" size="small" onClick={() => hideAlert()}>
              Нет
            </Button>
          </>
        }
      >
        Вы действительно хотите отменить все изменения и выйти? Введенные данные
        будут утеряны
      </Alert>
    </Grid>
  );
};
export default ModalCloseConfirm;
