import React, { FC } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TextField from "@material-ui/core/TextField";
import { IngredientCategory } from "../../_shared/types";
import { copyObject, getFloatFromString } from "../../_shared/utils";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  weightPriceWrapper: { width: "40%" },
  w35p: { width: "40%" },
  mr5p: { marginRight: "5%" },
  page: {
    width: "100%",
    flexDirection: "column",
    "& > div": {
      "& .MuiAccordionSummary-content": {
        margin: "0 !important",
      },
      "& .MuiAccordionDetails-root": {
        padding: "0 0 0 20px !important",
        "&:last-child": {
          paddingBottom: "10px !important",
        },
      },
    },
  },
  pl15: { paddingLeft: 15 },
  variantsWrapper: { width: "60%", display: "flex", alignItems: "center" },
  variants: { display: "flex", alignItems: "center" },
});

const IngredientCombinations: FC<{
  categoryIndex: number;
  categoriesData: IngredientCategory[];
  onUpdateList: (data: IngredientCategory[]) => void;
  isOpen: boolean;
}> = ({ categoryIndex, categoriesData, onUpdateList, isOpen }) => {
  const classes = useStyles();

  const isCurrentOptionDisabled = (optionId: number, categoryIndex: number) => {
    const selectedOptions = categoriesData[categoryIndex].options.filter(
      (el) =>
        el.optionVariants.filter((variant) => variant.isChecked).length > 0
    );
    const selectedImpactOnPrice = selectedOptions.find(
      (el) => el.impactOnPrice
    );
    const currentImpact = categoriesData[categoryIndex].options.find(
      (el) => el.id === optionId
    )!.impactOnPrice;
    return !selectedImpactOnPrice
      ? false
      : selectedImpactOnPrice.id !== optionId && currentImpact;
  };

  return isOpen ? (
    <div className={classes.page}>
      {categoriesData[categoryIndex].options.map((el, key) => (
        <Accordion key={key}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-label="Expand"
            aria-controls="additional-actions1-content"
            id="additional-actions1-header"
          >
            <FormControlLabel
              aria-label="Acknowledge"
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}
              control={
                <Checkbox
                  color={"primary"}
                  disabled={isCurrentOptionDisabled(el.id, categoryIndex)}
                  checked={categoriesData[categoryIndex].options[key].isChecked}
                  onChange={(event) => {
                    event.stopPropagation();
                    const currentList = copyObject(categoriesData);
                    currentList[categoryIndex].options[key].isChecked =
                      event.target.checked;
                    for (
                      let i = 0;
                      i <
                      currentList[categoryIndex].options[key].optionVariants
                        .length;
                      i++
                    ) {
                      currentList[categoryIndex].options[key].optionVariants[
                        i
                      ].isChecked = event.target.checked;
                    }
                    onUpdateList(currentList);
                  }}
                />
              }
              label={el.value}
            />
          </AccordionSummary>
          {el.optionVariants.map((variant, index) => (
            <AccordionDetails key={index} className={classes.variants}>
              <div className={classes.variantsWrapper}>
                <FormControlLabel
                  className={classes.pl15}
                  aria-label="Acknowledge"
                  onFocus={(event) => event.stopPropagation()}
                  control={
                    <Checkbox
                      color={"primary"}
                      onChange={(event) => {
                        event.stopPropagation();
                        const currentList = copyObject(categoriesData);
                        currentList[categoryIndex].options[key].optionVariants[
                          index
                        ].isChecked = event.target.checked;
                        currentList[categoryIndex].options[key].isChecked =
                          currentList[categoryIndex].options[
                            key
                          ].optionVariants.filter((el: any) => el.isChecked)
                            .length > 0;
                        onUpdateList(currentList);
                      }}
                      disabled={isCurrentOptionDisabled(el.id, categoryIndex)}
                      checked={
                        categoriesData[categoryIndex].options[key]
                          .optionVariants[index].isChecked
                      }
                    />
                  }
                  label={variant.value}
                />
              </div>
              {el.impactOnPrice && (
                <div className={classes.weightPriceWrapper}>
                  <TextField
                    margin="dense"
                    label="Вес"
                    className={[classes.w35p, classes.mr5p].join(" ")}
                    inputProps={{ maxLength: 10 }}
                    value={variant.weight || 0}
                    disabled={
                      !variant.isChecked ||
                      isCurrentOptionDisabled(el.id, categoryIndex)
                    }
                    onChange={(e) => {
                      if (e.target.value === " ") return null;
                      const currentList = copyObject(categoriesData);
                      currentList[categoryIndex].options[key].optionVariants[
                        index
                      ].weight = getFloatFromString(e.target.value);
                      onUpdateList(currentList);
                    }}
                    type="text"
                  />
                  <TextField
                    margin="dense"
                    type="text"
                    label="Цена"
                    value={variant.price || ""}
                    error={
                      !(
                        !variant.isChecked ||
                        isCurrentOptionDisabled(el.id, categoryIndex)
                      ) &&
                      (variant.price === 0 ||
                        variant.price === "0" ||
                        variant.price === "")
                    }
                    required
                    onFocus={() => {
                      if (variant.price === undefined) {
                        const currentList = copyObject(categoriesData);
                        currentList[categoryIndex].options[key].optionVariants[
                          index
                        ].price = "";
                        onUpdateList(currentList);
                      }
                    }}
                    helperText={
                      variant.price === (0 || "0" || "")
                        ? "Укажите стоимость больше 0"
                        : ""
                    }
                    disabled={
                      !variant.isChecked ||
                      isCurrentOptionDisabled(el.id, categoryIndex)
                    }
                    inputProps={{ maxLength: 10 }}
                    onChange={(e) => {
                      if (e.target.value === " ") return null;
                      const currentList = copyObject(categoriesData);
                      currentList[categoryIndex].options[key].optionVariants[
                        index
                      ].price = getFloatFromString(e.target.value);
                      onUpdateList(currentList);
                    }}
                    className={classes.w35p}
                  />
                </div>
              )}
            </AccordionDetails>
          ))}
        </Accordion>
      ))}
    </div>
  ) : null;
};

export default IngredientCombinations;
