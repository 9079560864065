import { Button, makeStyles } from "@material-ui/core";
import React, { FC, useContext, useEffect, useState } from "react";
import API from "../../_shared/axios";
import SyncTable from "./syncTable";
import { Shop } from "../_shared/types";
import { AlertContextType } from "../../_shared/types";
import { AlertContext } from "../_shared/ToastList";
import { alertError, alertSuccess, getErrorMsg } from "../../_shared/utils";
import { TouchedAlert } from "./index";
import { useDebounce } from "@react-hook/debounce";

interface iSyncItem {
  syncId: number;
  syncName: string;
  id: number | null;
  name: string | null;
  relation: Shop & { fullName: string };
}

interface iSyncAddressItem {
  syncId: number;
  syncName: string;
  id: number;
  name: string;
}

interface iShopPost {
  syncShopId: number;
  shopId?: number | null;
}

const useStyles = makeStyles({
  page: {
    margin: 0,
  },
  content: {
    padding: 50,
    "& .MuiTableCell-body": {
      cursor: "pointer",
    },
  },
  heading: {
    display: "flex",
    alignItems: "center",
  },
});

const ShopsSyncPage: FC = () => {
  const classes = useStyles();
  const alertContext = useContext<AlertContextType>(AlertContext);
  const touchedContext = useContext(TouchedAlert);

  const [backendData, setBackendData] = useState<
    Array<Shop & { fullName: string }>
  >([]);
  const [oneC_data, setOneC_data] = useState<iSyncItem[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [changedData, setChangedData] = useState<iShopPost[]>([]);
  const [unusedBackendData, setUnusedBackendData] = useState<
    Array<Shop & { fullName: string }>
  >([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [search1CString, setSearch1CString] = useState("");
  const [searchBackendString, setSearchBackendString] = useState("");
  // 0 - no filter, 1 - synced, 2 - not synced
  const [filter, setFilter] = useState(0);
  const [filters, setFilters] = useDebounce(
    {
      page: 0,
      pageSize: 25,
      search1CString: "",
      searchBackendString: "",
      filter: 0,
    },
    500
  );

  useEffect(() => {
    API.get("/shops")
      .then((resp) => {
        setBackendData(
          resp.data.map((i: Shop) => ({ ...i, fullName: i.name }))
        );
        API.get(`/syncShopList?page=1&pageSize=9999999`)
          .then((syncResp) => {
            const usedIds = syncResp.data.items.filter(
              (v: any) => v.id !== null
            );
            setUnusedBackendData(
              resp.data.filter(
                (shop: any) => !usedIds.some((id: number) => id === shop.id)
              )
            );
          })
          .catch((error) => {
            console.log(error);
            alertError(
              alertContext,
              getErrorMsg(
                error.response,
                "Неизвестная ошибка получения списка синхронизации"
              )
            );
          });
      })
      .catch((error) => {
        console.log(error);
        alertError(
          alertContext,
          getErrorMsg(
            error.response,
            "Неизвестная ошибка получения списка магазинов"
          )
        );
      });
  }, []);

  useEffect(() => {
    setUnusedBackendData(
      backendData
        ?.filter((j: Shop) => {
          return !oneC_data.some((item) => {
            if (item.id) {
              return j?.id === item?.id;
            }
          });
        })
        .map((v: Shop) => {
          return { ...v, fullName: v.name };
        })
    );
  }, [oneC_data, changedData]);

  const load = () => {
    const uriStrings = [];
    let indx = 0;
    if (searchBackendString) {
      uriStrings.push(
        `&FilterParams[${indx}].ColumnName=name` +
          `&FilterParams[${indx}].FilterOption=3` +
          `&FilterParams[${indx}].filterValue=${searchBackendString}`
      );
      indx += 1;
    }
    if (search1CString) {
      uriStrings.push(
        `&FilterParams[${indx}].ColumnName=syncName` +
          `&FilterParams[${indx}].FilterOption=3` +
          `&FilterParams[${indx}].filterValue=${search1CString}`
      );
      indx += 1;
    }
    if (filter !== 0) {
      uriStrings.push(
        `${
          filter === 1
            ? `&FilterParams[${indx}].ColumnName=id` +
              `&FilterParams[${indx}].FilterOption=12`
            : filter === 2
            ? `&FilterParams[${indx}].ColumnName=id` +
              `&FilterParams[${indx}].FilterOption=11`
            : ""
        }`
      );
    }

    let urlParams =
      `pageNumber=${page + 1}` +
      `&pageSize=${pageSize}` +
      `${uriStrings.join("")}`;

    API.get(`/syncShopList?${urlParams}`)
      .then((data) => {
        setTotalItems(data.data.totalItems);
        setOneC_data(
          data.data.items
            .map((item: iSyncItem) => {
              return {
                ...item,
                relation: backendData.find((s) => s.id === item?.id),
              };
            })
            .sort((a: iSyncItem, b: iSyncItem) => (a?.name === null ? 1 : -1))
        );
      })
      .catch((error) => {
        console.log(error);
        alertError(
          alertContext,
          getErrorMsg(
            error.response,
            "Неизвестная ошибка получения списка синхронизации"
          )
        );
      });
  };

  useEffect(() => {
    load();
  }, [backendData, filters]);

  useEffect(() => {
    setFilters({ filter, page, pageSize, search1CString, searchBackendString });
  }, [filter, page, pageSize, search1CString, searchBackendString]);

  const transformDataForPost = (
    backendObj: Shop & { fullName: string },
    oneC_obj: iSyncItem,
    index: number
  ) => {
    touchedContext.setter(true);
    setChangedData((prevStat: iShopPost[]) => {
      return [
        ...prevStat,
        { syncShopId: oneC_obj.syncId, shopId: backendObj?.id },
      ];
    });
    setOneC_data((prevStat) => {
      const obj3: iSyncItem = {
        ...oneC_obj,
        id: backendObj?.id || null,
        name: backendObj?.id ? backendObj.name : null,
        relation: backendObj,
      };

      const arr = [...prevStat];
      arr.splice(index, 1, obj3);

      return [...arr];
    });
  };

  const postData = () => {
    API.post("/mapSyncShops", changedData)
      .then((data) => {
        touchedContext.setter(false);
        alertSuccess(alertContext, "Успешно сихронизировано");
      })
      .catch((error) => {
        console.log(error);
        alertError(
          alertContext,
          getErrorMsg(error.response, "Неизвестная ошибка синхронизации")
        );
      });
  };

  return (
    <div className={classes.content}>
      <div className={classes.heading}>
        <h2>
          Синхронизация &nbsp;&nbsp;{" "}
          <Button variant="outlined" color="primary" onClick={() => postData()}>
            Синхронизировать
          </Button>
        </h2>
      </div>
      <SyncTable<iSyncItem, Shop & { fullName: string }>
        changeDatHandler={(
          v: Shop & { fullName: string },
          name: iSyncItem,
          index: number
        ) => transformDataForPost(v, name, index)}
        data={oneC_data}
        totalItems={totalItems}
        filteredGetData={unusedBackendData}
        title={"Магазин"}
        page={page}
        pageChange={(page) => setPage(page)}
        pageSize={pageSize}
        pageSizeChange={(pageSize) => setPageSize(pageSize)}
        search1CString={search1CString}
        setSearch1CString={(str) => {
          setPage(0);
          setSearch1CString(str);
        }}
        searchBackendString={searchBackendString}
        setSearchBackendString={(str) => {
          setPage(0);
          setSearchBackendString(str);
        }}
        filter={filter}
        setFilter={(val) => {
          setPage(0);
          setFilter(val);
        }}
      />
    </div>
  );
};
export default ShopsSyncPage;
