import React, { FC, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Alert } from "@material-ui/lab";
import { CategoryCreate } from "../../_shared/types";
import {
  getSizeOfStringInBytes,
  getIntFromString,
  isObjectsEqual,
} from "../../_shared/utils";
import { ProgressBar } from "../_shared/ProgressBar";
import { Checkbox, FormControl, FormControlLabel } from "@material-ui/core";
import { useStyles } from "./styles";

const NewCategoryForm: FC<{
  onCategoryCreate: (category: CategoryCreate) => Promise<any>;
}> = ({ onCategoryCreate }) => {
  const [open, setOpen] = React.useState(false);

  const categoryInit = {
    name: "",
    description: "",
    position: 0,
    showInMenu: false,
  };
  const [category, setCategory] = useState(categoryInit);

  const [progress, setProgress] = useState(false);

  const isBlurredInit = {
    name: false,
    position: false,
  };
  const [isBlurred, setIsBlurred] = useState(isBlurredInit);
  const setBlurredField = (field: string) => {
    setIsBlurred({ ...isBlurred, [field]: true });
  };

  const handleClickOpen = () => {
    setIsBlurred(isBlurredInit);
    setCategory(categoryInit);
    setOpen(true);
    setAlerted(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  const [alerted, setAlerted] = useState(false);

  return (
    <div className={classes.wrapper}>
      <h2>
        Категории &nbsp; &nbsp;
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          Добавить категорию
        </Button>
      </h2>
      <Dialog
        disableBackdropClick
        onBackdropClick={() =>
          progress
            ? null
            : isObjectsEqual(category, categoryInit)
            ? setOpen(false)
            : setAlerted(true)
        }
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Новая категория</DialogTitle>
        <DialogContent>
          <form>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Наименование"
              inputProps={{ maxLength: 100 }}
              value={category.name}
              onChange={(e) =>
                setCategory({ ...category, name: e.target.value })
              }
              type="text"
              fullWidth
              onBlur={() => setBlurredField("name")}
              error={!category.name && isBlurred.name}
              helperText={
                !category.name && isBlurred.name ? "Обязательное поле" : ""
              }
              required
            />
            <TextField
              margin="dense"
              id="description"
              label="Описание"
              value={category.description}
              onChange={(e) => {
                if (getSizeOfStringInBytes(e.target.value) <= 4000)
                  setCategory({ ...category, description: e.target.value });
              }}
              type="text"
              fullWidth
            />
            <TextField
              margin="dense"
              id="position"
              label="Позиция"
              value={category.position}
              type="text"
              onChange={(e) =>
                e.target.value === " "
                  ? null
                  : setCategory({
                      ...category,
                      position: getIntFromString(e.target.value),
                    })
              }
              fullWidth
              onBlur={() => setBlurredField("position")}
              error={!category.position && isBlurred.position}
              helperText={
                !category.position && isBlurred.position
                  ? "Обязательное поле"
                  : ""
              }
              required
            />
            <FormControl className={classes.showInMenu}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={category.showInMenu}
                    onChange={(e) =>
                      setCategory({
                        ...category,
                        showInMenu: !category.showInMenu,
                      })
                    }
                    name="showInMenu"
                    color="primary"
                  />
                }
                label="Показывать в меню"
              />
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          {alerted ? (
            <Alert
              severity="warning"
              action={
                <>
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => setOpen(false)}
                  >
                    Да
                  </Button>
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => setAlerted(false)}
                  >
                    Нет
                  </Button>
                </>
              }
            >
              Вы действительно хотите отменить все изменения и выйти? Введенные
              данные будут утеряны
            </Alert>
          ) : (
            <>
              <Button onClick={handleClose} color="primary" disabled={progress}>
                Отмена
              </Button>
              <Button
                onClick={() => {
                  setProgress(true);
                  onCategoryCreate(category)
                    .then((data) => setOpen(false))
                    .catch((error) => console.log(error))
                    .finally(() => setProgress(false));
                }}
                color="primary"
                type="submit"
                disabled={
                  category.name === "" || category.position === 0 || progress
                }
              >
                Создать
              </Button>
            </>
          )}
        </DialogActions>
        {progress && <ProgressBar bottom />}
      </Dialog>
    </div>
  );
};

export default NewCategoryForm;
