import React, { FC } from "react";
import EmployeesPage from "./EmployeesPage";
import CustomersPage from "./CustomersPage";
import { Redirect, Route, Switch } from "react-router-dom";
import CenteredTabs from "./CenteredTabs";
import ProtectedRoute from "../../ProtectedRoute";
import { UserCookie } from "../../_shared/types";
import cookies from "../../../cookies";

const UsersPage: FC<{ match: { path: string } }> = ({ match }) => {
  const routes = [
    {
      component: EmployeesPage,
      path: `${match.path}/employees`,
    },
    {
      component: CustomersPage,
      path: `${match.path}/customers`,
    },
  ];

  const user: UserCookie | undefined = cookies.get("user");

  return (
    <>
      <CenteredTabs
        tabs={[
          { label: "Сотрудники", route: "employees" },
          { label: "Клиенты", route: "customers" },
        ]}
      />
      <Switch>
        {routes.map((rdata, key) => (
          <ProtectedRoute {...rdata} key={key} />
        ))}
        <Route
          render={() => (
            <Redirect
              to={
                user?.roles[0] !== "Operator"
                  ? `${match.path}/employees`
                  : `${match.path}/customers`
              }
            />
          )}
        />
      </Switch>
    </>
  );
};

export default UsersPage;
