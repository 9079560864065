import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { AuthCookie } from "./types";
import cookies from "../../cookies";

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: "json",
  headers: {
    post: {
      "Access-Control-Allow-Origin": "*",
    },
  },
});

API.interceptors.request.use(
  (config) => {
    const auth: AuthCookie | undefined = cookies.get("auth");
    if (auth) config.headers["Authorization"] = `Bearer ${auth.accessToken}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const refreshAuthLogic = (failedRequest: any) => {
  const auth: AuthCookie | undefined = cookies.get("auth");
  return auth?.refreshToken && auth?.userName
    ? API.post("/auth/refresh", {
        userName: auth.userName,
        token: auth.refreshToken,
      })
        .then(({ data }) => {
          console.log("refresh");
          let now = new Date();
          now.setTime(now.getTime() + 30 * 24 * 60 * 60 * 1000);
          cookies.set(
            "auth",
            {
              ...auth,
              accessToken: data.token,
              refreshToken: data.refreshToken,
            },
            { path: "/", expires: now, sameSite: "lax" }
          );
        })
        .catch(() => {
          cookies.remove("auth");
          Promise.reject(failedRequest);
        })
    : Promise.reject(failedRequest);
};

createAuthRefreshInterceptor(API, refreshAuthLogic, {
  skipWhileRefreshing: false,
});

export default API;
