import React, { FC, useState, useEffect, useContext } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import {
  City,
  KladrObjectCreate,
  KladrObject,
  KladrObjectType,
} from "./_shared/types";
import { AlertContextType, UserCookie } from "../../_shared/types";
import { AlertContext } from "../_shared/ToastList";
import { alertError } from "../../_shared/utils";
import { Alert, Autocomplete } from "@material-ui/lab";
import API from "../../_shared/axios";
import { Shop } from "../_shared/types";
import cookies from "../../../cookies";

const EditStreetForm: FC<{
  onSend: (id: number, data: KladrObjectCreate) => Promise<any>;
  data?: KladrObject | undefined;
  onClose: () => void;
  districtData?: KladrObject | undefined;
}> = ({ onSend, data, onClose, districtData }) => {
  const alertContext = useContext<AlertContextType>(AlertContext);

  const [alerted, setAlerted] = useState(false);
  const [blur, setBlur] = useState({
    cityId: false,
    name: false,
    kladrTypeId: false,
  });
  const [progress, setProgress] = useState(false);
  const [cities, setCities] = useState<City[]>([]);
  const [cityId, setCityId] = useState(0);
  const [name, setName] = useState("");
  const [kladrObjectTypes, setKladrObjectTypes] = useState<KladrObjectType[]>(
    []
  );
  const [districts, setDistricts] = useState<KladrObject[]>([]);
  const [district, setDistrict] = useState<KladrObject | string | null>();
  const [kladrTypeId, setKladrTypeId] = useState(0);
  const [shops, setShops] = useState<Shop[]>([]);
  const [shopId, setShopId] = useState(0);
  const [aroundTheClockShopId, setAroundTheClockShopId] = useState(0);
  const [aroundTheClockShops, setAroundTheClockShops] = useState<Shop[]>([]);

  const user: UserCookie | undefined = cookies.get("user");
  const myCityId = user?.city.id;
  const myCityName = user?.city.name;

  useEffect(() => {
    setKladrObjectTypes([]);
    setAroundTheClockShopId(0);
    setShopId(0);
    setDistrict("");
    if (!!data) {
      if (districtData) setDistrict(districtData.name);
      API.get(`/kladrObjects/types`)
        .then(({ data: types }) => {
          setKladrObjectTypes(types);
          let districtTypeId =
            types.find((el: any) => el.nameRu.toLowerCase() === "район")?.id ||
            2;
          if (myCityId && myCityName) {
            setCities([{ id: myCityId, name: myCityName, code: "" }]);
            setCityId(myCityId);
          }
          API.get(`/kladrObjects/${myCityId}/children?level=1`)
            .then(
              ({
                data: kladrObjectsWithChildrenData,
              }: {
                data: KladrObject[];
              }) => {
                setDistricts(
                  kladrObjectsWithChildrenData.filter(
                    (el) => el.kladrObjectTypeId === districtTypeId
                  )
                );
                setShops([]);
                API.get("/shops")
                  .then(({ data: shopsData }: { data: Shop[] }) => {
                    setShops(shopsData);
                    let currentShopsCounter = 0;
                    let currentShops: Shop[] = [];
                    shopsData.forEach((shop) =>
                      API.get(`/shops/${shop.id}`)
                        .then(({ data: shopData }: { data: Shop }) => {
                          currentShops = [...currentShops, shopData];
                          currentShopsCounter++;
                          if (currentShopsCounter === shopsData.length) {
                            const aroundTheClockShopsData = currentShops.filter(
                              (el) =>
                                el.isAroundTheClockDelivery ||
                                el.isAroundTheClockPickup
                            );
                            setAroundTheClockShops(aroundTheClockShopsData);
                            if (
                              data.aroundTheClockShopId &&
                              aroundTheClockShopsData.find(
                                (el) => el.id === data.aroundTheClockShopId
                              )
                            )
                              setAroundTheClockShopId(
                                data.aroundTheClockShopId
                              );
                          }
                        })
                        .catch(() =>
                          alertError(
                            alertContext,
                            "Ошибка получения точки продаж"
                          )
                        )
                    );
                  })
                  .catch(() =>
                    alertError(
                      alertContext,
                      "Ошибка получения списка точек продаж"
                    )
                  );
              }
            )
            .catch((error) => {
              alertError(alertContext, "Ошибка получения списка улиц");
            });
        })
        .catch((error) =>
          alertError(alertContext, "Ошибка получения списка типов улиц")
        );

      setAlerted(false);
      setBlur({
        cityId: false,
        name: false,
        kladrTypeId: false,
      });
      setName(data.name);
      if (data.shopId) setShopId(data.shopId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (!!data && kladrObjectTypes.length !== 0)
      setKladrTypeId(data.kladrObjectTypeId);
  }, [data, kladrObjectTypes]);

  const onSubmit = () => {
    if (!!data && kladrTypeId !== 0) {
      setProgress(true);
      const streetData: KladrObjectCreate = {
        name,
        kladrObjectTypeId: Number(kladrTypeId),
        shopId: shopId === 0 ? null : Number(shopId),
        aroundTheClockShopId:
          aroundTheClockShopId === 0 ? null : Number(aroundTheClockShopId),
      };
      if (district) {
        if (typeof district !== "string") streetData.parentId = district.id;
        else {
          const districtByName = districts.find(
            (el) => el.name.toLowerCase() === district.toLowerCase()
          );
          if (districtByName) streetData.parentId = districtByName.id;
          else {
            streetData.parent = {
              parentId: cityId,
              kladrObjectTypeId: kladrObjectTypes.find(
                (el) => el.nameRu === "Район"
              )!.id,
              name: district,
            };
          }
        }
      } else streetData.parentId = cityId;
      onSend(data.id, streetData)
        .then((data) => onClose())
        .catch((error) => console.log(error))
        .finally(() => setProgress(false));
    }
  };

  const isDistrictChanged = () =>
    !districtData && district === "" ? false : districtData?.name !== district;

  const safeToExit =
    ((!aroundTheClockShops.find((el) => el.id === data?.aroundTheClockShopId) &&
      aroundTheClockShopId === 0) ||
      data?.aroundTheClockShopId === +aroundTheClockShopId) &&
    ((!data?.shopId && shopId === 0) || data?.shopId === +shopId) &&
    data?.kladrObjectTypeId === kladrTypeId &&
    data?.name === name &&
    !isDistrictChanged();

  const readyToSubmit = !safeToExit && name !== "" && kladrTypeId !== 0;

  return (
    <Dialog
      disableBackdropClick
      onBackdropClick={() =>
        progress ? null : safeToExit ? onClose() : setAlerted(true)
      }
      open={!!data}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Редактирование улицы</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <InputLabel id="simple-select-cityid">Город *</InputLabel>
          <Select
            labelId="simple-select-city"
            id="simple-select-city"
            value={cityId}
            onChange={(e) => setCityId(Number(e.target.value))}
            disabled={cities.length === 0}
            onBlur={() => setBlur({ ...blur, cityId: true })}
          >
            {cities.map((c, idx) => (
              <MenuItem key={idx} value={c.id}>
                {c.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <Autocomplete
            id="combo-box-demo"
            onChange={(event, newValue: KladrObject | null | string) => {
              if (!newValue) setDistrict(null);
              else if (typeof newValue !== "string") setDistrict(newValue);
              else {
                const existingDistrict = districts.find(
                  (el) => el.name.toLowerCase() === newValue.toLowerCase()
                );
                setDistrict(existingDistrict ? existingDistrict : newValue);
              }
            }}
            defaultValue={district}
            clearOnBlur
            freeSolo
            options={districts.map((el) => el.name)}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            renderInput={(params) => (
              <TextField
                {...params}
                onChange={(e) => {
                  const existingDistrict = districts.find(
                    (el) =>
                      el.name.toLowerCase() === e.target.value.toLowerCase()
                  );
                  setDistrict(
                    existingDistrict ? existingDistrict : e.target.value
                  );
                }}
                label="Район"
              />
            )}
          />
        </FormControl>
        <TextField
          margin="dense"
          id="name"
          label="Наименование"
          value={name}
          onChange={(e) => setName(e.target.value)}
          type="text"
          fullWidth
          onBlur={() => setBlur({ ...blur, name: true })}
          error={!name && blur.name}
          helperText={!name && blur.name ? "Обязательное поле" : ""}
          required
        />
        <FormControl fullWidth error={kladrTypeId === 0 && blur.kladrTypeId}>
          <InputLabel id="simple-select-streettypeid">Тип *</InputLabel>
          <Select
            labelId="simple-select-streettype"
            id="simple-select-streettype"
            value={kladrTypeId}
            onChange={(e) => setKladrTypeId(Number(e.target.value))}
            onBlur={() => setBlur({ ...blur, kladrTypeId: true })}
          >
            {kladrObjectTypes
              .filter((el) => el.level === 10)
              .map((s, idx) => (
                <MenuItem key={idx} value={s.id}>
                  {s.nameRu}
                </MenuItem>
              ))}
          </Select>
          <FormHelperText hidden={!(kladrTypeId === 0 && blur.kladrTypeId)}>
            Обязательное поле
          </FormHelperText>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="simple-select-shopid">Привязанная точка</InputLabel>
          <Select
            labelId="simple-select-shop"
            id="simple-select-shop"
            value={shopId}
            style={{
              color:
                shopId !== 0 ? "rgba(0, 0, 0, 0.87)" : "rgba(0, 0, 0, 0.54)",
            }}
            onChange={(e: any) => setShopId(e.target.value)}
            disabled={shops.length === 0}
          >
            <MenuItem value={0}>Привязанная точка</MenuItem>
            {shops.map((s, idx) => (
              <MenuItem key={idx} value={s.id}>
                {s.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="simple-select-alldayshopid">
            Круглосуточная точка
          </InputLabel>
          <Select
            labelId="simple-select-shop"
            id="simple-select-shop"
            value={aroundTheClockShopId}
            style={{
              color:
                aroundTheClockShopId !== 0
                  ? "rgba(0, 0, 0, 0.87)"
                  : "rgba(0, 0, 0, 0.54)",
            }}
            onChange={(e: any) => setAroundTheClockShopId(e.target.value)}
            disabled={aroundTheClockShops.length === 0}
          >
            <MenuItem value={0}>Круглосуточная точка</MenuItem>
            {aroundTheClockShops.map((s, idx) => (
              <MenuItem key={idx} value={s.id}>
                {s.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        {alerted ? (
          <Alert
            severity="warning"
            action={
              <>
                <Button color="inherit" size="small" onClick={onClose}>
                  Да
                </Button>
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => setAlerted(false)}
                >
                  Нет
                </Button>
              </>
            }
          >
            Вы действительно хотите отменить все изменения и выйти? Введенные
            данные будут утеряны
          </Alert>
        ) : (
          <>
            <Button onClick={onClose} color="primary" disabled={progress}>
              Отмена
            </Button>
            <Button
              onClick={onSubmit}
              color="primary"
              type="submit"
              disabled={!readyToSubmit || progress}
            >
              Подтвердить
            </Button>
          </>
        )}
      </DialogActions>
      {progress && (
        <LinearProgress
          style={{ position: "absolute", bottom: "0px", width: "100%" }}
        />
      )}
    </Dialog>
  );
};

export default EditStreetForm;
