import React, { FC, useEffect, useState } from "react";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import { AlertProps } from "../../../_shared/types";

const useStyles = makeStyles({
  alert: {
    marginTop: 15,
    cursor: "pointer",
  },
});

const Toast: FC<{ alert: AlertProps; removeAlert: (id: number) => void }> = ({
  alert,
  removeAlert,
}) => {
  const classes = useStyles();
  const [callRemoveAlert, setCallRemoveAlert] = useState(false);

  useEffect(() => {
    if (callRemoveAlert) removeAlert(alert.id);
  }, [callRemoveAlert, alert.id, removeAlert]);

  useEffect(() => {
    const timeout = setTimeout(() => setCallRemoveAlert(true), 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Alert
      className={classes.alert}
      severity={alert.type}
      onClick={() => {
        removeAlert(alert.id);
      }}
    >
      {alert.text}
    </Alert>
  );
};

export default Toast;
