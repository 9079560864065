import { TableCell, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { FC, useEffect, useState } from "react";

const SyncTableRow: FC<any> = ({
  item,
  defaultProps,
  searchHandler,
  changeDatHandler,
  title,
  index,
}) => {
  const [searchString, setSearchString] = useState("1");
  const [cachedValue, setCachedValue] = useState<any>();

  useEffect(() => {
    setCachedValue(item.relation);
  }, []);

  return (
    <>
      <TableCell>{item?.syncName}</TableCell>
      <TableCell>
        <Autocomplete
          getOptionLabel={defaultProps.getOptionLabel}
          options={
            cachedValue && defaultProps.options
              ? [cachedValue, ...defaultProps.options]
              : defaultProps.options
              ? defaultProps.options
              : []
          }
          renderInput={(params) => (
            <TextField
              {...params}
              style={{ width: "450px" }}
              variant={"outlined"}
              size={"small"}
              label={title}
              onChange={(e) => {
                searchHandler
                  ? searchHandler(e.target.value)
                  : console.log(e.target.value);
              }}
            />
          )}
          // getOptionLabel={(option: any) => option?.fullName}
          // options={filteredGetData.filter((i:any)=>i.id !== filteredGetData.find((j: any) => j?.id).id)}
          value={item.relation ? item.relation : null}
          onChange={(e, v) => {
            setCachedValue(v);
            changeDatHandler(v, item, index);
          }}
        />
      </TableCell>
    </>
  );
};

export default React.memo(SyncTableRow);
