import React, { useState, useEffect, useContext, FC } from "react";
import { AlertContextType, AlertType } from "../../_shared/types";
import { alertError, alertSuccess, alertWarning } from "../../_shared/utils";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import API from "../../_shared/axios";
import { AlertContext } from "../_shared/ToastList";
import NewShopForm from "./NewShopForm";
import EditShopForm from "./EditShopForm";
import { makeStyles } from "@material-ui/styles";
import { Shop, WorkingTime } from "../_shared/types";
import { weekDays } from "./_shared/types";
import { ProgressBar } from "../_shared/ProgressBar";

const useStyles = makeStyles({
  page: {
    margin: 0,
  },
  content: {
    padding: 50,
    "& .MuiTableCell-body": {
      cursor: "pointer",
    },
  },
  clientTable: {
    "& th": {
      minWidth: 150,
    },
  },
});

const getDateWithoutZ = (date: WorkingTime) => {
  return weekDays.reduce(
    (base, { eng: day }) => ({
      ...base,
      [day]: {
        from: new Date(
          // @ts-ignore
          date[day].from.getTime() + 3 * 60 * 60 * 1000
        )
          .toISOString()
          .slice(0, -1),
        till: new Date(
          // @ts-ignore
          date[day].till.getTime() + 3 * 60 * 60 * 1000
        )
          .toISOString()
          .slice(0, -1),
      },
    }),
    {} as WorkingTime
  );
};

const processData = (data: Shop) => {
  let roundTT = weekDays.reduce(
    (base, { eng: day }) => ({
      ...base,
      [day]: {
        from: new Date(new Date().setHours(0, 0, 0, 0))
          .toISOString()
          .slice(0, -1),
        till: new Date(new Date().setHours(0, 0, 0, 0))
          .toISOString()
          .slice(0, -1),
      },
    }),
    {} as WorkingTime
  );

  return {
    ...data,
    deliveryWorking:
      !data.isDelivered || data.isAroundTheClockDelivery
        ? roundTT
        : getDateWithoutZ(data.deliveryWorking),
    pickupWorking:
      !data.isLocalPickup || data.isAroundTheClockPickup
        ? roundTT
        : // @ts-ignore
          getDateWithoutZ(data.pickupWorking),
  };
};

const ShopsPage: FC = () => {
  const [rows, setRows] = useState<Shop[]>([]);
  const [data, setData] = useState<Shop | undefined>();

  const [cityId, setCityId] = useState<number>();

  const alertContext = useContext<AlertContextType>(AlertContext);
  const classes = useStyles();

  const [progress, setProgress] = useState(false);

  useEffect(() => {
    API.get(`/auth/me`).then(({ data }) => {
      setCityId(data.city.id);
    });
  }, []);

  useEffect(() => {
    setProgress(true);
    API.get(`/shops`)
      .then(({ data }) => {
        setRows(data);
        setProgress(false);
      })
      .catch((error) => {
        alertError(alertContext, "Ошибка получения списка точек продаж");
        setProgress(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSend = (data: Shop) =>
    new Promise((resolve, reject) =>
      API.post("/shops", { ...processData(data), cityId })
        .then(({ data }) => {
          alertSuccess(alertContext, "Точка продаж добавлена");
          API.get(`/shops`)
            .then(({ data }) => {
              setRows(data);
              resolve(data);
            })
            .catch((error) => {
              alertError(alertContext, "Ошибка обновления списка точек продаж");
              reject(error);
            });
        })
        .catch((error) => {
          const { response } = error;
          if (response.status === 400) {
            if (response.data === "Address not exist")
              alertError(
                alertContext,
                "Не удалось получить координаты по введенному адресу"
              );
            else alertError(alertContext, "Ошибка добавления точки продаж");
          } else alertError(alertContext, "Ошибка добавления точки продаж");
          reject(error);
        })
    );

  const onEdit = (id: number, data: Shop) =>
    new Promise((resolve, reject) =>
      API.patch(`/shops/${id}`, { ...processData(data), cityId })
        .then(({ data }) => {
          alertSuccess(alertContext, "Успешное редактирование точки продаж");
          API.get(`/shops`)
            .then(({ data }) => {
              setRows(data);
              resolve(data);
            })
            .catch((error) => {
              alertError(alertContext, "Ошибка обновления списка точек продаж");
              reject(error);
            });
        })
        .catch((error) => {
          const { response } = error;
          if (response.status === 400) {
            if (response.data === "Address not exist")
              alertError(
                alertContext,
                "Не удалось получить координаты по введенному адресу"
              );
            else alertError(alertContext, "Ошибка редактирования точки продаж");
          } else alertError(alertContext, "Ошибка редактирования точки продаж");
          reject(error);
        })
    );

  return (
    <div className={classes.page}>
      <EditShopForm
        onSend={onEdit}
        data={data}
        onClose={() => setData(undefined)}
      />
      <div className={classes.content}>
        <h2>
          Точки продаж &nbsp;&nbsp; <NewShopForm onSend={onSend} />
        </h2>
        {progress ? (
          <ProgressBar />
        ) : (
          <TableContainer component={Paper}>
            <Table aria-label="simple table" className={classes.clientTable}>
              <TableHead>
                <TableRow>
                  <TableCell>Адрес</TableCell>
                  <TableCell align="left">Позиция</TableCell>
                  <TableCell align="left">Самовывоз</TableCell>
                  <TableCell align="left">Доставка</TableCell>
                  <TableCell align="left"> </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, key) => (
                  <TableRow key={key} hover onClick={() => setData(row)}>
                    <TableCell component="th" scope="row">
                      {row.name ? row.name : "-"}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.position ? row.position : "-"}
                    </TableCell>
                    <TableCell align="left">
                      {row.isLocalPickup ? "есть" : "нет"}
                    </TableCell>
                    <TableCell align="left">
                      {row.isDelivered ? "есть" : "нет"}
                    </TableCell>
                    <TableCell align="left">
                      <Button
                        style={{ maxWidth: "9rem", minWidth: "9rem" }}
                        variant="outlined"
                        color="primary"
                        onClick={(e) => {
                          e.stopPropagation();
                          API.get(`/orders/current`)
                            .then(({ data }) => {
                              if (
                                data.data.items
                                  .map((el: any) => el.shopId)
                                  .includes(row.id)
                              ) {
                                alertWarning(
                                  alertContext,
                                  "Невозможно удалить точку продаж, она используется в активных заказах"
                                );
                              } else
                                API.delete(`/shops/${row.id}`)
                                  .then(() =>
                                    API.get(`/shops`)
                                      .then(({ data }) => setRows(data))
                                      .catch((error) =>
                                        alertContext.showAlert({
                                          type: AlertType.ERROR,
                                          text:
                                            "Ошибка обновления списка точек продаж",
                                        })
                                      )
                                  )
                                  .catch((error) =>
                                    alertContext.showAlert({
                                      type: AlertType.ERROR,
                                      text: "Ошибка удаления точки продаж",
                                    })
                                  );
                            })
                            .catch((error) => {
                              alertError(
                                alertContext,
                                "Ошибка получения списка заказов"
                              );
                            });
                        }}
                      >
                        Удалить
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </div>
  );
};

export default ShopsPage;
