import React, { FC, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useHistory } from "react-router";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    borderRadius: 0,
  },
});
interface iTab {
  label: string;
  routeAll: string;
  routeSync: string;
  mainField: string;
  cField: string;
  postSync: string;
  idFieldName: string;
  idSyncFieldName: string;
}
const CenteredTabs: FC<{
  tabs: any;
  clickHandler: (tab: any) => void;
}> = ({ tabs, clickHandler }) => {
  const history = useHistory();
  const classes = useStyles();

  const [value, setValue] = useState(0);

  useEffect(() => {
    console.log(
      tabs.reduce((val: number | undefined, t: any, i: number) => {
        console.log(history.location.pathname.includes(t.route));
        console.log(val);
        return history.location.pathname.includes(t.route) ? i : undefined;
      }, undefined)
    );
    setValue(
      tabs.reduce(
        (val: number | undefined, t: any, i: number) =>
          val !== undefined
            ? val
            : history.location.pathname.includes(t.route)
            ? i
            : undefined,
        undefined
      )
    );
  }, [history]);

  return (
    <Paper className={classes.root}>
      <Tabs
        value={value}
        onChange={(event: any, newValue: any) => setValue(newValue)}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        {tabs.map((tab: any, key: any) => (
          <Tab
            label={tab.label}
            key={key}
            onClick={() => clickHandler(tab.route)}
          />
        ))}
      </Tabs>
    </Paper>
  );
};

export default CenteredTabs;
