import React, { FC, useEffect, useRef } from "react";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 0,
    maxWidth: 90,
    border: "1px solid #3f51b5",
    borderRadius: 5,
    height: 28,
    "& *": {
      border: "none",
    },
    "& button": {
      padding: 5,
    },
    "& .MuiInputBase-input": {
      padding: 0,
    },
    "& input::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& .MuiInputBase-root.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.87)",
    },
  },
  disabled: {
    "& svg": {
      color: "rgba(0, 0, 0, 0.54)",
    },
  },
  input: { "& input": { textAlign: "center" } },
});

const InputCounter: FC<{
  valueProp: number;
  maxQuantity?: number;
  setCounter: (value: number) => void;
  disabled?: boolean;
}> = ({ valueProp, setCounter, disabled, maxQuantity }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(valueProp);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setValue(valueProp);
  }, [valueProp]);

  useEffect(() => {
    if (ref.current) {
      const span = ref.current.children[0].children[1].children[0].children[0];
      if (span) {
        // @ts-ignore
        span.style.display = "none";
      }
    }
  }, [ref]);

  return (
    <div className={classes.container} onClick={(e) => e.stopPropagation()}>
      <IconButton
        className={disabled || value === 1 ? classes.disabled : ""}
        disabled={disabled || value === 1}
        onClick={(e) => {
          e.stopPropagation();
          if (value - 1 > 0 && value - 1 < 100 && !disabled)
            setCounter(value - 1);
        }}
      >
        <RemoveCircleOutlineIcon fontSize="small" color={"primary"} />
      </IconButton>
      <TextField
        ref={ref}
        className={classes.input}
        type="string"
        variant="outlined"
        size="small"
        disabled
        value={value}
      />
      <IconButton
        className={
          disabled || value === (maxQuantity !== undefined ? maxQuantity : 100)
            ? classes.disabled
            : ""
        }
        disabled={
          disabled || value === (maxQuantity !== undefined ? maxQuantity : 100)
        }
        onClick={(e) => {
          e.stopPropagation();
          if (value + 1 > 0 && value + 1 < 100 && !disabled)
            setCounter(value + 1);
        }}
      >
        <AddCircleOutlineIcon fontSize="small" color={"primary"} />
      </IconButton>
    </div>
  );
};

export default InputCounter;
